/** 
 * GSA Search Results
 * 
 * This component sends an AJAX request to the GSA provider, then renders the handlebars template with the results.
 * 
 */
;
(function($, document, frk, Handlebars) {
    
    var componentName = 'searchFilters', defaults = {};
	
	function Component(element, options) {
        
        console.log('invoked searchFilters constructor');
        
		this.element = element;
		this.options = $.extend({}, defaults, options);
		this._defaults = defaults;
		this._name = componentName;
		
		this.searchFilterAll = $(this.element).find('#search-filter-all');
        this.searchFilterPages = $(this.element).find('#search-filter-pages');
        this.searchFilterFunds = $(this.element).find('#search-filter-funds');
        this.searchFilterLiterature = $(this.element).find('#search-filter-literature');
        
		this.init();

	}

	Component.prototype = {
		init : function() {
			var _self = this;
            console.log('invoked searchFilters - init function');
            this.controller = frk.searchController;
        	
            var model = this.controller.getModel();
            if (model) {
            	this.filterUpdated(model);
            	this.updatePageCount(model);
            	this.updateFundCount(model);
            	this.updateLiteratureCount(model);
            }
            
            this.bindEvents();
		},
		
		// bind events to handlers
		bindEvents: function() {
			var _self = this;
                  
            // set filters on click
            $(this.element).find('a').each(function () {
                $(this).on('click', function () {
                	_self.controller.updateFilter($(this).attr('data-fti-filter-value'));
                });
            });
            
            // bind filterUpdated
            $(this.controller).on('filterUpdated', function(e, model){
            	_self.filterUpdated(model);
            });         
            
            // searchResults updated
            $(this.controller).on('pageSearchFinished', function(e, model){
            	_self.updatePageCount(model);
            });
            
            $(this.controller).on('literatureSearchFinished', function(e, model){
            	_self.updateLiteratureCount(model);
            });

            $(this.controller).on('fundSearchFinished', function(e, model){
            	_self.updateFundCount(model);
            });
		},
        
		filterUpdated: function (model) {
            var _self = this;
            
            $(this.element).find('.active-filter').each(function () {
                $(this).removeClass('active-filter');
                $(this).addClass('filter');
                $(this).attr('href', '#');
            });

            $(this.element).find('[data-fti-filter-value="' + model.filter + '"]').each(function () {
                $(this).removeClass('filter');
                $(this).addClass('active-filter');
                $(this).removeAttr('href');
                
                var attrs = {};
                                
                $(this).find('a').replaceWith(function () {
                    return $("<span />", attrs).append($(this).contents());
                });
            });
        },

        updatePageCount: function(model) {
            $(this.element).find('.pages-count').each(function () {
                $(this).text(model.pages.total);
            });
            this.updateTotal(model);
        },
        updateFundCount: function(model) {
            $(this.element).find('.funds-count').each(function () {
                $(this).text(model.funds.total);
            });   
            this.updateTotal(model);
        },
        updateLiteratureCount: function(model) {
            $(this.element).find('.literature-count').each(function () {
                $(this).text(model.literature.total);
            }); 
            this.updateTotal(model);
        },
        updateTotal: function(model) {
            $(this.element).find('.all-count').each(function () {
                $(this).text(model.pages.total + model.funds.total + model.literature.total);
            });       	
        }
        
	};
    
	$.fn[componentName] = function(options) {
		return this.each(function() {
			if (!$.data(this, 'component_' + componentName)) {
				$.data(this, 'component_' + componentName, new Component(this,
						options));
			}
		});
	};

})(jQuery, document, frk, Handlebars);