/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

/*
 * managing hide/display part of registration form
 */

$(document).ready(function () {
    //set equal height for panels on UM
    var biggestHeight = 0;
    //check each of them
    $('.equal-height').each(function () {
        //if the height of the current element is
        //bigger then the current biggestHeight value
        if ($(this).height() > biggestHeight) {
            //update the biggestHeight with the
            //height of the current elements
            biggestHeight = $(this).height();
        }
    });
    //when checking for biggestHeight is done set that
    //height to all the elements
    $('.equal-height').height(biggestHeight);


    //begin hide/show for UM registration page
    $("#usertypeselect").change(function () {
        $(this).find("option:selected").each(function () {
            //default select - no value
            if ($(this).attr("value") === "0") {
                //hide all usertype information
                $(".usertype").hide();
                $(".fa-options").hide();
                //show disabled button used for visual only
                $(".disabled-form-btn").show();
            }
            //.financialprofessional
            else if ($(this).attr("value") === "1") {

                //hide all usertypes that are not financial professional type
                $(".usertype").not(".financialprofessional").hide();
                //show financial professional radio options
                $(".financialprofessional").show();
                //additional FA actions..choose one of the following radio options
                $('input[type="radio"]').click(function () {
                    //hide show express number and form buttons
                    if ($(this).attr("value") === "express-number") {
                        $(".fa-options").not(".express-number").hide();
                        $(".express-number").show();
                        $(".disabled-form-btn").hide();
                    }
                    //hide show dealer number and form buttons
                    if ($(this).attr("value") === "dealer-number") {
                        $(".fa-options").not(".dealer-number").hide();
                        $(".dealer-number").show();
                        $(".disabled-form-btn").hide();
                    }
                    //hide show don't have an express number or dealer number and form buttons
                    if ($(this).attr("value") === "need-number") {
                        $(".fa-options").not(".need-number").hide();
                        $(".need-number").show();
                        $(".disabled-form-btn").hide();
                    }
                }); //end radio button options


            }
            //.individual-investor
            else if ($(this).attr("value") === "2") {
                //hide all usertypes that are not individual investor type
                $(".usertype").not(".individual-investor").hide();
                //show individual investor content
                $(".individual-investor").show();
                $(".fa-options").hide();
                //hide unused disabled button
                $(".disabled-form-btn").hide();
            }
            //.backOffice
            else if ($(this).attr("value") === "3") {
                //hide all usertypes that are not back office type
                $(".usertype").not(".backOffice").hide();
                //show back office content
                $(".backOffice").show();
                $(".fa-options").hide();
                //hide unused disabled button
                $(".disabled-form-btn").hide();
            }

        });
    }).change();


    //form validation
    var $messages = $('#form-validation');


    $(".editdetails").click(function () {
        var editField = $(this).text();
        var formId = $(this).attr("data-formId");
        var textEdit = $(this).attr("data-textEdit");
        var textCancel = $(this).attr("data-textCancel");

        $("#" + formId).toggle();
        $(this).text(editField === textEdit ? textCancel : textEdit);
    });


    //terms of use modal script
    $('.btn-accept').hide();
    //if user clicks on disabled button
    $(".btn-scroll-disabled").click(function () {
        $('.um-errorMsg').show();
    });
    $('.read-terms').scroll(function () {
        if ($(this).scrollTop() === $(this)[0].scrollHeight - $(this).height()) {
            //hide disabled button
            $(this).parent().parent().find('.btn-scroll-disabled').hide();
            //hide error message
            $(this).parent().parent().find('.um-errorMsg').hide();
            //show accept and continue button
            $(this).parent().parent().find('.btn-accept').show();
        }
    });
    $('.read-terms-page').scroll(function () {
        if ($(this).scrollTop() === $(this)[0].scrollHeight - $(this).height()) {
            //hide disabled button
            $(this).parent().parent().find('.btn-scroll-disabled').hide();
            //hide error message
            $(this).parent().parent().find('.um-errorMsg').hide();
            //show accept and continue button
            $(this).parent().parent().find('.btn-accept').show();
        }
    });


    $('.edelivery-optradio').change(function () {
        if ($('.us-mail-radio:checked').length === 2) {
            $('.finishReg').attr('data-target', '#terms-and-conditions');
            $('.um-breadcrumb, .terms-step').addClass('hidden');
        } else {
            $('.finishReg').attr('data-target', '#terms-and-conditions-edelivery');
            $('.um-breadcrumb, .terms-step').removeClass('hidden');
        }
    });


    $('.edelivery-cancel').click(function () {
        $('.us-mail-radio').prop('checked', true);
        $('.edelivery-optradio').change();
    });

    $('.edelivery-optradio').change();


    $('#terms-and-conditions-edelivery .btn-accept').click(function () {
        $('#terms-and-conditions-edelivery, #terms-and-conditions').removeClass('fade');
    });
    $('#terms-and-conditions .btn-accept, btn-default').click(function () {
        $('#terms-and-conditions-edelivery, #terms-and-conditions').addClass('fade');
    });
//show hide password
    $("#showPswrd").click(function () {
        if ($("#inputPassword").attr("type")==="password"){
            $("#inputPassword").attr("type","text");
            
        }else{ 
            $("#inputPassword").attr("type","password");
            
        }

    });
}); //end document ready


function showEditForm(nameField) {
    $("#" + nameField + "Form").toggle();
    var text = $(this).text();
    console.log(text);
    return false;
}
function hideEditForm(nameField) {
    $("#" + nameField + "Form").hide();
    return false;
}
