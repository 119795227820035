/**
 * Module for loading content though an ajax request
 */
;(function ( $, window, document, undefined ) {

	var thisModuleName = "common_ajax";
	
    $.fn[thisModuleName] = function ( ) {
        return this.each(function() {
			$.ajax({
				url: $(this).data("ajax-url") + '&t=' + Date.now(), // cache buster for IE
				
				success: $.proxy(function(data, status) {
					this.after(data);
					var newContent = this.next();
					this.remove();
					newContent.loader({
			            dataAttributes: ['ftiModule']
			        });
				}, $(this))
				
			});
		});
    };
})( jQuery, window, document);