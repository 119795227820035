/**
 * Cart Address
 */
;
(function ($, document, frk, Handlebars) {

    var componentName = 'cartdeliveryaddress',
            defaults = {
            };

    // The actual plugin constructor 
    function Component(element, options) {

        this.element = element;
        this.options = $.extend({}, defaults, options);

        this._defaults = defaults;
        this._name = componentName;

        this.serviceUrl = "/forms-literature/address";

        this.radioMe = $(this.element).find('#rad_me');
        this.radioClient = $(this.element).find('#rad_MyClient');

        this.form = $(this.element).find('#mailing-address');
        this.error = $(this.element).find('#display-error');
        this.clientmsg = $(this.element).find('#clientmsg');
        this.continueBtn = $(this.element).find('a[data-fti-element=continue-btn]');
        this.backBtn = $(this.element).find('a[data-fti-element=back-btn]');

        this.fname = $(this.element).find('#txt_FName');
        this.lname = $(this.element).find('#txt_LName');
        this.address1 = $(this.element).find('#txt_AddressLine1');
        this.address2 = $(this.element).find('#txt_AddressLine2');
        this.city = $(this.element).find('#txt_City');
        this.state = $(this.element).find('#sel_State');
        this.zip = $(this.element).find('#txt_Zip');
        this.phone = $(this.element).find('#txt_DayPhone');
        this.email = $(this.element).find('#txt_ConfirmationEmail');  

        this.addressData;
        this.myAddress;
        this.clientAddress;
        this.currentAddress = this.myAddress;
        this.deliverTo = 'me';
        this.clientNoUse = true;
        this.svrmsg = 0;

        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
            var _self = this;

            // Set up click handlers for the next/previous buttons
            $(this.continueBtn).on('click', this.nextStep.bind(this));
            $(this.backBtn).on('click', this.previousStep.bind(this));

            $("#display-error").hide();

            // Initialize form validation
            $.validate({
                validateOnBlur: true,
                form: '#mailing-address',
                modules: 'security',
                onError: function () {
                    _self.error.show();
                    if(!_self.clientNoUse || _self.deliverTo == 'me'){ 
                    $("#clientmsg").addClass("hidden");
                    }
                	$("#svrmsg"+_self.svrmsg).addClass("hidden");
                   	_self.svrmsg =0;
                }
            });
            $(this.phone).mask('999-999-9999');
            // Extend form validation to style whole input groups
            $(this.element).find('input, select').on('validation', function (e, valid) {
                if (valid) {
                    $(this).parents('.form-group').addClass('has-success').removeClass('has-error');
                } else {
                    $(this).parents('.form-group').addClass('has-error').removeClass('has-success');
                }
            });

            // Set up handlers for the delivery address options
            $(this.radioMe).on('click', function () {
            	 
                _self.saveAddresses();
                _self.currentAddress = _self.myAddress;
                _self.renderForm();
            	$("#svrmsg"+_self.svrmsg).addClass("hidden");
               	_self.svrmsg =0;
               
            });
            $(this.radioClient).on('click', function () {
            	
                _self.saveAddresses();
                _self.currentAddress = _self.clientAddress;
                _self.renderForm(); 
                if(_self.clientNoUse){ 
               	 _self.error.show();
               	$("#clientmsg").removeClass("hidden");
               	$("#svrmsg"+_self.svrmsg).addClass("hidden");
               	_self.svrmsg =0;
               }
               
            });

            // Remember the addresses in the session as they're updated so we can leave and return to the form later
            $(_self.form).find('input:text, select').on('blur', this.saveAddresses.bind(this));

            this.getAddresses();
           
        },
        getAddresses: function () {
            var dfd = $.Deferred();
            var _self = this;
            if (!_self.addressData) {
                $.ajax({
                    url: _self.serviceUrl,
                    dataType: 'json',
                    cache:false
                }).done(function (data) {
                    _self.addressData = data;
                    dfd.resolve(data);
                    _self.myAddress = _self.addressData["com.frk.ci.gws.orderlit.model.viewmodel.CustomerInformationVM"].originalCustomer;
                    if (_self.myAddress === undefined) {
                        _self.myAddress = {};
                    }
                    _self.clientAddress = _self.addressData["com.frk.ci.gws.orderlit.model.viewmodel.CustomerInformationVM"].deliveryClient;
                    if (_self.clientAddress === undefined) {
                        _self.clientAddress = {};
                    }
                    _self.deliverTo = _self.addressData["com.frk.ci.gws.orderlit.model.viewmodel.CustomerInformationVM"].deliverTo;
                    if (_self.deliverTo === 'me') {
                        _self.currentAddress = _self.myAddress;
                    } else {
                        _self.currentAddress = _self.clientAddress;
                    }
                    
                    _self.clientNoUse = _self.addressData["com.frk.ci.gws.orderlit.model.viewmodel.CustomerInformationVM"].clientNoItemInCart;
                    
                    _self.svrmsg = _self.addressData["com.frk.ci.gws.orderlit.model.viewmodel.CustomerInformationVM"].serverMessageIndicater;
                     
                    _self.renderForm();
                    
                    
                    if(_self.clientNoUse && _self.deliverTo!= 'me'){ 
                    	 _self.error.show();
                    	 $("#clientmsg").removeClass("hidden");
                    }
                    
                    if(_self.svrmsg != 0){  
                    	 $("#display-error").show();
                    	 $("#svrmsg"+_self.svrmsg).removeClass("hidden");
                    }
                    
                }).fail(function () {
                    dfd.reject('Unable to load data');
                });
            }
            else {
                dfd.resolve(this.addressData);
            }
            return dfd.promise();
        },
        renderForm: function () {
            var _self = this;
            $(this.form)[0].reset();
            $('#mailing-address div').removeClass('has-error').removeClass('has-success');
            $(this.error).hide();
            $(this.fname).val(_self.currentAddress.firstName);
            $(this.lname).val(_self.currentAddress.lastName);
            $(this.address1).val(_self.currentAddress.addressLine1);
            $(this.address2).val(_self.currentAddress.addressLine2);
            $(this.city).val(_self.currentAddress.city);
            $(this.state).val(_self.currentAddress.mailStateCode);
            $(this.zip).val(_self.currentAddress.zip);
            $(this.phone).val(_self.currentAddress.workPhone);
            $(this.email).val(_self.currentAddress.emailAddress);
            if (_self.deliverTo === 'me') {
                $(_self.radioMe).prop('checked', true);
            } else {
                $(_self.radioClient).prop('checked', true);
            }
        },
        rememberAddress: function () {
            var _self = this;
            _self.deliverTo = $('input[name=rad_delivery]:checked').val();
            _self.currentAddress.firstName = $(this.fname).val();
            _self.currentAddress.lastName = $(this.lname).val();
            _self.currentAddress.addressLine1 = $(this.address1).val();
            _self.currentAddress.addressLine2 = $(this.address2).val();
            _self.currentAddress.city = $(this.city).val();
            _self.currentAddress.mailStateCode = $(this.state).val();
            _self.currentAddress.zip = $(this.zip).val();
            _self.currentAddress.workPhone = $(this.phone).val();
            _self.currentAddress.emailAddress = $(this.email).val();
        },
        saveAddresses: function (e) {
            this.rememberAddress();
            var _self = this;
            var postData = {
                "delivery": this.myAddress, 
                "client": this.clientAddress, 
                "deliverTo": $('input[name=rad_delivery]:checked').val()
            };
//            console.log(JSON.stringify(postData));
            $.ajax({
                type: 'POST',
                url: _self.serviceUrl,
                data: postData
            }).done(function(e, data) {
//                console.log("post result: " + e + data);
            });
        },
        nextStep: function (event) {
        	event.preventDefault();
            event.stopPropagation();
            this.saveAddresses();            
            $(this.form).submit();
        },
        previousStep: function (event) {
        	event.preventDefault();
            event.stopPropagation();  
            this.saveAddresses();
        }
         
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName, new Component(this, options));
            }
        });
    };

    // markup hook
    $(document).find('[data-fti-component="cart-delivery-address"]').each(function () {
        $(this).cartdeliveryaddress();
    });

})(jQuery, document, frk, Handlebars);