(function ($) {

    // Create the defaults once 
    var componentName = 'rtModal',
   
    defaults = {
    };

    // The actual plugin constructor 
    function Component(element, options) {
      this.element = element;
      this.options = $.extend({}, defaults, options);
      this._defaults = defaults;
      this._name = componentName;

      this.init();
    }

    // Component methods 
    Component.prototype = {

      init: function () {

        this.loadModal();

      },

      // Load modal

      loadModal: function() {

        var self = this;

        $(this.element).on('click',function(e){

          console.log('show modal click');
          console.log(el);

          var el = $(this),
              doc = el.attr('href'),
              dest = $('#rt-modal'),
              wrapper = dest.find('.modal-content');

          // Loads modal specified in href

          wrapper.load(doc);

          // Show modal

          dest.modal('show');

          // When modal shows

          dest.on('shown.bs.modal', function() {

            // Set modal height

            wrapper.css('height',$(window).height()*0.9);

          });

          e.preventDefault();

        });

      }

    };

    $.fn[componentName] = function (options) {
      return this.each(function () {
        if (!$.data(this, 'component_' + componentName)) {
          $.data(this, 'component_' + componentName,
            new Component(this, options));
        }
      });
    };

    // ----------
    // markup hook
    $(document).ready(function () {
      $(document).find('.rt-popup-modal').each(function () {
        $(this).rtModal();
      });
    });
    
  })(jQuery);


