;
(function ($) {

    /**
     * Create the defaults once 
     */
    var componentName = 'historicalModal',
            defaults = {
                todayHighlight: true
            };

    /**
     * The actual plugin constructor 
     * @param {type} element
     * @param {type} options
     * @returns {historical-modal component}
     */
    function Component(element, options) {
        this.dateRegex = /date=([^&#]*)/;
        this.element = element;

        // The modal and content
        this.modal = $('#' + $(element).data('target-modal'));
        this.modalContent = this.modal.find('.modal-content');

        // The beginning of time
        this.dataFromYear = '1997';

        // Dates
        this.selectedDate = '';  // Date to show highlighted on calendar     
        this.requestedDate = ''; // Date request from DSL
        this.endDate = ''; // The last working day

        this.url = $(element).attr('href');

        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;

        this.init();
    }

    // Component methods 
    Component.prototype = {
        /**
         * Initialise
         * @returns {undefined}
         */
        init: function () {
            this.loadModal();
        },
        /**
         * Load modal
         * @returns {undefined}
         */
        loadModal: function () {

            var _self = this;

            console.log('Initialise Modal ON START UP: ' + $(_self.element).attr('href'));

            // When anchor is clicked launch modal with href
            $(_self.element).on('click', function (e) {


                console.log('Load Modal ON CLICK: ' + $(_self.element).attr('href'));

                // Clean modal
                //console.log('clean: ' + _self.modalContent.html());
                _self.modalContent.empty();
                //console.log('after clean: ' + _self.modalContent.html());

                // Show modal
                _self.modal.modal('show');

                _self.modal.on('shown.bs.modal', function () {

                    // Add Loader until we retrieve the JSON
                    _self.addLoader(_self.modalContent);

                    console.log('Load Modal ON SHOW: ' + $(_self.element).attr('href'));

                    // Loads modal specified in href
                    _self.modalContent.load($(_self.element).attr('href'), function () {

                        // Now Modal has loaded buildCalendar
                        _self.buildCalendar(_self.parseDate($(this).attr('href')));

                        _self.rebuildDynamicContent();

                        // Trigger refresh
                        _self.modal.trigger("refreshed.bs.modal");

                    });

                    _self.modal.off('shown.bs.modal');

                });



                e.preventDefault();

            });

        },
        /**
         * Refresh Modal
         * @param {type} date
         * @returns {undefined}
         */
        refreshModal: function (date) {

            var _self = this;

            var currentUrl = _self.modalContent.data('href');

            if (_self.parseDate(currentUrl) !== '') {
                // replace original date                
                currentUrl = currentUrl.replace(_self.dateRegex, 'date=' + date);
                //console.log('1: ' + currentUrl);
            } else {
                // append date
                currentUrl = currentUrl += '&date=' + date;
                //console.log('2: ' + currentUrl);
            }

            // We now have a new date lets not highlight today
            _self.options.todayHighlight = false;

            // Add Loader until we retrieve the JSON
            _self.addLoader(_self.modalContent);

            _self.modalContent.load(currentUrl,
                    function () {
                        _self.url = currentUrl;
                        _self.buildCalendar(date);

                        _self.rebuildDynamicContent();

                        // Now hide loader
                        _self.removeLoader(_self.modalContent);

                        // Trigger refresh
                        _self.modal.trigger("refreshed.bs.modal");

                    });

        },
        /**
         * Rebuild charts and caveats
         * @returns {undefined}
         */
        rebuildDynamicContent: function () {

            var _self = this;

            // Initialise charts
            $(_self.modal).find('[data-fti-module="ftichart"]').each(function () {                 // target each element with the "data-highcharts-chart" attribute
                //alert('on reload: ' + $(this).attr('id'));
                $(this)['ftichart']($(this).data());
            });

            // Initialise footnotes
            $(_self.modal).find('[data-fti-module="footnotes"]').each(function () {                 // target each element with the "data-highcharts-chart" attribute
                //alert('on reload: ' + $(this).attr('id'));
                $(this)['footnotes']($(this).data());
            });

            // Info icons
            $(_self.modal).find('.collapsable-header').each(function () {
                $(this).toggleCollapse();
            });

        },
        /**
         * Build Calendar
         * @param {type} date
         * @returns {undefined}
         */
        buildCalendar: function (date) {

            var _self = this;

            // Find calendar 
            var calendar = _self.modal.find('.date-picker');
            var dateFormat = calendar.data('date-format');

            // Buttons
            var showButton = _self.modal.find('.btn-show');
            var resetButton = _self.modal.find('.btn-reset');

            // Set modal height
            _self.modalContent.css('height', $(window).height() * 0.9);
            _self.modalContent.data('href', _self.url);

            // Get dates
            _self.selectedDate = _self.getSelectedDate(calendar);  // The date returned from DSL
            _self.requestedDate = _self.getRequestedDate(calendar); // The date we requested from DSL
            _self.endDate = _self.getEndDate(calendar); // The Date the calendar ends

            // Should disable reset
            if (date === '' ||_self.selectedDate === _self.endDate) {
                _self.disableResetButton();
            }
            // For month and year dropdowns
            if (dateFormat === 'mm/yyyy' && typeof _self.selectedDate !== 'undefined') {
                splitDate = _self.selectedDate.split('/');
                splitEndDate = _self.endDate.split('/');
                console.log(splitDate[0] + ":" + splitDate[2]);
                if (splitDate[0] === splitEndDate[0] && splitDate[2] === splitEndDate[2]) {
                    _self.disableResetButton();
                }
            }

            // Init datepicker and attach methods    
            var $J = jQuery.noConflict();
            if (calendar.length) {
                calendar.datepicker({
                    beforeShowDay: $J.datepicker.noWeekends,
                    changeMonth: true,
                    changeYear: true,
                    minDate: calendar.data('date-start-date'),
                    maxDate: _self.endDate,
                    showOtherMonths: true,
                    defaultDate: _self.selectedDate,
                    yearRange: _self.dataFromYear + ':' + _self.endDate.split('/')[2],
                    dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'], // will need improved for localisation
                    // Add listener for when the date has changed
                    onSelect: function (dateText, inst) {
                        var date = $(this).val();
                        _self.refreshModal(date);
                    },
                    onChangeMonthYear: function (year, month, widget) {
                            showButton.removeClass('btn-disabled');
                            showButton.removeAttr('disabled');
                    }
                });

                // If date has been passed lets set the date has been returned from DSL         
                if (typeof date !== 'undefined') {
                    calendar.datepicker('setDate', calendar.data('date-selected'));
                }

                // Add listener for reset button
                resetButton.on('click', function (e) {
                    if (!resetButton.hasClass('btn-disabled')) {
                        _self.refreshModal(_self.endDate);
                        e.preventDefault();
                    }
                });

                // Add listener for show button
                showButton.on('click', function (e) {
                    var month = $(".ui-datepicker-month :selected", calendar).val();
                    var year = $(".ui-datepicker-year :selected", calendar).val();
                    _self.refreshModal($.datepicker.formatDate('mm/dd/yy', new Date(year, month, 1)));
                    e.preventDefault();
                });

            }

            // Add listener for distributions drop down
            var yearDropdown = _self.modal.find('#historical-yr');
            yearDropdown.off().on('change', function () {
                var date = this.value;
                _self.refreshModal(date);
            });


        },
        /**
         * Disable reset button
         * @returns {undefined}
         */
        disableResetButton: function () {
            var _self = this;
            var resetButton = _self.modal.find('.btn-reset');
            resetButton.addClass('btn-disabled');
            resetButton.removeClass('btn-reset');
            resetButton.attr('disabled', 'disabled');
            resetButton.prop('disabled', true);
        },
        /**
         * Read End Date (previous working day)
         * @returns {Date}
         */
        getEndDate: function (calendar) {
            return calendar.data('date-end-date');
        },
        /**
         * Read Requested Date
         * @returns {Date}
         */
        getRequestedDate: function (calendar) {
            return calendar.data('date-requested');
        },
        /**
         * Read Selected (the date to highlight)
         * @returns {Date}
         */
        getSelectedDate: function (calendar) {
            return calendar.data('date-selected');
        },
        /**
         * Read date string from URL
         * @param {type} url
         * @returns {String}
         */
        parseDate: function (url) {
            var _self = this;
            if (_self.dateRegex.test(url)) {
                // replace original date         
                //console.log('regex: ' + url);
                return (url.match(_self.dateRegex)[1]).trim();
            } else
                return '';
        },
        /**
         * The display date maybe a different format 
         * than the internal calendar date
         * @param {type} calendar
         * @param {type} date
         */
        getDisplayDate: function (calendar, date) {

            var _self = this;
            var format = calendar.data('date-display-format');

            var dateArray = date.split('/');

            if (typeof format !== 'undefined') {
                if (format === 'mm/yyyy') {
                    calendar.find('input').val(dateArray[0] + '/' + dateArray[2]);
                }
            }
        },
        /**
         * Show Ajax loader
         * @param {type} element
         * @returns {undefined}
         */
        addLoader: function (element) {
            $(element).find('#loader').remove();
            $(element).append('<div id="loader" style="background-color:white;position:absolute;width:100%;height:100%;left:0; top:0;z-index:100;opacity:0.75;"><div class="loader">Loading...</div></div>');
        },
        /**
         * Remove Ajax loader
         * @param {type} element
         * @returns {undefined}
         */
        removeLoader: function (element) {
            window.setTimeout(function () {
                $(element).find('#loader').remove();
            }, 500);
        }

    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };

    // ----------
    // markup hook
    $(document).ready(function () {
        $(document).find('[data-target-modal*="historical"]').each(function () {

            //console.log("found");

            // Set up the modal for the anchor
            $(this).historicalModal();

        });
    });

})(jQuery);


