DataLoadingException = function DataLoadingException(msg) {
    this.message = msg;
    // Use V8's native method if available, otherwise fallback
    if ("captureStackTrace" in Error)
        Error.captureStackTrace(this, DataLoadingException);
    else {
        this.stack = (new Error()).stack;
//        alert(msg);
    }
};

DataLoadingException.prototype = Object.create(Error.prototype);
DataLoadingException.prototype.name = 'DataLoadingException';
DataLoadingException.prototype.constructor = DataLoadingException;
