;
(function ($) {

    // Create the defaults once 
    var componentName = 'toggleFavorite',
            defaults = {
            };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        
        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
        
         
           
            
         $('[data-toggle="tooltip"]').tooltip();         
         $('[data-toggle="popover"]').popover({html:true});
            
            $(this.element).on('click',function(e){
                var el = $(this);
                
                
              //  $(this).parents('.popover').popover('hide');
                var targeted = e.target;
                
                if($(e.target).hasClass('close'))
                {
                     el.find('i')
                    .toggleClass('ft-icon-favorite-active')
                    .toggleClass('ft-icon-favorite');
                    $(targeted).parents('.popover').popover('hide');
                  
                    
                }
                
                if($(e.target).hasClass('popover-link'))
                {
                    window.open($(targeted).attr('href'), '_self');
                }
                 if($(e.target).is('i'))
                {   
                     el.find('i')
                    .toggleClass('ft-icon-favorite-active')
                    .toggleClass('ft-icon-favorite');
                }
                
            
                
                e.preventDefault();
                
            });
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };
    
})(jQuery);


