/** 
 * Literature Search/Browse
 * 
 */
;
(function ($, document, frk, Handlebars) {

    var componentName = 'literatureSearch',
            defaults = {
    		resultClass: 'results-container',
            noResultFound: 'No results found for',
            assetClass: ['Multi Asset', 'Fixed Income', 'Equity', 'Alternatives']
            };
	var SERVICE_NAME = "com.frk.ci.gws.orderlit.model.viewmodel.LiteratureResultVM";
    var PAGE_SIZE = 20;
    var TOT_PAGE_SIZE = 0;
    var setSubTopicFlag;
    var setTopicFlag;
    var modalFilterOption;
    var productData;
    var newSearchFlag = false;

    // The plugin constructor 
    function Component(element, options) {
        this.element = element;

        this.options = $.extend({}, defaults, options);

        this._defaults = defaults;
        this._name = componentName;
        
        // the search box
        this.searchBoxInput = $(this.element).find('.search-box input');
       
       //Cancel Button in search box
        var searchBoxInput = this.searchBoxInput;
        var cancelButton = $(this.element).find('.ft-icon-cross');
        
        $('.clear-results').click(function () {
            $(searchBoxInput).val('');     
            $('.clear-results').addClass('hidden');
            $(searchBoxInput).css({"padding-right":"0px"});
        });
        
      
        // the search box
        this.searchBox = $("#keyword");
        this.modalSearchBox = $("#txt_Search-Fund");
        // the search button
        this.searchBtn = $("#litSearchBtn,#litSearchBtnMobile,#modalSearchBtn");
        //this.viewCart =$(document).find('#viewCart');
        this.resultList = $(this.element).find('.list-group');

        this.filtersInput = $("#filters");
        this.btnonoff=$("client-use-on-btn");
        this.sortOption = $("#sort");
        this.keyWordFlag = $("#keyWordFlag");
        this.error = $(this.element).find('#display-error');
		this.navbar = $(this.element).find('#navbar');
		this.selectedFilters = $(this.element).find('#selected-filters');
		this.resultpanel = $(this.element).find('#resultpanel');
		
        this.page = $("#page");
        this.pageSize = $("#pageSize");
        this.clearFiltersBtn = $("#clear-filters");
        this.sortResult = $(this.element).find('#sel_SortbyPublicationDate');        
        this.loadMoreBtn = $(this.element).find('.load-more-results');
        this.clientButton = $(".btn-group .btn-on-off");

        this.filters = {};

       //Changes has been added for the suggestion Box 
        
        this.form = $(this.element).find('form');

        // the fields to filter against
        this.fields = ['symbol.val', 'fundLegalName.val', 'fundNumber.val', 'cusip.val'];
        // Search object with query and array where result gets stored
        this.searchResult = {
            query: '',
            result: []
        };
        this.searching = false;

        this.searchIcon = $(this.element).find('.ft-icon-search');

        this.hovered = false;
        

        this.templates = {
            listItem: '<b class="results-header">Funds</b>' +
                    '<div class="list-unstyled list-group results-funds">' +
                    '{{#each result}}' +
					'<a href="/{{frkRole}}/resources/lit/search/{{fundNumber.val}}" class="list-group-item fund-name">{{symbol.val}} - {{fundLegalName.val}} - {{shareClass.val}}</a>' +
                    '{{else}}' +
                    '<span>' + this.options.noResultFound + ' {{query}}</span>' +
                    '{{/each}}' +
                    '</div>' +
                    '<b class="results-header">Search Suggestions</b>' +
                    '<div class="list-unstyled list-group results-other">' +
                    '<a href="/{{frkRole}}/resources/lit/search/{{query}}" class="list-group-item">Keyword <em>{{query}}</em> </a>' +
                    '<a href="/{{frkRole}}/resources/lit/search/topic/forms-and-applications/{{query}}" class="list-group-item"><em>{{query}}</em> in Forms &amp; Applications</a>' +
                    '<a href="/{{frkRole}}/resources/lit/search/type/fund-fact-sheets/{{query}}" class="list-group-item"><em>{{query}}</em> in Fund Fact Sheets</a>' +                    
                    '<a href="/{{frkRole}}/resources/lit/search/type/prospectuses-and-reports/{{query}}" class="list-group-item"><em>{{query}}</em> in Prospectuses &amp; Reports</a>' +
                    '</div>' +
                    '</div>'
        };

        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
        	var rolePattern =  /(investor|advisor)\/.*/;
            var role = rolePattern.exec(window.location.pathname);
            
            setSubTopicFlag = false;
       	 	setTopicFlag = false;
            
            console.log("Role :"+role[1]);
        	PAGE_SIZE = 20;
            TOT_PAGE_SIZE = 0;
            
            $('#nav-bar').addClass("hidden");
            $('#result-panel').addClass("hidden");
            $("#display-error").hide();
            
            // hiding all the panels on loading he page.
            
			$(this.navbar).hide();
			$(this.resultpanel).hide();
			$(this.selectedFilters).hide();
			$(this.error).hide();

            

            var _self = this;
            // get and set json url
            if ($(this.element).data('ftiJson')) {
                this.jsonURL = $(this.element).data('ftiJson');
            }
            else {
                throw 'No JSON Data specified';
            }
            
         // get and set json url
            if ($(this.element).data('ftiSuggestionJson')) {
                this.jsonSuggestionURL = $(this.element).data('ftiSuggestionJson');
            }
            else {
                throw 'No JSON Data specified';
            }
            
            
            
            // add empty list where result will be displayed
            $(this.searchBox).parent().find('.' + this.options.resultClass).bind('mouseover', function () {
            	console.log("inside mouse over");
                _self.hovered = true;
            }).bind('mouseout', function () {
            	console.log("inside mouse out");
                _self.hovered = false;
            });
            
            // set up listeners
            $(this.searchBox).on('keyup mouseup', function () {

            	if(newSearchFlag === true) {
           		 $("#browseBy").val('');
           		 $("#browseType").val('');
           		 newSearchFlag = false;
           	 }
                if ($("#keyword").val() !== '') {
                    $('.clear-results').removeClass('hidden');
                 $(this.searchBoxInput).css({"padding-right":"25px"});
            } else {
                    $('.clear-results').addClass('hidden');
                 $(this.searchBoxInput).css({"padding-right":"0px"});
            }
            	
                frk.delay(_self._searchSuggestionData.bind(_self), 500);
            });
            $(this.searchBox).on('focus', this._showResults.bind(this));
            $(this.searchBox).on('blur', this._hideResults.bind(this));
            
            // Get data on load to speed up user interaction
            _self._getSuggestionData();

            if ($(this.resultList).data('ftiTemplate')) {
                this.templateUrl = $(this.resultList).data('ftiTemplate');
                this.getTemplate();
            }
            else {
                throw 'No Search Result Template specified';
            }
			
            this.initView();

            // set up listener on search box - autocomplte
            //$(this.searchBox).on('keyup change', this.search.bind(this));
            // set up listener on search button

            $(this.searchBtn).on('click', this.setPageParam.bind(this));
            
            $(this.searchBox).on('keydown', function (event) {
                if (event.keyCode === 13) {
                	$('[data-fti-component="error-messages"]').remove();
                	
                    event.preventDefault();
                    event.stopPropagation();
                    $("#browseBy").val('');
              		$("#browseType").val('');
              		$("#litSearchBtn").focus();
              		
                    _self.clearFilters();
                }
            });
            $(this.clearFiltersBtn).on('click', this.clearFilters.bind(this));

            console.log("loggedIn user test:"+loggedIn);

            if(loggedIn === 'false')
            {
            	console.log("Inside investor");
            	$('#client-use').addClass("hidden");
            }
            
            
            // Client Use Only toggle button handlers
            $("label[for=client-use-on]").on('click', function(){
                _self.addFilter('client_use', 'Y', 'Yes', 0);
                _self.renderSelectedFilters();
                _self.search();
            });
            $("label[for=client-use-off]").on('click', function(){
            	_self.removeFilter('client_use');
            });

			$('#filter-details').addClass("hidden");

            // Check for an existing search term, e.g. from My Literature search bar.
            // or browse by type/topic
            if ($(this.searchBox).val() !== "") {
                this.search();
            }
            if ($("#browseBy").val() !== "") {
                $('#sel_SortbyPublicationDate').val('lit_title');
                $(_self.sortOption).val($("#sel_SortbyPublicationDate").val());
                
                if ($('#browseTopic').val() !== "") {
                    _self.addFilter('topic', $('#browseTopic').val(), $('#browseTopic').val(), -1);
                    if ($('#browseSubTopic').val() !== "") {
                        _self.addFilter('subtopic', $('#browseSubTopic').val(), $('#browseSubTopic').val(), -1);
                    }
                }
                
                if ($('#qsfilters').val() !== "") {
                    var fs = $('#qsfilters').val().split(",");
                    var i, pair, val;
                    for (i = 0; i < fs.length; i++) {
                        pair = fs[i];
                        val = pair.split(":");
                        _self.addFilter(val[0], val[1], val[1], 1);
                    }
                }                
                
                this.renderSelectedFilters();
                this.search();
            } else {
                $('#sel_SortbyPublicationDate').val('recentlyOrdered');
                $(_self.sortOption).val($("#sel_SortbyPublicationDate").val());
            }
            $(this.loadMoreBtn).on("click", this, this.loadMore);
            $(this.sortResult).on("change", this, this.performSorting); 
        },
        _initNumbers: function () {
            this._getSuggestionData().then(function (data) {
            	console.log("init Numbers check");
            });

        },
        _getSuggestionData: function () {
        	console.log("getSuggestionData method");
            var dfd = $.Deferred();
            var _self = this;

            this.searching = true;
            
            if(productData) {
            	this.data = productData;
            }
            
            // No data in memory, we need to load it
            if (!this.data) {
            	console.log("inside Data");
            	console.log("json URL:"+_self.jsonSuggestionURL);
                frk.load($(this.element).data('ftiUsConfig'), 'json').then(function (data) {
                    frk.UsConfig.setData(data, $(_self.element).data('ftiActor'));
                    $.ajax({
                        url: _self.jsonSuggestionURL,
                        dataType: 'json'
                    }).done(function (data) {
                        // save data
                        _self.data = data;
                        productData = data;
                        _self.searching = false;
                        dfd.resolve(data);
                    }).fail(function () {
                        _self.searching = false;
                        _self.removeLoader();
                        $(this.selectedFilters).hide();
           			    $(this.navbar).hide();
           			    $(this.resultpanel).hide();
           			    $(this.loadMoreBtn).hide();
           			    
           			    $('[data-fti-component="error-messages"]').remove();
           			    $('[data-fti-component="back-to-top"]').backToTop();
           			    frk.info('Info', 'Unable to load data. Please try again later.'); 
                 	   // throw new DataLoadingException('Unable to load data');
                 	    dfd.reject('Unable to load ' + 'json' + 'at' + _self.jsonSuggestionURL);
                    });
                });
            }
            // Already have data in memory
            else {
                _self.searching = false;
                dfd.resolve(this.data);
            }

            return dfd.promise();
        },
        _searchSuggestionData: function () {
            var _self = this;
            console.log("searchSuggestionData"+$(this.searchBoxInput).val()+"this searching"+this.searching);
            // search input has changed, check if there is something to search against
            if ($(this.searchBoxInput).val().length > 0 && !this.searching) {
            	console.log("Inside search box val > 0"+$(this.searchBoxInput).val());
                
                this._getSuggestionData().then(function (data) {
                    //clearup previous results
                    _self.searchResult.result = [];
                    // setup query string
                    _self.searchResult.query = $(_self.searchBoxInput).val();
                    // we need to filter data
                    _self.searchResult.result = frk.filter($(_self.searchBoxInput).val(), data.productsVO.ppsList, _self.fields);
                    // sort data
                    _self.searchResult.result= frk.orderBy.alphabetialOrder(_self.searchResult.result, 'shareClass.val');
                    _self.searchResult.result= frk.orderBy.alphabetialOrder(_self.searchResult.result, 'fundLegalName.val');

                    
                    // Compile and render template
                    var html = Handlebars.compile(_self.templates.listItem)(_self.searchResult);
                   
                    // display result
                    _self._clearSuggestionResults(); //clean first
                    $(_self.searchBoxInput).parent().find('.' + _self.options.resultClass).prepend(html);
                    // setup listeners on result list items
                    $('.' + _self.options.resultClass + ' li').on('click mouseover mouseleave', _self._handleSuggestionItem.bind(_self));
                    
                });
            }
            else {
                // we clear previous results if any
                _self._clearSuggestionResults();
            }
        },
        _handleSuggestionItem: function (event) {
            event.preventDefault();
            console.log("Inside handle item");
            if (event.type === 'mouseover') {
                $(event.target).addClass('active');
            }
            if (event.type === 'mouseleave') {
                $(event.target).removeClass('active');
            }
            if (event.type === 'click') {
            	console.log("Inside event type click");
                $(this.searchBoxInput).val($(event.target).attr('id'));
                $(this.searchBoxInput).attr('value', $(event.target).attr('id'));
                console.log("Fund ID as:"+$(event.target).attr('id'));
                this.hovered = false;
            }
        },
        _clearSuggestionResults: function () {
        	console.log("inside clearSuggestionResults Results");
            this.searchResult.result = [];
            
            console.log("$(this.searchBoxInput).parent() :"+$(this.searchBoxInput).parent());
            console.log("this.options.resultClass :"+this.options.resultClass);
            $(this.searchBoxInput).parent().find('.' + this.options.resultClass).empty();
            this.removeLoader();
        },
        _showResults: function () {
        	console.log("Inside show results");
            $(this.searchBoxInput).parent().find('.' + this.options.resultClass).show();
        },
        _hideResults: function () {
            if (!this.hovered) {
                $(this.searchBoxInput).parent().find('.' + this.options.resultClass).hide();
            }
        },
        initView: function () {
            var _self = this;
            // view values
            this.view = {
                searchResultCount: function () {
                    return _self.data ? _self.data["com.frk.ci.gws.orderlit.model.viewmodel.LiteratureResultVM"].totalResults : "0";
                },
                searchResultCountClientOnly: function () {
                    return _self.data ? _self.data["com.frk.ci.gws.orderlit.model.viewmodel.LiteratureResultVM"].filters.audienceFilter.facetValues[0].matches : "0";
                }
                // TODO: update filter values and counts.
            };
            // init view engine for data binding
            this.viewEngine = frk.viewEngine(this.view);
            this.viewEngine.init();
            this.viewEngine.updateAll();
        },
        getData: function () {
            var dfd = $.Deferred();
            var _self = this;

            $.ajax({
                url: this.jsonURL,
                data: $("#lit-search-form").serialize(),
                dataType: 'json'
            }).done(function (data) {
                _self.data = data;
                dfd.resolve(data);
            }).fail(function () {
            	$(_self.selectedFilters).hide();
   			    $(_self.navbar).hide();
   			    $(_self.resultpanel).hide();
   			    $(_self.loadMoreBtn).hide();
            	_self.removeLoader();
            	$('[data-fti-component="error-messages"]').remove();
                $('[data-fti-component="back-to-top"]').backToTop();
                frk.info('Info', 'Unable to load data. Please try again later.'); 
            	
//            	throw new DataLoadingException('Unable to load data');
            	
            	dfd.reject('Unable to load ' + 'json' + 'at' + _self.jsonURL);
            });

            return dfd.promise();
        },
        getTemplate: function () {
            var dfd = $.Deferred();
            var _self = this;
            if (!_self.template) {
                $.ajax({
                    url: _self.templateUrl,
                    dataType: 'html'
                }).done(function (data) {
                    _self.template = data;
                    dfd.resolve(data);
                }).fail(function () {
                    dfd.reject('Unable to load data');
                });
            }
            else {
                dfd.resolve(this.template);
            }
            return dfd.promise();
        },
        search: function () {
        	
            this.addLoader();
            var _self = this;
            
            // Horrible hack: $CONTEXT{} is reading the escaped & (%26) as an actual querystring separator 
            // and not passing through the escaped value as needed by the search service -->
            if ($("#browseType").val() === 'Prospectuses ') {
                $("#browseType").val('Prospectuses & Reports');
            }

            // search input has changed, check if there is something to search against
            if ($(_self.searchBox).val() !== "") {
                _self.setNavText();
                this.getData().then(function () {
                    _self.render(_self.template, _self.data);
                });
            } else if ($(_self.modalSearchBox).val() !== "") {
            	console.log("Inside modal search box");
            	$(_self.searchBox).val($(_self.modalSearchBox).val());
                this.getData().then(function () {
                    _self.render(_self.template, _self.data);
                });
                $('#myModal').modal('hide');
            } else if ($("#browseBy").val() !== "") {
                // or we're browsing by type/topic
                _self.setNavText();
                this.getData().then(function () {
                	_self.clearResults();
                    _self.render(_self.template, _self.data);
                });
            } else {
                // we clear previous results if any
                _self.clearResults();
            }
            
        },
        setNavText: function(reset) {
            var main = $('#browseType').val() + $('#browseTopic').val();
            var sub = $('#browseSubType').val() + $('#browseSubTopic').val();
            
            var breadcrumb = main;
            if (sub !== '') {
                breadcrumb += ' > ' + sub;
            }
            
            var heading = main;
            if (sub !== '') {
                heading = sub;
            }
            
//            if (reset !== '') {
//                $("[data-fti-component=page-title] h1").text('Literature Search');
//                $(".breadcrumb li.active").text('Literature Search');
//            } else 
            if ($("#browseBy").val() !== '') {
                $("[data-fti-component=page-title] h1").text(heading);
                $(".breadcrumb li.active").text(breadcrumb);
//            } else if (breadcrumb !== '') { 
//                $("[data-fti-component=page-title] h1").text('Literature Search');
//                $(".breadcrumb li.active").text('Literature Search "' + breadcrumb + '"'); 
            } else {
                $("[data-fti-component=page-title] h1").text('Literature Search');
                $(".breadcrumb li.active").text('Literature Search');
            }
        },
        render: function (template, data) {
            var _self = this;
            // compile template and get html 
            var html = Handlebars.compile(template)(data);
            // add html to content
            $(this.resultList).empty();
            $(this.resultList).append(html);
            // update view values
            this.viewEngine.updateAll();
			_self.hideFilter('ALL');
			
            // Update filter lists
            $.each(data["com.frk.ci.gws.orderlit.model.viewmodel.LiteratureResultVM"].filters, function (filterName, filter) {
                var filterList = $("ul#" + filter.code);
                var modalFilterList = $("ul#" + filter.code +"_modal");
                if ($(filterList)) {
                    $(filterList).find('li').remove();
                    $(modalFilterList).find('li').remove();
                    $.each(filter.facetValues, function (facetName, facet) {
                        $(filterList).append('<li class="divider" role="presentation"></li><li><a href="#" data-fti-element="filter-option" data-value="' + facet.code + ':' + facet.encoded + ':' + facet.value + ':' + facet.matches + '"><span>' + facet.value + '</span><span class="badge">' + facet.matches + '</span></a></li>');
                        $(modalFilterList).append('<li><a href="#" data-fti-element="filter-option-modal" data-value="' + facet.code + ':' + facet.encoded + ':' + facet.value + ':' + facet.matches + '">'+facet.value+'<span class="badge">'+facet.matches+'</span></a></li>');
                    });
                }
            });

            console.log("SettopicFlag:"+setTopicFlag);
            console.log("setSubTopicFlag:"+setSubTopicFlag);
            
            
            
			//if Topic or Subtopic not selected as filter then hide subtopic drop down
			if(setSubTopicFlag === false)
            {
            	_self.hideFilter('subtopic');
            }

            // Bind click handlers to filter options
            $(this.element).find('[data-fti-element="filter-option"]').on('click', function (e) {

            	console.log("click option abc: " + $(this).data("value"));
                
                var f = $(this).data("value").split(':');
                	
                if(f[0] === 'topic' || f[0] === 'subtopic')
                	setSubTopicFlag = true;
                
                _self.addFilter(f[0], f[1], f[2], f[3]);
                _self.renderSelectedFilters();
                _self.search();
            });
            
            
            // Bind click handlers to filter options
            $(this.element).find('[data-fti-element="filter-option-modal"]').on('click', function (e) {
                
            	$(this.element).find('[data-fti-element="filter-option-modal"]').focus();
            	console.log("click option modal: " + $(this).data("value"));
                modalFilterOption = $(this).data("value");
                console.log("modalFilterOption: " + modalFilterOption);
                setSubTopicFlag = false;
                var f = modalFilterOption.split(':');
                
                if(f[0] === 'topic') {
                	setTopicFlag = true;
                	setSubTopicFlag = true;
                }
                	
                if(f[0] === 'subtopic')
                	setSubTopicFlag = true;
                
                _self.addFilter(f[0], f[1], f[2], f[3]);
                _self.renderSelectedFilters();
                _self.search();
                
                $('#myModal').modal('hide');
            });
            
            
            
         // Bind click handlers to filter options
            $(this.element).find('[data-fti-element="filter-option-save"]').on('click', function (e) {
                console.log("click save change value: " + modalFilterOption);
                setSubTopicFlag = false;
                var f = modalFilterOption.split(':');
                
                if(f[0] === 'topic') {
                	setTopicFlag = true;
                	setSubTopicFlag = true;
                }
                	
                if(f[0] === 'subtopic')
                	setSubTopicFlag = true;
                
                _self.addFilter(f[0], f[1], f[2], f[3]);
                _self.renderSelectedFilters();
                _self.search();
            });
            

            this.quickViewLink = $(this.element).find('[data-fti-component="quick-view"]').each(function () {
                // alert("Before calling    $(this).quickView()");
                $(this).quickView(); // this creates an instance of quick view for each element
            });

            this.cartButton = $(this.element).find('[data-fti-component="button-cart"]').each(function () {
                $(this).buttonCart(); // this creates an instance of buttonCart for each element
            });

		   console.log("total result ...... :"+_self.data["com.frk.ci.gws.orderlit.model.viewmodel.LiteratureResultVM"].totalResults);
      	   console.log("service length ..... :"+this.data["com.frk.ci.gws.orderlit.model.viewmodel.LiteratureResultVM"].info.length);
      	   
		   var keyWordString = $(this.searchBox).val();
      	   var showText = "Your Search - [ "+keyWordString +" ] - did not match any documents";
      	   
      	   if(_self.data[SERVICE_NAME].totalResults === 0){
      			
				console.log("No results found for search criteria");
				$(this.navbar).hide();
				$(this.resultpanel).hide();
				$(this.loadMoreBtn).hide();
				$(this.selectedFilters).hide();
				$(this.searchBox).val("");
				$(this.error).show();
				$('#search-text').text(showText);
				
			} else {
				
				$(this.navbar).show();
				$(this.resultpanel).show();
				$(this.selectedFilters).show();
				$(this.error).hide();
        		 
			 }


      	    TOT_PAGE_SIZE = data[SERVICE_NAME].info.length;
      	   
	      	if (data[SERVICE_NAME].totalResults > data[SERVICE_NAME].info.length) {
	            $(this.loadMoreBtn).show();
	            $(this.clearFiltersLbln).show();
	            
	        } else {
	            $(this.loadMoreBtn).hide();
	            $(this.clearFiltersLbln).hide();
	        }
	      	
	      	newSearchFlag = true;
//	      	$(this.searchBox).val("");
	      	this.removeLoader();
        },
        renderSelectedFilters: function() {
            var _self = this;
            
            // Form filter input
            var filterInputVal = "";
            $("#selected-filters-area").find('a').remove();
            $("#selected-filters-mobile").find('a').remove();   
            $.each(_self.filters, function (key, object) {
                console.log("rendering filter: " + key + ": " + object.value);
                if (filterInputVal !== "") {
                    filterInputVal += ",";
                }
                filterInputVal += key + ':' + object.value;
                // Prevent client_use = 'Yes' from displaying as a selected filter.
                if (object.count !== 0) {
                    $("#selected-filters-area").append('<a href="#" data-fti-element="filter-remove" data-value="' + key + '">' + object.text + (object.count > 0 ? ' (' + object.count + ')' : '') + ' <span class="glyphicon glyphicon-remove"></span></a>');
                    $("#selected-filters-mobile").append('<a href="#" data-fti-element="filter-remove" data-value="' + key + '">' + object.text + (object.count > 0 ? ' (' + object.count + ')' : '') + ' <span class="glyphicon glyphicon-remove"></span></a>'); 
                }
            });
            $(_self.filtersInput).val(filterInputVal);
            
            // Bind click handlers to remove filter tags
            $(this.element).find('[data-fti-element="filter-remove"]').on('click', function (e) {
                console.log("remove filter option: " + $(this).data("value"));
                _self.removeFilter($(this).data("value"));
                
            });
        },
        addFilter: function(code, value, text, count) {
            console.log("adding filter: " + code + ',' + value + ',' + text + ',' + count);
            this.filters[code] = {code: code, value: value, text: text, count: count};
            console.log("filters now: " + JSON.stringify(this.filters));
//            this.renderSelectedFilters();
//          	this.search();
        },
        removeFilter: function(code) {
            delete this.filters[code];
			if(code === 'topic') {
				delete this.filters['subtopic'];
				delete this.filters['subtopic_modal'];
				setSubTopicFlag = false;
				setTopicFlag = false;
			}
            this.renderSelectedFilters();
          	this.search();
        },
        clearFilters: function() {
            this.filters = {};
            $('#browseTopic').val('');
            $('#browseSubTopic').val('');
            $('#qsfilters').val('');
            $('#sel_SortbyPublicationDate').val('recentlyOrdered');
            $(this.sortOption).val('');
            this.renderSelectedFilters();
            setSubTopicFlag = false;
            setTopicFlag = false;
            $(this.searchBoxInput).parent().find('.' + this.options.resultClass).hide();
			this.search();
        },
        setPageParam: function() {
        	console.log("Inside set page param .... ");
        	$('[data-fti-component="error-messages"]').remove();
        	 PAGE_SIZE = 20;
        	 TOT_PAGE_SIZE = 0;
        	 setSubTopicFlag = false;
        	 setTopicFlag = false;
        	 this.pageSize.val("");
       	     this.pageSize.val("");
    	     this.clearResults(); 
			 this.sortResult.val('recentlyOrdered');
			 $(this.selectedFilters).hide();
			 
			 $(this.navbar).hide();
			 $(this.resultpanel).hide();
			 $(this.loadMoreBtn).hide();
	         

	         if ($("#client-use-on").is(":checked")) {
	        	 
	        	
	        	 $('#client-use-on').removeAttr('checked');
	             $('#client-use-off').attr('client-use-off','checked');

	             console.log("Adding label for selected option");
	             
	             $("label[for=client-use-on]").removeClass("active");
	             $("label[for=client-use-off]").addClass("active");
	             
	         }
			
	        // this.clearFilters();
	         this.filters = {}; 
             this.renderSelectedFilters();
			 var keywordsearch;
			 
			 if($(this.searchBox).val() !== '')
			 {
				 keywordsearch = $(this.searchBox).val();
				 
			 } else {
				
				 keywordsearch = $(this.modalSearchBox).val();
			 }
			 
			 console.log("Keywordsearch valuse ===="+keywordsearch);
			 
	         var showText = "Your search - "+keywordsearch+" - matched an extensive number of results. Please enter more details";
	         
	         if($(this.searchBox).val().length <= 2 && $(this.modalSearchBox).val().length <= 2){
					console.log("Length less than two characters");
					$(this.searchBox).val("");
					$(this.error).show();
					$('#search-text').text(showText);
					this.removeLoader();
					
				} else {
					 console.log("Keyword is ok");
					 $(this.error).hide();
	        		 this.search();
					 $(this.navbar).show();
					 $(this.selectedFilters).show();
					 $(this.resultpanel).show();
				 }
        },
		hideFilter: function (pkCode) {
        	
			if(pkCode === 'ALL') {
        	$.each([ 'client_use','prd_cat','lit_content_type','lit_format','prd_type','fund_name','subtopic','topic'], function( index, value ) {
        		  console.log( index + ": " + value );
        		  var filterList = $("ul#" + value);
        		  var modalFilterList = $("ul#" + value +"_modal");
                  if ($(filterList)) {
                      $(filterList).find('li').remove();
                      $(modalFilterList).find('li').remove();
                  }
        		});
			} else {
				
				 var filterList = $("ul#" + pkCode);
				 var modalFilterList = $("ul#" + pkCode +"_modal");
                 if ($(filterList)) {
                     $(filterList).find('li').remove();
                     $(modalFilterList).find('li').remove();
                 }
			}
            
        },
        clearResults: function () {
           // $(this.searchBox).parent().find('.' + this.options.resultClass).remove();
            $(this.searchBox).parent().find('.' + this.options.resultClass).empty();
        },
        handleItem: function (event) {
            event.preventDefault();

            if (event.type === 'mouseover') {
                $(event.target).addClass('active');
            }
            if (event.type === 'mouseleave') {
                $(event.target).removeClass('active');
            }
            if (event.type === 'click') {
                $(this.searchBox).val($(event.target).html());
                $(this.searchBox).attr('value', $(event.target).attr('id'));
                this.clearResults();
            }
        },
		performSorting: function (e) {
			  
      	  var _self = e.data;
      	  var selectedOption = $("#sel_SortbyPublicationDate").val();
      	console.log("Inside perform sort option....>>>>>>.."+selectedOption);
      	  $(_self.sortOption).val(selectedOption);
      	  $(_self.keyWordFlag).val("false");
      	
      	  _self.search();
      	
      },
      loadMore: function (e) {
    	  var _self = e.data;
    	  console.log("Total Page Size " + TOT_PAGE_SIZE);
    	  console.log("To view  " + PAGE_SIZE);
    	  var pageSizeToView = TOT_PAGE_SIZE + PAGE_SIZE;
    	  
    	  console.log("no of pgs To view : " + pageSizeToView);
    	  
    	  $(_self.page).val("1");
    	  $(_self.pageSize).val(pageSizeToView);
    	
    	  _self.search();
    	
    	 
      },addLoader: function () {
    	  console.log("insied Add loader");
    	  $(this.element).find('#loader').remove();
          $(this.element).append('<div id="loader" style="background-color:white;position:absolute;width:100%;height:100%;top:0;z-index:100;opacity:0.75;"><div class="loader">Loading...</div></div>');
          
      },
      removeLoader: function () {
    	  console.log("insied remove loader");
    	  var _self = this;
          window.setTimeout(function () {
              $(_self.element).find('#loader').remove();
          }, 1000);
      }
      
    
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };

    // ----------
    // markup hook
    $(document).find('[data-fti-component="search-browse"]').each(function () {
        $(this).literatureSearch();
    });

})(jQuery, document, frk, Handlebars);
