(function ($, document, window) {
    // Create the defaults once 
    var componentName = 'taxInfo',
            defaults = {
            };

    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.resetLink = $(this.element).find('.reset-to-curr-year');
        this.selectTaxYear = $(this.element).find('#tax-year');
        this.init();
    }

    Component.prototype = {
        init: function () {
            var _self = this;

            if ($(":selected", _self.selectTaxYear).index() !== 0)
            {
                _self.resetLink.show();
            } else {
                _self.resetLink.hide();
            }

            this.resetLink.click(function () {
                $(_self.selectTaxYear).val($("option:first", _self.selectTaxYear).val());
                $(this).hide();
                $('#tax-info-form').submit();
            });
         
            this.selectTaxYear.change(function () {
                if ($(":selected", _self.selectTaxYear).index() !== 0)
                {
                   _self.resetLink.show();
                } else {
                    _self.resetLink.hide();
                }
                $('#tax-info-form').submit();
            });

        }
    }

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "component_" + componentName)) {
                $.data(this, "component_" + componentName,
                        new Component(this, options));
            }
        });
    };

    // self initialise  

        $(document).find('[data-fti-component="tax-information"]').each(function () {
            $(this).taxInfo();
        });


})(jQuery, document, window);