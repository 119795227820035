;
(function ($, document) {

    // Create the defaults once 
    var componentName = 'ppssChangeEnd',
            defaults = {
            };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;

        this.endVal = $(this.element).val();

        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
            $(this.element).on('change', this._handleChange.bind(this));
        },
        _handleChange: function () {
            if (this.endVal !== $(this.element).val()) {
                // update status
                this.endVal = $(this.element).val();

                $(this.element).trigger({
                    type: 'readyToRender',
                    endMonth: this._getBooleanVal(this.endVal)
                });
            }
        },
        _getBooleanVal: function (string) {
            return string === 'month' ? true : false;
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };

})(jQuery, document);
