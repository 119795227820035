;
(function ($, window, document, undefined) {

    // Create the defaults once
    var componentName = "fundStaticBar",
            defaults = {
            };

    // The actual plugin constructor
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.chart;
        this.data = [];
        this.init();
    }

    // Create methods associated with the plugin 
    Component.prototype = {
        init: function () {
            var _self = this;

            $(".btn-group :input", _self.element).change(function () {
                if ($(this).attr("id") === "sales-charges-on") {
                    _self.showSalesChargeValues();
                } else {
                    _self.hideSalesChargeValues();
                }
            });

            // compare overview-header-left and overview-header-right only for desktop
            var currentHeightL = 0;
            var currentHeightR = 0;

            $(window).load(function () {
                if (window.innerWidth > 1200) {
                    currentHeightL = $('#overview-header-left').outerHeight();
                    currentHeightR = $('#overview-header-right').outerHeight();

                    if (currentHeightL > currentHeightR) {
                        currentHeightR = currentHeightL;
                        $('#overview-header-right').css('height', currentHeightR);

                    }
                    if (currentHeightL < currentHeightR) {
                        currentHeightL = currentHeightR;
                        $('#overview-header-left').css('height', currentHeightL);

                    }
                }


            });

        },
        showSalesChargeValues: function () {
            var _self = this;

            $("[data-sales-chrg-on]", _self.element).each(function () {
                $(this).html($(this).attr("data-sales-chrg-on"));
            });

        },
        hideSalesChargeValues: function () {
            var _self = this;

            $("[data-sales-chrg-off]", _self.element).each(function () {
                $(this).html($(this).attr("data-sales-chrg-off"));
            });

        }


    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };

})(jQuery, window, document);
