/**
 * visual hint plugin
 * plugin tests if inner table width is bigger than surrounding div and displaying required hint
 * 
 *
 * author Piotr Orzechowski
 */
;
(function ($, document, frk) {

    var componentName = 'visualHint';

    function Component(element, options) {
        this._name = componentName;
        this.element = element;
        this.tableresponsive = $(".table-responsive");
        this.init();
    }

    function displayHint(target) {

        console.log("displayhint: " + $(target).attr('class'));
        if ($(target).find('.visualHintElement').length === 0)
        {
            $(target).wrapAll('<div class="table-responsive-wrapper" />');
            $('<div class="drop-shadow"></div>').insertBefore(target);
            $(target).prepend('<div class="visualHintElement"></div>');
        }
        $(target).on("click scroll", function () {
            $(target).find('.visualHintElement').fadeOut();
        });

        var hintelement = $(target).find('.visualHintElement');

        hintelement.fadeIn();
        hintelement.centerHint($('.table-responsive'));
    }

    function hideHint(target) {
        $(target).find('.visualHintElement').fadeOut();
    }


    function hasHorizontalScrollbar(target) {
        //alert("hasScrollbarH w1: "+$(target).attr('id')+" ->" +$(target).get(0).scrollWidth+"--"+$(target).get(0).clientWidth);      
        return $(target).get(0).scrollWidth > $(target).get(0).clientWidth;
    }

    Component.prototype = {
        init: function () {
            console.log('visualHint plugin initiated');
            var self = this;
            var windowWidth = $(window).width();

            $(window).scroll(function () {
                if ($('.visualHintElement').is(":visible")) {
                    $('.visualHintElement:visible').centerHint($('.table-responsive'));
                }

            });
            
            $(window).resize(function () {
                // Check window width has actually changed and it's not just iOS triggering a resize event on scroll  #USPI-5894
                if ($(window).width() !== windowWidth) {
                    // Update the window width for next time
                    windowWidth = $(window).width();
                    self.checkAndDisplay();
                    if ($('.visualHintElement').is(":visible")) {
                        $('.visualHintElement').centerHint($('.table-responsive'));
                    }
                }
            });
            
            $(document).on("shown.bs.collapse shown.bs.tab", ".panel-collapse, a[data-toggle='tab']", function (e) {
                self.checkAndDisplay();
            });
            
            $(function() {
                setTimeout(function() {
                    self.checkAndDisplay();
                }, 10);
            });

            this.tableresponsive.scroll(function () {
                $('.visualHintElement').fadeOut();
            });
        },
        checkAndDisplay: function () {
            var self = this;
            self.tableresponsive.each(function () {
                console.log("load: " + $(this).attr('id') + " visible:" + $(this).find('.visualHintElement').is(":visible"));
                if (hasHorizontalScrollbar(this))
                {
                    displayHint(this);
                } else
                {
                    hideHint(this);
                }
            });

        }


    };
    
    Component.prototype.showVisualHint = function () {
        console.log('Component prototype showVisualHint fired');
        this.checkAndDisplay();
    };

    $.fn.centerHint = function (target) {
        console.log("centruje:" + target.attr('class'));
        this.css("position", "absolute");
        var distanceFromTop = $(this).offset().top;
        var parentDistanceFromTop = $(this).parent().offset().top;
        var parentHeight = $(this).parent().height();
        var strollDistanceFromTop = $(window).scrollTop();
        var thisHeight = $(this).height();

        var hintPosition = -(parentDistanceFromTop - strollDistanceFromTop);
        var defaultPositionTop = 100;

        console.log("pozycja:" + parentHeight + " ," + parentDistanceFromTop + " ," + strollDistanceFromTop);

        this.css("top", Math.min(Math.max(defaultPositionTop, hintPosition + defaultPositionTop), parentHeight - thisHeight) + "px");
        this.css("left", Math.max(0, (($(window).width() - $(this).outerWidth()) / 2) +
                $(window).scrollLeft()) + "px");
        return this;
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName, new Component(this,
                        options));
            }
        });
    };


})(jQuery, document, frk);