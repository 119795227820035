;
(function ($, viewport) {

    // Create the defaults once 
    var componentName = 'fullscreenModal',
            defaults = {
            };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.el = $(this.element);
        this.modal = $(this.el);
        this.modalIsOpen = false;
        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
            this.modalStates();
            this.modalSetSize();
            this.modalResize();
        },
        // Calculate sizes

        modalSizes: function () {

            var _self = this,
                    modalHeight,
                    headerHeight = _self.modal.find('.modal-header').outerHeight(),
                    footerHeight = 10,
                    bodyHeight;

            if (viewport.is('>=md')) {
                modalHeight = $(window).height() * 0.9;
            }

            if (viewport.is('<=sm')) {
                modalHeight = $(window).height();
            }

            bodyHeight = modalHeight - (headerHeight + footerHeight);

            return {
                modalHeight: modalHeight,
                bodyHeight: bodyHeight,
                footerHeight: footerHeight,
                headerHeight: headerHeight
            };

        },
        // Retrieves sizeds and applied them to dom

        modalSetSize: function () {

            var _self = this,
                    sizes = _self.modalSizes();

            _self.modal.find('.modal-content').css('height', sizes.modalHeight);
            _self.modal.find('.modal-body').css('max-height', sizes.bodyHeight);

            console.log(sizes);

        },
        // Sets open and close states 

        modalStates: function () {

            var _self = this;

            // when modal opens
            _self.modal.on('shown.bs.modal', function () {
                _self.modalSetSize(); // header size not available until modal shown.
                _self.modalIsOpen = true; // Used by modalResize
            });
            
            // when modal content is dynamically refreshed
            _self.modal.on('refreshed.bs.modal', function () {
                _self.modalSetSize(); // header size not available until modal shown.
                _self.modalIsOpen = true; // Used by modalResize
            });

            // when modal closes
            _self.modal.on('hidden.bs.modal', function () {
                _self.modalIsOpen = false;
            });

        },
        // Resizes modal window if it's open.

        modalResize: function () {

            var _self = this;
           
            $(window).on("resize", function () {
                if (_self.modalIsOpen === true) {
                    _self.modalSetSize();
                }
            });

        }

    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };

    // ----------
    // markup hook

    $(document).ready(function () {
        $(document).find('.modal-fullscreen').each(function () {
            $(this).fullscreenModal();
        });
    });

})(jQuery, ResponsiveBootstrapToolkit);


