;
(function ($) {

    // Create the defaults once 
    var componentName = 'findAFundMenu',
            defaults = {
            };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;

        this.menu = $(this.element);

        this.init();
    }

    // Component methods 
    Component.prototype = {
      init: function () {
        this.menuClickHandler();
      },
      menuClickHandler: function() {
        
        this.menu.on("click", ".second-level-item > a", function (e) {

          var el = $(e.target),
              parentDropdown = el.parent('.dropdown'),
              openMenu = parentDropdown.find('.collapse.in');

          parentDropdown.toggleClass("open");
          el.next('.collapse').collapse('toggle');

          e.preventDefault();
          e.stopPropagation();

        });
 
      }

    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                  new Component(this, options));
            } 
        }); 
    }; 
    
})(jQuery);
  

