;
(function ($, document, frk) {

    // Create the defaults once 
    var componentName = 'commentaryPerspectives',
            defaults = {
            };

    // The actual plugin constructor 
    function Component(element, options, Handlebars) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this._filterInfo = [];
        this.commentaryWrapper =  $(this.element).find('#commentaryWrapper');
//        this.listType = 'grid';
        this.filterCheck = false;
        this.tableTemplate = '<table class="table table-striped perspective-list-view tablesorter" data-table-sorter>'
                + ' <thead>'
                + '     <tr class="header-sortable">'
                + '         <th scope="col" class="title"><a href="javascript:void(0);" role="button" title>Title</a></th>'
                + '         <th scope="col" class="speaker"  ><a href="javascript:void(0);" role="button" title >Speaker</a></th>'
                + '         <th scope="col" class="category" ><a href="javascript:void(0);" role="button" title >Category</a></th>'
                + '         <th scope="col" class="date-published " aria-sorted="descending"><a href="javascript:void(0);" role="button" title>Date</a></th>'
                + '     </tr>'
                + ' </thead>'
                + ' <tbody>'
                + '     {{#each funds}}'
                + '     <tr commentary-type="{{cardcommentaryType}}" class="{{cardClassVal}}" >'
                + '         <th class="title" scope="row"><a href="{{cardLink}}">{{cardTitle}}</a></th>'
                + '         <td class="speaker">{{cardSpeaker}}</td>'
                + '         <td class="category">{{cardTopic}}</td>'
                + '         <td class="date-published">{{cardDate}}</td>'
                + '     </tr>'
                + '     {{/each}}'
                + ' </tbody>'
                + '</table>';


        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
            
            var _self = this;
            this.extractData();
            this.createTable();
            x = 10;
            this._initDropdowns();

            $(this.element).find('[commentary-type]').addClass('hidden');
            $(this.element).find('[commentary-type]:lt(' + x + ')').removeClass('hidden');

            $(this.element).find('.load-more').click(function () {
                x = x + 10;


                var filters = $.map($("select.form-control").toArray(), function (e) {
                    return $(e).val().replace(/\s+/g, '-').replace('.', '-');
                }).join(".");
                _self.commentaryWrapper.find("div.all").addClass('hidden');
                _self.commentaryWrapper.find("div." + filters + ':lt(' + x + ')').removeClass('hidden');
                _self.commentaryWrapper.find("tr.all").addClass('hidden');
                _self.commentaryWrapper.find("tr." + filters + ':lt(' + x + ')').removeClass('hidden');

                
                if (x > $('[commentary-type]').length) {
                $(".load-more").hide();
                }
            });

            var usedNames = {};
            $(this.element).find("#fund-ojective option").each(function () {
                if (usedNames[this.value]) {
                    $(this).remove();
                } else {
                    usedNames[this.value] = this.text;
                }
            });

            $("select.form-control").change(function () {
                var selectedSize = 0;
                _self.filterCheck = true;
                $(".load-more").show();
                var filters = $.map($("select.form-control").toArray(), function (e) {
                    return $(e).val().replace(/\s+/g, '-').replace('.', '-');
                }).join(".");
                
                _self.commentaryWrapper.find('[commentary-type="card"]').addClass('hidden');
                _self.commentaryWrapper.find("div.all").addClass('hidden');
                _self.commentaryWrapper.find("div." + filters + ':lt(' + x + ')').removeClass('hidden');
                _self.commentaryWrapper.find("tr.all").addClass('hidden');
                _self.commentaryWrapper.find("tr." + filters + ':lt(' + x + ')').removeClass('hidden');
                _self.commentaryWrapper.find("div." + filters).removeClass('col-sm-8').addClass('col-sm-4');
                _self.commentaryWrapper.find("div." + filters).removeClass('col-md-6').addClass('col-md-3');



                if (filters === 'all.all') {
                    x = 10;
                    $('[commentary-type]').addClass('hidden');
                    $('[commentary-type]:lt(' + x + ')').removeClass('hidden');
                    $('div[commentary-type]').eq(4).removeClass(' col-sm-4').addClass('col-sm-8').removeClass('col-md-3').addClass('col-md-6');
                    $('div[commentary-type]').eq(8).removeClass(' col-sm-4').addClass('col-sm-8').removeClass('col-md-3').addClass('col-md-6');

                    selectedSize = $('[commentary-type]').size();
                }

                if (x >= selectedSize) {
                    $(".load-more").hide();
                }
            });
            
            var params = frk.getQueryStringParams();

            if (params.length > 0) {
                var matches = [];
                // check is any key matches filter ID
                for (var param in params) {
                    for (var filter in this._filterInfo) {
                        if (params[param].key === this._filterInfo[filter].filterId) {
                            // Yes
                            var m = params[param];
                            m.type = this._filterInfo[filter].type;
                            matches.push(m);
                        }
                    }
                }
                // apply filters
                for (var match in matches) {               
                    // if button
                    if ($('#' + matches[match].key + ' option[value="' + matches[match].value + '"]').length > 0) {
                        $('#' + matches[match].key).val(matches[match].value).trigger('change');
                    }
                }
            }
            
            // Sort the speakers dropdown list in alphabetical order
            $("select#fund-ojective").append($("select#fund-ojective option:gt(0)").sort(function (a, b) {
                return a.text == b.text ? 0 : a.text < b.text ? -1 : 1;
            }));
        },
        extractData: function () {

            var cardsContainer = $("#commentaryWrapper", this.element),
                    cards = cardsContainer.find('.card:not(".card-commentary")'),
                cardsJson = {'funds': []};

            $.fn.ignore = function (sel) {
                return this.clone().find(sel || ">*").remove().end();
            };

            cards.each(function (index) {

                var card = $(this),
                        title = card.find('h3 a').text(),
                        titleLink = card.find('h3 a').attr('href'),
                    topic = card.find('.topic').ignore('time').text(),
                        date = card.find('time.date').text(),
                    cardSpeaker = card.parent().attr('speaker-name'),
                        commentaryType = card.parent().attr('commentary-type'),
                        classVal = card.parent().attr('class').replace('col-sm-4', '').replace('col-md-3', '').replace('col-sm-8', '').replace('col-md-6', ''),
                        fund = {
                            cardTitle: title,
                            cardLink: titleLink,
                            cardTopic: topic,
                            cardDate: date,
                        cardSpeaker: cardSpeaker,
                            cardcommentaryType: commentaryType,
                            cardClassVal: classVal
                        };

                cardsJson.funds.push(fund);
            });
            this.cardsData = JSON.parse(JSON.stringify(cardsJson));
        },
        createTable: function () {
            var _self = this;

            var template = Handlebars.compile(this.tableTemplate),
                    tableHtml = template(this.cardsData),
                    gridHtml = $('.perspectives', _self.element).html();


            $('.nav-tabs>li .ft-icon-grid-view').css({"color": "#b2b2b2"});
            $('.nav-tabs>li .ft-icon-list-view').css({"color": "#005598"});

            $('.ft-icon-list-view').on('click', function (e) {
               $(_self.commentaryWrapper).addClass('panel').removeClass('row');
                $('.nav-tabs>li .ft-icon-list-view').css({"color": "#b2b2b2"});
                $('.nav-tabs>li .ft-icon-grid-view').css({"color": "#005598"});

    
               
               
                x = 10;
                $(_self.element).find('.perspectives').empty();
                $(_self.element).find('.perspectives').html(tableHtml).tableSorterExt();

                $(".load-more").show();
                if (_self.filterCheck === true) {
                $("select.form-control").change();
                } else {
                    $(_self.element).find('[commentary-type]').addClass('hidden');
                    $(_self.element).find('[commentary-type]:lt(' + x + ')').removeClass('hidden');
                }

  
                e.preventDefault();
                return false;
            });

            $('.ft-icon-grid-view').on('click', function (e) {
                 $(_self.commentaryWrapper).addClass('row').removeClass('panel');
                $('.nav-tabs>li .ft-icon-grid-view').css({"color": "#b2b2b2"});
                $('.nav-tabs>li .ft-icon-list-view').css({"color": "#005598"});
          
    
                
                x = 10;
                $(_self.element).find('.perspectives').empty();
                $(_self.element).find('.perspectives').html(gridHtml);

                $(".load-more").show();
                if (_self.filterCheck === true) {
                $("select.form-control").change();
                } else {
                    $(_self.element).find('[commentary-type]').addClass('hidden');
                    $(_self.element).find('[commentary-type]:lt(' + x + ')').removeClass('hidden');
                }

                e.preventDefault();
                return false;
            });
        },
        _initDropdowns: function () {
            var _self = this;
            $(this.element).find('[data-select]').each(function () {
                // add element in filter info
                _self._filterInfo.push({filterId: $(this).attr('id'), type: 'dropdown', filter: 'ff_' + frk.toCamelCase($(this).data('select'))});
            });
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };

})(jQuery, document, frk);