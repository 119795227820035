/**
 * PPSSPanel Class
 * 
 */
;
(function (frk) {

    function PPSSPanel(sorter) {
        // Object of filters
        this._filters = {};
        // Object of sortable 
        this._sortable = {};
        this._activeSorting = '';
        this._isUpToDate = false;
        this._sorter = sorter;
    }

    /**
     * Big update function. Applies ALL filters
     * @returns {undefined}
     */
    PPSSPanel.prototype.update = function (force) {
        if (!this._isUpToDate || force) {
            // we need create the filtered data using only the filters that are active
            // and don't have to search on filtered data
            var keys = [], queries = [];
            for (var f in this._filters) {
                if (this._filters[f].isActive && !this._filters[f].searchOnFilteredData) {
                    keys.push(this._filters[f].fields[0]);
                    queries.push(this._filters[f].query);
                }
            }
            // filter
            frk._filteredData = frk._displayedData = frk.multiFilter(keys,
                    frk._data,
                    queries);

            // now we need to apply all the filters needed to search on
            // filtered data. We are only using the active filters
            for (f in this._filters) {
                if (this._filters[f].isActive && this._filters[f].searchOnFilteredData) {

                    frk._displayedData = frk.filter(this._filters[f].query,
                            frk._displayedData,
                            this._filters[f].fields);
                }
            }

            // add sales charge status
            frk._displayedData.isSalesChargesOn = frk._data.isSalesChargesOn;

            // Sort data?
            if (this._activeSorting) {
                frk._displayedData = this.sort.apply(this, this._activeSorting);
            }
            if (this._sorter.getActiveSorting()) {
                frk._displayedData = this._sorter.sort(this._sorter.getActiveSorting());
            }
            // Filter data?
            if (this._filters['searchbox'].isActive) {
                this.filter('searchbox');
            }
        }
        // will update next time function is called, unless set to true by other tabs
        this._isUpToDate = false;

        return frk._displayedData;
    };

    PPSSPanel.prototype.getData = function () {
        return frk._data;
    };

    /**
     * 
     * @returns {Number}
     */
    PPSSPanel.prototype.getNbFundDisplayed = function () {
        return frk._displayedData.length;
    };

    /**
     * 
     * @param {string} id
     * @param {Object} filterConf {query: string, fields: array[string]}
     * @param {boolean} searchOnFilteredData Optional.
     * @returns {array}
     */
    PPSSPanel.prototype.addFilter = function (id, filterConf, searchOnFilteredData) {
        // ID check
        if (!id || !(typeof id === 'string') || id.length < 1) {
            throw 'Filter id incorrect (needs to be a string with at least 1 character)';
        }
        // check if filter already exists
        if (this._filters[id]) {
            throw 'Filter already exists';
        }
        // config check
        if (!filterConf || !filterConf.fields) {
            throw 'Filter configuration error';
        }
        if (!(typeof filterConf.query === 'string')) {
            throw 'Query needs to be a string (it can be empty)';
        }
        if (!Array.isArray(filterConf.fields)) {
            throw 'Fields needs to be an array';
        }
        // search on filtered data false by default
        filterConf.searchOnFilteredData = searchOnFilteredData || false;
        // active by default unless query is empty
        filterConf.isActive = (filterConf.query.length > 0) ? true : false;
        // filter is valid, add it to list
        this._filters[id] = filterConf;
    };

    /**
     * 
     * @param {string} id
     * @param {string} query
     * @returns {undefined}
     */
    PPSSPanel.prototype.modifyFilterQuery = function (id, query) {
        // check if filter exists
        if (!this._filters[id]) {
            throw 'Filter does not exist';
        }
        // check query
        if (!(typeof query === 'string')) {
            throw 'Query needs to be a string (it can be empty)';
        }
        // update state
        this._filters[id].isActive = (query.length < 1) ? false : true;
        // update query
        this._filters[id].query = query;

        return this._filters[id];
    };

    /**
     * 
     * @param {string} id
     * @returns {object} deleted filter
     */
    PPSSPanel.prototype.removeFilter = function (id) {
        return delete this._filters[id];
    };

    /**
     * 
     * @param {string} id
     * @returns {Array}
     */
    PPSSPanel.prototype.filter = function (id) {
        if (!this._filters[id]) {
            throw 'Filter undefined';
        }
        frk._displayedData = frk.filter(this._filters[id].query,
                this._filters[id].searchOnFilteredData ? frk._filteredData : frk._data,
                this._filters[id].fields);

        return frk._displayedData;
    };

    /**
     * 
     * @param {type} id
     * @param {boolean} reverse Optional.
     * @returns {Array}
     */
    PPSSPanel.prototype.sort = function (id, reverse) {
        this._sorter.sort(id, reverse);

        if (this._filters['searchbox'].isActive) {
            return this.filter('searchbox');
        } else {
            return frk._filteredData;
        }
    };

    PPSSPanel.prototype.setUpToDate = function () {
        var _self = this;
        return {
            toFalse: function () {
                _self._isUpToDate = false;
            },
            toTrue: function () {
                _self._isUpToDate = true;
            }
        };
    };

    // Expose class on Global object
    frk.PPSSPanel = PPSSPanel;

})(frk);

