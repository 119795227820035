/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

(function ($, document) {
    // Create the defaults once 
    var componentName = 'leavingSite',
            defaults = {
                leavingSiteText: 'Clicking OK below will take you to an independent site. Information and services provided on this independent site are not reviewed by, guaranteed by, or endorsed by Franklin Templeton or its affiliates. Please keep in mind that this independent site\'s terms and conditions, privacy and security policies, or other legal information may be different from those of Franklin Templeton\'s site. Franklin Templeton is not liable for any direct or indirect technical or system issues, consequences, or damages arising from your use of this independent website.',
                leavingSiteOk: 'OK',
                leavingSiteCancel: 'Cancel'
            };

    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.btnaExternalLink = $(this.element).find('a.external-link');
        this.init();
    }

    Component.prototype = {
        init: function () {
            _self = this;
            this.btnaExternalLink.click(function (e) {
                e.preventDefault();
                var leavingSiteInclude = '<div class="modal fade" id="leaving-site" tabindex="-1" role="dialog" aria-hidden="true"><div class="modal-dialog"><div class="modal-content"><div class="modal-body"><p>' + _self.options.leavingSiteText + '</p></div><div class="modal-footer"><a href="#" id="ext-link-ok-button" class="btn btn-primary pull-left">' + _self.options.leavingSiteOk + '</a><a href="#" class="btn btn-default" data-dismiss="modal">' + _self.options.leavingSiteCancel + '</a></div></div></div></div>';
                $("body").append(leavingSiteInclude);
                $('#leaving-site').modal('show');
                $('#ext-link-ok-button').attr('href', this.href);
                $('#ext-link-ok-button').attr('target', '_blank');

                $('#ext-link-ok-button').click(function () {
                    $('#leaving-site').modal('hide');
                });

            });
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "component_" + componentName)) {
                $.data(this, "component_" + componentName,
                        new Component(this, options));
            }
        });
    };

    // self initialise  
    $(document).ready(function () {
        $(document).find('[data-fti-area="main-area"], [id="page"]').each(function () {
            $(this).leavingSite();
        });
    });

})(jQuery, document);