/**
 * Ftichart component plugin
 * This is used to render custom highcharts based on their associated table
 */

// Set up custom Highcharts theme
frk = window.frk || {};
frk.chartPalette =
        ['#005598', '#4e9d2d', '#00a0dc', '#333333', '#ed7700', '#8a1811',
            '#163758', '#81bb00', '#5fc0eb', '#767676', '#f7bb00', '#cb0000',
            '#bad583', '#a5d7f5', '#a7a7a7', '#f5db8b',
            '#d3e3b0', '#DBEFFB', '#c9c9c9',
            '#e8e8e8'
        ];

Highcharts.setOptions({
    colors: frk.chartPalette
});

// We need the ability to call resize highcharts on page tabs are selected
$(function () {
    // fix dimensions of chart that was in a hidden element
    $(document).on('shown.bs.tab', 'a[data-toggle="tab"]', function (e) {       // on tab selection event
        $("[data-highcharts-chart]:visible").each(function () {                 // target each element with the "data-highcharts-chart" attribute
            var chart = $(this).highcharts();                                   // target the chart itself
            chart.reflow();                                                     // reflow that chart
        });
    });
});

;
(function ($, window, document, undefined) {

    // Create the defaults once
    var componentName = "ftichart",
            defaults = {
                align: "left",
                colorByPoint: true,
                enableXAxis: true,
                endColumn: null,
                endRow: null,
                firstColumnAsNames: false,
                firstRowAsNames: false,
                groupPadding: 0.2,
                labelsX: null,
                labelsY: null,
                minChartHeight: 300,
                pointPadding: 0.1,
                pointWidth: null,
                prefix: "",
                shadowTable:false,
                skipColumns: [],
                startColumn: 0,
                startRow: 0,
                suffix: "",
                switchRowsAndColumns: false,
                theme: "",
                type: "bar",
                xAxisEnabled: true,
                yAxisEnabled: true,
                yAxisTitle: 'Values'
            };

    // The actual plugin constructor
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.init();
    }

    // Create methods associated with the plugin
    Component.prototype = {
        init: function () {

            var _self = this;

            // Find first instance of chart and table
            var chart = $(_self.element).find('.chart').first();
            var table = $(_self.element).find('.table').first();

            // set chart div the same height as the table
            if (table.height() > _self.options.minChartHeight) {
                chart.height(table.height());
            }

            var tableId = table.attr('id');

            if (_self.options.shadowTable) {
                // Table Id must be passed to highcharts
                tableId = table.attr('id') + '-shadow';

                // Creat shadow table with only rows we should render
                var shadowTable = table.clone();
                shadowTable.attr('id', tableId);

                // Remove all rows to be hidden in the chart
                $(".chart-hide", shadowTable).remove();
                shadowTable.hide();
                
                // Add table
                $(_self.element).append(shadowTable);
            }

            var nonZeroCount = 0;
            
            var chartPallete = frk.chartPalette;
            if(!_self.options.colorByPoint){
                chartPallete = ['#A6D7F7'];
            }

            // Use HighCharts plugin
            chart.highcharts({
                exporting: {
                    enabled: false
                },
                chart: {
                    backgroundColor: null,
                    type: _self.options.type
                },
                colors: chartPallete,
                data: {
                    endColumn: _self.options.endColumn,
                    endRow: _self.options.endRow,
                    parsed: function (columns) {

                        // Remove column(s) from chart
                        if (_self.options.skipColumns.length !== 0) {
                            for (var i = 0; i < _self.options.skipColumns.length; ++i) {
                                var col = _self.options.skipColumns[i];
                                columns.splice(col, 1);
                            }
                        }
                        // Loop through both dimensions
                        for (var i = 0; i < columns.length; ++i) {
                            var entry = columns[i];
                            for (var j = 0; j < entry.length; ++j) {

                                var regexp = /^[\+\-]?(?:\.?\d+|\d+\.?\d+)$/;
                                var emdash = /\u2013|\u2014/;

                                var withoutBold = _self.strip(entry[j]).replace(_self.options.prefix, "").replace(_self.options.suffix, "");

                                if (regexp.test(withoutBold)) {
                                    entry[j] = parseFloat(withoutBold);
                                    nonZeroCount++;
                                    //console.log('Number: ' + entry[j]);
                                } else if (emdash.test(withoutBold)) {
                                    entry[j] = 0;
                                    //console.log('Number 2: ' + entry[j]);
                                } else {
                                    entry[j] = withoutBold;
                                }
                            }
                        }
                        return columns;
                    },
                    startColumn: _self.options.startColumn,
                    startRow: _self.options.startRow,
                    switchRowsAndColumns: _self.options.switchRowsAndColumns,
                    table: tableId
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    // Used for top holdings charts
                    bar: {
                        colorByPoint: _self.options.colorByPoint,
                        dataLabels: {
                            enabled: true,
                            align: 'left',
                            inside: true,
                            style: {
                                'color': '#000',
                                'fontWeight': 'normal',
                                'textShadow': 'false',
                                'text-transform': 'uppercase',
                                'opacity': '1'
                            },
                            formatter: function () {
                                return this.point.name;
                            }
                        }
                    },
                    // Used for multiple breakdown charts
                    pie: {
                        allowPointSelect: true,
                        dataLabels: {
                            enabled: false
                        },
                        // TODO: Define size (currently copying the size of the place holder img for consistency)
                        size: 304,
                        innerSize: "50%"
                    },
                    series: {
                        pointPadding: _self.options.pointPadding,
                        pointWidth: _self.options.pointWidth,
                        groupPadding: _self.options.groupPadding
                    }
                },
                title: {
                    text: ""
                },
                tooltip: {
                    formatter: function () {

                        // Hide tool tip for 0 values
                        if (this.point.y === 0) {
                            return false;
                        }

                        if (_self.options.type === "column") {
                            // Performance chart labels
                            return this.point.series.name + '<br/>' + _self.options.prefix + Highcharts.numberFormat(this.point.y, 2) + _self.options.suffix;
                        } else {
                            // Other charts
                            return this.point.name + '<br/>' + _self.options.prefix + Highcharts.numberFormat(this.point.y, 2) + _self.options.suffix;
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    labels: {
                        useHTML: true,
                        enabled: _self.options.enableXAxis,
                        align: _self.options.align,
                        x: _self.options.labelsX,
                        y: _self.options.labelsY
                    },
                    title: {
                        enabled: _self.options.xAxisEnabled
                    }
                },
                yAxis: {
                    labels: {
                        useHTML: true,
                        format: _self.options.prefix + '{value:.1f}' + _self.options.suffix
                    },
                    title: {
                        enabled: _self.options.yAxisEnabled,
                        text: _self.options.yAxisTitle
                    }
                }

            });

            // If no figures exist hide chart
            if (nonZeroCount === 0) {
                chart.hide();
            }

        },
        strip: function (html) {
            var temp = $("<div/>");
            temp.html(html);
            temp.find('small').remove();
            return temp.text();
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "component_" + componentName)) {
                $.data(this, "component_" + componentName, new Component(this, options));
            } else {
                //console.log($.data(this, "component_" + componentName));
                $.data(this, "component_" + componentName);
            }
        });
    };

})(jQuery, window, document);
