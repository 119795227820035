/** 
 * PPSS VIP component 
 * 
 * Works with the following markup:
 * 
 * div fti-component=ppss fti-jon=url-to.json
 *  div fti-template=url-to-html.template
 *      
 * This component will load the data and the 
 * template with an ajax request.
 * 
 * After compile the html, the result is rendered and 
 * appended to the tab content
 */
;
(function ($, document) {

    // Create the defaults once 
    var componentName = 'ppssMoney',
            defaults = {
                type: 'money',
                hasFundFilter: true,
                hasFavorite: false,
                hasSalesCharges: false,
                hasEndChange: true,
                hasTabs: false,
                dataEntryPoint: 'productsVO.ppsList',
                searchFields: ['symbol.val', 'fundLegalName.val', 'fundShortName.val', 'fundNumber.val', 'cusip.val', 'cusip.alt']
            };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;

        // Call init method
        this.init();
    }

    // Component methods  
    Component.prototype = {
        init: function () {
            var _self = this;
            // load config
            frk.load($(this.element).data('ftiUsConfig'), 'json').then(function (usData) {
                frk.UsConfig.setData(usData, $(_self.element).data('ftiActor'));
                // Call PPSS Plugin with the right options
                $(_self.element).ppssPlugin(_self.options);
            });
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };

})(jQuery, document);
