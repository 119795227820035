;
(function ($, window, document, undefined) {

// Create the default component options
    var componentName = "gpsdocuments",
            defaults = {
            };
    // The actual component constructor
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.init();
    }

    Component.prototype = {
        init: function () {
            function downloadJSAtOnload() {
                var element = document.createElement("script");
                element.src = "http://rcosvr3037:8000/javascripts/api/viz_v1.js";
                document.body.appendChild(element);
            }
            if (window.addEventListener)
                window.addEventListener("load", downloadJSAtOnload, false);
            else if (window.attachEvent)
                window.attachEvent("onload", downloadJSAtOnload);
            else
                window.onload = downloadJSAtOnload;
            
            var _self = this;
            //for dropdown
            $("#document-category").change(function () {
                var documentCategory = $("#document-category").val();
                var nameCategory = $("#document-category  option:selected").text();
                if (documentCategory !== "all") {
                    $('.gps-documents').hide();
                    $('.gps-documents[data-document-category=' + documentCategory + ']').show();
                    $('.category-name').empty();
                    $('.category-name').append(nameCategory);
                } else {
                    $('.gps-documents').show();
                }
            });
            $("#tableau-category").change(function () {
                var documentCategory = $("#tableau-category").val();
                var nameCategory = $("#tableau-category option:selected").text();
                if (documentCategory !== "all") {
                    $('.gps-tableau-list').hide();
                    $('.gps-tableau-list[data-tableau-list-category=' + documentCategory + ']').show();
                    $('.category-name').empty();
                    $('.category-name').append(nameCategory);
                } else {
                    $('.gps-tableau-list').show();
                }
            });
            //archive
            $("#document-dates").change(function () {
                var documentDate = $("#document-dates").val();
                if (documentDate !== "all") {
                    //alert(documentDate);
                    $('.gps-documents').hide();
                    $('.gps-documents[data-document-date=' + documentDate + ']').show();
                } else {
                    $('.gps-documents').show();
                }
            });
            $("#date-selector").change(function () {
                var documentDate = $("#date-selector").val();
                if (documentDate !== "all") {
                    $('.tableau-list').addClass('hidden');
                    $('.tableau-list[data-fti-documentdate=' + documentDate + ']').removeClass('hidden');
                }

            });
            //for links
            $("a[data-fti-categorylink]").click(function () {

                var documentCategoryLinkName = $(this).data('fti-categorylink');
                $(".category-links a").removeClass("bolded");
                $(this).addClass("bolded");
                var documentCategoryLinkName1 = $(this).text();
                $(".category-name").empty();
                $(".category-name").append(documentCategoryLinkName1);
                $(".gps-documents").hide();
                $(".gps-documents[data-document-category='" + documentCategoryLinkName + "']").show();
            });
        }

    };
    console.log("Registering" + componentName);
    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "component_" + componentName)) {
                $.data(this, "component_" + componentName,
                        new Component(this, options));
            } else {
                $.data(this, "component_" + componentName).init();
            }
        });
    };
})(jQuery, window, document);
