;
(function ($) {

    /**
     * Create the defaults once 
     */
    var componentName = 'hypoToolModal',
            defaults = {
            };

    /**
     * The actual plugin constructor 
     * @param {type} element
     * @param {type} options
     * @returns {historical-modal component}
     */
    function Component(element, options) {
        this.element = element;
        // The modal and content
        this.modal = $('#' + $(element).data('target-modal'));
        this.templateUrl = $(this.modal).data('fti-template');
        this.modalContent = this.modal.find('.modal-content');
        this.url = $(element).attr('href');
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.init();
    }

    // Component methods 
    Component.prototype = {
        /**
         * Initialise
         * @returns {undefined}
         */
        init: function () {
            this.getTemplate();
        },
        /**
         * Load modal
         * @returns {undefined}
         */
        loadModal: function () {
//            alert("Here");

            var _self = this;

            // When anchor is clicked launch modal with href
            $(_self.element).on('click', function (e) {

                // Show modal
                _self.modal.modal('show');
                _self.modalContent.empty();

                _self.modal.on('shown.bs.modal', function () {

                    // Add Loader until we retrieve the JSON
                    _self.addLoader(_self.modalContent);
                    if ($(_self.element).attr('data-json').indexOf('javascript') > -1) {
                    	$(_self.modalContent).html('<h3>You do not have access to the Hypo Tool</h3>' );
                        // Now hide loader
                        _self.removeLoader(_self.modalContent);
                    }
                    else {
                        frk.load($(_self.element).attr('data-json'), 'jsonp').then(
                                function (data) {
                                    // Apply Business Rules
                                    _self.applyBusinessRules(data);
                                    // Apply Templates
                                    var html = Handlebars.compile(_self.template)(data);
                                    // Set Modal HTML
                                    $(_self.modalContent).html(html);
                                    // Now hide loader
                                    _self.removeLoader(_self.modalContent);
                                }
                        );                    	
                    }

                    // Trigger refresh
                    _self.modal.trigger("refreshed.bs.modal");
                    _self.modal.off('shown.bs.modal');

                });

                e.preventDefault();

            });

        },
        /**
         * Apply any data rules on the returned
         * @param {type} data
         * @returns {undefined}
         */
        applyBusinessRules: function (data) {
            var _self = this;
            
            if(data.PresentationTitle === null && data.PresentationName !== null){
                if(isNaN(data.PresentationName)){
                    data.PresentationTitle = data.PresentationName;
                }
            }
            
            _self.formatNumbers(data);
            _self.formatTimeFrame(data);
        },
        /**
         * Loads the Handlebars template
         */
        getTemplate: function () {
            var _self = this,
                    dfd = $.Deferred();
            if (typeof _self.template === 'undefined') {
                frk.load(this.templateUrl, 'html').then(
                        function (data) {
                            console.log('loaded');
                            // save data
                            _self.template = data;
                            _self.loadModal();
                            dfd.resolve(data);
                        },
                        function (message) {
                            dfd.reject(message);
                        }
                );
            }
            else {
                _self.loadModal();
                dfd.resolve(_self.template);
            }
            return dfd.promise();
        },
        /**
         * Format Numbers 
         * 
         * @returns {undefined}
         */
        formatNumbers: function (data) {
            var _self = this;

            if (data.InitialInvestment !== null) {
                data.InitialInvestment = _self.addCommas(Math.round(data.InitialInvestment));
            }

            if (data.EndValue !== null) {
                data.EndValue = _self.addCommas(Math.round(data.EndValue));
            }

            if (data.Investments !== null) {
                for (i = 0; i < data.Investments.length; i++) {
                    var allocation = data.Investments[i].Allocation.toFixed(2);
                    if (allocation !== "XX") {
                        data.Investments[i].Allocation = (allocation * 100);
                    }
                }
            }

        },
        /**
         * Calculates and formats the time frame node based 
         * on start date and end date
         * @param {type} data
         * @returns {undefined}
         */
        formatTimeFrame: function (data) {
            var timeFrame = data.Timeframe;
            var timeFrameStr = "None-None";

            if (timeFrame !== null) {
                // Start - Copied logic from existing site
                var startDate = eval(timeFrame.StartDate.replace(/\/Date\((-?\d+)\)\//gi, "new Date($1)"));
                startDate = (startDate.getUTCMonth() + 1) + "/" + startDate.getUTCDate() + "/" + startDate.getUTCFullYear();

                var endDate = eval(timeFrame.EndDate.replace(/\/Date\((-?\d+)\)\//gi, "new Date($1)"));
                endDate = (endDate.getUTCMonth() + 1) + "/" + endDate.getUTCDate() + "/" + endDate.getUTCFullYear();

                timeFrameStr = startDate + "-" + endDate;
                data.Timeframe.StartDate = timeFrameStr;
            }

        },
        /**
         * Method copied from existing site - to format returned values
         */
        addCommas: function (nStr) {
            nStr += '';
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            return x1 + x2;
        },
        /**
         * Show Ajax loader
         * @param {type} element
         * @returns {undefined}
         */
        addLoader: function (element) {
            $(element).find('#loader').remove();
            $(element).append('<div id="loader" style="background-color:white;position:absolute;width:100%;height:100%;left:0; top:0;z-index:100;opacity:0.75;"><div class="loader">Loading...</div></div>');
        },
        /**
         * Remove Ajax loader
         * @param {type} element
         * @returns {undefined}
         */
        removeLoader: function (element) {
            window.setTimeout(function () {
                $(element).find('#loader').remove();
            }, 500);
        }

    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };



})(jQuery);


