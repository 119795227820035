/** 
 * Search Results Controller
 * @author: Ben St Johnston
 * 
 * Controls all the subcomponents including possibly:
 * searchBar
 * searchFilters
 * searchResultsPages
 * searchResultsFunds
 * searchResultsLiterature
 * 
 */
;
(function($, document, window, frk, Handlebars) {

    var componentName = 'searchController',
    defaults = {};

	function Component(element, options) {
		frk = window.frk || {};
        console.log('invoked searchControls - Component function');
        
        
        this.element = element;
		this.options = $.extend({}, defaults, options);
		this._defaults = defaults;
		this._name = componentName;
		        
		// only want one serachController per page.
		if (frk.searchController === undefined) {
			frk.searchController = this;
	        this.model = {
        		query: '',
        		filter: 'all',
        		loadMoreIncrement: 10,
        		
        		all: {
                    pageStart: 0,
                    pageSize: 3
                },
                pages: {
                	total: 0,
                    pageStart: 0,
                    pageSize: 10,
                    results: [],
                    error: null,
            		isSearching: false,
            		load: this._getPageData.bind(this)
                },
                literature: {
                	total: 0,
                    pageStart: 0,
                    pageSize: 10,
                    results: [],
                    error: null,
            		isSearching: false,
                    load: this._getLiteratureData.bind(this)
                },
                funds: {
                	fields: ['symbol.val', 'fundShortName.val', 'fundNumber.val', 'fundLegalName.val'],
                	total: 0,
                    pageStart: 0,
                    pageSize: 10,
                    results: [],
                    error: null,
            		isSearching: false,
                    load: this._getFundData.bind(this)
                }
            };
	        this.isSearchResultsPage = false; // will be set to true if one or more of the search results components are on the page
			this.init();
		}
	}

	Component.prototype = {
		init : function() {
			this.showFilters = $(this.element).data('showFilters');
			this.showPageResults = $(this.element).data('showPageResults');
			this.showFundResults = $(this.element).data('showFundResults');
			this.showLiteratureResults = $(this.element).data('showLiteratureResults');
            this.parsePageParams();
            this.searchPage = $(this.element).data("searchPage");
            this.literatureSearchPage = $(this.element).data("literatureSearchPage");
            this.search();
        },
		
		// parse the url parameters for the query and filters
		parsePageParams: function() {
            var urlParams = frk.getQueryStringParams();
            var query, filter;
            for (var urlParam = 0 ; urlParam < urlParams.length; urlParam++) {
                if (urlParams[urlParam].key === 'filter') {
                	this.setFilter(urlParams[urlParam].value);
                }
                if (urlParams[urlParam].key === 'query') {
                	this.model.query = decodeURIComponent(urlParams[urlParam].value).replace(/\+/g, " ");
                }
            }	
		},
						
		getModel: function() {
			return this.model;
		},
		
        updateFilter: function(filter) {
        	var oldFilter = this.model.filter;
        	this.setFilter(filter);
        	if (oldFilter !== this.model.filter) {
	        	if (window.history.pushState) {
	        	    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?query=' + this.model.query + '&filter=' + this.model.filter;
	        	    window.history.pushState({path:newurl},'',newurl);
	        	}
	        	$(this).trigger('filterUpdated', this.model);
        	}
        },
        
        setFilter: function(filter) {
			var section;
			var count = 0;
			if (this.showPageResults) {
				count++;
				section='pages';
			}
			if (this.showFundResults) {
				count++;
				section='funds';
			}
			if (this.showLiteratureResults) {
				count++;
				section='literature';
			}
			if (count === 1) {
				// only one section so always set filter
				filter = section;
			}
        	this.model.filter = filter;
        },
               
        updateQuery: function(query) {
        	if (this.model.query !== query) {
        		this.model.query = query;
        		this.model.pages.total = 0;
        		this.model.pages.pageStart = 0;
        		this.model.funds.total = 0;
        		this.model.funds.pageStart = 0;
        		this.model.literature.total = 0;
        		this.model.literature.pageStart = 0;
        		this.search();
        	}
        },
        
		search: function() {
            if (this.model.query ) {
            	if (window.history.pushState) {
            	    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?query=' + this.model.query + '&filter=' + this.model.filter;
            	    window.history.pushState({path:newurl},"Franklin Templeton Investments - Search for " + this.model.query, '?query=' + this.model.query + '&filter=' + this.model.filter, newurl);
            	}
            
                this.model.pages.isSearching = true;
                this.model.funds.isSearching = true;
                this.model.literature.isSearching = true;
                
                $(this).trigger('searchStarted', this.model);
                this.model.pages.load();
                if(this.model.funds.data !== undefined) {
                	this.model.funds.load();
                }
                this.model.literature.load();
            }
		},
        
		loadMore: function(what) {
			this.model[what].pageSize += this.model.loadMoreIncrement;
			this.model[what].load();
		},

        _getPageData: function() {
            var _self = this;
            if (this.model.query) {
	            $.ajax({
	               url : $(this.element).data('ftiJsonPages') + '&start=' + this.model.pages.pageStart + '&num=' + this.model.pages.pageSize + '&q=' + this.model.query, dataType : 'json'
	            }).done(function(data) {
	            	if (data.results !== undefined) {
	            		_self.model.pages.results = data.results;
	            	} else {
	            		_self.model.pages.results = [];
	            	}
	                var pagesResultCount = 0;
	                if (data.results_nav.total_results !== '') {
	               	 	pagesResultCount = parseInt(data.results_nav.total_results, 10);
	                }
	                _self.model.pages.total = pagesResultCount;
	                _self.model.pages.error = null;
	                _self.model.pages.isSearching = false;
	                $(_self).trigger('pageSearchFinished', _self.model);
	            }).fail(function(error) {
	            	 _self.model.pages.total = 0;
	            	 _self.model.pages.results = [];
	            	 if (error.status === 404) {
	            		_self.model.pages.error = "No results found";
	            	 } else {
		            	_self.model.pages.error = "Error loading results";	            		
	            	 }
	                 _self.model.pages.isSearching = false;
	            	 $(_self).trigger('pageSearchFinished', _self.model);
	            });
            }
	    },
		
		_getLiteratureData: function() {
            var _self = this;
            if (_self.showLiteratureResults && this.model.query) {        
	            $.ajax({
	                url: $(this.element).data('ftiJsonLiterature') + "?pageSize=" + this.model.literature.pageSize + "&qsfilters=&sort=recentlyOrdered&keyWordFlag=&page=&browseBy=&browseType=&browseTopic=&browseSubType=&browseSubTopic=&keyword=" + this.model.query,
	                dataType: 'json'
	            }).done(function (data) {
	            	_self.model.literature.results = data["com.frk.ci.gws.orderlit.model.viewmodel.LiteratureResultVM"].info;
	            	_self.model.literature.total = parseInt(data["com.frk.ci.gws.orderlit.model.viewmodel.LiteratureResultVM"].totalResults, 10);
	            	_self.model.literature.error = null;
	                _self.model.literature.isSearching = false;
	            	$(_self).trigger('literatureSearchFinished', _self.model);
	            }).fail(function (error) {
	            	_self.model.literature.total = 0;
	            	_self.model.literature.results = [];
	            	if (error.status === 404) {
	            		_self.model.literature.error = "No results found";
	            	} else {
		            	_self.model.literature.error = "Error loading results";	            		
	            	}
	            	_self.model.literature.isSearching = false;
	            	$(_self).trigger('literatureSearchFinished', _self.model);
	            });
            }
	    },
	       
		_getFundData: function() {
            // we need to filter data
            this.model.funds.results = frk.filter(this.model.query, this.model.funds.data.productsVO.ppsList, this.model.funds.fields);
            this.model.funds.total = this.model.funds.results.length;
            this.model.funds.isSearching = (this.model.funds.data === undefined);
			$(this).trigger('fundSearchFinished', this.model);
		},		

		loadFundData: function() {
			var _self = this;
	        if (_self.showFundResults && _self.model.funds.data === undefined) {
	            $.when(frk.load($(this.element).data('ftiUsConfig'), 'json', true), 
	            	   frk.load($(_self.element).data('ftiJsonFunds'), 'json', true))
	            .then(function (config, data) {
	                 frk.UsConfig.setData(config, $(_self.element).data('ftiActor'));
	            	 _self.model.funds.data = data;
	            	 _self._getFundData();
	            });
	        }
		},
		
		// called by search-bar for autocomplete
		loadFindAFundData: function() {
			var _self = this;
	        if (_self.showFundResults && _self.model.funds.fafdata === undefined) {
	            $.when(frk.load($(this.element).data('ftiUsConfig'), 'json', true), 
	            	   frk.load($(_self.element).data('ftiJsonFindAFund'), 'json', true))
	            .then(function (config, data) {
	                 frk.UsConfig.setData(config, $(_self.element).data('ftiActor'));
	            	 _self.model.funds.fafdata = data;
	            });
	        }			
		},
		        
        addLoader: function (resultsPanel) {
            // TODO: implement a better solution
            // Text content in code is not ideal
            resultsPanel.find('.loader-container').remove();
            resultsPanel.append('<div class="loader-container" style="margin:30px;background-color:white;position:relative;width:92%;height:100%;left:0; top:0;z-index:100;opacity:0.75;"><div class="loader" style="border-width:5px; border-radius:50% !important;width:30px;height:30px;">Loading...</div></div>');
            //$(resultsPanel).addClass('loader loadersmall');
            
            console.log('added loader');
        },
        
        removeLoader: function (resultsPanel) {
            window.setTimeout(function () {
                
                resultsPanel.find('.loader-container').remove();
                //$(resultsPanel).removeClass('loader loadersmall');
                
            }, 500);
        }
		
	};

	// set as jquery plugin
	$.fn[componentName] = function(options) {
		return this.each(function() {
			if (!$.data(this, 'component_' + componentName)) {
				$.data(this, 'component_' + componentName, new Component(this,
						options));
			}
		});
	};

})(jQuery, document, window, frk, Handlebars);