(function ($) {

    // Create the defaults once 
    var componentName = 'commentaryy',
            defaults = {
            };

    // The actual plugin constructor 
    function Component(element, options, Handlebars) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
 

        this.tableTemplate = '<table class="table table-striped perspective-list-view">'
                            + ' <thead>'
                            + '     <tr class="header-sortable">'
                            + '         <th scope="col" class="title"><a href="#">Title</a></th>'
                            + '         <th scope="col" class="speaker"><a href="#">Speaker</a></th>'
                            + '         <th scope="col" class="category"><a href="#">Category</a></th>'
                            + '         <th scope="col" class="date-published current-sort"><a href="#">Date<span class="caret"></span></a></th>'
                            + '     </tr>'
                            + ' </thead>'
                            + ' <tbody>'
                            + '     {{#each funds}}'
                            + '     <tr commentary-type="{{cardcommentaryType}}" class="{{cardClassVal}}" >'
                            + '         <th class="title" scope="row"><a href="{{fund.titleLink}}">{{cardTitle}}</a></th>'
                            + '         <td class="speaker">-</td>'
                            + '         <td class="category">{{cardTopic}}</td>'
                            + '         <td class="date-published">{{cardDate}}</td>'
                            + '     </tr>'
                            + '     {{/each}}'
                            + ' </tbody>'
                            + '</table>'
        
        this.init();
    }

    // Component methods 
    Component.prototype = {
       
        init: function () {

        },

        extractData: function(){


        },

        createTable: function(){
           
        },

        toggleView: function() {

            $('.list-view').on('click', function(e){

            });

        },

        updateTable: function(){

        },

        updateTiles: function(){

        }

    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };

//           $(this).commentaryy();

    
})(jQuery);


