/** 
 * Literature Search/Browse
 * 
 */
;
(function ($, document, frk, Handlebars) {

    var componentName = 'literatureSearchBar',
            defaults = {
    		resultClass: 'results-container',
            noResultFound: 'No results found for',
            assetClass: ['Multi Asset', 'Fixed Income', 'Equity', 'Alternatives']
            };
    
    var productData;
    var newSearchFlag = false;
    

    // The plugin constructor 
    function Component(element, options) {
        this.element = element;

        this.options = $.extend({}, defaults, options);

        this._defaults = defaults;
        this._name = componentName;
        
        // the search input box for suggestion list
        this.searchBoxInput = $(this.element).find('.search-box input');
       
        //Cancel Button in search box
        var searchBoxInput = this.searchBoxInput;
        var cancelButton = $(this.element).find('.ft-icon-close');  
        
        $(searchBoxInput).keyup(function() {
        	 console.log("inside search box key up method - "+$(searchBoxInput).val());
        	 
        	 if(newSearchFlag === true) {
        		 console.log("inside a new search resetting the value to null");
        		 $("#browseBy").val('');
        		 $("#browseType").val('');
        		 $(searchBoxInput).val('');
        		 newSearchFlag = false;
        	 }
        	 
             if ($(searchBoxInput).val() != '' ){
                      $(cancelButton).css({visibility:"visible"});
                      $(searchBoxInput).css({"padding-right":"25px"});
                 }
             else
                 {
             $(cancelButton).css({visibility:"hidden"});  
             $(searchBoxInput).css({"padding-right":"0px"});
                 }
            });
            
        $(cancelButton).click(function(){
            $(searchBoxInput).val('');     
            $(cancelButton).css({visibility:"hidden"});  
            $(searchBoxInput).css({"padding-right":"0px"});
        });

        // the search box
        this.searchBox = $("#txt_Search");
        // the search button
        this.searchBtn = $("#litSearchBtn,#litSearchBtnMobile");
        //this.viewCart =$(document).find('#viewCart');
        this.resultList = $(this.element).find('.list-group');
        
        this.literatureTypesDropDown = $(this.element).find('#literatureTypesDropDown');
    
        
        //Changes has been added for the suggestion Box 
        
        this.form = $(this.element).find('form');

        // the fields to filter against
        this.fields = ['symbol.val', 'fundLegalName.val', 'fundNumber.val', 'cusip.val'];
        // Search object with query and array where result gets stored
        this.searchResult = {
            query: '',
            result: []
        };
        this.searching = false;

        this.searchIcon = $(this.element).find('.ft-icon-search');

        this.hovered = false;
        

        this.templates = {
                listItem: '<b class="results-header">Funds</b>' +
                        '<div class="list-unstyled list-group results-funds">' +
                        '{{#each result}}' +
    					'<a href="/{{frkRole}}/resources/lit/search/{{fundNumber.val}}" class="list-group-item fund-name">{{symbol.val}} - {{fundLegalName.val}} - {{shareClass.val}}</a>' +
                        '{{else}}' +
                        '<span>' + this.options.noResultFound + ' {{query}}</span>' +
                        '{{/each}}' +
                        '</div>' +
                        '<b class="results-header">Search Suggestions</b>' +
                        '<div class="list-unstyled list-group results-other">' +
                        '<a href="/{{frkRole}}/resources/lit/search/{{query}}" class="list-group-item">Keyword <em>{{query}}</em> </a>' +
                        '<a href="/{{frkRole}}/resources/lit/search/topic/forms-and-applications/{{query}}" class="list-group-item"><em>{{query}}</em> in Forms &amp; Applications</a>' +
                        '<a href="/{{frkRole}}/resources/lit/search/type/fund-fact-sheets/{{query}}" class="list-group-item"><em>{{query}}</em> in Fund Fact Sheets</a>' +                    
                        '<a href="/{{frkRole}}/resources/lit/search/type/prospectuses-and-reports/{{query}}" class="list-group-item"><em>{{query}}</em> in Prospectuses &amp; Reports</a>' +



                        '</div>' +
                        '</div>'
            };

        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
        	
            var _self = this;
          
           if ($(this.element).data('ftiJson')) {
                this.jsonURL = $(this.element).data('ftiJson');
            }else {
              throw 'No JSON Data specified';
          }
           
           
           
           // get and set json url
           if ($(this.element).data('ftiSuggestionJson')) {
               this.jsonSuggestionURL = $(this.element).data('ftiSuggestionJson');
           }
           else {
               throw 'No JSON Data specified';
           }
           
        // add empty list where result will be displayed
           $(this.searchBox).parent().find('.' + this.options.resultClass).bind('mouseover', function () {
           	console.log("inside mouse over");
               _self.hovered = true;
           }).bind('mouseout', function () {
           	console.log("inside mouse out");
               _self.hovered = false;
           });
           
           
        // set up listeners
           $(this.searchBox).on('keyup mouseup', function () {
           	console.log("key up mouse up");
           	if(newSearchFlag === true) {
          		 console.log("inside a new search resetting the value to null");
          		 $("#browseBy").val('');
          		 $("#browseType").val('');
          		 $(searchBoxInput).val('');
          		 newSearchFlag = false;
          	 }
               frk.delay(_self._searchSuggestionData.bind(_self), 500);
           });
           $(this.searchBox).on('focus', this._showResults.bind(this));
           $(this.searchBox).on('blur', this._hideResults.bind(this));
           
           // Get data on load to speed up user interaction
           _self._getSuggestionData();
           

            if ($(this.literatureTypesDropDown).data('ftiTemplate')) {
            	
                this.templateUrl = $(this.literatureTypesDropDown).data('ftiTemplate');
               
                $.when(this.getTemplate(), this.getData()).then(function (template, data) {
                
                    //_self.initView();
                    _self.render(template, data);
                    //_self.initDetails(_self.data);
                });
           }
          else {
               throw 'No Search Result Template specified';
          }


            $(this.searchBtn).on('click', this.search.bind(this));
            
           
           
            $(this.searchBox).on('keydown', function (event) {
                if (event.keyCode === 13) {
                    event.preventDefault();
                    event.stopPropagation();
                    _self.search();
                }
            });
            
          
        },
        _getSuggestionData: function () {
        	console.log("getSuggestionData method");
            var dfd = $.Deferred();
            var _self = this;

            this.searching = true;
            
            if(productData) {
            	console.log("To retrieve the product data for next set of search"+productData);
            	this.data = productData;
            }
            
            // No data in memory, we need to load it
            if (!this.data) {
            	console.log("inside Data");
            	console.log("json URL:"+_self.jsonSuggestionURL);
                frk.load($(this.element).data('ftiUsConfig'), 'json').then(function (data) {
                    frk.UsConfig.setData(data, $(_self.element).data('ftiActor'));
                    $.ajax({
                        url: _self.jsonSuggestionURL,
                        dataType: 'json'
                    }).done(function (data) {
                        // save data
                        _self.data = data;
                        productData = data;
                        _self.searching = false;
                        dfd.resolve(data);
                    }).fail(function () {
                    	 _self.searching = false;
                         _self.removeLoader();
                         $(this.selectedFilters).hide();
            			 $(this.navbar).hide();
            			 $(this.resultpanel).hide();
            			 $(this.loadMoreBtn).hide();
            			    
            			 $('[data-fti-component="error-messages"]').remove();
            			 $('[data-fti-component="back-to-top"]').backToTop();
            			 frk.info('Error', 'Unable to load data, Please try after some time'); 
            			 throw new DataLoadingException('Unable to load data');
            			 dfd.reject('Unable to load ' + 'json' + 'at' + _self.jsonSuggestionURL);
                    });
                });
            }
            // Already have data in memory
            else {
                _self.searching = false;
                dfd.resolve(this.data);
            }

            return dfd.promise();
        },
        _searchSuggestionData: function () {
            var _self = this;
            console.log("searchSuggestionData"+$(this.searchBoxInput).val()+"this searching"+this.searching);
            // search input has changed, check if there is something to search against
            if ($(this.searchBoxInput).val().length > 0 && !this.searching) {
            	console.log("Inside search box val > 0"+$(this.searchBoxInput).val());
                
                this._getSuggestionData().then(function (data) {
                	console.log("Inside getData Then method ========="+JSON.stringify(data));
                    //clearup previous results
                    _self.searchResult.result = [];
                    // setup query string
                    _self.searchResult.query = $(_self.searchBoxInput).val();
                    // we need to filter data
                    _self.searchResult.result = frk.filter($(_self.searchBoxInput).val(), data.productsVO.ppsList, _self.fields);
                    
                    console.log("Filter Data is:"+_self.searchResult.result);
                    
                    // sort data
                    _self.searchResult.result= frk.orderBy.alphabetialOrder(_self.searchResult.result, 'shareClass.val');
                    _self.searchResult.result= frk.orderBy.alphabetialOrder(_self.searchResult.result, 'fundLegalName.val');

                    // Compile and render template
                    var html = Handlebars.compile(_self.templates.listItem)(_self.searchResult);
                    
                    // display result
                    _self._clearSuggestionResults(); //clean first
                    $(_self.searchBoxInput).parent().find('.' + _self.options.resultClass).prepend(html);

                    // setup listeners on result list items
                    $('.' + _self.options.resultClass + ' li').on('click mouseover mouseleave', _self._handleSuggestionItem.bind(_self));
                    
                });
            }
            else {
                // we clear previous results if any
                _self._clearSuggestionResults();
            }
        },
        _handleSuggestionItem: function (event) {
            event.preventDefault();
            console.log("Inside handle item");
            if (event.type === 'mouseover') {
                $(event.target).addClass('active');
            }
            if (event.type === 'mouseleave') {
                $(event.target).removeClass('active');
            }
            if (event.type === 'click') {
            	console.log("Inside event type click");
                $(this.searchBoxInput).val($(event.target).attr('id'));
                $(this.searchBoxInput).attr('value', $(event.target).attr('id'));
                console.log("Fund ID as:"+$(event.target).attr('id'));
                this.hovered = false;
            }
        },
        _clearSuggestionResults: function () {
        	console.log("inside clearSuggestionResults Results");
            this.searchResult.result = [];
            
            console.log("$(this.searchBoxInput).parent() :"+$(this.searchBoxInput).parent());
            console.log("this.options.resultClass :"+this.options.resultClass);
            $(this.searchBoxInput).parent().find('.' + this.options.resultClass).empty();
            
        },
        _showResults: function () {
        	console.log("Inside show results");
            $(this.searchBoxInput).parent().find('.' + this.options.resultClass).show();
        },
        _hideResults: function () {
        	console.log("inside hideResults");
            if (!this.hovered) {
                $(this.searchBoxInput).parent().find('.' + this.options.resultClass).hide();
            }
        },
        getTemplate: function () {
            var dfd = $.Deferred();
           var _self = this;
           if (!_self.template) {
                $.ajax({
                    url: _self.templateUrl,
                    dataType: 'html'
                }).done(function (data) {
                    _self.template = data;
                  
                    dfd.resolve(data);
                }).fail(function () {
                    throw new DataLoadingException('Unable to load data');
	                this.removeLoader();
	                dfd.reject('Unable to load ' + 'json' + 'at' + _self.jsonSuggestionURL);

               });
            }
           else {
               dfd.resolve(this.template);
         }
           return dfd.promise();
        },
        search: function () {
            
            var action = $("#lit-search-form").attr("action");
            console.log("action=" + action);
            window.location = action + "/" + encodeURIComponent($(this.searchBox).val());

        },
        // get the data from the literature service
        getData: function () {

            var dfd = $.Deferred();
            var _self = this;
            $.ajax({
               url: this.jsonURL, dataType: 'json',cache:false 
            }).done(function (data) {
              _self.data = data;
            
              dfd.resolve(data);
            }).fail(function () {
            	 _self.searching = false;
                 _self.removeLoader();
                 $(this.selectedFilters).hide();
    			 $(this.navbar).hide();
    			 $(this.resultpanel).hide();
    			 $(this.loadMoreBtn).hide();
    			    
    			 $('[data-fti-component="error-messages"]').remove();
    			 $('[data-fti-component="back-to-top"]').backToTop();
    			 frk.info('Error', 'Unable to load data, Please try after some time'); 
          	    throw new DataLoadingException('Unable to load data');
          	    dfd.reject('Unable to load ' + 'json' + 'at' + _self.jsonSuggestionURL);            	
             
            });

            return dfd.promise();
        },
        render: function (template, data) {
            var _self = this;
          
            var html = Handlebars.compile(template)(data);
        
           
            $(this.literatureTypesDropDown).empty();
            $(this.literatureTypesDropDown).append(html);
            $(this.element).find('[data-fti-literature-type]').each(function(index, el) {
          	  
				$(el).on("click", _self, _self.typesearch);
			});
            this.viewEngine.updateAll();

        },
        typesearch: function () {
        	
        	//console.log("-Inside typesearch text-"+ $(this).text());
          
            //console.log("action=" + action);
        	var action = $("#lit-search-form").attr("action");
        	if(action.indexOf("jsessionid") > -1){
        		var filteredAction = action.split(";");
        		action = filteredAction[0];
        	}
        	window.location = action + "/" +encodeURIComponent($(this).text());
             //window.location = action +"?browseBy=type&browseType="+encodeURIComponent($(this).text());
            

         }


    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };

    // ----------
    // markup hook
    $(document).find('[data-fti-component="search-bar"]').each(function () {
        $(this).literatureSearchBar();
    });

})(jQuery, document, frk, Handlebars);
