/** 
 * Order historyy
 * 
 */
;
(function ($, document, frk, Handlebars) {

    var componentName = 'orderHistoryList', defaults = {};
    var totalItem = 0;

    function Component(element, options) {

        this.element = element;

        this.options = $.extend({}, defaults, options);

        this._defaults = defaults;
        this._name = componentName;

        // the search box
        this.searchBox = $(this.element).find('.search-box input');

        // the search button
        this.searchBtn = $(this.element).find('.search-box .btn-primary');


        // pager
        this.resultList = $(this.element).find('.ord-list-group');
        this.formControl = $(this.element).find('.form-control');
        this.pagerBtns = $(this.element).find('.pager');
        this.pagerPreviousBtn = $(this.element).find('.pager .previous');
        this.pagerNextBtn = $(this.element).find('.pager .next');

        this.init();
    }

    Component.prototype = {
        init: function () {

            var _self = this;
            
            
            
           if(totalItem === 0) {
        	   console.log("Inside total 0 .....");
        	   _self.pagerNextBtn.addClass('hidden').removeClass('active-page');
          	   _self.pagerPreviousBtn.addClass('hidden').removeClass('active-page');
          	   _self.pagerBtns.addClass('hidden');
          	 
           }

            if ($('div.ord-list-group').length > 0) {// only for testing
                if ($(this.element).data('ftiJson')) {
                    this.jsonURL = $(this.element).data('ftiJson');
                } else {
                    throw 'No JSON Data specified';
                }
                if ($(this.resultList).data('ftiTemplate')) {
                    this.templateUrl = $(this.resultList).data('ftiTemplate');
                    this.getTemplate();
                } else {
                    throw 'No Search Result Template specified';
                }
            }


            this.initView();

            this.getData().then(function (data) {
                _self.render(_self.template, _self.data);
                
                
                var startitem = 0; 
                
                var enditem = $('option:selected', _self.formControl).val();
                
                totalItem = $('#order-history-list-table tbody tr').length;
                
                console.log("Total Count:"+totalItem);
                console.log((($('#order-history-list-table tbody tr').length) / enditem)+1);
                
               

                if(totalItem < enditem)
                {
                	 
             	   _self.pagerPreviousBtn.addClass('hidden').removeClass('active-page');
            	   _self.pagerNextBtn.addClass('hidden').removeClass('active-page');
            	   _self.pagerBtns.addClass('hidden').removeClass('active-page');
                } 
                else {
                	_self.pagerPreviousBtn.removeClass('hidden').addClass('active-page');
             	   _self.pagerNextBtn.removeClass('hidden').addClass('active-page');
             	  _self.pagerBtns.removeClass('hidden').addClass('active-page');
                }
                
               /* else {
                	
                	$('a.next-arrow',_self.pagerNextBtn).removeClass('hidden').addClass('active-page');
                	$('a.previous-arrow',_self.pagerPreviousBtn).removeClass('hidden').addClass('active-page');
                }*/
                
                
                for (var i = 2; i <= ((($('#order-history-list-table tbody tr').length)) / enditem) + 1; i++) {
                    $('.pager .pagination').append('<li class="page page-new"><a href="#" >' + i + '</a><span class="hidden">' + i + '</span></li>');
                }

                $('.page', _self.pagerBtns).on('click', function (e) {
                    e.preventDefault();

                    var pageNr = $('a', this).text();
                    var itemsPerPage = $('option:selected', _self.formControl).val();
                    var startitem = (pageNr - 1) * itemsPerPage;
                    var enditem = parseInt(startitem) + parseInt(itemsPerPage);
                    var pageTotalNr = $('li.page', _self.pagerBtns).length;
                    
                    console.log("Page No =======:"+pageNr+" pageTotalNr ==== :"+pageTotalNr);
                    console.log('startitem= ' + startitem + ' enditem= ' + enditem + ' itemsPerPage= ' + itemsPerPage);
                    _self.paginated(startitem, enditem);
                    $('.page a', _self.pagerBtns).removeClass('hidden').removeClass('active-page');
                    $('.page span', _self.pagerBtns).addClass('hidden').removeClass('active-page');
                    $('a', this).addClass('hidden').addClass('active-page');
                    $('span', this).removeClass('hidden').addClass('active-page');
                    
                    if(pageNr == 1) {
                    	
                  	   _self.pagerPreviousBtn.addClass('hidden').removeClass('active-page');
                	   _self.pagerNextBtn.addClass('active-page').removeClass('hidden');
                    }
                    
                    if(pageNr > 1 && pageNr < pageTotalNr) {
                    	
                        _self.pagerPreviousBtn.addClass('active-page').removeClass('hidden');
                   	   _self.pagerNextBtn.addClass('active-page').removeClass('hidden');
                     }
                      
                    
                    if(pageNr == pageTotalNr) {
                    	
                       _self.pagerNextBtn.addClass('hidden').removeClass('active-page');
                  	   _self.pagerPreviousBtn.addClass('active-page').removeClass('hidden');
                    	
                    }
                    
                });
                
                var pageNr = $('a.active-page', _self.pagerBtns).text();
                
                if(pageNr == 1) {
                	
                	   _self.pagerPreviousBtn.addClass('hidden').removeClass('active-page');
                  }
                
                _self.paginated(startitem, enditem);

                
            });

           

            $(_self.formControl).on('change', function (e) {
                $('.page-new').remove();
                var startitem = 0;
                var enditem = $(this).val();

                $('.page a', _self.pagerBtns).addClass('active-page').addClass('hidden');
                $('.page span', _self.pagerBtns).addClass('active-page').removeClass('hidden');

                for (var i = 2; i < (($('#order-history-list-table tbody tr').length)) / enditem + 1; i++) {
                    $('.pager .pagination').append('<li class="page page-new"><a href="#" >' + i + '</a><span class="hidden">' + i + '</span></li>');
                }
                
                console.log("Onchange .....");

                $('.page', _self.pagerBtns).on('click', function (e) {
                    e.preventDefault();

                    var pageNr = $('a', this).text();
                    var itemsPerPage = $('option:selected', _self.formControl).val();
                    var startitem = (pageNr - 1) * itemsPerPage;
                    var enditem = parseInt(startitem) + parseInt(itemsPerPage);
                    var pageTotalNr = $('li.page', _self.pagerBtns).length;
                    console.log("Page No ========:"+pageNr+" pageTotalNr =========:"+pageTotalNr);
                    console.log('startitem= ' + startitem + ' enditem= ' + enditem + ' itemsPerPage= ' + itemsPerPage+"Total Item:"+totalItem);
                    _self.paginated(startitem, enditem);
                    $('.page a', _self.pagerBtns).removeClass('hidden').removeClass('active-page');
                    $('.page span', _self.pagerBtns).addClass('hidden').removeClass('active-page');
                    $('a', this).addClass('hidden').addClass('active-page');
                    $('span', this).removeClass('hidden').addClass('active-page');
                    
                    if(pageNr == 1) {
                    	
                   	   _self.pagerPreviousBtn.addClass('hidden').removeClass('active-page');
                 	   _self.pagerNextBtn.addClass('active-page').removeClass('hidden');
                     }
                    
                     
                    if(pageNr > 1 && pageNr < pageTotalNr) {
                    	
                       _self.pagerPreviousBtn.addClass('active-page').removeClass('hidden');
                  	   _self.pagerNextBtn.addClass('active-page').removeClass('hidden');
                    }
                     
                     
                     if(pageNr == pageTotalNr) {
                     	
                        _self.pagerNextBtn.addClass('hidden').removeClass('active-page');
                   	   _self.pagerPreviousBtn.addClass('active-page').removeClass('hidden');
                     	
                     }
                     
                     if(totalItem < itemsPerPage) {
                    	 
                    	 _self.pagerNextBtn.addClass('hidden').removeClass('active-page');
                    	 _self.pagerPreviousBtn.addClass('hidden').removeClass('active-page');
                     }
                     
                     	
                     
                    
                });
                
                var pageNr = $('a.active-page', _self.pagerBtns).text();
                
                console.log("page number after change :"+pageNr);
                
                if(pageNr == 1) {
                	
                	   _self.pagerPreviousBtn.addClass('hidden').removeClass('active-page');
                	   _self.pagerNextBtn.addClass('active-page').removeClass('hidden');
                  }
                
                var itemsPerPage = $('option:selected', _self.formControl).val();
                
                if(totalItem <= itemsPerPage) {
                    	 
                    	 _self.pagerNextBtn.addClass('hidden').removeClass('active-page');
                    	 _self.pagerPreviousBtn.addClass('hidden').removeClass('active-page');
               }
                
                
                _self.paginated(startitem, enditem);
            });

            $('option:selected', _self.formControl).change();


            $(_self.pagerNextBtn).on('click', function (e) {
                e.preventDefault();

                var pageTotalNr = $('li.page', _self.pagerBtns).length;
                var pageNr = $('a.active-page', _self.pagerBtns).text();
                
                
                console.log('pageTotalNr ..*****......= ' + pageTotalNr + '   pageNr ... = ' + pageNr);
                
                if(pageNr == (pageTotalNr-1)) {
                	
                	 _self.pagerPreviousBtn.removeClass('hidden').addClass('active-page');
                 	 _self.pagerNextBtn.removeClass('active-page').addClass('hidden');
                     
                }
                

                if (pageNr < pageTotalNr) {

                    $('span.active-page', _self.pagerBtns).addClass('hidden').removeClass('active-page').parent('li').next().find('span').removeClass('hidden').addClass('active-page');
                    $('a.active-page', _self.pagerBtns).removeClass('hidden').removeClass('active-page').parent('li').next().find('a').addClass('hidden').addClass('active-page');

                    pageNr = $('a.active-page', _self.pagerBtns).text();
                    var itemsPerPage = $('option:selected', _self.formControl).val();
                    var startitem = (pageNr - 1) * itemsPerPage;
                    var enditem = parseInt(startitem) + parseInt(itemsPerPage);

                    _self.paginated(startitem, enditem);
                }
                
                if(pageNr > 1 && pageNr < pageTotalNr) {
                	
                    _self.pagerPreviousBtn.addClass('active-page').removeClass('hidden');
               	   _self.pagerNextBtn.addClass('active-page').removeClass('hidden');
                 }
            });

            $(_self.pagerPreviousBtn).on('click', function (e) {
                e.preventDefault();
                var pageNr = $('a.active-page', _self.pagerBtns).text();
                var pageTotalNr = $('li.page', _self.pagerBtns).length;
                
                
               
                if (pageNr > 1) {
                    console.log('pageNr= ' + pageNr);

                    $('span.active-page', _self.pagerBtns).addClass('hidden').removeClass('active-page').parent('li').prev().find('span').removeClass('hidden').addClass('active-page');
                    $('a.active-page', _self.pagerBtns).removeClass('hidden').removeClass('active-page').parent('li').prev().find('a').addClass('hidden').addClass('active-page');

                    pageNr = $('a.active-page', _self.pagerBtns).text();
                    var itemsPerPage = $('option:selected', _self.formControl).val();
                    var startitem = (pageNr - 1) * itemsPerPage;
                    var enditem = parseInt(startitem) + parseInt(itemsPerPage);

                    _self.paginated(startitem, enditem);

                }
                
                console.log("Previous pageNr ..... = "+pageNr);
                
                if(pageNr > 1 && pageNr < pageTotalNr) {
                	
                    _self.pagerPreviousBtn.addClass('active-page').removeClass('hidden');
               	   _self.pagerNextBtn.addClass('active-page').removeClass('hidden');
                 }
                
                if(pageNr == 1) {
                	
                	 _self.pagerPreviousBtn.removeClass('active-page').addClass('hidden');
                 	 _self.pagerNextBtn.removeClass('hidden').addClass('active-page');
                     
                }
                
            });
        },
        initView: function () {

            var _self = this;

            this.view = {
            		orderCount: function () {
                    return _self.data ? _self.data["com.frk.ci.gws.orderlit.model.viewmodel.OrderResultVM"].totalResults : "0";
                }
            };

            this.viewEngine = frk.viewEngine(this.view);
            this.viewEngine.init();
            this.viewEngine.updateAll();
        },
        getData: function () {
            var dfd = $.Deferred();
            var _self = this;

            // No data in memory, we need to load it
            //            if (!this.data) {
            $.ajax({
                url: this.jsonURL,
                dataType: 'json'
            }).done(function (data) {
                // save data
                _self.data = data;

                dfd.resolve(data);
                
            }).fail(function () {

                dfd.reject('Unable to load data');
            });

            return dfd.promise();
        },
        getTemplate: function () {
            var dfd = $.Deferred();
            var _self = this;
            if (!_self.template) {
                $.ajax({
                    url: _self.templateUrl,
                    dataType: 'html'
                }).done(function (data) {
                    // save data
                    _self.template = data;
                    dfd.resolve(data);
                }).fail(function () {
                    dfd.reject('Unable to load data');
                });

            } else {
                dfd.resolve(this.template);
            }
            return dfd.promise();
        },
        search: function () {
            var _self = this;

            // search input has changed, check if there is something to search against
            if ($(_self.searchBox).val().length > 0) {
                this.getData($(_self.searchBox).val()).then(function (data) {

                    _self.render(_self.template, _self.data);

                });
            } else {
                // we clear previous results if any
                _self.clearResults();
            }
        },
        render: function (template, data) {
            var html = Handlebars.compile(template)(data);
            $(this.resultList).empty();
            $(this.resultList).append(html);
            $("#order-history-list-table").tablesorter();

            this.viewEngine.updateAll();
        },
        clearResults: function () {
            $(this.searchBox).parent().find('.' + this.options.resultClass)
                    .remove();
        },
        handleItem: function (event) {

            event.preventDefault();

            if (event.type === 'mouseover') {
                $(event.target).addClass('active');
            }
            if (event.type === 'mouseleave') {
                $(event.target).removeClass('active');
            }
            if (event.type === 'click') {
                $(this.searchBox).val($(event.target).html());
                $(this.searchBox).attr('value', $(event.target).attr('id'));
                this.clearResults();
            }
        },
        paginated: function (startitem, enditem) {
        	
        	$.each($('thead tr', this.resultList), function (i, val) {
        		$(this).addClass('active-page');
        	});
        	
            $.each($('tbody tr', this.resultList), function (i, val) {
            	
                if ((startitem <= i) && (i < enditem)) {
                	
                    $(this).removeClass('hidden');
                } else {
                	
                    $(this).addClass('hidden');
                }
            });
        }

    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName, new Component(this,
                        options));
            }
        });
    };

    // ----------
    // markup hook
    $(document).find('[data-fti-component="order-history-list"]').each(function () {
        console.log("Inside markup hookup method");
        $(this).orderHistoryList();
    });
    // ----------

})(jQuery, document, frk, Handlebars);


