/**
 * PPSS Fund Filter Sub Component
 * jQuery Plugin
 * 
 */
;
(function ($, document, frk, Handlebars) {

    // Create the defaults once 
    var componentName = 'ppssFundFilter',
            defaults = {
                oneFilterActive: false
            };
    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        // List of active filters
        this._activeFilters = {};
        //List of saved filters when toggling Favourite
        this._savedFilters = {};
        // 
        this._filterInfo = [];
        // Call init method
        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
            this._initDopdowns();
            this._initButtons();
            var params = frk.getQueryStringParams();
            // check if there are any query strings
            if (params.length > 0) {
                var matches = [];
                // check is any key matches filter ID
                for (var param in params) {
                    for (var filter in this._filterInfo) {
                        if (params[param].key === this._filterInfo[filter].filterId) {
                            // Yes
                            var m = params[param];
                            m.type = this._filterInfo[filter].type;
                            matches.push(m);
                        }
                    }
                }
                // apply filters
                for (var match in matches) {
                    // if button
                    if (matches[match].type === 'button' && matches[match].value === 'true') {
                        $('#' + matches[match].key).prop("checked", true).trigger('click');
                    }
                    // if dropdown
                    else {
                        // only if option exists
                        if ($('#' + matches[match].key + ' option[value="' + matches[match].value + '"]').length > 0) {
                            $('#' + matches[match].key).val(matches[match].value).trigger('change');
                        }
                    }
                }
            }
        },
        _initButtons: function () {
            var _self = this;
            $(this.element).find('[data-button]').each(function () {
                // add element in filter info
                _self._filterInfo.push({filterId: $(this).attr('id'), type: 'button'});
                // add element in ppssPanel filter list
                // a button is composed of 2 inputs. Only add the filter for the checked one.
                if ($(this).is(':checked')) {
                    _self._addFilters('ff_' + frk.toCamelCase($(this).data('button')), {query: '', fields: [frk.toCamelCase($(this).data('button'))]});
                }
                // If fav button but not logged in
                if ($(this).closest('[data-ppss-favorite]').length && _self.options.renderOptions.isLoggedIn === 'false') {
                    $(this).parent().popover();
                    $(this).parent().on('click', function (e) {
                        //alert('You need to be logged in');
                        var _self = this;
                        // close button
                        $('button.close', $(this).parent()).click(function () {
                            $('.popover', $(_self).parent()).popover('hide');
                        });
                        
                        // open links
                        $('.popover-link').click(function(e){
                            window.open($(e.target).attr('href'), '_self');
                        });
                        e.preventDefault();
                        e.stopPropagation();
                        //return false;
                    });
                } else {
                    // emit event when toggle button
                    _self._setEmitters(this, 'button');
                }
            });
        },
        _initDopdowns: function () {
            var _self = this;
            $(this.element).find('[data-select]').each(function () {
                // add element in filter info
                _self._filterInfo.push({filterId: $(this).attr('id'), type: 'dopdown', filter: 'ff_' + frk.toCamelCase($(this).data('select'))});
                // add options if autopopulate set to true
                if ($(this).data('autopopulate') === true) {
                    $(this).append(
                            Handlebars.compile('{{#each this}}' +
                                    '<option value="{{this}}">{{this}}</option>' +
                                    '{{/each}}')(frk.orderBy.alphabetialOrder(frk.getUnique(frk.toCamelCase($(this).data('select')), _self.options.data)))
                            );
                }
                // add element in ppssPanel filter list
                _self._addFilters('ff_' + frk.toCamelCase($(this).data('select')), {query: '', fields: [frk.toCamelCase($(this).data('select'))]});
                // default value cheking
                if ($(this).val() !== '') {
                    _self._modifyFilterQueries('ff_' + frk.toCamelCase($(this).data('select')), $(this).val());
                }
                // emit event when select value
                _self._setEmitters(this, 'select');
            });
        },
        _setEmitters: function (element, type) {
            var _self = this;
            // emit on parent to make it easy
            $(element).on('change', function (e) {
                $('[data-ppss-favorite="tool-bar"]').trigger('resetSelected');
                // One filter active at the time
                if (_self.options.oneFilterActive) {
                    if (type === 'select' && frk.toCamelCase($(this).data(type)) !== 'shareClass.val') {
                        // Last minute change, not really graceful...
                        if ($(this).val() !== '') {
                            $('select[id!="share-class"][id!="' + $(this).attr('id') + '"]', $(this).parent().siblings()).each(function () {
                               $(this).prop('disabled', 'disabled'); 
                            });
                        } else {
                            $('select[id!="share-class"][id!="' + $(this).attr('id') + '"]', $(this).parent().siblings()).each(function () {
                                $(this).prop('disabled', false); 
                            });
                        }
                    }
                }
                // favourite toggle
                if (frk.toCamelCase($(this).data(type)) === 'favorite.val') {
                    var q;
                    // if true, save filters, set to ALL and disable shareclass
                    if ($(e.target).val() === 'true') {
                        _self._savedFilters = $.extend(true, {}, _self._activeFilters);
                        for (q in _self._activeFilters) {
                            _self._modifyFilterQueries(q, '');
                            _self._replicateFilterInDom(q, '');
                        }
                        $('select[data-select]').prop('disabled', 'disabled');
                    }
                    // if set to false, reset filters
                    else {
                        $('select[data-select]').prop('disabled', false);
                        for (q in _self._savedFilters) {
                            _self._modifyFilterQueries(q, _self._savedFilters[q]);
                            _self._replicateFilterInDom(q, _self._savedFilters[q]);
                        }                        
                    }
                }
                // update filter
                _self._modifyFilterQueries('ff_' + frk.toCamelCase($(this).data(type)), $(e.target).val());
                // let's get ready to render
                $(element).trigger('readyToRender');
            });
        },
        _setListeners: function (element) {
            // when parent tells us to change
            $(element).on('update', {val: val}, function (e) {
                console.log(e);
            });
        },
        _addFilters: function (id, conf) {
            for (var panel in this.options.ppssPanels) {
                this.options.ppssPanels[panel].addFilter(id, conf);
            }
        },
        _modifyFilterQueries: function (id, query) {
            for (var panel in this.options.ppssPanels) {
                this.options.ppssPanels[panel].modifyFilterQuery(id, query);
            }
            if (query !== '') {
                this._activeFilters[id] = query;
            }
            else if (this._activeFilters[id]) {
                delete this._activeFilters[id];
            }
        },
        _replicateFilterInDom: function (id, val) {
            for (var fi in this._filterInfo) {
                if (this._filterInfo[fi].filter === id) {
                    // share class
                    if (this._filterInfo[fi].filterId === 'share-class' && val === '') {
                        // add no val option
                        $('#' + this._filterInfo[fi].filterId).append($("<option></option>").attr('selected', 'selected').attr("value", '').text('-'));
                    }
                    // other dropdowns
                    else {
                        if (this._filterInfo[fi].filterId === 'share-class') {
                            $('option[value=""]', $('#' + this._filterInfo[fi].filterId)).remove();
                        }
                        else if (val !== '' && this.options.oneFilterActive) {
                            $('select[id!="share-class"][id!="' + this._filterInfo[fi].filterId + '"]').each(function () {
                               $(this).prop('disabled', 'disabled'); 
                            });                            
                        }
                        $('#' + this._filterInfo[fi].filterId).val(val);                        
                    }
                }
            }
        }
    };
    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };
})(jQuery, document, frk, Handlebars);