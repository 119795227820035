/**
 * This module is used to load other modules
 * 
 */
;
(function ($, frk) {

    var componentName = 'loader',
            defaults = {
                dataAttributes: ['ftiModule']
            };

    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.load();
    }

    /**
     * 
     * @returns {undefined}
     */
    Component.prototype.load = function () {
        for (var attr in this.options.dataAttributes) {
            this._process(this.options.dataAttributes[attr]);
        }
    };

    /**
     * 
     * @param {type} attribute
     * @returns {undefined}
     */
    Component.prototype._process = function (attribute) {
        var componentSelector = '[data-' + frk.toHyphens(attribute) + ']';
        $(this.element).find(componentSelector).each(function () {

            try {
                var componentName = frk.toCamelCase($(this).data(attribute));
                $(this)[componentName]($(this).data());
                //console.log(componentName + ' loaded');
            }
            catch (err) {
                console.error('Error loading ' + componentName + ':' + err);
            }

        });
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            $.data(this, 'component_' + componentName,
                    new Component(this, options));
        });
    };

})(jQuery, frk);


