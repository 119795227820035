
;(function ($) {

    // Create the defaults once 
    var componentName = 'myFunds',
            defaults = {
            };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.viewMoreBtn = $(this.element).find('.view-more-btn');
        this.favTab = $(this.element).find('[data-my-funds-tab="favorite"]');
        this.popularTabContent = $(this.element).find('[data-my-funds-tab-content="popular"]');
        this.favTabContent = $(this.element).find('[data-my-funds-tab-content="favorite"]');

        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
            var _self = this;
            
      
            
            this.viewMoreBtn.on('click', function (e) {
                $(_self.element).find('#popular .hidden-card').removeClass('hidden');
                $(this).addClass('hidden');
                e.preventDefault();
            });

            // if funds are getting added/removed from fav in popular fund tab, 
            // reload page when clicking back on favourite tab
            $(this.popularTabContent).find('[data-fti-component="favorite-a-fund"]').each(function () {
                $(this).click(function () {
                    if (_self.favTab.length > 0) {
                        $(_self.favTab).click(function (e) {
                            location.reload();
                            e.preventDefault();
                            return false;
                        });
                    }
                });
            });

            // if funds are getting removed from fav in fav fund tab, 
            // remove row
            $(this.favTabContent).find('[data-fti-component="favorite-a-fund"]').each(function () {
                $(this).click(function () {
                    // last one has been removed, reload page (quick fix, need to do an AJAX alternative)
                    if($(this).closest('tr').siblings().length === 0) {
                        location.reload();
                    }
                    $(this).closest('tr').fadeOut("normal", function () {
                        $(this).remove();
                    });
                });
            });
            
            // table sorting
            $(this.favTabContent).find('table').each(function(){
                console.log(this);
                $(this).tablesorter();
            });
            
              // TODO: NEEDS REMOVED AFTER DSL FIXED
              // Find all <a> tags where href ends with .igs, hide and loop over them 
            $(this.element).find(".icon-docs a").each(function (i, el) {
                
                console.log(el.href);
                
                $.ajax({
                    type: "HEAD",
                    url: el.href,
                    success: function () {
                        // Success, we can unhide the element.
                        el.parent().show();
                    }
                });
            });
            
        }
    };




    $.fn[componentName] = function (options) {
        return this.each(function () {
            $.data(this, 'component_' + componentName,
                    new Component(this, options));
        });
    };
    
})(jQuery);


