/** 
 * Search bar (includes auto complete)
 * 
 */
;
(function ($, document, frk, Handlebars) {

    var componentName = 'searchBar',
    	defaults = {
            resultClass: 'results-container',
            noResultFound: 'No results found for'
    	};

    // The actual plugin constructor 
    function Component(element, options) {       
        this.element = element;

        this.options = $.extend({}, defaults, options);

        // the search box
        this.searchBox = $(this.element).find('.input-group input');
        this.autocompleteSearchResult = {};
        this.form = $(this.element).find('form');

        this.searching = false;
        this.searchIcon = $(this.element).find('.ft-icon-search');
        this.hovered = false;
        
        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
            var _self = this;
            this.controller = frk.searchController;
            this.controller.loadFindAFundData();
            
            this.templates = {
                listContainer: '<div class="' + this.options.resultClass + '">',
                listItem:
                        '<b class="results-header">Search</b>' +
                        '<div class="list-unstyled list-group results-other">' +
                        '{{#if controller.showPageResults}}' +
                        '<a href="' + this.controller.searchPage + 'query={{query}}&filter=pages" class="list-group-item">Search results for <em>{{query}}</em> in Page Results</a>' +
                        '{{/if}}' +
                        '{{#if controller.showLiteratureResults}}' +
                        '<a href="' + this.controller.literatureSearchPage + '/{{query}}" class="list-group-item">Search results for <em>{{query}}</em> in Forms &amp; Literature</a>' +
                        '{{/if}}' +
                        '{{#if controller.showFundResults}}' +
                        '<b class="results-header">Funds</b>' +
                        '<div class="list-unstyled list-group results-funds">' +
                        '{{#each result}}' +
                        '<a href="{{productUrl \'overview\' fundNumber.val fundCategory.val}}?FundID={{fundNumber.val}}" class="list-group-item fund-name">{{symbol.val}} - {{fundLegalName.val}} - {{shareClass.val}}</a>' +
                        '{{else}}' +
                        '<span>' + this.options.noResultFound + ' {{query}}</span>' +
                        '{{/each}}' +
                        '</div>' +
                        '</div>' +
                        '{{/if}}' +
                        '</div>'
            };
            
            // add empty list where autocomplete result will be displayed
            $(this.searchBox).parent().append(this.templates.listContainer);
            
            var model = this.controller.getModel();
            if (model) {
            	$(_self.searchBox).val(model.query);
            }
            
            this.bindEvents();
        },
        
		bindEvents: function() {
			var _self = this;
			$(this.element).find('.search-box .btn-primary').on("click", function() {
                _self.controller.updateQuery($(_self.searchBox).val());
            });

            $(this.searchBox).on('keydown', function(event) {
    			if (_self.controller.isSearchResultsPage) {
	                if (event.keyCode === 13) {
	                    event.preventDefault();
	                    event.stopPropagation();
	                    _self.controller.updateQuery($(_self.searchBox).val());
	                }
    			}
            });				
            
            $(this.searchBox).on('keyup mouseup', function () {
                frk.delay(_self.searchAutocomplete.bind(_self), 500);
            });
            $(this.searchBox).on('focus', this.showAutocomplete.bind(this));
            $(this.searchBox).on('blur', this.hideAutocomplete.bind(this));

            // autocomplete
            $(this.searchBox).parent().find('.' + this.options.resultClass).on('mouseover', function () {
                _self.hovered = true;
            }).on('mouseout', function () {
                _self.hovered = false;
            });
            
		},
        searchAutocomplete: function () {
            var _self = this;
            var model = this.controller.getModel();

            _self.autocompleteAddLoader();
            if ($(this.searchBox).val().length > 0 && !this.searching && (!this.controller.showFundResults || model.funds.fafdata !== undefined)) {
               
                var query = $(this.searchBox).val();
                var funds = (this.controller.showFundResults)?frk.filter(query, model.funds.fafdata.productsVO.ppsList, model.funds.fields):[];

                // Compile and render template
                var html = Handlebars.compile(this.templates.listItem)({
                	controller: this.controller,
            		query: query,
            		result: funds
                });
                // display result
                this.clearAutocomplete(); //clean first
                $(this.searchBox).parent().find('.' + this.options.resultClass).append(html);

                $(this.searchBox).parent().find('.' + this.options.resultClass).css({'visibility': 'visible'});

                this.autocompleteRemoveLoader();
            }
            else {
                // we clear previous results if any
                _self.clearAutocomplete();
            }
        },
        clearAutocomplete: function () {
            $(this.searchBox).parent().find('.' + this.options.resultClass).empty();
        },
        showAutocomplete: function () {
            $(this.searchBox).parent().find('.' + this.options.resultClass).show();
        },
        hideAutocomplete: function () {
            if (!this.hovered) {
                $(this.searchBox).parent().find('.' + this.options.resultClass).hide();
            }
            this.autocompleteRemoveLoader();
        },
        autocompleteAddLoader: function () {
            $(this.searchIcon).addClass('loader loadersmall');
        },
        autocompleteRemoveLoader: function () {
            $(this.searchIcon).removeClass('loader loadersmall');
        },
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };


})(jQuery, document, frk, Handlebars);
