/** 
 * Quick/View   quick-view
 * 
 */  
;
(function ($, document, frk, Handlebars) {

    // Create the defaults once 
    var componentName = 'quickView',
            defaults = {
                quickviewDataUrl: '/forms-literature/quickview'
                //litOrderQty: '1'
            };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.litcode = $(this.element).data('ftiLitCode'); // it is "fti-lit-code" in the DOM but in JavaScript we use CamelCase. jQuery handles that automatically
        
        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
            // when add to cart button is clicked
            $(this.element).click(this.handleClick.bind(this));
        },
        handleClick: function (event) {
            event.preventDefault();
            event.stopPropagation();
            this.getQuickViewData().then(function (data) {
            	
            	var rolePattern =  /(investor|advisor)\/.*/; 
            	var role = rolePattern.exec(window.location.pathname);
                 
                for (var key in data) {
                    var titl = data[key].internetTitle;
                    var desc = data[key].internetDescription;
                    var cUse = data[key].isClientUse;
                    var litCode = data[key].literatureCode;
                    var nDate = data[key].nextUpdateDateAsString;
                    var pDate = data[key].publicationDateAsString;
                    var formate = data[key].formatDescription;
                    var cSize = data[key].contentSizeDescription;
                    var thumbpath = data[key].thumbnailPath;
                    var finrapath = data[key].finraPath;
                    var hasaddedflag = data[key].hasAdded;
                    var isFAflag = data[key].isFA;
                    var literaturePath = data[key].literaturePath;
                    console.log("OrderLimt is --"+data[key].orderLimit);
                    var orderLimit = data[key].orderLimit;
                    
                     

                    $("#fundName").html(titl);
                    $("#desc").html(desc);
                    
                    if(cUse){
                    $("#isClientUse").html("Yes ");
                    }else{
                     $("#isClientUse").html("No ");
                    }
                    $("#literatureCode").html(litCode);
                    //$("#nextUpdateDateAsString").html(moment(nDate, "YYYY-MM-DD").format("MM/YY"));
                    //$("#publicationDateAsString").html(moment(pDate, "YYYY-MM-DD").format("MM/YY"));
                    $("#nextUpdateDateAsString").html(nDate);
                    $("#publicationDateAsString").html(pDate);
                    $("#formatDescription").html(formate);
                    $("#contentSizeDescription").html(cSize);
                    $("#addcartid").attr("data-fti-lit-code", litCode);
                    console.log('lb lit code:' + $("#addcartid").attr("data-fti-lit-code") + '--' + $('#addcartid').data('ftiLitCode'));
                    if(thumbpath== null || thumbpath==""){                    	
                    	$("#thumbnail").attr("src", "/assets/img/default_lit.png");
                    }else{
                    $("#thumbnail").attr("src", thumbpath);
                    }
                    
                    if(finrapath == null || finrapath ==""){
                    $("#finraspan").addClass("hidden");	
                    }else{
                    $("#finra").attr("href", "/forms-literature/downloadFinra/" + litCode); 
                    }
                    if(role[1] === 'advisor'){ 
                    $("#viewmore").attr("href", "/advisor/resources/lit/info/"+litCode);
                    }else{
                    $("#viewmore").attr("href", "/investor/resources/lit/info/"+litCode);  
                    }
                    $("#quickview-download-link").attr("href", "/forms-literature/download/" + litCode); 
                    if(literaturePath== null || literaturePath==""){                    	
                    	 $('#quickview-download-link').addClass('hidden');
                    	 $('#down-unavl').removeClass('hidden');
                    }else{
                    	$('#quickview-download-link').removeClass('hidden');
                    	 $('#down-unavl').addClass('hidden');
                    }
                    
                    if(!isFAflag){
                    	 $('#isClientRow').addClass('hidden');
                    }
                    
                    $('#addcartid').buttonCart(); // this creates an instance of buttonCart for each element 
                    
                    if(hasaddedflag){
                    	 $('#addcartid').addClass('disabled');
                         $('#addcartid').text("Added ");
                         $('#viewcartid').show();
                    }else{
                    	  
                    	 $('#addcartid').removeClass('disabled');
                    	 if(orderLimit > 0){
                    		 $('#addcartid').text("Add To Cart ");
                    		 $('#addcartid').removeClass('hidden');
                        	 $('#hard-unavl').addClass('hidden');
                    	 }else{
                    		 $('#addcartid').addClass('hidden');
                        	 $('#hard-unavl').removeClass('hidden');
                    	 }
                         
                         $('#viewcartid').hide();
                    }
                    
                    $('#quickview-lightbox').modal('show');
                }
               
            },
             function (errorMessage) {
                // FAILED...
                console.log(errorMessage);
            });
        },
        
        getQuickViewData: function () {
            var dfd = $.Deferred();

            $.ajax({
                url: this.options.quickviewDataUrl,
                data: {literatureCode: this.litcode},
                dataType: 'json',
                type: 'GET'
            }).done(function (data) {
                dfd.resolve(data);
            }).fail(function () {
                dfd.reject('Unable to load data');
            });
            //dfd.resolve({data: this.litcode}); // hard coded with dummy data for demo purposes
            return dfd.promise();
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };

    // ----------
    // markup hook
    $(document).find('[data-fti-component="quick-view"]').each(function () {
        $(this).quickView();
    });
    // ----


})(jQuery, document, frk, Handlebars);