/** 
 * "Add to Cart" Button Handler
 */
;
(function ($, document, frk, Handlebars) {
   var literatureCode;
    // Create the defaults once 
    var componentName = 'buttonCart',
            defaults = {
                cartDataUrl: '/forms-literature/addtocart',
                litOrderQty: '1'
                
            };
 
    // The plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);

        this._defaults = defaults;
        this._name = componentName;
 
        this.litcode = $(this.element).data('ftiLitCode'); // it is "fti-lit-code" in the DOM but in JavaScript we use CamelCase. jQuery handles that automatically
      
        this.cartList = $(document).find('[data-fti-component="cart-list"]');
        
        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
            // when add to cart button is clicked
            $(this.element).click(this.handleClick.bind(this));
            this.disableAddedButtons();
        },
        disableAddedButtons: function () {
            var _self = this;
            // TODO
            // Get cart contents (cartList.js)
            // If this code already in cart, disable button
            // Trigger any time documents are shown (search, my-literature)
        },
        handleClick: function () {
        	var lightBoxId = $(this.element).attr('id');
        	var lightBoxName="";
            var _self = this;
            if(typeof(lightBoxId) != "undefined" && null != lightBoxId && "" != lightBoxId && lightBoxId == "addcartid"){
            	lightBoxName ="quickView";
            }
            
            // Add item to the cart
            this.setCartData().then(function(data) {
                // data = LiteratureInformationVM array of what was added to the cart
            	
                // Style button as "Added"
            	
                $(_self.element).addClass('disabled');
                //$('#addcartid').text("Added ");
                if(lightBoxName == "quickView" ){
                $('#viewcartid').show();
                }
                // Multiple pages using different style of Add To Cart lable due to this here added multiple times.
                $(_self.element).html($(_self.element).html().replace("Add to Cart", "Added"));
                $(_self.element).html($(_self.element).html().replace("Add To Cart", "Added"));
                $(_self.element).html($(_self.element).html().replace("Add to cart", "Added")); // TODO insert text from labels
                var isMandatoryMaterials = false;
                $.each(data, function(key, value) {
                	var obj = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].mandatoryMaterials;
                	var litCode = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].literatureCode;
                
	                	if(literatureCode === litCode && null != obj && "" != obj){
	                	     $("#"+literatureCode).html("Mandatory materials have been added to your cart.");
	                		 literatureCode = "";
	                	}
                });
            	
             
              
                // Show the "Added to Cart" modal lightbox
                $("#totalItems").html(data.length + " Items");
                $(_self.cartList).trigger({
                    type:'showItemAddedModal',
                    litData: data,
                    lgtName:lightBoxName
                });
                
                // trigger cartList.js.addItem(), which updates the header cart with item count and re-renders the list of items
                $(_self.cartList).trigger({
                    type:'updateCart',
                    litData: data,
                    lgtName:lightBoxName
                });
            }, function (errorMessage) {
                // Add to cart failed
                console.error(errorMessage);
            });
        },
        // Adds an item to the cart
        setCartData: function () {
        	this.litcode = $(this.element).attr('data-fti-lit-code');
        	literatureCode = this.litcode;
            var dfd = $.Deferred();
           $.ajax({
                url: this.options.cartDataUrl,
                data: {literatureCode: this.litcode, litOrderQty: this.options.litOrderQty},
                dataType: 'json',
                type: 'POST',
                timeout: 60000
            }).done(function (data) {
            	
            
                dfd.resolve(data);
            }).fail(function (jqXHR, textStatus, errorThrown) {
                dfd.reject('Unable to load data: ' + textStatus + " " + errorThrown);
            });
            return dfd.promise();
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };

    // ----------
    // markup hook
    $(document).find('[data-fti-component="button-cart"]').each(function () {
        $(this).buttonCart();
    });

})(jQuery, document, frk, Handlebars);