;
(function ($, window, document, undefined) {

// Create the default component options
    var componentName = "cardsList",
            defaults = {
            };
    // The actual component constructor
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.init();
    }

    Component.prototype = {
        init: function () {
            var y = 1;
            $('#commentaryWrapper [class^="col-sm-"]').each(function (index) {
                $(this).addClass('pos-' + y);
               
                if (y > 8) $(this).addClass('hidden');
                
                if (y >= 8)$('.load-more').removeClass('hidden');
                 if ($(this).hasClass('col-sm-8')) y = y + 2;                
                if ($(this).hasClass('col-sm-4')) y = y + 1;
                
                
            });
            $('.load-more').click(function () {
                $('#commentaryWrapper [class^="col-sm-"]').removeClass('hidden');
                $('.load-more').addClass('hidden');
            });


        }

    };
    console.log("Registering" + componentName);
    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "component_" + componentName)) {
                $.data(this, "component_" + componentName,
                        new Component(this, options));
            } else {
                $.data(this, "component_" + componentName).init();
            }
        });
    };
})(jQuery, window, document);
