;(function ($, window, document, undefined) {

    // Create the default component options
    var componentName = "rich_text",
            defaults = {
                
            };

    // The actual component constructor
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.init();
    }

    Component.prototype = {
        init: function () {

            var _self = this;

          //expand all/ collapse all control for rich text component
          $('.collapseAll', this.element).on('click', function(){
        	  $('.panel-collapse.in', _self.element).collapse('hide');
          });
          $('.expandAll', this.element).on('click', function(){
        	  $('.panel-collapse:not(".in")', _self.element).collapse('show');
          });

        }

    };
    console.log("Registering" +  componentName);
    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "component_" + componentName)) {
                $.data(this, "component_" + componentName,
                        new Component(this, options));
            } else {
                $.data(this, "component_" + componentName).init();
            }
        });
    };

})(jQuery, window, document);
