/* 
 * Client side Caveat module
 * 
 * Init with caveat data and all element with data-fti-caveat="secName|eleName"
 * will be replace with the appropriate caveat. e.g. 
 *   <span data-fti-caveat="Fund Tab|Morningstar Rating"></span>
 *   
 * To init the plugin:  $('myElement').caveat({data: caveatData});
 * To update:           $('myElement').caveat('render');
 * 
 */
;
(function ($, frk) {

    // Create the defaults once 
    var componentName = 'caveat',
            defaults = {
            };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;

        this._caveatData = this.options.data || {};

        this.isDebugOn = false;
        var queries = frk.getQueryStringParams();

        for (var q in queries) {
            if (queries[q].key === 'debug' && queries[q].value === 'caveats') {
                this.isDebugOn = true;
            }
        }

        // render proximal caveats if any
        if (this._caveatData.proximity) {
            this.proximal();
        }
        // render footnotes if any
        if (this._caveatData.impLegalInfo) {
            this.footer();
        }
        this.render();
    }

    /**
     * Searches the element for data-fti-caveat attrs
     * and try to append <sup> tag reading caveatData
     * 
     * @returns {undefined}
     */
    Component.prototype.render = function () {
        // render caveats if any
        if (this._caveatData.elementLevel) {
            var _self = this;
            // first pass on init, render caveats and remove data-attrs
            $(this.element).find('[data-fti-caveat]').each(function () {
                var kv = _self._getKeyValue($(this).data('ftiCaveat'));
                _self._debugMode(this, 'caveat', kv);
                if (kv) {
                    for (var c in _self._caveatData.elementLevel.caveat) {
                        // we have a match
                        if (_self._caveatData.elementLevel.caveat[c].eleName === kv[1] &&
                                _self._caveatData.elementLevel.caveat[c].secName === kv[0]) {
                            // add sup
                            $(this).after('<sup data-for="' + _self._caveatData.elementLevel.caveat[c].id + '" data-caveatfor="'+$(this).data('ftiCaveat')+'"></sup>');
                        }
                    }
                }
                // remove element so we don't have to deal with it in the future
                $(this).remove();
            });
            // refresh foot notes
            $('[data-fti-module="footnotes"]').footnotes();
        }
    };   
        
    
    /**
     * Searches the element for data-fti-caveat attrs
     * and try to replace <sup> with oroginal placeholder
     * to render caveats again. Required for fund-comparison
     * 
     * @returns {undefined}
     */
    Component.prototype.clearFootnotes = function () {
        // render caveats if any
            var _self = this;
            // first pass on init, render caveats and remove data-attrs
            $(this.element).find('sup[data-for!=""]').each(function () {
                var kv = $(this).data('caveatfor');
                if (kv) {
                        $(this).after('<span data-fti-caveat="' + kv + '"></span>');
                        }
                // remove element so we don't have to deal with it in the future
                $(this).remove();
            });
    };     

    Component.prototype.proximal = function () {
        var _self = this;

        $(this.element).find('[data-fti-caveat-proximal]').each(function () {
            var secName = $(this).data('ftiCaveatProximal');
            _self._debugMode(this, 'proximal', secName);

            for (var info in _self._caveatData.proximity) {
                if (_self._caveatData.proximity[info].secName === secName) {
                    $(this).replaceWith(_self._caveatData.proximity[info].text);
                }
            }
        });
    };

    Component.prototype.footer = function () {
        this._debugMode($('[data-fti-module="footnotes"]'), 'footnotes', this._caveatData.elementLevelCaveats.caveat);

        for (var info in this._caveatData.impLegalInfo.text) {
            $('.legalinfo').append('<li> ' +
                    this._caveatData.impLegalInfo.text[info] +
                    '</li>');
        }

        for (var c in this._caveatData.elementLevelCaveats.caveat) {
            $('[data-fti-module="footnotes"]').append('<li data-footnote="' + this._caveatData.elementLevelCaveats.caveat[c].id + '"> ' +
                    this._caveatData.elementLevelCaveats.caveat[c].text +
                    '</li>');
        }
    };

    Component.prototype._getKeyValue = function (raw) {
        var split = raw.split('|');

        if (split.length === 2) {
            return split;
        }
        return false;
    };

    Component.prototype._debugMode = function (el, type, data) {
        if (this.isDebugOn) {
            switch (type) {
                case 'proximal':
                    $(el).before('<div style="color:red;">[Proximal: \'' + data + '\']</div>');
                    break;
                case 'caveat':
                    $(el).after('<sup style="color:red;" title="secName: \'' + data[0] + '\' ; eleName: \' ' + data[1] + ' \'">[f]</sup>');
                    break;
                case 'footnotes':
                    for (var c in data) {
                        $(el).append('<li style="color:red;">' + data[c].id + ' - ' + data[c].text +'</li>');
                    }
                    break;
            }
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            // first call, init
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            } else {
                // already been called on this element, at
                // look for options (contains method to run)
//                try {
//                    $.data(this, 'component_' + componentName)[options]();
//                }
//                catch (e) {
//                    throw 'Unable to execute method ' + options;
//                }
                // only method we can call
                if (options.data && options.fundcomparison===true)
                {
                    // refresh data if provided - GAST-355
                    $.data(this, 'component_' + componentName)._caveatData = options.data;    
                    $('[data-fti-module="footnotes"]').html('');
                    $('ul.legalinfo').html('');  // TODO - make it component dependent
                    $.data(this, 'component_' + componentName).footer();
                }
                $.data(this, 'component_' + componentName).render();
            }
        });
    };
})(jQuery, frk);
