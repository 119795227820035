;
(function ($, window, document, undefined) {

// Create the default component options
    var componentName = "gpsfeed",
            defaults = {
            };
    // The actual component constructor
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.init();
    }

    Component.prototype = {
        init: function () {



            //for links
            $("a#load-feed").click(function () {

                jQuery.ajax({
                    url: "//sp.frk.com/communities/GlobalResearchandAnalytics/default.aspx",
                    dataType: "text", // "html"
                    success: function (html) {
                        var $load_data = jQuery('#WebPartWPQ6', $(html)); // look for a specific element in the returned HTML
                        load_data_title = jQuery($load_data).find('.content').text();
                        jQuery('.target').text(load_data_title);
                    }
                });
            });
        }

    };
    console.log("Registering" + componentName);
    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "component_" + componentName)) {
                $.data(this, "component_" + componentName,
                        new Component(this, options));
            } else {
                $.data(this, "component_" + componentName).init();
            }
        });
    };
})(jQuery, window, document);
