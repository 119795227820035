/**
 * Online Scheduling Tool Launch module
 * 
 * author Ben St Johnston
 */
;
(function ($, document, frk) {

    var componentName = 'onlineSchedulingTool';
    
    var FORM_ID = 'ostForm';
    var CAMPAIGN_ID_PARAM = 'campaignId';
    var QUESTION_ID_PARAM = 'questionId__comment';
    
    var DEBUG_ACTION_ID = "debugActionId";

	function Component(element, options) {
		this._name = componentName;
		var action = $(element).data('action');
		var debug = $(element).data('debug');
		var campaignID = getURLParameter(CAMPAIGN_ID_PARAM);
		var questions = getURLParameter(QUESTION_ID_PARAM);
		campaignID = (campaignID !== "") ? "campaignId=ft.com_" + campaignID : "campaignId=ft.com";
		action += "?" + campaignID + "&questionId__comment="+questions;
		if (debug) {
			var result = document.getElementById(DEBUG_ACTION_ID);
			if (result) {
				$(result).html(action);
			}
		} else {
			element.action=action;
			element.submit();					
		}
    }
	
	function getURLParameter(paramname) {
		var url = window.location.search.substring(1);

		var  parameters = url.split("&");

		for (i = 0; i < parameters.length; i++) {
			ft = parameters[i].split("=");
			if (ft[0] == paramname) {
				return ft[1];
			}
		}
		return "";
	}
	
	$.fn[componentName] = function(options) {
		return this.each(function() {
			if (!$.data(this, 'component_' + componentName)) {
				$.data(this, 'component_' + componentName, new Component(this,
						options));
			}
		});
	};
	
	
})(jQuery, document, frk);

