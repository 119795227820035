/* View Cart Details */

(function ($, document, frk, Handlebars) {
    // Create the defaults once 
    var componentName = 'cartDelivery',
            defaults = {
    		 modalTemplateUrl: '/assets/templates/html/components/literature/order-lit/mandatory-materials-cart-list.html'
            };

    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.deliveryType = $(this.element).find('.deliveryType');
        this.radioBtnClick = $(this.element).find('input[type="radio"]');
        this.resultList = $(this.element).find('.cart-items');
        this.zipdownload = $(this.element).find('[ data-fti-download="zipDownload"]');
        //this.removeCartItem =$(this.element).find('[ data-fti-remove="removeItem"]');
        this.CartToDeliveryInfo = $(this.element).find('[ data-fti-button="CartToDeliveryInfo"]');
        this.emailLiterature = $(this.element).find('[ data-fti-email="emailLiterature"]');
        this.litcode = $(this.element).data('ftiLitCode');
        this.varcancel = $(this.element).find('#cancel');
      
      
        this.init();
    }

    Component.prototype = {
        init: function () {
        	
            this.deliveryType.hide();
            
            $(this.varcancel).on('click', function () {
        		// alert("cancel");  
        		 $.ajax({
                     type: 'POST',
                     url: '/forms-literature/remove-all-cartItems' 
                 }).done(function(e, data) {
                     console.log("post result: " + e + data);
                 });
        	 }); 
           
         
            $('input[name="inputEmailAddress"]').keyup(function(){
            	var emailReg = /^([-\w\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
       	        var email = $('#inputEmailAddress').val();
    	        if(!emailReg.test(email)) {  
    		     $("#emailError").show(); 
    		     $('#emailLit').prop('disabled', true);
    		      return;
    	        }else{
    	        	if(null == email || "" == email){
    	        		 $("#emailError").show(); 
    	    		     $('#emailLit').prop('disabled', true); 
    	        		return 
    	        	}
    	          $("#emailError").hide(); 
    	          $('#emailLit').prop('disabled', false); 
      		      return;
    	        }
    	    });	
             
            this.zipdownload.click(function () {
                //window.location.href = "/forms-literature/download-zip";
                window.open('/forms-literature/download-zip','_blank');
            });

            this.emailLiterature.click(function () {
            	
            	
            	
                $.ajax({
                    url: '/forms-literature/sendEmail',
                    dataType: 'text',
                    type: 'GET',
                    data: {email: $('#inputEmailAddress').val()},
                    success: function (data) {
                    	$("#em1").show();
                    	$("#emailid").text($('#inputEmailAddress').val());
                       console.log("Success");
                    },
                    error: function () {
                    	
                        console.log("Failed");
                    }
                });
            });
            this.CartToDeliveryInfo.click(function () {
                var cartMap = {};
                $("select").each(function(){
                cartMap[$(this).prop('name')] =$(this).val();
                });
              $.ajax({
                    url: '/forms-literature/update-cartItem',
                    dataType: 'text',
                    type: 'POST',
                    data: {cartMap: cartMap},
                    success: function (data) {
                    	 var rolePattern = /(investor|advisor)\/.*/;
                         var role = rolePattern.exec(window.location.pathname);
                        if(role[1] === 'advisor'){
                        	  window.location.href = "/advisor/resources/lit/checkout-address";
                     	}else{
                     		 window.location.href = "/investor/resources/lit/checkout-address";
                     	}
                    },
                    error: function () {
                        console.log("Failed");
                    }
                });
            });

            this.radioBtnClick.click(function () {
            	var aLitCodes = "";
            	$.each(_self.data, function(key, value) {
            		var flag = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].isNoLitPathIndicator;
            		console.log("==11=="+value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].literatureCode);
            		if(flag){
            			if(aLitCodes!==""){
            			aLitCodes = aLitCodes+"<li style='color:black'>"+ value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].internetTitle +'</li>';
            			}else{
            			aLitCodes = "<li style='color:black'>"+value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].internetTitle+'</li>';
            			}
            		}
            	}); 
            	 
            	
             if ($(this).attr("value") === "downloadLit") {
                 _self.clientUse(_self.data,"download",aLitCodes);
              }
             if ($(this).attr("value") === "emailFile") {
                  _self.clientUse(_self.data,"email",aLitCodes);
             }
            if ($(this).attr("value") === "snailMail") {
                 _self.clientUse(_self.data,"mail",aLitCodes);
              }
            }); //end radio button options 
            
            
           	 

            if ($(this.resultList).data('ftiTemplate')) {
            	
		            if ($(this.element).data('ftiJson')) {
		                this.jsonURL = $(this.element).data('ftiJson');
		            } else {
		                throw 'No JSON Data specified';
		            }
	                this.templateUrl = $(this.resultList).data('ftiTemplate');
	                
	                var _self = this;
	                
	                $.when(this.getTemplate(), this.getData()).then(function (template, data) {
	                   
	                    _self.render(_self.template, _self.data);
	                    _self.getCount(_self.data);
	                  
	                    var mailUrl = window.location.search.substring("?");
	                    if(null != mailUrl && "" != mailUrl && mailUrl.indexOf("deliveryType=Mail") > -1){
	                         $("#snailMail").click();
	                    }else if(null != mailUrl && "" != mailUrl && mailUrl.indexOf("deliveryType=Email") > -1){
	                    	 $("#emailFile").click();
	                    }else if(null != mailUrl && "" != mailUrl && mailUrl.indexOf("deliveryType=Download")> -1){
	                    	
	                    	 $("#downloadLit").click();
	                    }else{
	                    	
	                    }
	                   
	                    
	                });
                
            } else {
                throw 'No Search Result Template specified';
            }

           
           
           
 
        },
        getCount: function (data) {
        

            var _self = this;
           
           
           
        
            this.view = {
            		
                totalTitles: function () {
                	
                	 var _title = 0;
                $.each(data, function(key, value) {
                		
                		_title =_title+1;
                	});
                	
                    return _title;
                },
                totalItems: function () {
                	 var _item = 0;
                	
                    $.each(data, function(key, value) {
                    	
                    	_item =_item+value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].orderQuantity;
                    	});
                    	
                        return _item;
                    }
                // TODO: update filter values and counts.
            };
            // init view engine for data binding
            this.viewEngine = frk.viewEngine(this.view);
            this.viewEngine.init();
            this.viewEngine.updateAll();
        
        	
        },
        getData: function () {

            var dfd = $.Deferred();
            var _self = this;

            $.ajax({
                url: this.jsonURL,
                dataType: 'json',
                cache:false 
            }).done(function (data) {
                _self.data = data;
                var i=0;
                var k=0;
            	$.each(data, function(key, value) {
            		var flag = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].isNoLitPathIndicator;
            		console.log("==121=="+value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].literatureCode);
            		if(flag){
            			i++;
            		}else{
            			k++;
            		}
            	});
            	
            	if(i>0 && k == 0){
            		console.log("only items");
            		 $('#downloadLit').prop('disabled', true); 
            		 $('#emailFile').prop('disabled', true); 
            		 $("#snailMail").prop("checked", true)
            		 $("input[name='deliverOptions']").val("snailMail");
            	}
                dfd.resolve(data);
            }).fail(function () {
                dfd.reject('Unable to load data');
            });

            return dfd.promise();
        },
        clientUse: function (data,selection,litcodes) {
        	
        	
        	//console.log(" ===="+ JSON.stringify(data[0]['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM']))
        	var noClientFlag = false;        	
        	$.each(data, function(key, value) {
        		//console.log(key)
        		//console.log(value)
        		//console.log(" ---"+ JSON.stringify(lit))
        		
        		//console.log("===="+ value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].isClientUse)
        		
        		var clientUse = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].isClientUse; 
        		
        		
							if(selection == "download"){
								$("#noLitAlertID").show();	
							if (clientUse) {
							 
								$(".deliveryType").not(".downloadLit").hide();
								$(".downloadLit").show();
								$("#rw1").hide();
								$("#rw2").show();
								if(litcodes!=""){ 
									$("#rw1").show();
									$("#msg1").hide();
									$("#msg3").html(litcodes);
								}
								
								
								} else {
									noClientFlag = true; 
									$(".deliveryType").not(".downloadLit").hide();
									$(".downloadLit").show();
									$("#rw1").show();
									if(litcodes!=""){  
										$("#msg2").show();
										$("#msg3").html(litcodes);
									}else{ 
										$("#msg2").hide();
									}
									
									
									return false;
									
								}
							    
								}
								if(selection == "email"){
									$("#noLitAlertID").show();
									if (clientUse  ) {

										$(".deliveryType").not(".emailFile").hide();
										$(".emailFile").show();
										$("#rw3").hide();
										$("#rw4").show();
										$("#rw5").show();
										
										if(litcodes!=""){ 
											$("#rw3").show();
											$("#emsg1").hide();
											$("#emsg3").html(litcodes);
										}
										
									} else {
										noClientFlag = true;
										$(".deliveryType").not(".emailFile").hide();
										$(".emailFile").show();
										$("#rw3").show();
										
										if(litcodes!=""){ 
											$("#emsg2").show();
											$("#emsg3").html(litcodes);
										}else{ 
											$("#emsg2").hide();
										}
										
										
										return false;
										
									}
								}
								if(selection == "mail"){
									$("#noLitAlertID").hide();
									if (clientUse) {

										$(".deliveryType").not(".snailMail").hide();
										$(".snailMail").show();
										$("#rw6").hide();
										$("#CartToDeliveryInfo").show();
										
									
										
									} else {
										$(".deliveryType").not(".snailMail").hide();
										$(".snailMail").show();
										$("#rw6").show();
										
										
										
										return false;
										
									}	
								}
								
        		
              
								
       
             });
        	if(noClientFlag){
		    	$("#msg1").show();
		    	$("#emsg1").show();
		    }
        },
        getTemplate: function () {

            var dfd = $.Deferred();
            var _self = this;
            if (!_self.template) {
                $.ajax({
                    url: _self.templateUrl,
                    dataType: 'html'
                }).done(function (data) {
                    _self.template = data;

                    dfd.resolve(data);
                }).fail(function () {
                    dfd.reject('Unable to load data');
                });

            } else {
                dfd.resolve(this.template);
            }
            return dfd.promise();
        },
        render: function (template, data) {
        	
        	if(data.length > 0){
        		var _self = this;
                var html = Handlebars.compile(template)({object: data});
                $(this.resultList).empty();
                $(this.resultList).append(html);
                
                
                	 $(this.element).find('[data-fti-component="removeItem"]').click(function () {
                		 var itemSelected = $(this).attr('id');
                		
                		 _self.getModalTemplate().then(function () {
                                     
                                	 _self.removeCartItem(_self.data,itemSelected,_self.modalTemplate);
                               
                             }, function() {
                                 console.error('error getting modal template');
                             });
                       
    				
    				
    			});
        	}else{
        		$("#cartDeliveryModule").hide();
        		$("#message").show();
        	}
        	
        },
        getModalTemplate: function () {
        	
            var dfd = $.Deferred();
            var _self = this;
            if (!_self.modalTemplate) {
            	
                $.ajax({
                    url: '/assets/templates/html/components/literature/order-lit/mandatory-materials-cart-list.html',
                    dataType: 'html'
                }).done(function (data) {
                	
                    _self.modalTemplate = data;
                   
                    dfd.resolve(data);
                }).fail(function () {
                	
                    dfd.reject('Unable to load data');
                });
            }
            else {
            	
                dfd.resolve(this.modalTemplate);
            }
            return dfd.promise();
        },
        removeCartItem : function(data,literatureCode,modalTemplate){
        	
        	$.each(data, function(key, value) {
        	    
        		var litCode = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].literatureCode;
        		
        		if(litCode == literatureCode){
        			var madatoryVar = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].mandatoryMaterials;
        			var parentVar = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].parents;
        			var packageCodeVar = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].packageCode;
        			var packageTypeVar = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].literatureType;
        			var internettitleVar = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].internetTitle;
        			
        			if(typeof madatoryVar !== "undefined" && madatoryVar != null && madatoryVar !="" && (parentVar == null || parentVar == "")){
        				var jsonObj = [];
        				var items = {};
        			
        				var divided = madatoryVar.split(',');
        				for(var i=0;i<divided.length;i++){
        					var item = {};
        					$.each(data, function(key, value) {
        						var litCde = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].literatureCode;
	        					if(divided[i] == litCde){
	        						var item = {};
	        				        item ["title"] = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].internetTitle;
	        				        item ["litCode"] = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].literatureCode;
	        				        jsonObj.push(item);
	        						
	        					}
        					});
        				}
        				items["items"]=jsonObj;
        				$("#parentLiterature").html(internettitleVar);
        				$("#parent_lit").val(litCode);
        				var html = Handlebars.compile(modalTemplate)(items);
        		        $("#mandItems").html(html);
        				$('#mand-modal').modal('show');
        				
        			}else if(typeof parentVar !== "undefined" && parentVar != null && parentVar != ""){
        				
        				$("#"+value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].literatureCode).show();
        				$("#adng"+value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].literatureCode).show();
        				
        			}else{
        				
        				removeItemFromCart(packageCodeVar,packageTypeVar);
        			}
        			
        		}
        		
        	});
        	},
        	removeItemFromCart : function(packageCode,packageType){


        	    $.ajax({
        	        url: '/forms-literature/remove-cartItem',
        	        dataType: 'text',
        	        type: 'POST',
        	        data: {packageCode: packageCode, packageType: packageType},
        	        success: function (data) {
        	        	var optionSelected = $("input[name='deliverOptions']:checked").val();
        	        	 var rolePattern =  /(investor|advisor)\/.*/;
        	             var role = rolePattern.exec(window.location.pathname);
        	           if(typeof optionSelected !== "undefined" && optionSelected === "downloadLit"){
        	        	 
        	        	   if(role[1] === 'advisor'){
        	             	  window.location.href = "/advisor/resources/lit/cart?deliveryType=Download";
        	          	}else{
        	          		 window.location.href = "/investor/resources/lit/cart?deliveryType=Download";     
        	          	}
        	        	   
        	           }else if(typeof optionSelected !== "undefined" && optionSelected === "emailFile"){
        	        	   
        	        	   if(role[1] === 'advisor'){
        	             	  window.location.href = "/advisor/resources/lit/cart?deliveryType=Email";
        	          	   }else{
        	          		 window.location.href = "/investor/resources/lit/cart?deliveryType=Email";     
        	          	   }
        	        	   
        	           }else if(typeof optionSelected !== "undefined" && optionSelected === "snailMail"){
        	        	   if(role[1] === 'advisor'){
        	             	  window.location.href = "/advisor/resources/lit/cart?deliveryType=Mail";
        	          	   }else{
        	          		 window.location.href = "/investor/resources/lit/cart?deliveryType=Mail";     
        	          	    }
        	           }else{
        	        	   window.location.reload(true); 
        	           }
        	        	
        	        	 
        	        },
        	        error: function () {
        	            console.log("Failed");
        	        }
        	    });

        	 }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "component_" + componentName)) {
                $.data(this, "component_" + componentName,
                        new Component(this, options));
            }
        });
    };
})(jQuery, document, frk, Handlebars);

function removeItemFromCart(packageCode, packageType) {

    $.ajax({
        url: '/forms-literature/remove-cartItem',
        dataType: 'text',
        type: 'POST',
        data: {packageCode: packageCode, packageType: packageType},
        success: function (data) {
        	var optionSelected = $("input[name='deliverOptions']:checked").val();
        	 var rolePattern =  /(investor|advisor)\/.*/;
             var role = rolePattern.exec(window.location.pathname);
           if(typeof optionSelected !== "undefined" && optionSelected === "downloadLit"){
        	 
        	   if(role[1] === 'advisor'){
             	  window.location.href = "/advisor/resources/lit/cart?deliveryType=Download";
          	}else{
          		 window.location.href = "/investor/resources/lit/cart?deliveryType=Download";     
          	}
        	   
           }else if(typeof optionSelected !== "undefined" && optionSelected === "emailFile"){
        	   
        	   if(role[1] === 'advisor'){
             	  window.location.href = "/advisor/resources/lit/cart?deliveryType=Email";
          	   }else{
          		 window.location.href = "/investor/resources/lit/cart?deliveryType=Email";     
          	   }
        	   
           }else if(typeof optionSelected !== "undefined" && optionSelected === "snailMail"){
        	   if(role[1] === 'advisor'){
             	  window.location.href = "/advisor/resources/lit/cart?deliveryType=Mail";
          	   }else{
          		 window.location.href = "/investor/resources/lit/cart?deliveryType=Mail";     
          	    }
           }else{
        	   window.location.reload(true); 
           }
        	
        	 
        },
        error: function () {
            console.log("Failed");
        }
    });
}

function updateTotals() {
	
    var cartMap = {};
    $("select").each(function(){
    cartMap[$(this).prop('name')] =$(this).val();
    });
  $.ajax({
        url: '/forms-literature/update-cartItem',
        dataType: 'text',
        type: 'POST',
        data: {cartMap: cartMap},
        success: function (data) {
        	 var rolePattern =  /(investor|advisor)\/.*/;
             var role = rolePattern.exec(window.location.pathname);
            if(role[1] === 'advisor'){
            	  window.location.href = "/advisor/resources/lit/cart?deliveryType=Mail";
         	}else{
         		 window.location.href = "/investor/resources/lit/cart?deliveryType=Mail";     
         	}
        },
        error: function () {
            console.log("Failed");
        }
    });
}
function removeItems() {
	var mandetoryMap="";
	var count = 0;
	$('input:checkbox[name=litCode]').each(function() 
			{    
			    if($(this).is(':checked')){
			    	
			    	mandetoryMap = $(this).val()+"~"+mandetoryMap;
			    	
			    	
			    }
			      
			});
	
	 $.ajax({
	        url: '/forms-literature/removeMandatoryAndParentItems',
	        dataType: 'text',
	        type: 'POST',
	        data: {mandetoryMap: mandetoryMap,parentLiterature:$("#parent_lit").val()},
	        success: function (data) {

	        	var optionSelected = $("input[name='deliverOptions']:checked").val();
	        	 var rolePattern =  /(investor|advisor)\/.*/;
	             var role = rolePattern.exec(window.location.pathname);
	           if(typeof optionSelected !== "undefined" && optionSelected === "downloadLit"){
	        	  
	        	   if(role[1] === 'advisor'){
	             	  window.location.href = "/advisor/resources/lit/cart?deliveryType=Download";
	          	}else{
	          		 window.location.href = "/investor/resources/lit/cart?deliveryType=Download";     
	          	}
	        	   
	           }else if(typeof optionSelected !== "undefined" && optionSelected === "emailFile"){
	        	   
	        	   if(role[1] === 'advisor'){
	             	  window.location.href = "/advisor/resources/lit/cart?deliveryType=Email";
	          	   }else{
	          		 window.location.href = "/investor/resources/lit/cart?deliveryType=Email";     
	          	   }
	        	   
	           }else if(typeof optionSelected !== "undefined" && optionSelected === "snailMail"){
	        	   if(role[1] === 'advisor'){
	             	  window.location.href = "/advisor/resources/lit/cart?deliveryType=Mail";
	          	   }else{
	          		 window.location.href = "/investor/resources/lit/cart?deliveryType=Mail";     
	          	    }
	           }else{
	        	   window.location.reload(true); 
	           }
	        	
	        	 
	        
	        	
	        },
	        error: function () {
	            console.log("Failed");
	        }
	    });
	
	
   
}
