;
(function ($, frk, document) {

    // Create the defaults once
    var componentName = "fundComparison",
            defaults = {
                addAnotherFundLabel: 'ADD ANOTHER FUND',
                removeFundLabel: 'REMOVE FUND',
                selectAFundLabel: 'Select a Fund',
                avgAnnualTitleIndex: 18,
                offSetRows: [19, 24],
                queryString: 'funds',
                maxFunds: 4
            };


    // The actual plugin constructor
    function Component(element, options) {
        this.colIndex = 2;
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.fundData = [];
        this._labelsUrl = this.options.ftiLabels;
        this.maxDate=0;
        this.caveats={};
        this.init();
    }

    // Create methods associated with the plugin
    Component.prototype = {
        /**
         * Initialise Component
         * @returns {undefined}
         */
        init: function () {
            // Read the JSON attributes
            this.readDataAttributes();

            // Set up Event Listeners
            this.addEventListeners();
            
            // read labels file
            this._initLabels();
            
            // set default frequency
            this.frequency="me";

            // Read the Query String
            // Check if any fund id has been passed - if so then load the data required
            var qs = frk.getQueryStringParams(this.options.queryString),
                    fundIds = [];
                if (qs.length > 0) {
                    
                    fundIds = qs.split(',');
                    this.addLoader();
                    this.iterateFundIDs(fundIds);
                }
        },
        _initLabels: function () {
            var _self = this;
            this._getLabels().then(function (labelsData) {
                $(_self.element).labels({data: labelsData.labels.label});
            });
        },   
        _getLabels: function () {
            var _self = this,
                    _dfd = $.Deferred();

            if (!this._labels) {
                frk.load(this._labelsUrl, 'json').then(
                        function (data) {
                            // save data
                            _self._labels = data;
                            _dfd.resolve(data);
                        },
                        function (message) {
                            _dfd.reject(message);
                        }
                );
            }
            else {
                _dfd.resolve(this._labels);
            }
            return _dfd.promise();
        },        
        /**
         * Read the Template URL and JSON URL from the component attributes
         * @returns {undefined}
         */
        readDataAttributes: function () {
            // get and set json url
            if ($(this.element).data('ftiJson')) {
                this.jsonURL = $(this.element).data('ftiJson');
            }
            else {
                throw 'No JSON Data specified';
            }
            // get handlebars template
            if ($(this.element).data('ftiTemplate')) {
                this.templateUrl = $(this.element).data('ftiTemplate');
            }
            else {
                throw 'No Template specified';
            }
        },
        /**
         * Add Event Listeners 
         * @returns {unresolved}
         */
        addEventListeners: function () {

            var _self = this;

            // Listen for add fund 
            $(_self.element).on('click', '.fund-comparison-table .btn-secondary', function (e) {
                e.preventDefault();
            });

            // Handle fund selection from find a fund (TODO: improve scope)
            $(document).find('[data-fti-module="find-a-fund"]').on('fund-selected', function (e, fundId) {
                // Close Modal
                $('[data-fti-component="fund-comparison-modal"]').modal('hide');
                // Render fund data
                _self.addLoader();
                _self.iterateFundIDs([fundId]);
            });

            // Handle the remove button click
            $(_self.element).on('click', '.fund-comparison-table .remove-btn', function (e) {
                // Get index of parent td among its siblings (add one for nth-child)
                var fundId=$(this).data("fundid");
                _self._clearFundColumn($(this).parent().index() + 1);
                _self.colIndex -= 1;
                
                
                if (_self.colIndex===2)
                {
                    _self._clearAsOfDate();
                    _self._clearCaveats();
                }
                else
                {
                    // set max dates after removing column - it might be max date.
                     _self._setAsOfDates();    
                }
                
                // recreate caveats                                     
                $elemCaveatObj=$.parseJSON(_self.caveats[fundId]);  
                _self.removeCaveats(fundId);  // remove caveats for fund id and merge again
                
                $mergedCaveats=_self.mergeCaveats($elemCaveatObj);  
                $(_self.element).caveat({data:$mergedCaveats,fundcomparison:true});
                                          
                e.preventDefault();                


                
            }); // end of remove handler
            
            // Handle frequency toggle, replacing qe and me attributes
            $(_self.element).on('change', '.fund-comparison-table .month-quarter-radio', function (e) {                      
                // TODO check if data was already loaded into table
                if ($(".fund-comparison-table input[value='quarter-end']").is(":checked")) {
                    _self.frequency="qe";
                    _self.toggleFrequency("qe");
                }
                else {
                    _self.frequency="me";
                    _self.toggleFrequency("me");
                }
            }); // end of frequency toggle        

        },
        /**
         * Fetch the template then render fund data in each column
         * @param {type} fundIds
         * @returns {undefined}
         */
        iterateFundIDs: function (fundIds) {
            var _self = this;

            // Now we must load a max of 4 funds
            if (typeof fundIds !== 'undefined' && fundIds.length !== 0) {

                _self.getTemplate().then(function (template) {
                        $.each(fundIds, function (i) {
                        if (i < _self.options.maxFunds) {
                            _self.getData(fundIds[i]).then(function (data) {
                                var fundIdentifier=fundIds[i];
                                if (_self.renderColumns(template, data))
                                      {
                                          _self.colIndex += 1; // increment col index if successfully rendered                                                                                     
                                          _self.caveats[fundIdentifier]=JSON.stringify(data.fundOverviewVO.caveatvo);
                                          // merge all caveats                                                                                                
                                          $mergedCaveats=_self.mergeCaveats(data.fundOverviewVO.caveatvo);                                                                                   
                                          //$mergedCaveats=data.fundOverviewVO.caveatvo; // TODO - remove this, and make proper merging                                                                                 
                                                                                 
                                          $(_self.element).caveat({data:$mergedCaveats,fundcomparison:true});
                                          
                                          if(data.fundOverviewVO.caveatvo && !$('.ili-heading').length)
                                          {
                                              $('#important-legal .legal').prepend('<h3 class="ili-heading">Important Legal Information</h3>').labels();
                                          }
                                          
                                           _self.removeLoader();
                                      }
                                       
                                    
                            });
                        }
                    });
                });
            }           
        },/**
         * Removes from global caveat llist caveats for specified fundID
         */
        removeCaveats: function (fundID) {
            var _self = this;            
            delete _self.caveats[fundID];
        },/**
         * Merge all element caveats available for page
         */
        mergeCaveats: function (caveatsData) {
            var _self = this;
            $mergedElementId=[];
            $mergedElementLevelId=[];
            $mergedElementLevel=[];
            $mergedElementLevelCaveats=[];
            
            $.each(_self.caveats,function(index, value ){
                $elemCaveatObj=$.parseJSON(value);                
                
                $.each($elemCaveatObj.elementLevel.caveat,function(elementIndex,elementValue){
                    if ($.inArray(elementValue.id , $mergedElementId)<0)
                    {
                        $mergedElementId.push(elementValue.id);
                        $mergedElementLevel.push(elementValue);
                    };                    
                });
                
                
                $.each($elemCaveatObj.elementLevelCaveats.caveat,function(elementIndex,elementValue){
                    if ($.inArray(elementValue.id , $mergedElementLevelId)<0)
                    {
                        $mergedElementLevelId.push(elementValue.id);
                        $mergedElementLevelCaveats.push(elementValue);
                    };                    
                });

            });                              
            //caveatsData.elementLevel.caveat=$mergedElementLevel; // TODO - we don;t need to regenerate superscript placeholders
            caveatsData.elementLevelCaveats.caveat=$mergedElementLevelCaveats;
            return caveatsData;
        },   
        /**
         * Loads the Handlebars template
         */
        getTemplate: function () {
            var _self = this,
                    dfd = $.Deferred();
            if (typeof _self.template === 'undefined') {
                frk.load(this.templateUrl, 'html').then(
                        function (data) {
                            // save data
                            _self.template = data;
                            dfd.resolve(data);
                        },
                        function (message) {
                            dfd.reject(message);
                        }
                );
            }
            else {
                dfd.resolve(_self.template);
            }
            return dfd.promise();
        },
        /**
         * Loads the JSON content for each FundID
         */
        getData: function (fundId) {
            var fundJsonUrl = this.jsonURL + fundId;
            var dfd = $.Deferred();
            var _self = this;

            $.when(frk.load($(this.element).data('ftiUsConfig'), 'json', true), frk.load(fundJsonUrl, 'json', true)).then(
                    function (config, data) {
                        frk.UsConfig.setData(config, $(_self.element).data('ftiActor'));
                        dfd.resolve(data);
                    },
                    function (message) {
                        dfd.reject(message);
                    }
            );
            return dfd.promise();
        },
        /**
         * Apply template to data
         * TODO: do some more test on data, making sure there is something to render with
         */
        renderColumns: function (template, data) {
            var _self = this,
                    offsetIndex = _self.colIndex,
                    fullTable = $('.fund-comparison-table'),
                    tdToReplace;

            if (data) {
                if (frk.UsConfig.getData()) {
                    var stdDeviation1Yr = frk.UsConfig.getData().config.performance.performance.displayUpdateFrequency1yr.fundID;
                    var configDistributions = frk.UsConfig.getData().config.global.distributions;                    
                    data.configStdDev=stdDeviation1Yr;
                    data.configDistributions=configDistributions;                  
                    }
                
                var shareClass = data.fundOverviewVO.fundInfo.shareClass.val;
                data.fundOverviewVO.perf.performanceDetails.shareClass=shareClass;
                data.fundOverviewVO.perf.performanceDetails.monthQuarterEnd='AATR_'+_self.frequency.toUpperCase();
                
                
                this.fundTable = Handlebars.compile(template)(data);
                
                $('> tbody > tr', this.fundTable).each(function (rowIndex) {

                    if (rowIndex !== _self.options.avgAnnualTitleIndex) {
                        offsetIndex = _self.colIndex;

                        if (_self.options.offSetRows.indexOf(rowIndex) > -1) {
                            offsetIndex = _self.colIndex + 1;
                        }

                        // Td to replace
                        tdToReplace = $('> tbody > tr:nth-child(' + (rowIndex + 1) + ')' + ' > *:nth-child(' + offsetIndex + ')', fullTable);
                        if (typeof tdToReplace.html() !== 'undefined') {
                            tdToReplace.replaceWith($(this).html());
                            tdToReplace.attr('rowspan', '');
                        } else {
                            $('> tbody > tr:nth-child(' + (rowIndex + 1) + ') > *:nth-child(' + (offsetIndex - 1) + ')', fullTable).after($(this).html());
                        }

                    }

                }); // end loop

                // put mdash for empty asset mix
                _self._putMdashIfEmpty();     
   
                // update labels
                $(this.element).labels();             
                
                // set asofdate for elements
                _self._setAsOfDates();                                
      
                // success                                
                return true;
            }
            else
                return false;
        },
        _setAsOfDates: function ()
        {
            var _self = this;
            var options=[];
            options['hash'] = [];
            options['hash']['date']="true";    
                
                // not needed after requirements change (from Wei, George, 2016.09.05)
                /*
                // find maxdate and set in first column
                _self._setNotEmptyAsof($('#total-return-nav-date'));                    
                _self._setNotEmptyAsof($('#distribution-rate-nav-date'));                        
                _self._setNotEmptyAsof($('#std-dev-date'));                  
                _self._setNotEmptyAsof($('#aatrdate'));
                
                // to check which values are not matching asofdate and replace it with mdash.
                
                _self._checkNotMatchingDates($('#total-return-nav-date'));                     
                _self._checkNotMatchingDates($('#distribution-rate-nav-date'));                       
                _self._checkNotMatchingDates($('#std-dev-date'));    
                */
        },
        _setNotEmptyAsof: function (where)
        {             
            // not needed after requirements change (from Wei, George, 2016.09.05)
            var _self=this;
            // this formula set max date for specific group of elements.
            // it is required to display max date when fund with max date is removed from table
            // 
            // create array of dates
            
            var elementDates=[];
            if (where.attr('id')==='aatrdate')
            {
                    var aatrType=_self.frequency;                    
                    where.parents('tr').next().find('td[data-aatrdate'+aatrType+']').map(function(){
                        elementDate=$(this).data('aatrdate'+aatrType);                                                                          
                        $(this).find('small').html('As of '+elementDate);
            
                     });   
            };
            

      
            
        },   
        _putMdashIfEmpty: function ()
        {
            var _self = this;
            var assetMix=$('.assetmix');
            
            $.each(assetMix,function(index,value){
                if (_self.isEmpty($(this))) {        
                    $(this).removeClass("nests").html('<span data-fti-label="mdash"></span>');
                }
            });
        },        
        _checkNotMatchingDates: function(where)
        {
            // TODO 
            // check elements with date not matching maxdate
            var elementValues = [];
            var _self = this;
            

            if (where.attr('id') === 'aatrdate')
            {
                
                elementDate = $("aatr-row1 .aatr-asofdata").data('aatrdate'+_self.frequency);
                convertedDate = new Date(elementDate).getTime();
                $.each(where.parents('tr').find('td[data-asofdate]'), function (index, value) {                  
                    // todo for all rows in AATR
                        elementValue = $.trim($(this).text());   // TODO this should verify text + qe + me
                        if ($(this).data('oldValue'+_self.frequency)) {
                            $(this).html($(this).data('oldValue'+_self.frequency)); // it is required in case we remove fund which had maxdate
                            $(this).data(_self.frequency,$(this).data('oldValue'+_self.frequency));
                        }                                                                                                
                        if (where.data('elemMaxDate') !== convertedDate && !$(this).html().trim().match("[na]") && !$(this).html().trim().match("N/A"))
                        {
                            $(this).data('oldValue'+_self.frequency, $(this).html());
                            $(this).html('-');
                        }
                        elementValues.push(elementValue);
                });
            } else
            {
                $.each(where.parents('tr').find('td[data-asofdate]'), function (index, value) {
                    elementDate = $(this).data('asofdate');
                    convertedDate = new Date(elementDate).getTime();
                    elementValue = $.trim($(this).text());
                    if ($(this).data('oldValue')) {
                        $(this).html($(this).data('oldValue')); // it is required in case we remove fund which had maxdate
                    }
                    if (where.data('elemMaxDate') !== convertedDate && !$(this).html().trim().match("[na]") && !$(this).html().trim().match("N/A"))
                    {
                        $(this).data('oldValue', $(this).html());
                        $(this).html('-');

                    }
                    elementValues.push(elementValue);
                });
            }

            // check if any value is different than mdash  
            var numericValuesAvailable = 0;
            numericValuesAvailable = elementValues.some(function (a) {
                if (a !== "-" && $.trim(a) !== "" && a !== "[na]" && a !== "[mdash]" && a !== "N/A") {
                    return true;
                }
            });

            if (!numericValuesAvailable) {
                where.html("");
            }


        },                
        /**
         * Clear the contents of the selected index
         * @returns {undefined}
         */
        _clearFundColumn: function (nIndex) {
            var _self = this;
            // Delete these crazy nested td's
            $('.fund-comparison-table > tbody  > tr').each(function (index) {
                if (index !== _self.options.avgAnnualTitleIndex) {
                    if (_self.options.offSetRows.indexOf(index) > -1) {
                        $(this).children().remove(':nth-child(' + (nIndex + 1) + ')');
                    } else {
                        $(this).children().remove(':nth-child(' + nIndex + ')');
                    }
                }
            });
            // lets add in the add fund Code TODO: change to template
            $('.fund-comparison-table > tbody  > tr:first').append('<td class="no-border add-another-fund" rowspan="18"><p>' + _self.options.addAnotherFundLabel + '</p><p><a href="#" class="btn btn-default btn-secondary" data-toggle="modal" data-target="#fund-comparison-modal">' + _self.options.selectAFundLabel + '</a></p></td>');
            $('.fund-comparison-table > tbody  > tr:nth-child(20)').append('<td rowspan="11"></td>');
            
            // set asofdate for elements
            _self._setAsOfDates();
        },
        /**
         * Clear as of date in first column - when last fund removed
         */
        _clearAsOfDate: function () {
             $('small.asofdate').html('');
        },    
        /**
         * Clear caveats - when last fund removed
         */
        _clearCaveats: function () {
             $('h3.footnotes-heading').hide('');
             var footnotesLis = $('.legal ol').find('[data-footnote]');
             footnotesLis.hide();
        },         
        /**
         * Throbber!!!!
         * @returns {undefined}
         */
        addLoader: function () {
            $(this.element).find('.fund-comparison-table').throbber('addLoader');
        },        
        /**
         * Throbber!!!!
         * @returns {undefined}
         */
        isEmpty: function(el ){
            return !$.trim(el.html())
        },        
        /**
         * Throbber!!!!
         * @returns {undefined}
         */
        removeLoader: function () {
            $(this.element).find('.fund-comparison-table').throbber('removeLoader');
        },
        toggleFrequency: function (type) {
            var _self = this;
            // Delete these crazy nested td's            
            $('.fund-comparison-table > tbody  > tr > td[qe]').each(function (index) {
                
                var $caveats=$(this).html().match('<sup.+sup>');
                $(this).html($(this).attr(type)+(($caveats!==null)?$caveats:""));
                
                if ($(this).data('type')==='asofdate'){
                    if ($(this).data("aatrdate"+type)!=='' && $(this).data("aatrdate"+type)!=='-')
                    {
                        $(this).append("<small>As of "+$(this).data("aatrdate"+type)+"</small>");
                    }
                    else
                    {
                        $(this).append("<small></small>");
                    }
                }               
                
                // set inception date for si value                                
                if ($(this).data('type')==='sinceinception'){
                    $(this).append("<small>"+$(this).attr(type+"date")+"</small>")
                }
            });                   
            
        }
                
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "component_" + componentName)) {
                $.data(this, "component_" + componentName, new Component(this, options));
            } else {
                $.data(this, "component_" + componentName);
            }
        });
    };

})(jQuery, frk, document);
