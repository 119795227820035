/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

(function ($, document, window) {
    // Create the defaults once 
    var componentName = 'breadcrumbs',
            defaults = {
            };

    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.btnPrintWindow = $(this.element).find('#print-window');
        this.init();
    }

    Component.prototype = {
        init: function () {
            this.btnPrintWindow.click(function () {
                $(window).trigger('resize');
                window.print();
            });
        }
    }

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "component_" + componentName)) {
                $.data(this, "component_" + componentName,
                        new Component(this, options));
            }
        });
    };

})(jQuery, document, window);