;
(function ($, document, frk) {

    // Create the defaults once 
    var componentName = 'ppssActionMenu',
            defaults = {
                selectedFunds: [],
                toolBarLimit: 1,
                maxComparableFunds: 4,
                isMaster: false
            };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;

        this.masterCheckbox = $(this.element).find('[data-ppss-favorite="select-all"]')[0];
        this.badge = $(this.element).find('.badge')[0];
        this.toolBar = $(this.element).find('[data-ppss-favorite="tool-bar"]')[0];
        this.factSheetBtn = $(this.element).find('[data-ppss-favorite="add-fact-sheet"]')[0];
        this.compareBtn = $(this.element).find('[data-ppss-favorite="compare"]')[0];
        this.isCompareBtnEnabled = true;
        this.selectedToFavoriteBtn = $(this.element).find('[data-ppss-favorite="add-selected"]')[0];
        // Not ideal because outside actual this.element but have to follow design/UX
        this.toggleFavs = $(document).find('[data-ppss-favorite="toggle"]')[0];
        this.xSelected = 0;
        this.numberOfExcluded = 0;

        this.factsheetUrl = '/forms-literature/addtocart';
        //
        this.syncQueue = 0;

        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
            var _self = this;
            // MASTER CHECKBOX
            $(this.masterCheckbox).click(function (e) {
                // SELECT ALL and Sync
                _self._selectAll(this, true);
            });
            $(this.masterCheckbox).on('sync', function (e) {
                // SELECT ALL
                _self._selectAll(this, false);
            });

            // SYNCHRONISATION, when master only
            if (this.options.isMaster) {
                // when a fund is being favourited
                $(this.toggleFavs).on('favRequest', function () {
                    _self.syncQueue += 1;
                });
                // when a fund has been favourited (success or not)
                $(this.toggleFavs).on('favIsReady', function () {
                    _self.syncQueue -= 1;
                    if (_self.syncQueue === 0) {
                        frk.FavoriteFunds.sync().then(function () {
                            frk.FavoriteFunds.addFav(frk._data);
                            // quick fix
                            $('#favourites-only-on').trigger('readyToRender');
                            if ($(_self.toggleFavs).find('input:checked').val() === 'true') {
                                // quick fix
                                $('#favourites-only-on').trigger('readyToRender');
                            }
                            $('[data-ppss-favorite="tool-bar"]').trigger('resetSelected');
                            
                        });
                    }
                });
            }

            // TOOL BAR
            // 
            // Add all to favs / remove all to favs
            $(this.selectedToFavoriteBtn).click(function (e) {
                _self._handleAllStars($(_self.checkboxes).filter(':checked'));
                e.preventDefault();
            });
            // Compare
            $(this.compareBtn).click(function (e) {
                _self._compare($(_self.checkboxes).filter(':checked'));
                e.preventDefault();
            });
            // Add Fact Sheet to cart
            $(this.factSheetBtn).click(function (e) {
                _self._addFactSheet($(_self.checkboxes).filter(':checked'));
                e.preventDefault();
            });

            //Reset listener
            $(this.toolBar).on('resetSelected', function () {
                _self._resetSelected();
                $(_self.checkboxes).filter(':checked').prop('checked', false).trigger('sync');
            });

            // Re-init table elements when they change
            $(this.element).on('ppssFavoriteChange', _self._initDynamic.bind(_self));
        },
        _initDynamic: function () {
            var _self = this;

            // reset
            this._hideToolBar();
            this.xSelected = 0;
            this.numberOfExcluded = 0;

            // get list of stars and checkboxes
            this.stars = $(this.element).find('[data-fti-component="favorite-a-fund"]');
            this.checkboxes = $(this.element).find('[data-ppss-favorite="checkbox"]');


            // SET up binding events below

            // STARS
            $(this.stars).each(function () {
                $(this).click(function (e) {
                    _self._handleStar(this);
                    e.preventDefault();
                });
            });

            // CHECKBOXES
            $(this.checkboxes).each(function () {
                if (_self.options.selectedFunds.indexOf($(this).val()) !== -1) {
//                    _self._select(this, false);
                    $(this).prop('checked', true);
                    _self._select(this, false);
                }
                $(this).on('change', function () {
                    // SELECT and synch
                    _self._select(this, true);
                });
                $(this).on('sync', function () {
                    // SELECT
                    _self._select(this, false);
                });
            });

            // handle master checkbox
			
            if ($(this.checkboxes).length > 0 && $(this.checkboxes).length <= this.xSelected) {
                $(this.masterCheckbox).prop('checked', true);
            }
            else {
                $(this.masterCheckbox).prop('checked', false);
            }
        },
        _handleStar: function (el) {
            // refresh UI
            if ($(this.toggleFavs).find('input:checked').val() === 'true') {
                $('#favourites-only-on').trigger('readyToRender');
                $('#favourites-only-on').trigger('readyToRender');
            }
        },
        _handleAllStars: function (els) {
            var _self = this,
                    favsOrNotFavs = [];

            if ($(this.toggleFavs).find('input:checked').val() !== 'true') {// if My Favorite Only is OFF, we ADD all the funds selected                
                // get a list of all selected fund that are not yet favourites
                console.log("my favorite is off");
                $(els).each(function () {
                    var index = _self._getIndex($(this).closest('tr').find('[data-fti-module="favorite-a-fund"]'));
                    if ($(_self.stars[index]).find('i').hasClass('ft-icon-favorite')) {
                        $(_self.stars[index]).trigger('addToFav');
                        favsOrNotFavs.push(this);
                    }
                });
            }
            else { // else if My Favorite Only is ON, we REMOVE all the funds selected
                // get a list of all selected fund that are favourites (should be all of them)
                console.log("my favorite is on");
                $(els).each(function () {
                    var index = _self._getIndex($(this).closest('tr').find('[data-fti-module="favorite-a-fund"]'));
                    if ($(_self.stars[index]).find('i').hasClass('ft-icon-favorite-active')) {
                        $(_self.stars[index]).trigger('removeFromFav');
                        favsOrNotFavs.push(this);
                    }
                });
            }
        },
        _getIndex: function(el) {
            for(var s in this.stars) {
                if($(el).is(this.stars[s])) return s;
            }
            return -1;
        },
        _compare: function (els) {
            if (this.isCompareBtnEnabled) {
                console.log('Comparing ', els);      
                var toStringFunds=els.map(function () {
                                            return $(this).val();
                                       }).get().join(',');
                window.location.href = "fund-comparison?funds="+toStringFunds;
            }
            else
            {       
                
                if (this.numberOfExcluded>0 && this.xSelected>4)
                {
                    $(document).find('[data-fti-component="cart-list"]').trigger({
                        type: 'showComparisonModal'
                    },'');
                    console.log('Too many funds AND compare not available ', els);
                }
                else if (this.numberOfExcluded>0)
                {
                    $(document).find('[data-fti-component="cart-list"]').trigger({
                        type: 'showComparisonModal'                    
                    },'money');
                    console.log('Money funds cannot be compared ', els);
                }
                else
                {
                     $(document).find('[data-fti-component="cart-list"]').trigger({
                        type: 'showComparisonModal'
                    },'toomany');
                     console.log('Too many funds  ', els);
                }
                
                
            }
        },
        _disableCompare: function () {
            this.isCompareBtnEnabled = false;
            $(this.compareBtn).addClass('disabled');
        },
        _enableCompare: function () {
            this.isCompareBtnEnabled = true;
            $(this.compareBtn).removeClass('disabled');
        },
        _addFactSheet: function (els) {
            var _self = this, list = [];
            $(els).each(function () {
                list.push($(this).val() + '-FF');
            });

            $.ajax({
                url: this.factsheetUrl,
                data: {literatureCode: list.join()},
                dataType: 'json',
                type: 'POST'
            }).done(function (data) {
                $(document).find('[data-fti-component="cart-list"]').trigger({
                    type: 'showItemAddedModal',
                    litData: data
                });
                $(document).find('[data-fti-component="cart-list"]').trigger({
                    type: 'updateCart',
                    litData: data
                });
                $('[data-ppss-favorite="tool-bar"]').trigger('resetSelected');
                $(_self.checkboxes).filter(':checked').prop('checked', false).trigger('sync');
            }).fail(function () {
                $('[data-fti-component="error-messages"]').remove();
                $('[data-fti-component="back-to-top"]').backToTop();
                frk.danger('Factsheet not added', 'Unfortunately, something went wrong. Sorry for the inconvenience.');
            });
        },
        _select: function (el, sync) {
            var _self = this;
            if (sync) {
                $('[data-fti-component="ppss"]').find('input.fund-' + $(el).val()).each(function () {
                    if (this !== el) {
                        $(this).prop('checked', $(el).is(':checked')).trigger('sync');
                    }
                });

                //add/remove to shared list
                if ($(el).is(':checked')) {
                    _self.options.selectedFunds.push($(el).val());
                }
                else {
                    _self.options.selectedFunds.splice(_self.options.selectedFunds.indexOf($(el).val()), 1);
                }
            }
            
            
            // modify selected count
            if ($(el).is(':checked')) {
                _self.xSelected += 1;
                $(el).closest('tr[data-fund]').addClass('selected');
               
               // check if is Money or Partial
                if ($(el).data('fundcat')==='Money Funds' || $(el).data('displaystatus')==='P')
                    {
                        _self.numberOfExcluded+=1;
                    }                
            }
            else {
                _self.xSelected -= 1;
                $(el).closest('tr[data-fund]').removeClass('selected');
                // check if is Money or Partial
                if ($(el).data('fundcat')==='Money Funds' || $(el).data('displaystatus')==='P')
                    {
                        _self.numberOfExcluded-=1;
                    }                                   
            }
            // see if we have selected fund to display toolbar
            if (_self.xSelected >= _self.options.toolBarLimit) {
                _self._displayToolBar();
            }
            else {
                _self._hideToolBar();
            }

            
            // disable compare funds if too many selected
            if ((_self.xSelected > _self.options.maxComparableFunds && _self.isCompareBtnEnabled) || 
                    _self.numberOfExcluded>0) {
                _self._disableCompare();
            }
            else if (_self.xSelected <= _self.options.maxComparableFunds && !_self.isCompareBtnEnabled
                    && _self.numberOfExcluded<1
                    ) {
                _self._enableCompare();
            }
            // always update badge
            _self._updateBadge();
        },
        _selectAll: function (el, sync) {
            var _self = this,
                    checked = $(el).is(':checked'); // checked or uncheck ?
            if (checked) {
                // reset select element BEFORE as it will be set by each individual cb
                _self.xSelected = 0;
            }
            // trigger select action on all checkboxes
            $(_self.checkboxes).each(function () {
                $(this).prop('checked', checked).trigger('sync');
            });

            if (!checked) {
                // reset select element AFTER in case we have a negative nb.
                _self.xSelected = 0;
            }

            if (sync) {
                $('[data-fti-component="ppss"]').find('[data-ppss-favorite="select-all"]').each(function () {
                    if (el !== this) {
                        $(this).prop('checked', $(el).is(':checked')).trigger('sync');
                    }
                });
                if (checked) {
                    _self.options.selectedFunds.splice(0, _self.options.selectedFunds.length);
                    $(_self.checkboxes).each(function () {
                        _self.options.selectedFunds.push($(this).val());
                    });
                }
                else {
                    _self.options.selectedFunds.splice(0, _self.options.selectedFunds.length);
                }
            }
        },
        _resetSelected: function () {
            // clear array
            this.options.selectedFunds.splice(0, this.options.selectedFunds.length);
            // reset counter
            //this.xSelected = 0;
        },
        _displayToolBar: function () {
//            $(this.toolBar).show();
            $(this.toolBar).removeClass('hidden');
        },
        _hideToolBar: function () {
//            $(this.toolBar).hide();
            $(this.toolBar).addClass('hidden');
        },
        _updateBadge: function () {
            $(this.badge).html(this.xSelected);
        },
        _toggleStarClass: function (el) {
            $(el).find('i')
                    .toggleClass('ft-icon-favorite-active')
                    .toggleClass('ft-icon-favorite');
        }
        
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };

})(jQuery, document, frk);


