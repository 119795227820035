/**
 * PPSS
 * 
 */
;
(function (frk, moment) {

    function PPSSBrRules(fundData, asOfDates) {
        _removeLiquidated(fundData);
        _distributionRule(fundData, asOfDates);
        _moningStarRule(fundData);
        _noScShareClass(fundData);
        _formatCUSIP(fundData);
    }

    function PPSSVIPBrRules(fundData) {
        _removeLiquidated(fundData);
        _removeDisplayNot('vip', fundData);
        _formatCUSIP(fundData);
    }

    function PPSS529BrRules(fundData) {
        _removeLiquidated529(fundData);
        _add529Cat(fundData);
    }
    
    function PPSSMONEYBrRules(fundData, asOfDates) {
    	_removeMasterFundData(fundData, asOfDates);
    }
    
    function _removeLiquidated(fundData) {
        var i = fundData.length;
        while (i--) {
            if (fundData[i].displayStatus.val !== 'Y' && fundData[i].displayStatus.val !== 'S' && fundData[i].displayStatus.val !== 'H') {
                fundData.splice(i, 1);
            }
        }
    }

    function _removeLiquidated529(fundData) {
        var i = fundData.length;
        while (i--) {
            if (!fundData[i].perfDet.displayStatus.val || (fundData[i].perfDet.displayStatus.val !== 'Y' && fundData[i].perfDet.displayStatus.val !== 'S' && fundData[i].perfDet.displayStatus.val !== 'H')) {
                fundData.splice(i, 1);
            }
        }
    }

    function _distributionRule(fundData, asOfDates) {
        var Dist = frk.UsConfig.getData().config.global.distributions;
        // get NB days from config configFileDistributions/thirtyDayStandardizedDataTimeframe
        var numberOfDays = Dist.thirtyDayStandardizedDataTimeframe || 0;
        // calculate date days ago
        var dateXdaysAgo = moment().add('-' + numberOfDays, 'days');
        // attributes add to fund object saying if we can display dist or not
        var isAllowedInConfigNode = 'brIsAllowedInConfig',
                canDisplayDistRate = 'brDisplayDistributionRate',
                isTooOld = 'brTooOldData';

        for (var f = 0; f < fundData.length; f++) {
            // if there is a date
            if (asOfDates.maxSecYieldDate && asOfDates.maxSecYieldDate.alt) {
                // if money funds
                if (fundData[f].prntFundCategory.val && fundData[f].prntFundCategory.val === 'Money Funds') {
                    // console.log('money funds');
                    fundData[f][canDisplayDistRate] = 'N';
                    // check if 7 day current date is the same for w waiver and w/o waiver
                    if (fundData[f].curr7DayYieldDate && fundData[f].curr7DayYieldWoWvrDate && (fundData[f].curr7DayYieldDate.alt !== fundData[f].curr7DayYieldWoWvrDate.alt)) {
                        delete fundData[f].curr7DayYieldWoWvr;
                    }
                    // if no valid w/o waiver and w waiver is 0.00, not valid
                    if ((!fundData[f].curr7DayYieldWoWvr || !fundData[f].curr7DayYieldWoWvr.alt || fundData[f].curr7DayYieldWoWvr.alt === '' || fundData[f].curr7DayYieldWoWvr.alt === '-') &&
                            fundData[f].curr7DayYield.alt === '0.00') {
                        fundData[f][isTooOld] = 'Y';
                        delete fundData[f].curr7DayYield;
                    }
                    // check if 7 day effective date is the same for w waiver and w/o waiver
                    if (fundData[f].eff7DayYieldDate && fundData[f].eff7DayYieldWoWvrDate && (fundData[f].eff7DayYieldDate.alt !== fundData[f].eff7DayYieldWoWvrDate.alt)) {
                        delete fundData[f].eff7DayYieldWoWvr;
                    }
                    // if no valid w/o waiver and w waiver is 0.00, not valid
                    if ((!fundData[f].eff7DayYieldWoWvr || !fundData[f].eff7DayYieldWoWvr.alt || fundData[f].eff7DayYieldWoWvr.alt === '' || fundData[f].eff7DayYieldWoWvr.alt === '-') &&
                            fundData[f].eff7DayYield.alt === '0.00') {
                        fundData[f][isTooOld] = 'Y';
                        delete fundData[f].eff7DayYield;
                    }
                }
                // if before dateXdaysAgo
                if (moment(new Date(asOfDates.maxSecYieldDate.alt)).isBefore(dateXdaysAgo)) {
                    // console.log('too old');
                    fundData[f][isTooOld] = 'Y';
                    delete fundData[f].secYield;
                    delete fundData[f].distRateAtNAV;
                    delete fundData[f].distRateAtPOP;
                }
                //if no standardized yield, can't display distribution (check it's not money fund)
                if ((fundData[f].prntFundCategory.val && fundData[f].prntFundCategory.val !== 'Money Funds') &&
                        (!fundData[f].secYield || !fundData[f].secYield.alt || fundData[f].secYield.alt === '' || fundData[f].secYield.alt === '-')) {
                    fundData[f][isTooOld] = 'Y';
                    delete fundData[f].secYield;
                    delete fundData[f].distRateAtNAV;
                    delete fundData[f].distRateAtPOP;
                }
                else {
                    // check if sec yield date is the same for w waiver and w/o waiver
                    if (fundData[f].secYieldDate && fundData[f].secYieldWoWvrDate && (fundData[f].secYieldDate.alt !== fundData[f].secYieldWoWvrDate.alt)) {
                        delete fundData[f].secYieldWoWvr;
                    }
                }
                // check if some divdSchedule cannot be displayed 
                if (fundData[f].divdSchedule && fundData[f].divdSchedule.val) {
                    //   console.log('checking schedule conf');
                    if (Dist.displayMonthlyDiv === 'N' && fundData[f].divdSchedule.val.toLowerCase() === 'monthly') {
                        // console.log('can\'t display monthly');
                        fundData[f][isAllowedInConfigNode] = 'N';
                        delete fundData[f].divdSchedule;
                        delete fundData[f].distRateAtNAV;
                        delete fundData[f].distRateAtPOP;
                    }
                    else if (Dist.displaySemiAnnuallyDiv === 'N' && (fundData[f].divdSchedule.val.toLowerCase() === 'semiannually' || fundData[f].divdSchedule.val.toLowerCase() === 'semi-annual')) {
                        // console.log('can\'t display semi annually');
                        fundData[f][isAllowedInConfigNode] = 'N';
                        delete fundData[f].divdSchedule;
                        delete fundData[f].distRateAtNAV;
                        delete fundData[f].distRateAtPOP;
                    }
                    else if (Dist.displayAnnuallyDiv === 'N' && fundData[f].divdSchedule.val.toLowerCase() === 'annually') {
                        // console.log('can\'t display annually');
                        fundData[f][isAllowedInConfigNode] = 'N';
                        delete fundData[f].divdSchedule;
                        delete fundData[f].distRateAtNAV;
                        delete fundData[f].distRateAtPOP;
                    }
                    else if (Dist.displayQuarterlyDiv === 'N' && fundData[f].divdSchedule.val.toLowerCase() === 'quarterly') {
                        // console.log('can\'t display quarterly');
                        fundData[f][isAllowedInConfigNode] = 'N';
                        delete fundData[f].divdSchedule;
                        delete fundData[f].distRateAtNAV;
                        delete fundData[f].distRateAtPOP;
                    }
                }
            }
            else {
                // No date, can't display distribution
                fundData[f][isTooOld] = 'Y';
            }
        }
    }

    function _moningStarRule(fundData) {
        // get minimun rating from us config
        var minRating = frk.UsConfig.getData().config.global.ratings.minimumMorningstar || 0,
                supressRatings = frk.UsConfig.getData().config.global.ratings.supressRatings.fundID || {};
        // attributes add to fund object saying if we can display dist or not
        var notRating = 'brNoRating',
                ratingTooLow = 'brMinRatingTooLow';

        for (var f = 0; f < fundData.length; f++) {
            //if mStarRatingOverall has nothing, not rated yet
            if (!fundData[f].mStarRatingOverall.val) {
                fundData[f][notRating] = 'Y';
            }
            else {
                // else check rating against min rat
                if (parseInt(fundData[f].mStarRatingOverall.val) < parseInt(minRating)) {
                    fundData[f][ratingTooLow] = 'Y';
                }
                else {
                    // check config file in case rating is suppressed for this fun
                    for (var s in supressRatings) {
                        if (String(supressRatings[s].val) === String(fundData[f].fundNumber.val)) {
                            fundData[f][ratingTooLow] = 'Y';
                            break;
                        }
                    }
                }
            }
            // if no rating or too low, remove data for sorting
            if (fundData[f][ratingTooLow] === 'Y' || fundData[f][notRating] === 'Y') {
                fundData[f]['mStarCategory'].val = 'zz';
                delete fundData[f]['mStarRatingOverall'];
                delete fundData[f]['mStarRating3Yr'];
                delete fundData[f]['mStarRating5Yr'];
                delete fundData[f]['mStarRating10Yr'];
                delete fundData[f]['rating3YearsUniv'];
                delete fundData[f]['rating5YearsUniv'];
                delete fundData[f]['rating10YearsUniv'];
            }
        }

    }

    function _removeDisplayNot(type, fundData) {
        // read fund config
        var fds = frk.UsConfig.getDisplayNotData(type);
        if (fds) {
            for (var f in fds.suppressByFund.fundID) {
                for (var fd = 0; fd < fundData.length; fd++) {
                    if (parseInt(fundData[fd].fundNumber.val) === parseInt(fds.suppressByFund.fundID[f].val)) {
                        fundData.splice(fd, 1);
                    }
                }
            }
        }
    }
    
    
    // DSL inconsistancy fix. Some of the share classes don't have Sales Charges data but still have to display values!!
    // So instead, we need to display the non-sales charges equivalent...
    function _noScShareClass(fundData) {
        for (var f = 0; f < fundData.length; f++) {
            if (fundData[f].shareClass && fundData[f].shareClass.val) {
                var shareClass = fundData[f].shareClass.val.toLowerCase();
                if (shareClass === 'advisor' ||
                        shareClass === 'z' ||
                        shareClass === 'r6' ||
                        shareClass === 'r' ||
                        fundData[f].prntFundCategory.val === 'Money Funds') {
                    fundData[f].avgAnnSinIncReturnSc.alt = fundData[f].avgAnnSinceIncReturn.alt;
                    fundData[f].avgAnnSinIncReturnScQtr.alt = fundData[f].avgAnnSinceIncReturnQtr.alt;
                    fundData[f].avgAnn1YearReturnSc.alt = fundData[f].avgAnn1YearReturn.alt;
                    fundData[f].avgAnn1YearReturnScQtr.alt = fundData[f].avgAnn1YearReturnQtr.alt;
                    fundData[f].avgAnn3YearReturnSc.alt = fundData[f].avgAnn3YearReturn.alt;
                    fundData[f].avgAnn3YearReturnScQtr.alt = fundData[f].avgAnn3YearReturnQtr.alt;
                    fundData[f].avgAnn5YearReturnSc.alt = fundData[f].avgAnn5YearReturn.alt;
                    fundData[f].avgAnn5YearReturnScQtr.alt = fundData[f].avgAnn5YearReturnQtr.alt;
                    fundData[f].avgAnn10YearReturnSc.alt = fundData[f].avgAnn10YearReturn.alt;
                    fundData[f].avgAnn10YearReturnScQtr.alt = fundData[f].avgAnn10YearReturnQtr.alt;
                    fundData[f].avgAnnSinIncReturnSc.alt = fundData[f].avgAnnSinceIncReturn.alt;
                    fundData[f].avgAnnSinIncReturnScQtr.alt = fundData[f].avgAnnSinceIncReturnQtr.alt;
                    if (fundData[f].distRateAtPOP && fundData[f].distRateAtNAV)
                        fundData[f].distRateAtPOP.alt = fundData[f].distRateAtNAV.alt;
                }
            }
        }
    }

    function _formatCUSIP(fundData) {
        for (var f = 0; f < fundData.length; f++) {
            if (fundData[f].cusip && fundData[f].cusip.val) {
                fundData[f].cusip.alt = fundData[f].cusip.val.split(/(?=(?:...)*$)/).join(' ');
            }
        }
    }

    function _removeMasterFundData(fundData,asOfDates) {
    	console.log("asOfDates ="+JSON.stringify(asOfDates));
    	var maxMktDate = moment(asOfDates.maxMktNavFndDt.alt).format("YYYY-MM-DD").toUpperCase();
    	console.log("maxNavDate :"+maxMktDate);
    	console.log("fudData is :"+JSON.stringify(fundData));
    	console.log("length of fundData:"+fundData.length);
    	var f = fundData.length;
        while (f--) {
               	
        	var mastNavDt = moment(fundData[f].mktNavMasterFundDate.val).format("YYYY-MM-DD").toUpperCase();
        	console.log("mastNavDt ---<>--------:"+mastNavDt);
        	
        	if(maxMktDate !== mastNavDt) {
        		console.log("set the value as -");
        		fundData[f].mktNavMasterFund.alt = 'TBD';
        	} 
        }
    }
    
    
    
    function _add529Cat(fundData) {
        var groups = frk.ftnConfig.getData().Funds.group;
        // for each group
        for (var g in groups) {
            var showFundCat = groups[g].showCategoryName || false;
            // go through each fund and attach category to fundData
            for (var fn in groups[g].fundID) {
                for (var f in fundData) {
                    if (parseInt(fundData[f].fundInfo.fundNo.val) === parseInt(groups[g].fundID[fn].val)) {
                        fundData[f].cat = groups[g].name;
                        if (showFundCat)
                            fundData[f].showCatName = true;
                        // horrible hack to be able to sort funds by age range
                        fundData[f].sortName = doTheRangeThing(fundData[f].fundInfo.fundName.val);
                        break;
                    }
                }
            }
        }

        // horrible hack to be able to sort funds by age range
        function doTheRangeThing(fundName) {
            var res = fundName;
            if (fundName.toLowerCase().indexOf('newborn - 8') !== -1) {
                res = "1 " + res;
            }
            else if (fundName.toLowerCase().indexOf('9 - 12 years') !== -1) {
                res = "2 " + res;
            }
            else if (fundName.toLowerCase().indexOf('13 - 16 years') !== -1) {
                res = "3 " + res;
            }
            else if (fundName.toLowerCase().indexOf('17+ years') !== -1) {
                res = "4" + res;
            }
            return res;
        }
    }


    // Expose class on Global object
    frk.PPSSBrRules = PPSSBrRules;
    frk.PPSSVIPBrRules = PPSSVIPBrRules;
    frk.PPSS529BrRules = PPSS529BrRules;
    frk.PPSSMONEYBrRules = PPSSMONEYBrRules;
    
})(frk, moment);