/* 
 * Custom handlebars helpers for Capital Gains
 */
;
(function (Handlebars, frk, Date) {

    Handlebars.registerHelper({
        /**
         * 
         * @returns {undefined}
         */
        distributionDetailLink: function (context, options) {
            if (options && options.hash) {
                if (options.hash.isCurrentYear === true) {
                    return frk.trimExtension(frk.UsConfig.getProductPageUrl('distributions', context, ''), '.page') + '?FundID=' + context;
                }
                else {
                    return frk.trimExtension(frk.UsConfig.getProductPageUrl('taxInfo', context, ''), '.page') + '?FundID=' + context;
                }
            }
            return context;
        },
        /**
         * 
         * @param {type} context
         * @param {type} options
         * @returns {String}
         */
        formatDate: function (context, options) {
            var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];
            var d = new Date(context);
            if (options && options.hash) {
                if (context && options.hash.format) {
                    return monthNames[d.getMonth()];
                }
            }
            return context;
        },
        ifDistData: function(context, options) {
            for(var f in context) {
                if(context[f].view !== 'A') {
                    return options.fn(this);
                }
            }
            return options.inverse(this);
        }
    });
})(Handlebars, frk, Date);
