;
(function ($, viewport) {

  // Create the defaults once 
  var componentName = 'colSameheight',
    defaults = {
    };

  // The actual plugin constructor 
  function Component(element, options) {
    this.element = element;
    this.options = $.extend({}, defaults, options);
    this._defaults = defaults;
    this._name = componentName;
    this.viewport = viewport;


    this.init();
  }

  // Component methods 
  Component.prototype = {
    init: function () {
      
      var _self = this;
      
      if (this.viewport.is('>sm')) {
        _self.equalColumns();
      }
      
      _self.resizeHandler();
    },
    equalColumns: function () {

      var _self = this,
        maxHeight = 0;
        $('.equal-heights').each(function() { maxHeight = Math.max(maxHeight, $(this).height()); }).height(maxHeight);
    },
    resizeHandler: function () {

      var _self = this;

      $(window).on("resize", function () {
        if (viewport.is('>sm')) {
          _self.equalColumns();
        }
        if(viewport.is('<md')) {
            var allcolumns = $('.equal-heights').toArray();
          for (var i = 0; i < allcolumns.length; i++) {
            $(allcolumns[i]).height('100%');
          }
        }

      });

    }
  };

  $.fn[componentName] = function (options) {
    return this.each(function () {
      if (!$.data(this, 'component_' + componentName)) {
        $.data(this, 'component_' + componentName,
          new Component(this, options));
      }
    });
  };
})(jQuery, ResponsiveBootstrapToolkit);


