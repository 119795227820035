/** 
 * Equal height javascript
 */
 
;(function ( $, window, document, frk, undefined ) { 
 
    var componentName = "equalize",
        defaults = {
          'elements': [
                // '.gradient'
            // '[data-fti-component="billboard"]',
            // '[data-fti-component="commentary"]',
            // '.perspectives-card',
//            '.panel',
            // '[data-fti-component="commentary"]',
            // '.commentary-ad-card'
          ]
        }; 
 
    function Component( element, options ) { 
        this.element = $(element); 
 
        this.options = $.extend( {}, defaults, options) ; 
 
        this._defaults = defaults; 
        this._name = componentName; 
 
        this.handlers = [];  // observers

        this.init(); 
    } 
 
    Component.prototype = { 
 
        init: function() { 
          for(var i=0; i<this.options.elements.length; i++) {
            this.subscribe(this.options.elements[i]);
          }
 
          this.attach(this.element); 
        }, 
 
        subscribe: function(fn) {
            this.handlers.push(fn);
        },
     
        unsubscribe: function(fn) {
            this.handlers = this.handlers.filter(
                function(item) {
                    if (item !== fn) {
                        return item;
                    }
                }
            );
        },
     
        equalize: function() {

            var currentTallest = 0,
                 currentRowStart = 0,
                 rowDivs = new Array(),
                 $el,
                 topPosition = 0;

            var elements = this.handlers.join(', ');
            
            $(elements).each(function () {

                $el = $(this);
                $($el).height('auto');

                if(frk.getCurrentGrid() === 'xs') {
                	return;
                }
                
                topPostion = $el.position().top;

                if (currentRowStart !== topPostion) {
                    for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                        rowDivs[currentDiv].height(currentTallest);
                    }
                    rowDivs.length = 0; // empty the array
                    currentRowStart = topPostion;
                    currentTallest = $el.height();
                    rowDivs.push($el);
                } else {
                    rowDivs.push($el);
                    currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
                }
                for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                    rowDivs[currentDiv].height(currentTallest);
                }
            });        
        },

        attach: function(el) { 
          var i = this; 

          el.on('resize.frk.equalize', frk.throttle(function() { 
              i.equalize();
            }, 1000)); 
 
          el.on('load.frk.equalize', i.equalize());

        }
 
    }; 
 
    $.fn[componentName] = function ( options ) { 
        return this.each(function () {
            if (!$.data(this, "component_" + componentName)) {
                $.data(this, "component_" + componentName,
                new Component( this, options ));
            }
        });
    };
 
    // self initialise
    $(function() {
      $(window).equalize();
    });
 
})( jQuery, window, document, frk );