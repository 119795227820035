/** 
 * Sticky header module for PPSS (could be use somewhere else, but I don't guarantee anything) 
 *
 * Markup can be: 
 * <div data-fti-module="sticky-header" data-max-viewport="md"> <!-- starts sticking here, won't stick if viewport below md (xs by default) -->
 *  <p data-fti-sticky-item="">Sticky stuff</p> <!-- this will stick -->
 * </div> <!-- stops sticking here -->
 * 
 * 
 * OR more complexe
 * 
 * <div data-fti-module="sticky-header" data-fti-sticky-for="id"> <!-- starts sticking here -->
 *  <p data-fti-sticky-item="id">Sticky stuff</p> <!-- this will stick -->
 *  <p data-fti-sticky-item="id">Another Sticky stuff</p> <!-- this will stick under -->
 * </div> <!-- stops sticking here -->
 * 
 * OR even more complexe
 * 
 * <div>
 *  <p data-fti-module="sticky-header" data-fti-sticky-for="id" data-fti-sticky-trigger="start">I am the sticky trigger</p> <!-- starts sticking here -->
 *  <p data-fti-sticky-item="id">Sticky stuff</p> <!-- this will stick -->
 *  <p data-fti-sticky-item="id">Another Sticky stuff</p> <!-- this will stick under -->
 *  <p data-fti-sticky-for="id" data-fti-sticky-trigger="stop">I am the sticky stopper</p> <!-- stops sticking here -->
 *  
 *  <p data-fti-module="sticky-header" data-fti-sticky-for="id2" data-fti-sticky-trigger="start">I am the sticky trigger</p> <!-- starts sticking here -->
 *  <p data-fti-sticky-item="id2">Sticky stuff</p> <!-- this will stick -->
 *  <p data-fti-sticky-item="id2">Another Sticky stuff</p> <!-- this will stick under -->
 *  <p data-fti-sticky-for="id2" data-fti-sticky-trigger="stop">I am the sticky stopper</p> <!-- stops sticking here -->
 * </div>
 */
;
(function ($, window, document, viewport) {

    // Create the defaults once 
    var componentName = 'stickyHeader',
            defaults = {
                maxViewport: 'xs' // xs, sm, md, lg: below chosen value, items won't stick
            };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;

        // if sticky module has an ID, we can expect more than 1 sticky items
        this._hasAnId = (this.options.ftiStickyFor) ? true : false;

        // if sticky module has a trigger, we have to start/stop sticky when scroll reaches triggers,
        // as opposed to reaching top/end of sticking area
        this._hasTrigger = (this.options.ftiStickyTrigger) ? true : false;

        // state of sticky header
        this._sticking = false;

        // all we need to calculate when and what to start/stop sticking
        this._parameters = {
            scrollTop: $(window).scrollTop(),
            startPoint: '',
            endPoint: '',
            items: [] // list of items to stick (could be only one
        };

        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
            // run function on scrolllllll!
            $(window).on('scroll resize', this._calculate.bind(this));
        },
        _update: function () {
            this._parameters.scrollTop = $(window).scrollTop();
            // 
            if (this._hasAnId) {
                // do the calculation
                this._parameters.items = $(document).find('[data-fti-sticky-item="' + this.options.ftiStickyFor + '"]');
            }
            else {
                //do the calculation
                this._parameters.items = $(this.element).find('[data-fti-sticky-item]');
            }
            this.parentWidth = $(this._parameters.items).parent().width();

            // If triggers instead of area
            if (this._hasTrigger) {
                // start trigger top position
                this._parameters.startPoint = $(this.element).offset().top;
                // bottom trigger bottom position
                if ($(document).find('[data-fti-sticky-trigger="stop"][data-fti-sticky-for="' + this.options.ftiStickyFor + '"]').length > 0)
                    this._parameters.endPoint = $(document).find('[data-fti-sticky-trigger="stop"][data-fti-sticky-for="' + this.options.ftiStickyFor + '"]').offset().top;
            }
            else {
                // sticky container top position
                this._parameters.startPoint = $(this.element).offset().top;
                // sticky container bottom
                this._parameters.endPoint = this._parameters.startPoint + $(this.element).height();
            }
        },
        _calculate: function () {
            if (viewport.is('>=' + this.options.maxViewport)) {
                this._update();

                if ((this._parameters.scrollTop > this._parameters.startPoint) && (this._parameters.scrollTop < this._parameters.endPoint - 50)) {
                    if (!this._sticking) {
                        this._sticking = true;
                        this._stick(this._parameters.items);
                    }
                }
                else {
                    if (this._sticking) {
                        this._sticking = false;
                        this._unstick(this._parameters.items);
                    }
                }

            }
            else {
                if (this._sticking) {
                    this._sticking = false;
                    this._unstick(this._parameters.items);
                }
            }
        },
        _stick: function (items) {
            var _self = this, h = 0;
            $(items).each(function () {
                _self._clone(this);
                $(this).find('th').each(function () {
                    $(this).css('width', $(this).context.offsetWidth);
                    $(this).css('max-width', $(this).context.offsetWidth);
                    $(this).css('min-width', $(this).context.offsetWidth);
                });
                $(this).css({position: 'fixed', top: h + 'px', width: _self.parentWidth + 'px', 'z-index': '5'});
                h = h + $(this).height();
            });
        },
        _unstick: function (items) {
            var _self = this;
            $(items)
                    .css({position: '', top: '', width: '', height: '', 'z-index': ''})
                    .each(function () {
                        _self._removeClone(this);
                        $(this).find('th').removeAttr('style');
                    });
        },
        _clone: function(item) {
            $(item).clone().removeData('[data-fti-sticky-item]').addClass('clone').insertAfter(item);
        },
        _removeClone: function(item) {
            $(item).next('.clone').remove();
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
            else {
                $.data(this, 'component_' + componentName)._update();
            }
        });
    };

})(jQuery, window, document, ResponsiveBootstrapToolkit);
