
;
(function ($) {

    // Create the defaults once 
    var componentName = 'carouselAnnouncements',
            defaults = {
            };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        
        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
         
          var elm = $(this.element),
              owl = elm.find("#carousel-announcements");

          owl.owlCarousel({
              pagination: false,
                items: 4, //10 items above 1000px browser width
                itemsDesktop: [1000, 4], //5 items between 1000px and 901px
                itemsDesktopSmall: [900, 3], // betweem 900px and 601px
                itemsTablet: [700, 2], //2 items between 600 and 0
                itemsMobile: [479, 1], // itemsMobile disabled - inherit from itemsTablet option
                itemsScaleUp: true,
                rewindNav: false,
                afterAction: function () {
                    if (this.itemsAmount > this.visibleItems.length) {

                        elm.find(".next").removeClass('hidden');
                        elm.find(".prev").removeClass('hidden');
                        if (this.currentItem == 0) {
                            elm.find(".prev").addClass('hidden');
                        }
                        if (this.currentItem == this.maximumItem) {
                            elm.find(".next").addClass('hidden');
                        }

                    } else {
                        elm.find(".next").hide();
                        elm.find(".prev").hide();
                    }
                }
          });
         
          // Custom Navigation Events
            elm.find(".next").click(function () {
            owl.trigger('owl.next');
          });

            elm.find(".prev").click(function () {
            owl.trigger('owl.prev');
          });

            elm.find(".play").click(function () {
                owl.trigger('owl.play', 1000); //owl.play event accept autoPlay speed as second parameter
          });

            elm.find(".stop").click(function () {
            owl.trigger('owl.stop');
          });

        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };
    
})(jQuery);
