/**
 * PPSSPanel Class
 * 
 */
;
(function (frk) {

    function PPSSSort() {
        this._sortable = {};
        this._activeSorting = '';
    }


    /**
     * 
     * @param {string} id unique ID
     * @param {object} sortableConf {order: order type, field: the field to sort on}
     * @returns {undefined}
     */
    PPSSSort.prototype.addSortable = function (id, sortableConf, sortOptions, isActive) {
        // ID check
        if (!id || !(typeof id === 'string') || id.length < 1) {
            console.error('Filter id incorrect (needs to be a string with at least 1 character)');
        }
        // config check
        if (!sortableConf || !sortableConf.order || !sortableConf.field) {
            console.error('Sortable configuration error');
        }
        if (!(typeof sortableConf.order === 'string') || sortableConf.order.length < 1) {
            console.error('Order needs to be a string of at least 1 character');
        }
        if (!(typeof sortableConf.field === 'string') || sortableConf.field.length < 1) {
            console.error('Field needs to be a string of at least 1 character');
        }
        // Add options if any
        if (sortOptions && sortOptions.length > 0) {
            sortableConf.options = sortOptions;
        }

        // Sortable is valid, add it to list
        this._sortable[id] = sortableConf;

        // if active by default
        if (isActive) {
            this._activeSorting = [id];
        }
    };

    /**
     * 
     * @param {string} id unique ID
     * @param {string} field the field to sort on
     * @returns {undefined}
     */
    PPSSSort.prototype._modifySortableField = function (id, field) {
        if (!this._sortable[id]) {
            console.error('Sortable undefined');
        }
        // update field
        this._sortable[id].field = field;
    };

    /**
     * 
     * @param {string} id unique ID
     * @param {string} field the field to sort on
     * @returns {undefined}
     */
    PPSSSort.prototype.switchSortMode = function (mode) {
        if (!mode || !(typeof mode === 'string')) {
            console.error('Invalid mode');
        }
        // try to find mode
        for (var s in this._sortable) {
            if (this._sortable[s].options && this._sortable[s].options.length > 0) {
                var sort = this._sortable[s];
                for (var o in sort.options) {
                    if (sort.options[o].mode === mode) {
                        this._modifySortableField(s, sort.options[o].field);
                    }
                }
            }
        }
    };

    /**
     * 
     * @param {type} id
     * @param {boolean} reverse Optional.
     * @returns {Array}
     */
    PPSSSort.prototype.sort = function (id, reverse) {
        if (!this._sortable[id]) {
            console.error('Sortable undefined');
        }
        this._activeSorting = id;
        // if reverse argument is passed, update reverse status
        if (typeof reverse !== 'undefined') {
            // change sorting order
            var rev = reverse ? true : false;
            this._sortable[id].reverse = rev;
        } 
        // default value
        if(!this._sortable[id].reverse) {
            this._sortable[id].reverse = false;
        }
        // temporary save result
        frk._filteredData = this._sortable[id].reverse ?
                frk.orderBy.reverse[this._sortable[id].order](frk._filteredData, this._sortable[id].field) :
                frk.orderBy[this._sortable[id].order](frk._filteredData, this._sortable[id].field);

        return frk._filteredData;
    };

    PPSSSort.prototype.getActiveSorting = function () {
        return this._activeSorting;
    };

    // Expose class on Global object
    frk.PPSSSort = PPSSSort;

})(frk);

