/* 
 * Custom handlebars partials
 */
;
(function (Handlebars, frk) {
    
    Handlebars.registerPartial({
        // Display header unless @index parameter is 0        
        'performance-header': '{{#ifCond @index "!==" 0}}<tr>\
                                <th class="nested-header money-funds" colspan="10" scope="colgroup" data-fti-sticky-trigger="stop" data-fti-sticky-for="performance"><span data-fti-label="money-funds"></span></th>\
                            </tr>\
                        <tr class="header-utilities">\
                            <th class="search-area" scope="colgroup" colspan="4"></th>\
                            <th scope="col">\
                                <small>\
                                    <span data-fti-label="as-of"></span>\
                                    <span data-view="ytd-as-of">mm/dd/yyyy</span>\
                                </small>\
                            </th>\
                            <th scope="colgroup" colspan="5" class="avann-th">\
                                <span class="avg-total-rtn-at-nav">\
                                    <label for="mth-end" id="avg-total-rtn-at-nav">\
                                        <span data-fti-label="nav-total-return"></span>\
                                        <span data-view="popOrNav">POP</span>\
                                        <span data-view="nav-total-ret-pop-or-nav-caveat"></span>\
                                        <small>\
                                            <span data-fti-label="as-of"></span>\
                                            <span data-fti-label=""></span>\
                                            <span data-view="nav-as-of">mm/dd/yyyy</span>\
                                            <span data-view="nav-as-of-total-ret-pop-or-nav-caveat"></span>\
                                        </small>\
                                    </label>\
                                </span>\
                            </th>\
                        </tr>\
                        <tr class="header-sortable"> \
			<th scope="col" class="checkbox-select hidden-print"></th>\
			<th scope="col" class="fund-symbol"><span data-fti-label="symbol"></span></th>\
			<th scope="col" class="fund"><span data-fti-label="fund-name"></span></th>\
			<th scope="col" class="fund-fav hidden-print"><span data-fti-label="fav"></span></th>\
			<th scope="col" class="ytd-total-rtn-at-nav text-right"><span data-fti-label="ytd-total-ret"></span></th>\
			<th scope="col" class="avg-total-rtn-at-nav-1-yr text-right"><span data-fti-label="1yr"></span></span></th>\
			<th scope="col" class="avg-total-rtn-at-nav-3-yr text-right"><span data-fti-label="3yr"></span></th>\
			<th scope="col" class="avg-total-rtn-at-nav-5-yr text-right"><span data-fti-label="5yr"></span></th>\
			<th scope="col" class="avg-total-rtn-at-nav-10-yr text-right"><span data-fti-label="10yr"></span></th>\
			<th scope="col" class="life-of-fund text-right"><span data-fti-label="inception"></span></th>\
		</tr>{{/ifCond}}',
        'yield-header': '{{#ifCond @index "!==" 0}}<tr>\
                                <th class="nested-header money-funds" colspan="10" scope="colgroup" data-fti-sticky-trigger="stop" data-fti-sticky-for="yield"><span data-fti-label="money-funds"></span></th>\
                            </tr>\
                            <tr class="header-utilities">\
                                <th class="search-area" scope="colgroup" colspan="4"></th>\
                                <th scope="col">\
                                    <small><span data-view="distr-rate-as-of">As of mm/dd/yyyy</span> <span data-view="dist-rate-pop-or-nav-caveat"></span>\
                                    </small>\
                                </th>\
                                <th scope="col">\
                                    <small>\
                                        <span data-view="distr-rate-as-of">As of mm/dd/yyyy</span> <span data-view="standard-yield-caveat"></span>\
                                    </small>\
                                </th>\
                                <th scope="colgroup" colspan="2">\
                                    <small>\
                                        <span data-fti-label="as-of"></span> \
                                        <span data-view="ytd-as-of">mm/dd/yyyy</span> \
                                    </small>\
                                </th>\
                            </tr>\
                            <tr class="header-sortable"> \
			<th scope="col" class="checkbox-select hidden-print"></th>\
			<th scope="col" class="fund-symbol"><span data-fti-label="symbol"></span></th>\
			<th scope="col" class="fund"><span data-fti-label="fund-name"></span></th>\
			<th scope="col" class="fund-fav hidden-print"><span data-fti-label="fav"></span></th>\
			<th scope="col" class="distribution-rate-at-nav"><span data-fti-label="7-day-current-yield"></span> <span data-fti-caveat="Fund Tab|7day Current Yield"></span></th>\
			<th scope="col" class="avg-total-rtn-at-nav-1-yr text-right"><span data-fti-label="7-day-effective-yield"></span> <span data-fti-caveat="Fund Tab|7day Effective Yield"></span></th>\
			<th scope="col" class="avg-total-rtn-at-nav-3-yr text-right"><span data-fti-label="nav"></span> <span data-fti-caveat="Fund Tab|NAV"></span></th>\
			<th scope="col" class="avg-total-rtn-at-nav-5-yr text-right"><span data-fti-label="nav-change"></span> <span data-fti-caveat="Fund Tab|NAV Change"></span></th>\
		</tr>{{/ifCond}}',
        'rating-header': '{{#ifCond @index "!==" 0}}<tr>\
                                <th class="nested-header money-funds" colspan="12" scope="colgroup" data-fti-sticky-trigger="stop" data-fti-sticky-for="ratings"><span data-fti-label="money-funds"></span></th>\
                            </tr>\
                            <tr class="header-utilities">\
                                <th class="search-area" scope="colgroup" colspan="4"></th>\
                                <th class="hidden-print"></th>\
                                <th scope="colgroup" colspan="4">\
                                    <span data-fti-label="morningstar-rating"></span>\
                                    <sup data-for="13323763">4</sup>\
                                    <small>\
                                        <span data-fti-label="as-of"></span>\
                                        <span data-view="morningstar-as-of">mm/dd/yyyy</span>\
                                    </small>\
                                </th>\
                                <th scope="colgroup" colspan="3">\
                                    <span data-fti-label="nb-funds-in-category"></span>\
                                    <small>\
                                        <span data-fti-label="as-of"></span> \
                                        <span data-view="fund-category-as-of">mm/dd/yyyy</span>\
                                    </small>\
                                </th>\
                            </tr>\
                            <tr class="header-sortable"> \
			<th scope="col" class="checkbox-select hidden-print"></th>\
			<th scope="col" class="fund-symbol"><span data-fti-label="symbol"></span></th>\
			<th scope="col" class="fund"><span data-fti-label="fund-name"></span></th>\
			<th scope="col" class="fund-fav hidden-print"><span data-fti-label="fav"></span></th>\
			<th scope="col" class="ytd-total-rtn-at-nav text-right"><span data-fti-label="morningstar-category"></span></th>\
			<th scope="col" class="avg-total-rtn-at-nav-1-yr text-right"><span data-fti-label="overall"></span></span></th>\
			<th scope="col" class="avg-total-rtn-at-nav-3-yr text-right"><span data-fti-label="3yr"></span></th>\
			<th scope="col" class="avg-total-rtn-at-nav-5-yr text-right"><span data-fti-label="5yr"></span></th>\
			<th scope="col" class="avg-total-rtn-at-nav-10-yr text-right"><span data-fti-label="10yr"></span></th>\
			<th scope="col" class="avg-total-rtn-at-nav-3-yr text-right"><span data-fti-label="3yr"></span></th>\
			<th scope="col" class="avg-total-rtn-at-nav-5-yr text-right"><span data-fti-label="5yr"></span></th>\
			<th scope="col" class="avg-total-rtn-at-nav-10-yr text-right"><span data-fti-label="10yr"></span></th>\
		</tr>{{/ifCond}}',
        'fund-name': '<a href="{{productUrl \'overview\' fundNumber.val fundCategory.val}}?FundID={{fundNumber.val}}" class="fund-name">{{#ifViewportIs \'>=md\'}}{{fundLegalName.val}}{{else}}{{fundShortName.val}}{{/ifViewportIs}} - {{shareClass.val}}</a><span data-fti-caveat="Fund Tab|Fund Shareclass Name"></span>',
        'fund-name-comparison': '<a href="{{productUrl \'overview\' fundNumber.val fundCategory.val}}?FundID={{fundNumber.val}}" class="fund-name">{{legalName.val}}</a>',
        'favourite-star': '{{#ifCond loggedIn \'!==\' \'false\'}}\
            <a href="#{{instrId.alt}}" data-fti-module="favorite-a-fund" data-fti-component="favorite-a-fund" data-favorite-instr-id="{{instrId.alt}}" data-sync="true">\
                <span class="sr-only">Favorite</span>\
                <i data-toggle="tooltip" data-html="true" data-placement="bottom" title="<p>Favorite or unfavorite a fund</p>" data-template="<div class=\'tooltip signed-in-tooltip\' role=\'tooltip\'><div class=\'tooltip-arrow\'></div><div class=\'tooltip-inner\' ></div></div>" class="icon ft-icon-favorite{{#isFav instrId.alt}}-active{{else}}{{/isFav}}"></i>\
            </a>\
            {{else}}\
            <a href="#{{instrId.alt}}" data-fti-module="favorite-a-fund" data-fti-component="favorite-a-fund" data-favorite-logged-off="true">\
                <span class="sr-only">Favorite</span> \
                <i data-toggle="popover" data-placement="bottom" title="<button type=\'button\' class=\'close\' data-dismiss=\'popover\' aria-label=\'Close\'>Close <span aria-hidden=\'true\'>&times;</span></button>" data-content="<p>To create a favorite list, you must <strong><a class=\'popover-link\' href=\'{{signinUrl}}\' title=\'Sign in\'>sign in</a></strong> or <strong><a class=\'popover-link\' href=\'{{registrationUrl}}\'>register</a></strong></p>" data-html="true" data-template="<div class=\'popover signed-out-tooltip\' role=\'tooltip\'><div class=\'arrow\'></div><h3 class=\'popover-title\'></h3><div class=\'popover-content\'></div></div>" class="icon ft-icon-favorite"></i>\
            </a>\
            {{/ifCond}}',
        'closure-flag': '{{#ifCond displayStatus.val \'===\' \'S\'}}\
            <p class="msg msg-table msg-closure-soft"><span data-fti-label="flag.soft-closure"></span></p><br/><br/>\
                {{else}}\
                {{#ifCond displayStatus.val \'===\' \'H\'}}\
                <p class="msg msg-table msg-closure-hard"><span data-fti-label="flag.hard-closure"></span></p><br/><br/>\
                {{/ifCond}}\
            {{/ifCond}}',
        'closure-flag-529': '{{#ifCond priceDet.displayStatus.val \'===\' \'S\'}}\
            <p class="msg msg-table msg-closure-soft"><span data-fti-label="flag.soft-closure"></span></p><br/><br/>\
                {{else}}\
                {{#ifCond priceDet.displayStatus.val  \'===\' \'H\'}}\
                <p class="msg msg-table msg-closure-hard"><span data-fti-label="flag.hard-closure"></span></p><br/><br/>\
                {{/ifCond}}\
            {{/ifCond}}',
         'closure-money-flag': '{{#ifCond displayStatus.val \'===\' \'S\'}}\
                <p class="msg msg-table msg-closure-soft"><span data-fti-label="flag.soft-closure"></span></p><br/><br/>\
                {{else}}\
                {{#ifCond displayStatus.val \'===\' \'H\'}}\
                <p class="msg msg-table msg-closure-hard"><span data-fti-label="flag.hard-closure"></span></p><br/><br/>\
                {{else}}\
                {{#ifCond displayStatus.val  \'===\' \'P\'}}\
                <p class="msg msg-table msg-closure-soft"><span data-fti-label="flag.liquidt-mergd"></span></p><br/><br/>\
                {{/ifCond}}\
                {{/ifCond}}\
                {{/ifCond}}',
         'share-hldr-net-flow': '<td class="avg-total-rtn-at-nav-3-yr text-center">{{chkShareHldrNetFlowVal shareHolderNetFlow.val shareHolderNetFlow.alt1 shareHolderNetFlow.alt2}}</td>\
        	 <i data-toggle="tooltip" data-html="true" data-placement="bottom" title="<p>Favorite or unfavorite a fund</p>" data-template="<div class=\'tooltip signed-in-tooltip\' role=\'tooltip\'><div class=\'tooltip-arrow\'></div><div class=\'tooltip-inner\' ></div></div>" class="icon ft-icon-favorite"></i>',
        'expenses-collapse': '<div class="collapsable-header" data-fti-module="toggle-collapse">\
            <i class="icon ft-icon-right-carrot"></i>\
            <a data-toggle="collapse" href="#collapse-{{fundNumber.val}}-{{tab}}" aria-expanded="false" aria-controls="collapse-{{fundNumber.val}}-{{tab}}" class="collapsed">\
                <span data-fti-label="expenses-collapse"></span>\
            </a>\
        </div>\
        <div id="collapse-{{fundNumber.val}}-{{tab}}" class="collapsable-content collapse" aria-expanded="false">\
            <small><span data-fti-label="expenses-collapse.ratio"></span>: <span data-fti-label="expenses-collapse.gross"></span><span data-fti-caveat="Fund Tab|Expense Ratio Gross"></span> - <b>{{format grossExpenseRatio.alt unit="%"}}</b>  |  <span data-fti-label="expenses-collapse.net"></span><span data-fti-caveat="Fund Tab|Expense Ratio Net"></span> - <b>{{format netExpenseRatio.alt unit="%"}}</b></small>\
            <small><span data-fti-label="expenses-collapse.sales-charges"></span>: <span data-fti-label="expenses-collapse.max"></span><span data-fti-caveat="Fund Tab|Sales Charge Max"></span> - <b>{{format maxIntialSalesCharge.alt unit="%"}}</b>  |  <span data-fti-label="expenses-collapse.cdsc"></span><span data-fti-caveat="Fund Tab|Sales Charge CDSC"></span> - <b>{{format cdsc.alt unit="%"}}</b></small>\
        </div>',
        'checkbox': '<input type="checkbox" value="{{fundNumber.val}}" class="fund-{{fundNumber.val}}" data-ppss-favorite="checkbox"\
                    data-fundcat="{{prntFundCategory.val}}"\
                    data-displaystatus="{{displayStatus.val}}"/>',
        'alert': '<div data-fti-component="error-messages">\
                <div role="alert" class="alert alert-{{alert-type}}">\
                {{#ifCond alert-type \'===\' \'success\'}}\
                    <div class="pull-left glyphicon glyphicon-ok-sign ft-1-5x"><span class="sr-only">Info:</span></div>\
                {{/ifCond}}\
                {{#ifCond alert-type \'===\' \'info\'}}\
                    <div class="pull-left icon ft-icon-info ft-1-5x"><span class="sr-only">Info:</span></div>\
                {{/ifCond}}\
                {{#ifCond alert-type \'===\' \'warning\'}}\
                    <div class="pull-left glyphicon glyphicon-alert ft-1-5x"><span class="sr-only">Warning:</span></div>\
                {{/ifCond}}\
                {{#ifCond alert-type \'===\' \'danger\'}}\
                    <div class="pull-left glyphicon glyphicon-alert ft-1-5x"><span class="sr-only">Warning:</span></div>\
                {{/ifCond}}\
                <div class="message"><p class="alert-heading">{{heading}}</p><p class="alert-body">{{body}}</p></div>\
            </div>'        
    });
})(Handlebars, frk);
