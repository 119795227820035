/** 
 * Page search - GSA based site page searching
 * 
 * This component sends an AJAX request to the GSA provider, then renders the handlebars template with the results.
 * 
 */
;
(function($, document, frk, Handlebars) {
        
	var componentName = 'searchResultsPages', defaults = {};
    
	function Component(element, options) {
        
        var _self = this;
        
        console.log('invoked searchResultsPages - Component function');
        
		this.element = element;
		this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        
		this.resultList = $(this.element).find('.search-list');		
		this.loadMoreBtn = $(this.element).find('.load-more-results');	
		this.total = $(this.element).find('.pages-count');
		this.pageNumber = 1;

		this.init();
	}

	Component.prototype = {
		init : function() {
			var _self = this;
			$(this.element).hide();
			this.controller = frk.searchController;
			this.controller.isSearchResultsPage = true;
            $(this.loadMoreBtn).hide();
                     
            var templateUrl = $(this.element).data('ftiTemplate');
			if (templateUrl) {
				frk.load(templateUrl, 'html').then(function(template) {
					_self.template = template;
					_self.updateView(_self.controller.model);
					_self.bindEvents();
				}, function(error) {
					throw 'Error retrieving: ' + templateUrl;
				})
			} else {
				throw 'No Search Result Template specified';
			}
		},
		
		bindEvents: function() {
			var _self = this;
            $(this.element).find('.panel-title a').each(function () {
                $(this).on('click', function (event, data) {
                    console.log('*********************** pages show all link hit');
                    _self.controller.updateFilter('pages');                
                });
                
            });
            
            $(this.element).find('.load-more-results').each(function () {
                $(this).on('click', function (event, data) {
                    console.log('*********************** pages show more hit');
                    _self.controller.loadMore('pages');
                });
            });
            
            $(this.controller).on('searchStarted', function(e, model) {
            	_self.updateView(model);
            });
            
            $(this.controller).on('pageSearchFinished', function (e, model) {
                _self.updateView(model);
            });
            $(this.controller).on('filterUpdated', function (e, model) {
            	_self.updateView(model);
            });
		},
		
		updateView: function(model) {
            switch(model.filter) {
	            case 'all': $(this.element).show();
	            			$(this.loadMoreBtn).hide();
	            			this.render(model);
	            			break;
	            case 'pages': $(this.element).show();
	            			  if (model.pages.total > model.pages.pageSize) {
	            				  $(this.loadMoreBtn).show();
	            			  } else {
	            				  $(this.loadMoreBtn).hide();
	            			  }
	            			  this.render(model);
    						  break;
    			default: $(this.element).hide();
            }
		},
						
		render : function(model) {
        	if (model.pages.isSearching) {
        		$(this.resultList).throbber('addLoader');
        	} else {
    			var html = model.pages.error;
    			if (html === null) {
      				html = Handlebars.compile(this.template)({
                		results: model.pages.results.slice(model[model.filter].pageStart, model[model.filter].pageSize)
      	            });
    	        } else {
    	        	html = '<div class="col-sm-12">' + html + '</div>';
    	        }

    			this.total.text(model.pages.total);
               
    			$(this.resultList).empty();
    			$(this.resultList).append(html);        
        	}
		},
//		clearResults : function() {
//            console.log('invoked searchResultsPages - clearResults function');
//			$(this.searchBox).parent().find('.' + this.options.resultClass).remove();
//		},
		        
	};

	$.fn[componentName] = function(options) {
		return this.each(function() {
			if (!$.data(this, 'component_' + componentName)) {
				$.data(this, 'component_' + componentName, new Component(this,
						options));
			}
		});
	};


})(jQuery, document, frk, Handlebars);