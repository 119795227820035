/**
 * Franklin Templeton Footnotes Plugin
 * author: lmcmill
 */

;
(function ($, window, document, frk) {

    // Create the default component options
    var componentName = "footnotes",
            defaults = {
                superScript: '#'
            };

    // The actual component constructor
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.context = $(this.element).data('caveat-context');        
        this.selectorContext = $(document);
        
        if(typeof this.context !== 'undefined'){
            this.selectorContext = $(this.context);
        }
        
        var _self = this;
        
        // Global listeners
        $(window).resize(frk.throttle(function () {
            $("ol[data-fti-module='footnotes']", _self.selectorContext).footnotes();
        }, 500));

        // re-render footnotes when changing tabs.
        $('a[data-toggle="tab"]').on('shown.bs.tab', function () {
            $("ol[data-fti-module='footnotes']", _self.selectorContext).footnotes();
        });

        // init plugin
        this.init();

    }

    Component.prototype = {
        init: function () {

            var _self = this;

            // component has loaded...

            // Set up our variables
            var processed = [];
            var footnotesLis = $(this.element).find('[data-footnote]');
            var superScripts = _self.selectorContext.find('sup[data-for]:visible, [data-fti-component="ppss"] .collapse sup[data-for]');

            // Hide all footnotes before reordering
            footnotesLis.hide();
//            console.log('Hide Footnotes');

            // Now lets number all the superscripts in the order they appear
            superScripts.each(function (index, element) {

                // For each <sup>
                var $element = $(element);
                // Take the ID
                var forId = $element.attr('data-for');

                var position;

                // If the footnote ID exists in the footnotes OL lets process

                if ($(_self.element).find("[data-footnote='" + forId + "']").length > 0) {

                    // If this id has already been processed skip
                    if ($.inArray(forId, processed) !== -1) {
                        index = index - 1;
                        position = $.inArray(forId, processed) + 1;

                        // Else lets process the id and give it appropriate number
                    } else {
                        
                        processed.push(forId);
                        position = processed.length;
                        
//                        console.log('Processing: ' + forId + ' Position: ' + position);
                        
                        // Now lets show the footnote li in the same order.
                        var footnote = $(_self.element).find("[data-footnote='" + forId + "']").first();
//                        console.log("Size: " + footnote.size());
                        
                        footnote.remove();
                        footnote.attr('id', 'footnote' + position);
                        footnote.insertAt(position - 1, $(_self.element)).show();

                    }
                    
                    $element.text(_self.options.superScript.replace('#', position));

                }

            });

            // Now lets remove h3 if no footnotes are visible
            if($(this.element).find("li:visible").length === 0){
              $('.footnotes-heading', _self.selectorContext).hide();
            } else {
              $('.footnotes-heading', _self.selectorContext).show();
            }

        }

    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "component_" + componentName)) {
                $.data(this, "component_" + componentName,
                        new Component(this, options));
            } else {
                $.data(this, "component_" + componentName).init();
            }
        });
    };

    $.fn.insertAt = function (index, $parent) {
        return this.each(function () {
            if (index === 0) {
                $parent.prepend(this);
            } else {
                $parent.children().eq(index - 1).after(this);
            }
        });
    };

})(jQuery, window, document, frk);
