/** 
 * Find A Fund 
 * 
 */
;
(function ($, window, document) {

    // Create the defaults once 
    var componentName = 'favoriteAFund',
            defaults = {
                ajaxBeanID: 'us.favorite-data',
                activeClass: 'ft-icon-favorite-active',
                inactiveClass: 'ft-icon-favorite',
                emmitTo: '[data-ppss-favorite="toggle"]'
            };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;

        this.options = $.extend({}, defaults, options);

        this._defaults = defaults;
        this._name = componentName;

        this.stateElement = $(this.element).find('i');
        // is it a favourite fund alrady or not
        this.favorite = $(this.stateElement).hasClass(this.options.activeClass) ? true : false;
        // does it have a fund id
        this.instrId = $(this.element).data('favoriteInstrId') ? $(this.element).data('favoriteInstrId') : null;
        this.loggedOff = $(this.element).data('favoriteLoggedOff') ? true : false;
        this.sync = $(this.element).data('sync') ? $(this.element).data('sync') : false;
        //
        if(this.sync) this.emmitTo = $(document).find(this.options.emmitTo)[0];
        //
        this.requesting = false;

        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
            var _self = this;
            $('[data-toggle="popover"]', $(this.element)).popover({html:true});
            $('[data-toggle="tooltip"]', $(this.element)).tooltip();

            $(this.element).click(function (e) {
                if (_self.loggedOff) {
                    // user is logged off
                    _self._handleClick('loggedOff');
                }
                else {
                    // logged in user, handle add/removing
                    _self._handleClick('loggedIn');
                }

                //e.preventDefault();
            });

            $(this.element).on('addToFav', function () {
                if (!_self.favorite) {
                    if (_self.loggedOff) {
                        // user is logged off
                        _self._handleClick('loggedOff');
                    }
                    else {
                        // logged in user, handle add/removing
                        _self._handleClick('loggedIn');
                    }

                }
            });

            $(this.element).on('removeFromFav', function () {
                if (_self.favorite) {
                    if (_self.loggedOff) {
                        // user is logged off
                        _self._handleClick('loggedOff');
                    }
                    else {
                        // logged in user, handle add/removing
                        _self._handleClick('loggedIn');
                    }

                }
            });
        },
        _handleClick: function (mode) {
            var _self = this;

            switch (mode) {
                case 'loggedIn':
                    if (!this.requesting) {
                        this.requesting = true;
                        if (this.sync) $(this.emmitTo).trigger('favRequest');
                        this._callService().then(function () {
                            _self.requesting = false;
                            _self.toggleState();
                            _self._synchronise();
                            if (_self.sync) $(_self.emmitTo).trigger('favIsReady', ['success']);
                        }, function (message) {
                            _self.requesting = false;
                            if (_self.sync) $(_self.emmitTo).trigger('favIsReady', ['fail']);
                            window.alert(message);
                        });
                    }
                    break
                default:
                    _self._displayPopup();
            }
        },
        _callService: function () {
            var dfd = $.Deferred();

            $.ajax({
                url: '?bid=' + this.options.ajaxBeanID + '&favorite=' + !this.favorite + '&instrID=' + this.instrId,
                dataType: 'json'
            }).done(function (data) {
                dfd.resolve(data);
            }).fail(function (err) {
                $('[data-fti-component="error-messages"]').remove();
                $('[data-fti-component="back-to-top"]').backToTop();
                frk.danger('An error occured', 'Unfortunately, something went wrong while adding the fund to your favorite list.');
                dfd.reject('An error occured');
            });

            return dfd.promise();

        },
        toggleState: function () {
            // update classes
            $(this.stateElement)
                    .toggleClass(this.options.activeClass)
                    .toggleClass(this.options.inactiveClass);

            // update state
            this.favorite = $(this.stateElement).hasClass(this.options.activeClass) ? true : false;
        },
        // if there is a another Star in the document with the same fund id
        _synchronise: function () {
            var _self = this;
            // methode called, increment queue
            $(document).find('[data-fti-module="favorite-a-fund"][data-favorite-instr-id="' + this.instrId + '"]').each(function () {
                // if not self
                if (this !== _self.element) {
                    $(this).favoriteAFund('toggleState');
                }
            });
        },
        _displayPopup: function () {
            var _self = this;
            // close button
            $('button.close', this.element).click(function () {
                $('.popover', _self.element).popover('hide');
            });
            
            // open links
            $('.popover-link').click(function(e){
                window.open($(e.target).attr('href'), '_self');
            });
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            } 
            else {
                try {
                    $.data(this, 'component_' + componentName)[options]();
                }
                catch (e) {
                    console.error('Unable to execute method ' + options);
                }
            }
        });
    };

})(jQuery, window, document);
