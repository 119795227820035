/** 
 * Add a loading spinner on element
 *
 */
;
(function ($, window, document) {

    // Create the defaults once 
    var componentName = 'throbber',
            defaults = {
                wrapperClass: 'loaderWrapper',
                loaderClass: 'loader',
                loadingText: 'Loading...'
            };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;

        this.addLoader();
    }

    // Component methods 
    Component.prototype = {
        init: function () {

        },
        addLoader: function () {
            $(this.element).find('.' + this.options.wrapperClass).remove();
            $(this.element).append('<div class="' + this.options.wrapperClass + '"><div class="' + this.options.loaderClass + '">' + this.options.loadingText + '</div></div>');
        },
        removeLoader: function () {
            $(this.element).find('.' + this.options.wrapperClass).remove();
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
            else {
                $.data(this, 'component_' + componentName)[options]();
            }
        });
    };

})(jQuery, window, document);
