$(function (){
    $('.tabcollapse').tabCollapse();
});

! function($) {

    "use strict";

    // TABCOLLAPSE CLASS DEFINITION
    // ======================

    var TabCollapse = function(el, options) {
        this.options = options;
        this.$tabs = $(el);

        this._accordionVisible = false; //content is attached to tabs at first
        this._initAccordion();
        this._checkStateOnResize();

        this.checkState();
    };

    TabCollapse.DEFAULTS = {
        accordionClass: 'visible-xs',
        tabsClass: 'hidden-xs',
        accordionTemplate: function(heading, groupId, parentId, active) {
            return '<div class="panel panel-default">' +
                '   <div class="panel-heading">' +
                '      <h4 class="panel-title">' +
                '        <a ' + (active ? 'aria-expanded="true"' : '') + ' class="' + (active ? '' : 'collapsed') + '" data-toggle="collapse" data-parent="#' + parentId + '" href="#' + groupId + '">' +
                '           ' + heading +
                '        </a>' +
                '      </h4>' +
                '   </div>' +
                '   <div id="' + groupId + '" class="panel-collapse collapse ' + (active ? 'in' : '') + '">' +
                '       <div class="panel-body js-tabcollapse-panel-body">' +
                '       </div>' +
                '   </div>' +
                '</div>';
        }
    };

    TabCollapse.prototype.checkState = function() {
        if (this.$tabs.is(':visible') && this._accordionVisible) {
            this.showTabs();
            this._accordionVisible = false;
        } else if (this.$accordion.is(':visible') && !this._accordionVisible) {
            this.showAccordion();
            this._accordionVisible = true;
        }
    };

    TabCollapse.prototype.showTabs = function() {
        this.$tabs.trigger($.Event('show-tabs.bs.tabcollapse'));

        var $panelBodies = this.$accordion.find('.js-tabcollapse-panel-body');
        $panelBodies.each(function() {
            var $panelBody = $(this),
                $tabPane = $panelBody.data('bs.tabcollapse.tabpane');
            $tabPane.append($panelBody.children('*').detach());
        });
        this.$accordion.html('');

        this.$tabs.trigger($.Event('shown-tabs.bs.tabcollapse'));
        console.log('shown-tabs.bs.tabcollapse triggered');
    };

    TabCollapse.prototype.showAccordion = function() {
        this.$tabs.trigger($.Event('show-accordion.bs.tabcollapse'));

        var $headings = this.$tabs.find('li:not(.dropdown) [data-toggle="tab"], li:not(.dropdown) [data-toggle="pill"]'),
            view = this;
        $headings.each(function() {
            var $heading = $(this);
            view.$accordion.append(view._createAccordionGroup(view.$accordion.attr('id'), $heading));
        });

        this.$tabs.trigger($.Event('shown-accordion.bs.tabcollapse'));
        console.log('shown-accordion.bs.tabcollapse triggered');
    };

    TabCollapse.prototype._checkStateOnResize = function() {
        var view = this;
        $(window).resize(function() {
            clearTimeout(view._resizeTimeout);
            view._resizeTimeout = setTimeout(function() {
                view.checkState();
            }, 100);
        });
    };


    TabCollapse.prototype._initAccordion = function() {
        this.$accordion = $('<div class="panel-group ' + this.options.accordionClass + '" id="' + this.$tabs.attr('id') + '-accordion' + '"></div>');
        this.$tabs.after(this.$accordion);
        this.$tabs.addClass(this.options.tabsClass);
        this.$tabs.siblings('.tab-content').addClass(this.options.tabsClass);
    };

    TabCollapse.prototype._createAccordionGroup = function(parentId, $heading) {
        var tabSelector = $heading.attr('data-target'),
            active = $heading.parent().is('.active');

        if (!tabSelector) {
            tabSelector = $heading.attr('href');
            tabSelector = tabSelector && tabSelector.replace(/.*(?=#[^\s]*$)/, ''); //strip for ie7
        }

        var $tabPane = $(tabSelector),
            groupId = $tabPane.attr('id') + '-collapse',
            $panel = $(this.options.accordionTemplate($heading.html(), groupId, parentId, active));
        $panel.find('.panel-body').append($tabPane.children('*').detach())
            .data('bs.tabcollapse.tabpane', $tabPane);

        return $panel;
    };



    // TABCOLLAPSE PLUGIN DEFINITION
    // =======================

    $.fn.tabCollapse = function(option) {
        return this.each(function() {
            var $this = $(this);
            var data = $this.data('bs.tabcollapse');
            var options = $.extend({}, TabCollapse.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('bs.tabcollapse', new TabCollapse(this, options));
        })
    };

    $.fn.tabCollapse.Constructor = TabCollapse;


}(window.jQuery);