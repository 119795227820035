;(function ($) {

    // Create the defaults once 
    var componentName = 'searchBox',
            defaults = {
            };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;

        this.searchBox = $(this.element).find('input[type="text"]');
        this.searchResults = $(this.element).find('.results-container');

        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {

            var self = this;

            setTimeout(self.matchParentSize(self.searchBox, self.searchResults), 500);
            self.showResults(self.searchBox, self.searchResults);
            self.resizeSearch(self.searchBox, self.searchResults);

        },
        // Match parent input size
        // called via timeout to allow rendering to complete

        matchParentSize: function (s, r) {
                if (s !== undefined && r !== undefined) {
                    var searchBoxWidth = s.outerWidth(),
                        searchBoxheight = s.outerHeight(),
                        newWidth = (searchBoxWidth - 1) + 'px',
                        newHeight = 10 + (searchBoxheight - 5) + 'px';

                    r.outerWidth(newWidth);
                    r.css({'top': newHeight});
                }
        },
        // showResults
        // Toggle visibility and do searches

        showResults: function (s, r) {
            
            var _self = this;

            console.log('Showing results...');

            var filter;

            // capture keypress
            s.on('paste', function () {
                // toggle visibility
                console.log(r.html());
                r.css({'visibility': 'visible'});
            });

            // capture keypress
            s.on('keypress', function () {
                // toggle visibility
                _self.matchParentSize(s,r);
                r.css({'visibility': 'visible'});
            }).keyup(function (e) {
                // capture search value key up
                filter = s.val();
                // hide if search empty or escape pressed
                if (filter === "" || e.keyCode === 27) {
                    r.css({'visibility': 'hidden'});
                }
            });

        },
        // resizeSearch 
        // Detect resize and recalculate sizes

        resizeSearch: function () {
            var self = this;
            $(window).resize(function () {
                console.log('Resizing...');
                setTimeout(self.matchParentSize(self.searchBox, self.searchResults), 500);
            });
        }

    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };
})(jQuery);


