;
(function ($, document) {

    // Create the defaults once 
    var componentName = 'ppssSalesCharges',
            defaults = {
            };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;

        this.isSalesChargesOn = $(this.element).find('input:checked').val();

        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
            var _self = this;

            $(this.element).click(this._handleToggle.bind(this));
            if (this._getBooleanVal(this.isSalesChargesOn)) {
                $(_self.element).trigger({
                    type: 'readyToRender',
                    isSalesChargesOn: _self._getBooleanVal(_self.isSalesChargesOn)
                });
            }
        },
        _handleToggle: function () {
            var _self = this;

            // bootstrap is slow to update input value with its toggle...
            window.setTimeout(function () {
                // 
                if (_self.isSalesChargesOn !== $(_self.element).find('input:checked').val()) {
                    // update button status
                    _self.isSalesChargesOn = $(_self.element).find('input:checked').val();

                    $(_self.element).trigger({
                        type: 'readyToRender',
                        isSalesChargesOn: _self._getBooleanVal(_self.isSalesChargesOn)
                    });
                }
            }, 0);
        },
        _getBooleanVal: function (string) {
            return string === 'true' ? true : false;
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };

})(jQuery, document);
