/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

(function ($, document, window) {
    // Create the defaults once 
    var componentName = 'backToTop',
            defaults = {
                offset: '300',
                duration: '300'//appear in 300 milliseconds
            };

    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.btnTopScroll = $(this.element).find('.back-to-top');
        this.init();
    }

    Component.prototype = {
        init: function () {
            var _self = this;
            this.btnTopScroll.click(function (event) {
                event.preventDefault();
                _self.scroll();
                return false;
            });
            $(window).scroll(function () {
                if ($(this).scrollTop() > _self._defaults.offset) {
                    $('.back-to-top').fadeIn(_self._defaults.duration);
                } else {
                    $('.back-to-top').fadeOut(_self._defaults.duration);
                }
            });
        },
        scroll: function(){
            $('html, body').animate({scrollTop: 0}, _self._defaults.duration);
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "component_" + componentName)) {
                $.data(this, "component_" + componentName,
                        new Component(this, options));
            }
            else {
                $.data(this, 'component_' + componentName).scroll();
            }
        });
    };

})(jQuery, document, window);

