(function ($, window, document) {
    // Create the defaults once 
    var componentName = 'roleSelector',
            defaults = {};

    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.closeRs = $(this.element).find(".close-rs");
        this.init();
    }

    Component.prototype = {
        init: function () {
            var _self = this;
            this.closeRs.click(function (event) {
                event.preventDefault();
                $(_self.element).hide();
            });

            $(this.element).find('li a[data-fti-parameter]').click(function(event) {
                event.preventDefault();
                var _button = $(this);
                $.ajax({
                    data: {personalize: $(_button).data("ftiParameter")},
                    dataType: 'json'
                }).done(function (data) {
                    // Receive an updated cookie
                    // Optionally redirect to the other role homepage
                    $(_self.closeRs).click();
                    if (data.redirect) {
                        window.location = $(_button).attr("href");
                    }
                }).fail(function () {
                    // Invalid role selected or other failure.
                    $(_self.closeRs).click();
                });
            });
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "component_" + componentName)) {
                $.data(this, "component_" + componentName,
                        new Component(this, options));
            }
        });
    };
})(jQuery, window, document);
