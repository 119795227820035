/* 
 * US Favourite class
 * 
 */
;
(function ($, frk) {

    frk.FavoriteFunds = {};

    frk.FavoriteFunds.setData = function (data, actor, url) {
        this.data = data;
        this.actor = actor;
        this.jsonUrl = url;
    };

    frk.FavoriteFunds.sync = function () {
        var _self = this,
                dfd = $.Deferred();
        frk.load(this.jsonUrl, 'json').then(function (favData) {
            _self.data = favData;
            dfd.resolve(favData);
        }, function () {
            dfd.reject('Error while synching Favorite data');
        });
        return dfd.promise();
    };

    frk.FavoriteFunds.addFav = function (data) {
        if (this.data && data.funds) {
            for (var f = 0; f < data.funds.length; f++) {
                if (data.funds[f]) {
                    if (frk.FavoriteFunds.isMyFav(data.funds[f].instrId.alt)) {
                        data.funds[f].favorite = {val: 'true'};
                    }
                    else {
                        if (data.funds[f].favorite) {
                            delete data.funds[f].favorite;
                        }
                    }
                }
            }
        }

        return data;
    };

    /**
     * checks if fund ID is in the list of favourite funds
     * @param {String} id
     * @returns {Boolean} true or false
     */
    frk.FavoriteFunds.isMyFav = function (id) {
        if (this.data) {
        	var sFunds = this.data.productsVO.savedFunds;
            for (var f = 0; f < sFunds.length; f++) {
                if (sFunds[f].instrId.val === id) {
                    return true;
                }
            }
        }
        return false;
    };


})(jQuery, frk);
