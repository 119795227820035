/* 
 * Custom handlebars helpers
 */
;
(function (Handlebars, moment, viewport) {

    Handlebars.registerHelper({
        /**
         * Repeats n times
         * Use template like this: {{#repeat var}}
         */
        repeat: function (variable, options) {
            var result = '';
            if (typeof parseInt(variable) === 'number') {
                for (var i = 0; i < variable; i++) {
                    result += options.fn(this);
                }
            }// we fail in silence if variable isn't a number
            return result;
        },
        /**
         * Repeats n times
         * Use template like this: {{#repeat var}}
         */
        repeatMStar: function (variable, options) {
            var result = '';
            if (typeof parseInt(variable) === 'number') {
                for (var i = 0; i < variable; i++) {
                    result += options.fn(this);
                }
            }
            return result;
        },
        toUpperCase: function (str) {
            return str.toUpperCase();
        },
        toLowerCase: function (str) {
            return str.toLowerCase();
        },
        /**
         * Logic conditions between 2 elements.
         * Use template like this: {{#ifCond var1 '&&' var2}}
         */
        ifCond: function (v1, operator, v2, options) {
            switch (operator) {
                case '===':
                    return (v1 === v2) ? options.fn(this) : options.inverse(this);
                case '!==':
                    return (v1 !== v2) ? options.fn(this) : options.inverse(this);
                case '<':
                    return (v1 < v2) ? options.fn(this) : options.inverse(this);
                case '<=':
                    return (v1 <= v2) ? options.fn(this) : options.inverse(this);
                case '>':
                    return (v1 > v2) ? options.fn(this) : options.inverse(this);
                case '>=':
                    return (v1 >= v2) ? options.fn(this) : options.inverse(this);
                case '&&':
                    return (v1 && v2) ? options.fn(this) : options.inverse(this);
                case '||':
                    return (v1 || v2) ? options.fn(this) : options.inverse(this);
                default:
                    return options.inverse(this);
            }
        },
        /**
         * Using ResponsiveBootstrapToolkit. Options can be
         * 'xs', 'sm', 'md', 'lg' OR
         * '>xs', '<=md', etc...
         **/
        ifViewportIs: function (context, options) {
            return (viewport.is(context)) ? options.fn(this) : options.inverse(this);
        },
        format: function (context, options) {
            if (options && options.hash) {
                if (context && options.hash.unit) {
                    if (context === 'N/A') {
                        return context;
                    }
                    else if (context === 'TBD') {
                        return '-';
                    }
                    else if (context === '-') {
                        return '-';
                    }                    
                    else {
                        // if unit has to be placed before
                        // check if context is a negative number and return either - unit context OR unit context
                        // otherwise return context unit
                        return (options.hash.before === 'true') ?
                                (!isNaN(parseInt(context)) && parseFloat(context) < 0) ? ('-' + options.hash.unit + context.replace('-', '')) : (options.hash.unit + context)
                                : (context + options.hash.unit);
                    }
                }
                return context ? context : '-';
            }
            return context;
        },
        litDate: function (dateIn) {
            return moment(dateIn, "YYYY-MM-DD").format("MM/YY");
        },
        foreach: function (arr, options) {
            if (options.inverse && !arr.length)
                return options.inverse(this);

            return arr.map(function (item, index) {
                item.$index = index;
                item.$first = index === 0;
                item.$last = index === arr.length - 1;
                return options.fn(item);
            });
        },
        frkLabel: function (key) {
            // TODO proper implementation
            var rolePattern = /(investor|advisor)\/.*/;
            var role = rolePattern.exec(window.location.pathname);
//            console.log('role=' + role[1]);
            if (key === 'url.literature-detail') {
                return role[1] + '/resources/lit/info/';
            } else if (key === 'url.download-literature') {
                return '/forms-literature/download/';
            } else if (key === 'url.document-url') {
				return 'http://www.ftinstitutional.com/ftinstitutional/institutional/investment-solutions/institutional-mutual-funds/mutual-fund-documents';
			}

        },
        frkRole: function (key, options) {
            // TODO proper implementation
            var rolePattern = /(investor|advisor)\/.*/;
            var role = rolePattern.exec(window.location.pathname);
            console.log('role=' + role[1]);
            if (key === 'url.download-finra') {
                if (role[1] === 'advisor') {
                    console.log("True");
                    return options.fn(this);
                } else {
                    return options.inverse(this);
                }

            }
            if (key === 'advisor') {
                if (role[1] === 'advisor') {
                    return options.fn(this);
                } else {
                    return options.inverse(this);
                }

            }

            return role[1];

        },
        productUrl: function (pageName, fundId, catName) {
            // TODO check if fund config is in memory
            return frk.trimExtension(frk.UsConfig.getProductPageUrl(pageName, fundId, catName), '.page');
        },
        times: function (n, packageCode, packageType, orderquantity, options) {

            var id = packageCode + "-" + packageType;
            var str = "";
            for (var i = 1; i <= n; i++) {
                var selectedProperty = i == orderquantity ? 'selected="selected"' : '';
                str = str + '<option value="' + i + '"' + selectedProperty + '>' + i + "</option>";

            }
            return new Handlebars.SafeString(str);
        },
        chkOrderLimit: function (orderLimit, options) {

            if (orderLimit > 0) {
                return options.fn(this);
                ;
            } else {
                return options.inverse(this);
            }

        },
        chkShareHolderNetFlowVal: function (net, options) {
            if (net >= 1000000) {
                return options.fn(this);
                
            } else if(net <= -1000000) {
				return options.fn(this);
            }
            else {
                return options.inverse(this);
            }
        },
        formatShHldrNetFlow: function (val,alt1,alt2) {
        	
        	if (val === 'N/A' || val === '' || val === 'undefined') {
                return val;
            }
            else if (val === 'TBD') {
                return '-';
            }
            else if(alt1 === '' && alt2 === '') {
            	console.log("Inside value formatter");
            	var shValue = (!isNaN(parseInt(val)) && parseFloat(val) < 0) ? ('-' + "$" + val.replace('-', '')) : ("$" + val);
        		return  shValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            else {
            	
            	if ((!isNaN(parseInt(val)) && parseFloat(val) >= 1000000) || (!isNaN(parseInt(val)) && parseFloat(val) <= -1000000))
            	{
            		console.log("returning alt2");
            		var millionValue = (!isNaN(parseInt(alt2)) && parseFloat(alt2) < 0) ? ('-' + "$" + alt2.replace('-', '')+"(M)") : ("$" + alt2+"(M)");
            		console.log("after comma formatter:"+millionValue.replace(/\B(?=(\d{3})+(?!\d))/g, ","));
            		return  millionValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
            		console.log("returning alt1");
            		var dollarValue = (!isNaN(parseInt(alt1)) && parseFloat(alt1) < 0) ? ('-' + "$" + alt1.replace('-', '')) : ("$" + alt1);
            		return  dollarValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
            }
        	
        },
        isFav: function (fundId, options) {
            return frk.FavoriteFunds.isMyFav(fundId) ? options.fn(this) : options.inverse(this);
        },
        formatCusip: function (context, options) {
            if (context && context !== '')
                return context.split(/(?=(?:...)*$)/).join(' ');
        },
        ifAnnuallyOrSemi: function (val, options) {
            if (typeof val === 'string' && (
                    val.toLowerCase() === 'annually'
                    || val.toLowerCase() === 'semiannually'
                    || val.toLowerCase() === 'semi-annual'
                    )) {
                return options.fn(this);
            }
            else {
                return options.inverse(this);
            }
        },        
        getByKey: function (array, options) {
            if (options && options.hash && $.isArray(array)) {
                var r;
                array.some(function (a) {
                    if (a.type === options.hash.key) {
                        r = (options.hash.date==='true')?a.date.alt:a.val.alt;
                        return true;
                    }
                });
                return r;
            }
        },        
        getAATR: function (array, options) {
            if (options && options.hash) {
                var r;
                var elemIdent=options.hash.elemType;
                var shareClass=array.shareClass;
                var specialShareClasses=['Advisor','R','R6','Z']; // it should be implemented in DSL, however... it was not
                if ($.inArray(shareClass,specialShareClasses)>-1) {elemIdent=elemIdent.replace('WSC','WOSC');};
                array.some(function (a) {
                    if (a.type === options.hash.key) {                        
                        r = (elemIdent==='asOfDate')?a.asOfDate.alt:a.fundPerfRtnDetlsVO[elemIdent].alt;
                        return true;
                    }
                });
                return r;
            }
        },
        checkStdDev: function (stdDev, options) {  //check if fundId is defined in config to display 1Yr stdDev and replace with dash
            if (options && options.hash) {
                var r=stdDev;
                var config=$.makeArray(options.hash.config); // in case config has only one element
              
                config.some(function (a) {
                    if (a.val.toString() === options.hash.fundId) {
                        r = "-";
                        return true;
                    }
                });
                
                if (r!=='-' && stdDev!=='' && (typeof stdDev != 'undefined')) {
                    return options.fn(this);
                }
                else
                {
                    return options.inverse(this);
                }
                
            }
        },
        isQuarterEnd: function (val, options) {
            if (typeof val === 'string' && (
                    val === 'qe')) {
                return options.fn(this);
            }
            else {
                return options.inverse(this);
            }
        },
        displayIfNotTooOldAndValidData: function (value, options) {  //check if distribution can be displayed on the page
        if (options && options.hash) {
            var config = options.hash.config; // in case config has only one element
            var yieldDate = options.hash.yieldDate;

            var thirtyDayStandardizedDataTimeframe = config.thirtyDayStandardizedDataTimeframe * 1000 * 60 * 60 * 24;

            // check if data is not too old
            tooOldData = ((Date.parse(new Date()) - Date.parse(yieldDate) > thirtyDayStandardizedDataTimeframe) ? 'Y' : 'N');

            if (tooOldData==='N' && yieldDate !== '' && value!=='' & value!=='-')
            {
                return options.fn(this);
            }
            else {
                return options.inverse(this);
            }
        }

        },       
        displayDistributionRateAtBreakpoint: function (cfgDistr, options) {  //check if distribution can be displayed on the page
            if (options && options.hash) {
                var configAllowed=tooOldData=categoryBlockedByUser=fundBlockedByUser='';
                var config=options.hash.config; // in case config has only one element
                var dvndDistSchd=options.hash.dvndDistSchd;
                var fundCategory=options.hash.fundCategory;
                var fundID=options.hash.fundId;                                           
                
                var categoryBlockedByUserArr=(config.hideDistributionForCategory?$.makeArray(config.hideDistributionForCategory.fundCat):[]);
                var fundBlockedByUserArr=(config.hideDistributionForFund?$.makeArray(config.hideDistributionForFund.fundID):[]);
                
                // check if category is not on the list of blocked
                categoryBlockedByUserArr.some(function (a) {
                    if (a.val.toString() === fundCategory) {
                        categoryBlockedByUser='Y';
                        return true;
                    }
                });
                
                // check if fund is not on the list of blocked
                fundBlockedByUserArr.some(function (a) {
                    if (a.val.toString() === fundID) {
                        fundBlockedByUser='Y';
                        return true;
                    }
                });

                // check if is allowed in config
                if (config.displayMonthlyDiv==='Y' && dvndDistSchd==='Monthly') {configAllowed='Y';}
                else if (config.displayQuarterlyDiv==='Y' && dvndDistSchd==='Quarterly') {configAllowed='Y';}
                else if (config.displaySemiAnnuallyDiv==='Y' && dvndDistSchd==='Semiannually') {configAllowed='Y';}
                else if (config.displayAnnuallyDiv==='Y' && dvndDistSchd==='Annually') {configAllowed='Y';}
                else if (dvndDistSchd==='-') {configAllowed='Y';}
                else {configAllowed='N';}
                                
                if (    configAllowed==='Y' 
                        && categoryBlockedByUser!=='Y'
                        && fundBlockedByUser!=='Y'){
                    return options.fn(this);
                }
                else {
                    return options.inverse(this);
                }
            }
        },        
        
    });
})(Handlebars, moment, ResponsiveBootstrapToolkit);
