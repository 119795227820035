/* 
 * This is for fund commentary important legal information expand and colleps functionality
 */

$(document).ready(function () {
$(".showMore2").click(function(){
                        if ($(this).siblings(".fullLegal").hasClass('closed')) {
                        $(this).text("[Minimize disclosure]").siblings(".fullLegal").removeClass('closed');    
                            $(this).siblings(".showMore2").text("[Minimize disclosure]");                    
                        }else{
                        $(this).text("[Read full disclosure]").siblings(".fullLegal").addClass('closed');    
                            $(this).siblings(".showMore2").text("[Read full disclosure]");
                        }
                    });
   
});
