/** 
 * Literature Inline View
 * 
 */  
;
(function ($, document, frk, Handlebars) {

    var componentName = 'literatureInline',
            defaults = {
                quickviewDataUrl: '/forms-literature/quickview'
            };

    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this._templateUrl = '/assets/templates/html/components/literature/order-lit/literature-inline.html';
        this.litcode = $(this.element).data('ftiLitCode'); 
        console.log("Literature code value is--->"+this.litcode); 
       
        this.template =  "{{#with [com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM]}}\n" +
"<div class=\"literature page-item\">\n" +
"    <div class=\"media\">\n" +
"        <div class=\"media-left\">\n" +
"            <div class=\"icon-docs\">\n" +
"                {{#if thumbnailPath}}\n" +
"                <img src=\"{{thumbnailPath}}\" alt=\"\"/>\n" +
"                {{/if}}\n" +
"                <span class=\"pdf icon-text\">PDF</span>\n" +
"            </div>\n" +
"        </div>\n" +
"        <div class=\"media-body\">\n" +
"            <div class=\"row\">\n" +
"                {{#if isActive}}\n" +
"                    <div class=\"col-sm-3 col-md-4 col-lg-3\">\n" +
"                        <div class=\"view-order\">\n" +
"                            <h3 class=\"btn-hdr\">View or Print</h3>\n" +
"                                {{#if literaturePath}}\n"+
"                                <a class=\"btn btn-default\" href=\"{{frkLabel 'url.download-literature'}}{{literatureCode}}\" target=\"_blank\">\n" +
"                                    <span class=\"ft-icon ft-icon-download\"></span>\n" +
"                                    Download\n" +
"                                </a> \n" +
"                                {{else}}\n" +
"                                <span>Download Unavailable</span>\n" +
"                                {{/if}}\n" +
"                        </div>\n" +
"                    </div>\n" +
"                    <div class=\"col-sm-6\" style=\"margin-left:20px\">\n" +
"                        <div class=\"view-order\">\n" +
"                            <h3 class=\"btn-hdr\">Order literature</h3>\n" +
"                            {{#chkOrderLimit orderLimit}}\n" +
"                                {{#if hasAdded}}\n" +
"                                    <a data-fti-component=\"button-cart\" data-fti-lit-code=\"{{literatureCode}}\" data-toggle=\"modal\" class=\"btn btn-primary disabled\" href=\"#\">\n" +
"                                        <span class=\"ft-icon ft-icon-cart\"></span>\n" +
"                                        Added\n" +
"                                    </a>\n" +
"                                {{else}}\n" +
"                                    <a data-fti-component=\"button-cart\" data-fti-lit-code=\"{{literatureCode}}\" data-toggle=\"modal\" class=\"btn btn-primary\" href=\"#\">\n" +
"                                        <span class=\"ft-icon ft-icon-cart\"></span>\n" +
"                                        Add to Cart\n" +
"                                    </a>\n" +
"                                {{/if}}\n" +
"                            {{else}}\n" +
"                                <span class=\"copy\">Hardcopy Unavailable</span>\n" +
"                            {{/chkOrderLimit}}\n" +
"                        </div>\n" +
"                    </div> \n" +
"                {{else}}\n" +
"                    <div class=\"col-sm-3 col-md-4 col-lg-3\">\n" +
"                       <span class=\"copy\">Discontinued</span>\n" +
"                     </div>\n" +
"                {{/if}}\n" +
"            </div>\n" +
"            <table class=\"table-condensed lit-details-table hidden-xs\">\n" +
"                <tbody>\n" +
"                    {{#frkRole 'url.download-finra'}}\n" +
"                    <tr>\n" +
"                        <th scope=\"row\">Client Use:</th>\n" +
"                        {{#if isClientUse}}\n" +
"                        <td>Yes (<a href=\"/forms-literature/downloadFinra/{{literatureCode}}\" target=\"_blank\">FINRA Letter</a>)</td>\n" +
"                        {{else}}\n" +
"                        <td>No</td>\n" +
"                        {{/if}}\n" +
"                    </tr>\n" +
"                    {{/frkRole}}\n" +
"                    <tr>\n" +
"                        <th scope=\"row\">Literature Code:</th>\n" +
"                        <td>{{literatureCode}}</td>\n" +
"                    </tr>\n" +
"                    {{#if formatDescription}}\n" +
"                    <tr>\n" +
"                        <th scope=\"row\">Format:</th>\n" +
"                        <td>{{formatDescription}} ({{contentSizeDescription}})</td>\n" +
"                    </tr>\n" +
"                    {{/if}}\n" +
"                    {{#if publicationDate}}\n" +
"                    <tr>\n" +
"                        <th scope=\"row\">Publication Date:</th>\n" +
"                        <td>{{litDate publicationDate}}</td>\n" +
"                    </tr>\n" +
"                    {{/if}}\n" +
"                    {{#if nextUpdateDate}}\n" +
"                    <tr>\n" +
"                        <th scope=\"row\">Next Update</th>\n" +
"                        <td>{{litDate nextUpdateDate}}</td>\n" +
"                    </tr>\n" +
"                    {{/if}}\n" +
"                </tbody>\n" +
"            </table>\n" +
"        </div>\n" +
"    </div>\n" +
"</div>\n" +
"{{/with}}";
       
        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
            this.getTemplate();
                    
            this.getQuickViewData().then(function (data) {
            	console.log("Inside getQuickViewData method");
                var _self = this;
                var html = Handlebars.compile(_self.template)(data);
                $(_self.element).replaceWith(html).buttonCart();
                $(document).find('[data-fti-component="button-cart"]').each(function () {
                	 $(this).buttonCart();
                	
                });
               
            },
             function (errorMessage) {
                console.log(errorMessage);
            });
        },
        
        getQuickViewData: function () {
            var _self = this;
            var dfd = $.Deferred();
            console.log("lit code value is--->"+this.litcode);
          console.log("Inside getQuickViewData ");
            $.ajax({
                url: '/forms-literature/quickview',
                data: {literatureCode: this.litcode},
                dataType: 'json',
                type: 'GET',
               
            }).done(function (data) {
                dfd.resolveWith(_self, [data]);
            }).fail(function () {
                dfd.reject('Unable to load data');
            });
            return dfd.promise();
        },
        
        getTemplate: function () {
           console.log("Inside getTemplate method");
            var dfd = $.Deferred();
            var _self = this;
            if (!_self.template) {
                $.ajax({
                    url: _self.templateUrl,
                    dataType: 'html',
                    context: $(_self)
                }).done(function (data) {
                    _self.template = data;
                    console.log("Data value is--->"+ _self.template);
                    dfd.resolve(data);
                }).fail(function () {
                    dfd.reject('Unable to load template');
                });
            }
            else {
                dfd.resolve(this.template);
            }
            return dfd.promise();
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };
    
    $(document).find('[data-fti-component="commentary"]').each(function () {
    	
        $(this).literatureInline();
    }); 

})(jQuery, document, frk, Handlebars);