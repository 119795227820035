/** 
 * Find A Fund 
 * 
 */
//
//(function ($, document, frk, Handlebars) {
//
//    // Create the defaults once 
//    var componentName = 'findAFund',
//            defaults = {
//                detailsPage: 'overview',
//                resultClass: 'results-container',
//                noResultFound: 'No results found for',
//                assetClass: ['Multi Asset', 'Fixed Income', 'Equity', 'Alternatives']
//            };
//    // The actual plugin constructor 
//    function Component(element, options) {
//        this.element = element;
//        this.options = $.extend({}, defaults, options);
//        this._defaults = defaults;
//        this._name = componentName;
//        // the search box
//        this.searchBox = $(this.element).find('.search-box input');
//        //Cancel Button in search box
//        var searchBox = this.searchBox;
//        var cancelButton = $(this.element).find('.ft-icon-close');
//        $(searchBox).keyup(function () {
//            if ($(searchBox).val() !== '') {
//                $(cancelButton).css({visibility: "visible"});
//                $(searchBox).css({"padding-right": "25px"});
//            } else
//            {
//                $(cancelButton).css({visibility: "hidden"});
//                $(searchBox).css({"padding-right": "0px"});
//            }
//        });
//        $(cancelButton).click(function () {
//            $(searchBox).val('');
//            $(cancelButton).css({visibility: "hidden"});
//            $(searchBox).css({"padding-right": "0px"});
//        });
//        this.form = $(this.element).find('form');
//        // the fields to filter against
//        this.fields = ['symbol.val', 'fundLegalName.val', 'fundNumber.val', 'cusip.val', 'cusipAlt.val'];
//        this.fieldsShort = ['symbol.val', 'fundNumber.val'];
//        this.categoryField = ['prntFundCategory.val'];
//        this.displayStatus = ['displayStatus.val'];
//        // Search object with query and array where result gets stored
//        this.searchResult = {
//            query: '',
//            result: []
//        };
//        this.searching = false;
//        this.searchIcon = $(this.element).find('.ft-icon-search');
//        this.hovered = false;
//        this.templates = {
//            listItem: '<div class="list-unstyled list-group results-funds">' +
//                    '{{#each result}}' +
//                    '<a data-fund-id="{{fundNumber.val}}" href="' + this.options.detailsPage + '?FundID={{fundNumber.val}}" class="list-group-item fund-name">{{symbol.val}} - {{fundLegalName.val}} - {{shareClass.val}}</a>' +
//                    '{{else}}' +
//                    '<span class="no-results">' + this.options.noResultFound + ' {{query}}</span>' +
//                    '{{/each}}' +
//                    '</div>' +
//                    '</div>'
//        };
//        this.init();
//    }
//
//    // Component methods 
//    Component.prototype = {
//        init: function () {
//            var _self = this;
//            // get and set json url
//            if ($(this.element).data('ftiJson')) {
//                this.jsonURL = $(this.element).data('ftiJson');
//            } else {
//                throw 'No JSON Data specified';
//            }
//            // add empty list where result will be displayed
//            $(this.searchBox).parent().find('.' + this.options.resultClass).bind('mouseover', function () {
//                _self.hovered = true;
//            }).bind('mouseout', function () {
//                _self.hovered = false;
//            });
//            // set up listeners
//            $(this.searchBox).on('keyup mouseup', function () {
//                frk.delay(_self._search.bind(_self), 500);
//            });
//            $(this.searchBox).on('paste', function () {
//                frk.delay(_self._search.bind(_self), 500);
//            });
//            $(this.searchBox).on('focus', this._showResults.bind(this));
//            $(this.searchBox).on('blur', this._hideResults.bind(this));
//            // verify if additional filtering needed
//            if ($(this.element).data('ftiFilterMoneyFunds')) {
//                this.isFundComparison = $(this.element).data('ftiFilterMoneyFunds');
//            }
//
//            if (_self.isFundComparison) {
//                $('#searchFundCompare').submit(function () {
//                      return false;
//                });
//            }
//                    
//            // Get data on load to speed up user interaction
//            _self._getData();
//        },
//        _initNumbers: function () {
//            this._getData().then(function (data) {
//            });
//        },
//        _formatCusip: function (fundData) {
//            var ppssList = fundData.productsVO.ppsList;
//            for (var f = 0; f < ppssList.length; f++) {
//                ppssList[f].cusipAlt = {val: ""};
//                ppssList[f].cusipAlt.val = ppssList[f].cusip.val.split(/(?=(?:...)*$)/).join(' ');
//            }
//        },
//        _getData: function () {
//            var dfd = $.Deferred();
//            var _self = this;
//            this.searching = true;
//            // No data in memory, we need to load it
//            if (!this.data) {
//                $.when(frk.load($(this.element).data('ftiUsConfig'), 'json', true), frk.load(_self.jsonURL, 'json', true)).then(function (config, data) {
//                    frk.UsConfig.setData(config, $(_self.element).data('ftiActor'));
//                    _self.data = _self._formatCusip(data);
//                    _self.searching = false;
//                    dfd.resolve(data);
//                },
//                        function (config, error) {
//                            _self.searching = false;
//                            dfd.reject('Unable to load data');
//                        });
//            }
//            // Already have data in memory
//            else {
//                _self.searching = false;
//                dfd.resolve(this.data);
//            }
//            return dfd.promise();
//        },
//        _search: function () {
//            var _self = this;
//            // search input has changed, check if there is something to search against
//            if ($(this.searchBox).val().length > 0 && !this.searching) {
//                _self._addLoader();
//                this._getData().then(function (data) {
//
//                    //clearup previous results
//                    _self.searchResult.result = [];
//                    // setup query string
//                    _self.searchResult.query = $(_self.searchBox).val();
//                    // we need to filter data
//                    var searchedCharsLength = $(_self.searchBox).val().trim().length;
//                    var searchedChars = $(_self.searchBox).val().trim();
//                    var searchedArray = [];
//
//
//                    if (!(isNaN(searchedChars))) {
//                        if (searchedCharsLength > 3) {
//                            searchedArray = _self.fields;
//                        } else {
//                            searchedArray = _self.fieldsShort;
//                        }
//                    } else {
//                        searchedArray = _self.fields;
//
//                    }
//
//                    _self.searchResult.result = frk.filter($(_self.searchBox).val().trim(), data.productsVO.ppsList, searchedArray);
//                    // remove Money and partial funds
//                    // GAST-355
//                    if (_self.isFundComparison) {
//                        _self.searchResult.result = frk.remove("Money Funds", _self.searchResult.result, _self.categoryField);
//                        _self.searchResult.result = frk.remove("P", _self.searchResult.result, _self.displayStatus);
//                    }
//                    // sort data
//                    _self.searchResult.result = frk.orderBy.alphabetialOrder(_self.searchResult.result, 'shareClass.val');
//                    _self.searchResult.result = frk.orderBy.alphabetialOrder(_self.searchResult.result, 'fundLegalName.val');
//
//                    var resultFunds = _self.searchResult.result;
//
//                    //GAST-1424- check  form contain action attr
//                    if ($('.panel-find-a-fund form').attr('action')) {
//                        //GAST-440 - limit arrays with searched fields based on number of signs
//                        var actionInit = $('.panel-find-a-fund form').attr('action');
//                        var aI;
//                        if (actionInit.indexOf('overview') > -1) {
//
//                            aI = actionInit.replace('overview', '');
//
//                        } else {
//                            aI = actionInit;
//                        }
//
//                        if (resultFunds.length === 1) {
//                            var singleFund = _self.searchResult.result[0]['fundNumber'];
//                            $('.panel-find-a-fund form').attr('action', aI + 'overview');
//                            $('.panel-find-a-fund form input:text').removeAttr('name');
//                            $('#FundID').remove();
//                            $('<input>').attr({
//                                type: 'hidden',
//                                id: 'FundID',
//                                name: 'FundID',
//                                value: singleFund.val
//                            }).appendTo('.panel-find-a-fund form');
//                            // alert ();
//
//
//                        } else {
//                            $('.panel-find-a-fund form').attr('action', aI);
//                            $('.panel-find-a-fund form input:text').attr('name', 'q');
//                            $('#FundID').remove();
//
//
//                        }
//                    }
//
//                    // Compile and render template
//                    var html = Handlebars.compile(_self.templates.listItem)(_self.searchResult);
//                    // display result
//                    _self._clearResults(); //clean first
//                    $(_self.searchBox).parent().find('.' + _self.options.resultClass).prepend(html);
//                    // setup listeners on result list items
//                    $('.' + _self.options.resultClass + ' li').on('click mouseover mouseleave', _self._handleItem.bind(_self));
//                    _self._removeLoader();
//                    // Listen for events on the fund name     
//                    if (_self.isFundComparison) {
//                        $(_self.element).find('a.list-group-item.fund-name').on('click', function () {
//                            $(_self.searchBox).val('');
//                            _self._clearResults();
//                            _self._hideResults();
//                            $(_self.element).trigger("fund-selected", $(this).attr('data-fund-id'));
//                            return false;
//                        });
//                    }
//
//                });
//            } else {
//                // we clear previous results if any
//                _self._clearResults();
//            }
//        },
//        _clearResults: function () {
//            this.searchResult.result = [];
//            $(this.searchBox).parent().find('.' + this.options.resultClass).children().filter('*:not(.results-all-funds)').remove();
//        },
//        _showResults: function () {
//            $(this.searchBox).parent().find('.' + this.options.resultClass).show();
//        },
//        _hideResults: function () {
//            if (!this.hovered) {
//                $(this.searchBox).parent().find('.' + this.options.resultClass).hide();
//            }
//        },
//        _handleItem: function (event) {
//            event.preventDefault();
//            if (event.type === 'mouseover') {
//                $(event.target).addClass('active');
//            }
//            if (event.type === 'mouseleave') {
//                $(event.target).removeClass('active');
//            }
//            if (event.type === 'click') {
//                $(this.searchBox).val($(event.target).attr('id'));
//                $(this.searchBox).attr('value', $(event.target).attr('id'));
//                this.hovered = false;
//                this._clearResults();
//            }
//        },
//        _addLoader: function () {
//            $(this.searchIcon).addClass('loader loadersmall');
//        },
//        _removeLoader: function () {
//            $(this.searchIcon).removeClass('loader loadersmall');
//        }
//    };
//    $.fn[componentName] = function (options) {
//        return this.each(function () {
//            if (!$.data(this, 'component_' + componentName)) {
//                $.data(this, 'component_' + componentName,
//                        new Component(this, options));
//            }
//        });
//    };
//})(jQuery, document, frk, Handlebars);
