;
(function ($, viewport) {

    // Create the defaults once 
    var componentName = 'header';
    defaults = {
    };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;

        this.viewport = viewport;
        this.navTitles = $('#mega').find('.title');

        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {

            var _self = this;

            //Hover Nav                   
            $('ul.nav li.dropdown').hover(function () {
                if (!$(this).hasClass("open") && viewport.is('>sm')) {
                    $(this).addClass('currentlyHovering');

                    if ($('#mega').find('li.open').length === 0) {
                        $(this).delay(250).queue(function () {
                            if ($(this).hasClass("currentlyHovering") && viewport.is('>sm')) {
                                $(this).find('.dropdown-toggle').attr("aria-expanded", "true");
                                $(this).addClass('open').dequeue();
                                if ($(this).hasClass('search'))
                                {
                                    $(this).find('input').focus();
                                }
                            }
                            else
                            {
                                $(this).dequeue();
                            }
                        });
                    }
                    else
                    {
                        if ($(this).hasClass("currentlyHovering") && viewport.is('>sm')) {
                            $(this).find('.dropdown-toggle').attr("aria-expanded", "true");
                            $('#mega').find('li.open').removeClass('open');
                            $(this).addClass('open').dequeue();
                            if ($(this).hasClass('search'))
                            {
                                $(this).find('input').focus();
                            }
                        }
                    }
                }
            }, function () {
                $(this).removeClass('currentlyHovering');
                $(this).delay(250).queue(function () {
	                    if (!$(this).is(":hover") && viewport.is('>sm')) {
                        $(this).removeClass('open').dequeue();
                        ;
                        $(this).find('.dropdown-toggle').attr("aria-expanded", "false");
	                    } else {
                        $(this).dequeue();
                    }
                });
            });


            //Hover Nav end
            $('#slide-nav.navbar').after($('<div id="navbar-height-col"></div>'));

            var toggler = '.navbar-toggle',
                    pagewrapper = '#page-content',
                    navigationwrapper = '.navbar-header',
                    slidewidth = '85%',
                    menuneg = '-100%',
                    slideneg = '-85%';

            $("#slide-nav").on("click", toggler, function (e) {

                var selected = $(this).hasClass('offcanvas-active');

                $('#navbar-close').toggleClass('hidden');
                $('#navbar-hamburger').toggleClass('hidden');

                $('#offcanvas-menu').stop().animate({
                    left: selected ? menuneg : '0px'
                });

                $('#navbar-height-col').stop().animate({
                    left: selected ? slideneg : '0px'
                });

                $(pagewrapper).stop().animate({
                    left: selected ? '0px' : slidewidth
                });

                $(navigationwrapper).stop().animate({
                    left: selected ? '0px' : slidewidth
                });

                $(this).toggleClass('offcanvas-active', !selected);
                $('#offcanvas-menu').toggleClass('offcanvas-active');
                $('#page-content, .navbar, body, .navbar-header').toggleClass('offcanvas-active');

            });
            
            $('form[data-fti-element=sign-in-form]').on('submit', function (e) {
                var _form = this;
                e.preventDefault();
                // Post login to pre-login handler to check for special cases
                var postData = $(_form).serialize();
                $.ajax({
                    type: 'POST',
                    url: '/api/account/prelogin',
                    data: postData,
                    dataType: 'json'
                }).done(function (e, data) {
                    if (e.location) {
                        window.location = e.location;
                    } else {
                    	$(_form).attr('action', e.loginUrl);
                        $(_form).off('submit');
                        $(_form).find("input[name='rememberMe']")[0].value = (postData.indexOf('_rememberMe=on') > -1);
                        $(_form).submit();
                    }
                });
            });

            var selected = '#slidemenu, #page-content, body, .navbar, .navbar-header';

            _self.setToggleCollapse();
            _self.resizeHandler(selected);
            _self.initTooltip();

        },
        setToggleCollapse: function () {

       

            var _self = this;


            if (viewport.is('<=sm')) {

                // Toggle Dropdown 
                _self.navTitles.data('toggle', 'collapse');

                // Clicks
                _self.navTitles.on("click", function (e, viewport) {
           
                    var el = $(e.target);
                    
                    el.next('.collapse').collapse('toggle');
                    el.attr('aria-expanded', function (i, attr) {
                        return attr === 'true' ? 'false' : 'true';
                    });
                    el.parent('.dropdown').toggleClass("open");
                    e.stopPropagation();
                    e.preventDefault();
                });

            } else {
                _self.navTitles.data('toggle', 'disabled');
                _self.navTitles.off("click");
            }

        },
        resizeHandler: function (selected) {

            var _self = this;

            $(window).on("resize", function () {

                if (viewport.is('>sm') && $('.navbar-toggle').is(':hidden')) {
                    $(selected).removeClass('offcanvas-active');
                }

                _self.setToggleCollapse();

            });

        },
        // tooltip instantiator for country hover in nav
        initTooltip: function () {
            $('[data-toggle="tooltip"]').tooltip();
        }

    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };
})(jQuery, ResponsiveBootstrapToolkit);

