;
(function ($) {

    // Create the defaults once 
    var componentName = 'fiftyTwoRange',
            defaults = {
            };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;

        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
            var _self = this;
            $(this.element).popover();

            $(this.element).on('click', function (e) {
                $(_self.element).parent().find('[data-dismiss="popover"]').click(function () {
                    $(_self.element).popover('hide');
                });
                e.preventDefault();
            });
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };

})(jQuery);


