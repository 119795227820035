/** 
 * Fund Tabs 
 * 
 */
;
(function ($, window, document) {

    // Create the defaults once 
    var componentName = 'productExplorer',
            defaults = {
            };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;

        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
            
            
            var _self = this;
            
            $(this.element).find('.div-toggle').click(function(){
                var fundId = $(this).attr('data-fund-id');
                
                $(_self.element).find('.fund-content').children().addClass('hidden');
                $(_self.element).find('.fund-content #' + fundId + '-content').removeClass('hidden');
                
                return true;
                
            });
     
        }

    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            } else {
                try {
                    $.data(this, 'component_' + componentName)[options]();
                }
                catch (e) {
                    console.error('Unable to execute method ' + options);
                }
            }
        });
    };

})(jQuery, window, document);
