/** 
 * My Literature
 * 
 * This component renders the handlebars template with the results from the literature service and then 
 * instantiates MyLiteratureDetails components for each document.
 * 
 */


;
(function ($, document, frk, Handlebars) {

    var componentName = 'myLiterature', defaults = {};
    var SERVICE_NAME = "com.frk.ci.gws.orderlit.model.viewmodel.LiteratureResultVM";
    var PAGE_SIZE = 20;
    
    var sort
    function Component(element, options) {

        this.element = element;

        this.options = $.extend({}, defaults, options);

        this._defaults = defaults;
        this._name = componentName;

        // the search box
        this.searchBox = $(this.element).find('.search-box input');

        // the search button
        this.searchBtn = $(this.element).find('.search-box .btn-primary');

        this.resultList = $(this.element).find('.list-group-data');

        this.loadMoreBtn = $(this.element).find('.load-more-results');

        this.sortResult = $(this.element).find('#sel_SortbyPublication');
        
        this.error = $(this.element).find('#display-error');

        this.pageNumber = 1;
        this.sortedPageNumber = 1;
        this.sortPageSize = 0;

        this.sortOrder = "mostRelevant";

        this.init();
    }

    Component.prototype = {
        init: function () {
            var _self = this;
            
            // Only load templates and the service if we're logged in and displaying a result list.
            if ($(this.resultList)) {
                if ($(this.element).data('fti-json')) {
                    this.jsonURL = $(this.element).data('fti-json');

                } else {
                   console.log("No JSON Data specified");
                }

                this.templateUrl = $(this.resultList).data('fti-template');

                $.when(this.getTemplate(), this.getData()).then(function (template, data) {
                    _self.initView();
                    _self.render(template, data);
                    _self.initDetails(_self.data);
                });
                
                $(this.loadMoreBtn).on("click", this, this.loadMore);
                $(this.sortResult).on("change", this, this.performSorting);                 
            } 
        },
        initView: function () {

            var _self = this;

            this.view = {
                totalResults: function () {
                    return (_self.data) ? _self.data[SERVICE_NAME].totalResults : "";
                }
            };

            this.viewEngine = frk.viewEngine(this.view);
            this.viewEngine.init();
            this.viewEngine.updateAll();
        },
        // get the data from the literature service
        getData: function () {

            var dfd = $.Deferred();
            var _self = this;

            $.ajax({
// 				url : '/stubs/literatureHistory1.json', dataType : 'json'
                url: this.jsonURL + "?page=" + this.pageNumber + "&pageSize=" + PAGE_SIZE + "&sort=" + this.sortOrder, dataType: 'json'
            }).done(function (data) {
                if (_self.data === null || _self.data === undefined) {
                    _self.data = data;
                    _self.sortPageSize =   PAGE_SIZE;
                   
                } else {
                    _self.data[SERVICE_NAME].info = _self.data[SERVICE_NAME].info.concat(data[SERVICE_NAME].info);
                    _self.sortPageSize =   _self.sortPageSize + PAGE_SIZE;
                    
                }

                dfd.resolve(data);
            }).fail(function () {

                dfd.reject('Unable to load data');
            });

            return dfd.promise();
        },
        // get the data from the literature service
        getSortedData: function () {

            var dfd = $.Deferred();
            var _self = this;
           
            $.ajax({
// 				url : '/stubs/literatureHistory1.json', dataType : 'json'
                url: this.jsonURL + "?page=" +this.sortedPageNumber + "&pageSize=" + this.sortPageSize + "&sort=" + this.sortOrder, dataType: 'json'
            }).done(function (data) {
               
                    _self.data = data;
                    

                dfd.resolve(data);
            }).fail(function () {

                dfd.reject('Unable to load data');
            });

            return dfd.promise();
        },
        // remove a doc from the list
        removeDataItem: function (literatureItem) {
            var indexOfLiteratureItem = this.data[SERVICE_NAME].info.indexOf(literatureItem);
            this.data[SERVICE_NAME].info.splice(indexOfLiteratureItem, 1);
        },
        // get the Handlebars template
        getTemplate: function () {

            var dfd = $.Deferred();
            var _self = this;
            if (!_self.template) {
                $.ajax({
                    url: _self.templateUrl,
                    dataType: 'html'
                }).done(function (data) {
                    // save data
                    _self.template = data;
                   
                    dfd.resolve(data);
                }).fail(function () {
                    dfd.reject('Unable to load data');
                });

            } else {
                dfd.resolve(this.template);
            }
            return dfd.promise();
        },
        search: function () {
            var _self = this;

            // search input has changed, check if there is something to search against
            if ($(_self.searchBox).val().length > 0) {
                this.getData($(_self.searchBox).val()).then(function (data) {

                    _self.render(_self.template, _self.data);

                });
            } else {
                // we clear previous results if any
                _self.clearResults();
            }
        },
        render: function (template, data) {
            var html = Handlebars.compile(template)(data);
            
            if(data[SERVICE_NAME] === undefined ) {
        		$(this.error).show();
        	}

            if(data[SERVICE_NAME] !== undefined ) { 
            	$(this.error).hide();
            if (data[SERVICE_NAME].totalResults > data[SERVICE_NAME].info.length) {
                $(this.loadMoreBtn).show();
            } else {
                $(this.loadMoreBtn).hide();
            }
            }
            
           
            $(this.resultList).find('li:not(:first)').remove();
          
            $(this.resultList).append(html);
            // Update registered view spans
            this.viewEngine.updateAll();
            // Apply add-to-cart button handlers
            this.cartButton = $(this.resultList).find('[data-fti-component="button-cart"]').each(function () {
                $(this).buttonCart(); // this creates an instance of buttonCart for each element
            });
            // Apply quick-view links
            $(this.resultList).find('[data-fti-component="quick-view"]').each(function () {
                $(this).quickView();
            });
        },
        clearResults: function () {
            $(this.searchBox).parent().find('.' + this.options.resultClass).remove();
        },
        // initialise the details of each row using sub component
        initDetails: function (data) {
            var _self = this;
            $(this.element).find('[data-fti-component="my-literature-details"]').each(function (index) {
                $(this).myLiteratureDetails({
                    data: data[SERVICE_NAME].info[index],
                    controller: _self
                });
            });

        },
        // load more results
        loadMore: function (e) {
            var _self = e.data;
            _self.pageNumber++;
            _self.getData().then(function () {
                _self.render(_self.template, _self.data);
                _self.initDetails(_self.data);
            });
        },
        performSorting: function (e) {
        	
        	  var _self = e.data;
        	
            _self.sortOrder = $("#sel_SortbyPublication").val();
         	
             _self.getSortedData().then(function () {
            	
                 _self.render(_self.template, _self.data);
                 _self.initDetails(_self.data);
             });
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName, new Component(this,
                        options));
            }
        });
    };

    // ----------
    // markup hook
    $(document).find('[data-fti-component="my-literature"],[data-fti-component="my-literature-homepage"]').each(function () {
        $(this).myLiterature();
    });
    // ----------
    
})(jQuery, document, frk, Handlebars);
