;
(function ($, window) {

    // Create the defaults once 
    var componentName = 'tabHistoryLocation',
            defaults = {
                selector: '[data-toggle="tab"]'
            };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;

        this.hash = window.location.hash;

        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
            // set active tab if we have a hash
            if (this.hash) {
                window.scrollTo(0, 0);
                $(this.element).find('a[href="' + this.hash + '"][data-toggle="tab"]').tab('show');
                $(this.element).find('a[data-toggle="tab"]').parent("li").addClass("hidden-print");
                $(this.element).find('a[href="' + this.hash + '"][data-toggle="tab"]').parent("li").removeClass("hidden-print");
                console.log(this.hash);
                window.setTimeout(function () {
                    window.scrollTo(0, 0);
                }, 1);
            }
            this._listenTabChange();
        },
        _listenTabChange: function () {
            $(this.element).find(this.options.selector).click(function (e) {
//                var scrollmem = $('body').scrollTop();
//                $(this).tab('show');
//                window.location.hash = this.hash;
//                $('html,body').scrollTop(scrollmem);
                if (history.pushState)
                {
                    history.pushState(null, null, this.hash);
                    
                    console.log("44:"+this.hash);                    
                    $(this).parent().parent().find("li").addClass("hidden-print");
                    $(this).parent("li").removeClass("hidden-print");
 
                }
            });
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };

})(jQuery, window);
