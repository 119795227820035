
;
(function ($, document, frk, Handlebars) {

    // Create the defaults once 
    var componentName = 'capitalGains',
            defaults = {
                dataEntryPoint: 'capitalGainDistributionVO.capitalGainsVO',
                searchFields: ['nasdaqSymbol.val', 'legalName.val', 'fndNumber.val', 'cusip.val', {field: 'fundNumber', type: 'array', parent: 'relFund'}],
                views: ['A', 'B', 'C', 'D', 'E']
            };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;

        this._docTitle = $($(this.element).find('h1')[0]).html();
        // today's date (from server)
        this._today = this.options.ftiToday;
        this._currentYear = new Date(this._today).getFullYear();

        //Base URL
        this._baseUrl = this.options.ftiJson;
        // Config URL
        this._configUrl = this.options.ftiConfig;
        // Labels URL
        this._labelsUrl = this.options.ftiLabels;
        // Template URL
        this._templateUrl = this.options.ftiTemplate;

        // where we stock the data
        this._data = {};
        this._configData = {};
        this._labelsData = {};

        //Holding tabs info
        this._tabs = {};

        // template will be rendered in this element
        this._template;
        this._altViewTemplates = {};

        // search bar
        this._searchBar = $(this.element).find('[data-fti-search]');

        // Call init method
        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
            var _self = this;

            this._initLabels();
            this._initConfig();
            this._initTabs();


            $(this._searchBar).on('keyup',
                    function (e) {
                        frk.delay(_self._search.bind(_self, e), 500);
                    });

            // trigger event on active tab
            $(this.element).find('.active [data-toggle="tab"]').trigger({type: 'show.bs.tab'});
        },
        _initTabs: function () {
            var _self = this;

            $(this.element).find('[data-toggle="tab"]').each(function () {
                // will contain tab info
                var data = {};
                data.id = $(this).attr('href');
                // init state
                data.rendered = false;
                // save element ref
                data.element = this;
                // save content panel ref
                data.content = $(_self.element).find(data.id);
                // get year val
                data.year = $(this).data('ftiJsonYear');
                // where the tempalte gets rendered
                data.templateView = $(data.content).find('[data-fti-template-view]');
                data.currentYear = $(this).data('currentYear');

                // using Bootstrap tab API, call show function when show event is triggered
                $(this).on('show.bs.tab', function () {
                    _self._show($(this).attr('href'));
                });

                // finally, add tab info to main tabs object
                _self._tabs[$(this).attr('href')] = data;
            });

        },
        _initLabels: function () {
            this._getLabels().then(function (labelsData) {
                $(document).labels({data: labelsData.labels.label});
            });
        },
        _initConfig: function () {
            var _self = this;
            this._getConfig().then(function (configData) {
                frk.UsConfig.setData(configData, $(_self.element).data('ftiActor'));
            });
        },
        _getData: function (tabId) {
            $(this.element).throbber('addLoader');
            var _self = this,
                    _dfd = $.Deferred();

            if (!this._tabs[tabId].data) {
                frk.load(this._baseUrl + '&taxYear=' + this._tabs[tabId].year, 'json').then(
                        function (data) {
                            // save data
                            _self._tabs[tabId].data = frk.orderBy.alphabetialOrder(frk.getValueByString(data, _self.options.dataEntryPoint), 'legalName.val');
                            // compute alt. view for each fund
                            for (var f in _self._tabs[tabId].data) {
                                _self._tabs[tabId].data[f].altViews = frk.capitalGains.getAltView(_self._tabs[tabId].data[f], _self._tabs[tabId].year, _self._today);
                            }
                            _dfd.resolve(_self._tabs[tabId].data);
                        },
                        function (message) {
                            _dfd.reject(message);
                        }
                );
            }
            else {
                _dfd.resolve(this._tabs[tabId].data);
            }
            return _dfd.promise();
        },
        _getTemplate: function () {
            var _self = this,
                    _dfd = $.Deferred();

            if (!this._template) {
                frk.load(this._templateUrl, 'html').then(
                        function (data) {
                            // save data
                            _self._template = data;
                            _dfd.resolve(data);
                        },
                        function (message) {
                            _dfd.reject(message);
                        }
                );
            }
            else {
                _dfd.resolve(this._template);
            }
            return _dfd.promise();
        },
        _getConfig: function () {
            var _self = this,
                    _dfd = $.Deferred();

            if (!this._config) {
                frk.load(this._configUrl, 'json').then(
                        function (data) {
                            // save data
                            _self._config = data;
                            _dfd.resolve(data);
                        },
                        function (message) {
                            _dfd.reject(message);
                        }
                );
            }
            else {
                _dfd.resolve(this._config);
            }
            return _dfd.promise();
        },
        _getLabels: function () {
            var _self = this,
                    _dfd = $.Deferred();

            if (!this._labels) {
                frk.load(this._labelsUrl, 'json').then(
                        function (data) {
                            // save data
                            _self._labels = data;
                            _dfd.resolve(data);
                        },
                        function (message) {
                            _dfd.reject(message);
                        }
                );
            }
            else {
                _dfd.resolve(this._labels);
            }
            return _dfd.promise();
        },
        _render: function (tabId, template, data) {
            $(this.element).throbber('addLoader');
            // Clear prev results
            $(this._tabs[tabId].templateView).empty();
            // compile template and get html 
            var html = Handlebars.compile(template)(data);

            // add html
            $(this._tabs[tabId].templateView).append(html);
            // update labels
            $(document).labels();
            $(this.element).throbber('removeLoader');

        },
        _show: function (tabId) {
            var _self = this,
                    bitToAdd = (this._currentYear > parseInt(this._tabs[tabId].year)) ? this._tabs[tabId].year + ' Final ' : this._tabs[tabId].year;

            //change page title
            $($(this.element).find('h1')[0]).html(bitToAdd + ' ' + this._docTitle);
            
            if (this._tabs[tabId] && !this._tabs[tabId].rendered) {
                // in the process of being rendered. We want to avoid multiple AJAX call until we get data.
                _self._tabs[tabId].rendered = true;
                this._getData(tabId).then(
                        function (data) {
                            _self._getTemplate().then(
                                    function (template) {
//                                        _self._render(tabId, template, {funds: data});
                                        _self._render(tabId, template, {
                                            funds: frk.filter($(_self._searchBar).val(), data, _self.options.searchFields),
                                            options: {currentYear: _self._tabs[tabId].currentYear}});
                                    },
                                    function (message) {
                                        // failed to get data
                                        _self._tabs[tabId].rendered = false;
                                        console.error(message);
                                    }
                            );
                        },
                        function (message) {
                            // failed to get data
                            _self._tabs[tabId].rendered = false;
                            console.error(message);
                        }
                );
            }
        },
        _search: function () {
            for (var t in this._tabs) {
                if (this._tabs[t].rendered) {
                    this._render(this._tabs[t].id, this._template, {
                        funds: frk.filter($(this._searchBar).val(), this._tabs[t].data, this.options.searchFields),
                        options: {currentYear: this._tabs[t].currentYear}
                    });
                }
            }
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };

})(jQuery, document, frk, Handlebars);
