/** 
 * PPSS 529 component 
 * 
 * Works with the following markup:
 * 
 * div fti-component=ppss fti-jon=url-to.json
 *   ul
 *      li class=nav-tabs href=#tab-id
 *    div class=tab-content
 *      div id=tab-id
 *          div fti-template=url-to-html.template
 *      
 * This component will load the data and the 
 * template with an ajax request.
 * 
 * After compile the html, the result is rendered and 
 * appended to the tab content
 */
;
(function ($, document, frk, Handlebars) {

    // Create the defaults once 
    var componentName = 'ppss529',
            defaults = {
                type: '529',
                hasFundFilter: true,
                hasFavorite: false,
                hasSalesCharges: true,
                hasEndChange: true,
                hasTabs: true,
                dataEntryPoint: 'productsVO.funds529List',
                searchFields: ['symbol.val', 'fundLegalName.val', 'fundNumber.val']
            };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;

        // Call init method
        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
            var _self = this;

            // load config
            frk.load($(this.element).data('ftiConfig'), 'json').then(function (usData) {
                frk.ftnConfig.setData(usData, $(_self.element).data('ftiActor'));

                // Call PPSS Plugin with the right options
                $(_self.element).ppssPlugin(_self.options);
            });
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };
})(jQuery, document, frk, Handlebars);
