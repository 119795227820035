/** 
 * Show search results for funds
 */
;
(function ($, document, frk, Handlebars) {

    // Create the defaults once 
    var componentName = 'searchResultsFunds',
            defaults = {
                resultClass: 'search-list',
                noResultFound: 'No results found for',
                assetClass: ['Multi Asset', 'Fixed Income', 'Equity', 'Alternatives'],
                pageStart: 0,
                pageSize: 3
            };

    // The actual plugin constructor 
    function Component(element, options) {
        var _self = this;
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;

        // the fields to filter against
        this.searching = false;
        this.searchIcon = $(this.element).find('.ft-icon-search');

        this.hovered = false;

        this.resultList = $(this.element).find('.search-list');
        this.fundTotalNode = $(_self.element).find('.funds-count');
        this.loadMoreBtn = $(this.element).find('.load-more-results');
        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
        	$(this.element).hide();

            this.controller = frk.searchController;
            this.controller.isSearchResultsPage = true;
            
            this.controller.loadFundData();

            $(this.loadMoreBtn).hide();
            var _self = this;
			if ($(this.resultList).data('ftiTemplate')) {
				var templateUrl = $(this.resultList).data('ftiTemplate');
				frk.load(templateUrl, 'html').then(function(template){
					_self.template = template;
					_self.updateView(_self.controller.model);
					_self.bindEvents();
				},function(error){
					throw 'Error loading: ' + templateUrl;
				});
			} else {
				throw 'No Search Result Template specified';
			}
        },

        bindEvents: function() {
        	var _self = this;
            $(this.element).find('.panel-title a').each(function () {
                $(this).on('click', function (event, data) {
                    console.log('*********************** funds show all link hit');
                    _self.controller.updateFilter('funds');
                });
            });

            $(this.element).find('.load-more-results').each(function () {
                $(this).on('click', function (event, data) {
                    console.log('*********************** funds show more hit');
                    _self.controller.loadMore('funds');
                });
            });
            $(this.controller).on('searchStarted', function(e, model) {
            	_self.updateView(model);
            });
            
            $(this.controller).on('fundSearchFinished', function (e, model) {
                _self.updateView(model);
            });
            $(this.controller).on('filterUpdated', function (e, model) {
            	_self.updateView(model);
            });

        },
        
        updateView: function(model) {
            switch(model.filter) {
	            case 'all': $(this.element).show();
	            			$(this.loadMoreBtn).hide();
	            			this.render(model);
	            			break;
	            case 'funds': $(this.element).show();
						           if (model.funds.total > model.funds.pageSize) {
						        	   $(this.loadMoreBtn).show();
						           } else {
						        	   $(this.loadMoreBtn).hide();
						           }
						           this.render(model);
						           break;
    			default: $(this.element).hide();
            }
		},

        render: function (model) {
        	if (model.funds.isSearching) {
        		$(this.resultList).throbber('addLoader');
        	} else {
	            $(this.fundTotalNode).text(model.funds.total);
	
	            // Compile and render template
	            var html = Handlebars.compile(this.template)({
	            		results: model.funds.results.slice(model[model.filter].pageStart, model[model.filter].pageSize)
	            });
	
	            // display result
	            $(this.resultList).empty(); //clean first
	            $(this.resultList).append(html);
        	}
        },
        _showResults: function () {
            $(_self.resultList).show();
        },
        _hideResults: function () {
            if (!this.hovered) {
                $(_self.resultList).hide();
            }
        },
        _handleItem: function (event) {
            event.preventDefault();

            if (event.type === 'mouseover') {
                $(event.target).addClass('active');
            }
            if (event.type === 'mouseleave') {
                $(event.target).removeClass('active');
            }
            if (event.type === 'click') {
                $(this.searchBox).val($(event.target).attr('id'));
                $(this.searchBox).attr('value', $(event.target).attr('id'));
                $(this.form).attr('action', '/us/investor/products/funds/overview.page?FundID=' + $(event.target).attr('id'));
                this.hovered = false;
                this._clearResults();
            }
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };


})(jQuery, document, frk, Handlebars);
