/**
 * Alert module
 * 
 * Sends an ajax request back to the server to pick up any server side alerts for this user
 * and then displays them at the top of the page.
 *
 * author Ben St Johnston
 * 
 * The option values of the select box should be the ids of the pieces of content to show or hide
 *
 */
;
(function ($, location, frk) {

    var componentName = 'alert';

    function Component(element, options) {
        this._name = componentName;
        
        this.init();
    }
    
    Component.prototype = {
    	init: function() {
    		var url = [location.protocol, '//', location.host, location.pathname].join(''); // current url without query string
    		frk.load(url + '?bid=gw.alerts&t=' + Date.now(), 'json').then(function(data){
    			if ($.isArray(data)) {
    				for(var i = data.length -1; i >=0 ; i--) { // add them backwards so they are appended in the order they were delivered.
    					data[i]['alert-type'] = data[i].type.toLowerCase();
    					frk.alert(data[i]);
    				}
    			}
    		});
    	}
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName, new Component(this,
                        options));
            }
        });
    };


})(jQuery, location, frk);

