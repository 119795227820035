;
(function ($, viewport) {

    // Create the defaults once 
    var componentName = 'tabs',
        defaults = {
        };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.viewport = viewport;

        this.headerTextContainer = $('.mobile-menu + span');
        this.navTabLinks = $('.nav-tabs-top-level a');

        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
            var _self = this;

            if (this.viewport.is('>xs')) {
                _self.equalColumns();
            }

            _self.resizeHandler();
            _self.setHeaderText();
        },
        // Sets tabs to equal heights
        equalColumns: function () {
            var _self = this,
            maxHeight = 0;

            $(_self.navTabLinks).height('100%');
            $(_self.navTabLinks).each(function () {
                maxHeight = Math.max(maxHeight, $(this).height());
            }).height(maxHeight);
            console.log("resizing tabs:" + maxHeight);
        },
        // Equal heights on resize 
        resizeHandler: function () {
            var _self = this;

            $(window).on("resize", function () {
                if (viewport.is('>xs')) {
                    _self.equalColumns();
                } else {
                    _self.navTabLinks.height('100%');
                }

            });
        },
        // Set title of tabbed navigation 
        // on mobile when nav item clicked

        setHeaderText: function () {
            var _self = this;

            _self.navTabLinks.on('click', function () {
                var elParent = $(this).parent('li');

                _self.headerTextContainer.text($(this).text());
                elParent.siblings().removeClass('hidden-xs');
                elParent.addClass('hidden-xs');

                // Close navigation
                $(_self.element).collapse('hide');
            });
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName, new Component(this, options));
            }
        });
    };

})(jQuery, ResponsiveBootstrapToolkit);

