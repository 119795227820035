/** 
 * top forms
 * 
 */

(function ($, document, frk, Handlebars) {

    // Create the defaults once 
    var componentName = 'securityTools',
            defaults = {
            };
   
    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;

        this.options = $.extend({}, defaults, options);

        this._defaults = defaults;
        this._name = componentName;

      

        this.init();  
    }

    // Component methods 
    Component.prototype = {
        init: function () {
        	
        	 this.quickViewLink = $(this.element).find('[data-fti-component="quick-view"]').each(function(){
        		
        		
                    $(this).quickView(); // this creates an instance of buttonCart for each element
                });
        	 
        	  
            
        }, 
         
        handleItem: function (event) {
            event.preventDefault();
          
            if (event.type === 'mouseover') {
                $(event.target).addClass('active');
            }
            if (event.type === 'mouseleave') {
                $(event.target).removeClass('active'); 
            }
            if (event.type === 'click') {
                $(this.searchBox).val($(event.target).html());
                $(this.searchBox).attr('value', $(event.target).attr('id'));
                this.clearResults();
            }
        }
    };

    $.fn[componentName] = function (options) {
    	
        return this.each(function () {
        
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
              
            }
            
        });
    };

    // ----------
    // markup hook
    $(document).find('[data-fti-component="rich-text"]').each(function () {
    	console.log("Inside securityTools component");
    	
        $(this).securityTools();
    });
    // ---------- 
   
    $(window).bind("load", function() {
    	

    	this.quickViewLink = $(document).find('[data-fti-component="quick-view"]').each(function(){
    		
   		
           	
               $(this).quickView(); // this creates an instance of buttonCart for each element
           });

    
    	});

})(jQuery, document, frk, Handlebars);








 