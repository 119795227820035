(function ($) {

    // Create the defaults once 
    var componentName = 'infoModal',
   
    defaults = {
    };

    // The actual plugin constructor 
    function Component(element, options) {
      this.element = element;
      this.options = $.extend({}, defaults, options);
      this._defaults = defaults;
      this._name = componentName;

      this.init();
    }

    // Component methods 
    Component.prototype = {

      // init
      init: function () {
        this.loadModal();
      },

      // Load modal
      loadModal: function() {
        var self = this;
        var modal = $(this.element).detach();        
        modal.insertAfter('[data-fti-component="footer"]');
      }

    };

    $.fn[componentName] = function (options) {
      return this.each(function () {
        if (!$.data(this, 'component_' + componentName)) {
          $.data(this, 'component_' + componentName,
            new Component(this, options));
        }
      });
    };

    // ----------
    // markup hook
    $(document).ready(function () {
      $(document).find('.more-info-container').each(function () {
        $(this).infoModal();
      });
    });
    
  })(jQuery);


