/** 
 * fund docs
 * 
 */  
;
(function ($, document, frk, Handlebars) {

    // Create the defaults once 
    var componentName = 'documents',
            defaults = {
                quickviewDataUrl: '/forms-literature/getCartDetails'
                //litOrderQty: '1'
            };

    // The actual plugin constructor 
    function Component(element, options) {
    	 
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.download = $(this.element).find('#downLoadForNotLit');
        
         
        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
        	 
             
            
            this.getFundDocData().then(function (data) {
            	
            	
            	 $.each(data, function(key, value) {
                 	
            		 var _item =value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].literatureCode;
            		  
                    	 $('#button'+_item).addClass('disabled');
                         $('#label'+_item).text("Added "); 
                 	}); 
               
            },
             function (errorMessage) {
                // FAILED...
                console.log(errorMessage);
            });
            
            
            
            $(this.download).on('click', function () {
            	
            	var name = this.name; 
                $.ajax({
                    url: name,  
                    type:'HEAD',
                    error: function()
                    {
                    console.log("Failed");
                     window.open('/en-us-retail/error.page');  
                    },
                    success: function()
                    {   console.log("success");
                        window.open(name); 
                    }
                });
                 
                 
            });
        },
        
        getFundDocData: function () {
            var dfd = $.Deferred();

            $.ajax({
                url: this.options.quickviewDataUrl,
                data: {literatureCode: this.litcode},
                dataType: 'json',
                type: 'GET'
            }).done(function (data) {
                dfd.resolve(data);
            }).fail(function () {
                dfd.reject('Unable to load data');
            });
            //dfd.resolve({data: this.litcode}); // hard coded with dummy data for demo purposes
            return dfd.promise();
        } 
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };

    // ----------
    // markup hook
    $(document).find('[data-fti-component="documents"]').each(function () {
        $(this).documents();
    });
    // ----


})(jQuery, document, frk, Handlebars);