/** 
 * Fund Tabs 
 * 
 */
;
(function ($, window, document) {

    // Create the defaults once 
    var componentName = 'dateToggle',
            defaults = {
            };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;

        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
            
            var _self = this;
            
            var asof = $(_self.element).find('h3 small.as-of');

            console.log('initialised date toggle');

            $(_self.element).on('shown.bs.tab', 'a[data-toggle="tab"]', function (e) {    
                // when date toggle is switched
                console.log($(this).attr('href'));
                console.log($(this).attr('href').indexOf('quarterly'));
                
                
                if($(this).attr('href').indexOf('quarterly') !== -1){
                    asof.html(asof.data('quarterly'));
                } else {
                    asof.html(asof.data('monthly'));
                }
            });


        }

    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            } else {
                try {
                    $.data(this, 'component_' + componentName)[options]();
                }
                catch (e) {
                    console.error('Unable to execute method ' + options);
                }
            }
        });
    };


})(jQuery, window, document);
