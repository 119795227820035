/** 
 * Find A Fund 
 * 
 */
;
(function ($, window, document) {

    // Create the defaults once 
    var componentName = "fundTabs",
            defaults = {
            };

    // The actual component constructor
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.init();
    }

    Component.prototype = {
        init: function () {



            // Find the navbvar toggle button
            var navToggle = $(this.element).find('.navbar-toggle');
            var currentLabel = $(this.element).find('.current-label');
            var collapsible = $(this.element).find('.collapse');
            var activeTab = $(this.element).find('.nav li.active a');

            // Set the current label to active tab
            currentLabel.html(activeTab.html());

            // On toggle
            navToggle.click(function () {                
                // If its a mobile device
                if ( $(window).width() < 739) {      
                    // Hide the active tab
                   activeTab.hide();
                }
                else {
                    activeTab.show();
                }
            });
            $('.navbar-collapse ul li a').click(function () {
                // Hide the active tab
                clicked = $(this).html();                
                // If its a mobile device
                if ( $(window).width() < 739) {      
                    // Hide the active tab
                    activeTab.hide();
                }
                else {
                    activeTab.show();
                }
                $('.navbar-collapse').removeClass('in');                
            });

            collapsible.on('hidden.bs.collapse', function () {
                // Show the active tab
                activeTab.show();
                console.log('on hidden.bs.collapse fired');

            });


        }

    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            } else {
                try {
                    $.data(this, 'component_' + componentName)[options]();
                }
                catch (e) {
                    console.error('Unable to execute method ' + options);
                }
            }
        });
    };


})(jQuery, window, document);
