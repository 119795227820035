;
(function ($, window, document, undefined) {

    // Create the default component options
    var componentName = "tableau",
            defaults = {
            };

    // The actual component constructor
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.init();
    }

    Component.prototype = {
        init: function () {

            var _self = this;

            $(document).ready(function () {
                var placeholderDiv = document.getElementById("tableauPlaceholder");
                var placeholderWidth = placeholderDiv.offsetWidth;
                var placeholderHeight = placeholderDiv.offsetHeight;

                var url = "http://rcosvr3036:8000/#/site/GlobalProductStrategy/views/WhereHavetheFlowsGone/MutualFund";

                var options = {
                    width: placeholderWidth,
                    height: placeholderHeight,
                    hideTabs: true,
                    hideToolbar: true
                };

                var viz = new tableauSoftware.Viz(placeholderDiv, url, options);

                $(window).resize(function () {
                    var newWidth = document.getElementById("tableauPlaceholder").offsetWidth
                    if (options.width === newWidth)
                        return;

                    viz.dispose();
                    $("#tableauPlaceholder").empty();

                    options.width = newWidth;

                    viz = new tableauSoftware.Viz(placeholderDiv, url, options);
                });
            });

        }

    };
    console.log("Registering" + componentName);
    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "component_" + componentName)) {
                $.data(this, "component_" + componentName,
                        new Component(this, options));
            } else {
                $.data(this, "component_" + componentName).init();
            }
        });
    };

})(jQuery, window, document);
