/**
 * Form module
 *  $(element).is(":visible"); 
 * author Ben St Johnston
 */
;
(function ($, document, frk) {

    var componentName = 'form';


    function Component(element, options) {
        this._name = componentName;
        this.element = element;
        this.init();
    }

    Component.prototype = {
        init: function () {

            var formId = '#' + this.element.id;
            var _self = this;
            var $formError =  $(formId + "-top-error");

            $.validate({
                form: formId,

                onElementValidate : function(valid, $el, $form, errorMess) {
                	if (! valid) {
                		$el.parents('.form-group').addClass('has-error');
                	} else {
                		$el.parents('.form-group').removeClass('has-error');
                	}
                    console.log('Input ' +$el.attr('name')+ ' is ' + ( valid ? 'VALID':'NOT VALID') );
                },
                
                onSuccess: function($form) {
                	frk.loaderManager($form.find('.panel-body')[0]).start()
                	$form.find("input[type='submit']").each(function(i, btn) {
                		btn.disabled = true;
                	});
                	console.log("submitting form");
                	return true;
                }

            });         
        }
    	
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName, new Component(this,
                        options));
            }
        });
    };


})(jQuery, document, frk);

$.formUtils.addValidator({
	name: 'phone',
	validatorFunction: function(value, $el, config, language, $form) {
		return value.match(/^[\s\d.()\-+]*$/) !== null; // brackets, spaces, digits, . + and -
	},
	errorMessage: 'Enter a valid telephone number',
	errorMessageKey: 'badPhoneNumber'
});