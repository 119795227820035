/** 
 * Literature Search Results
 * 
 */
;
(function ($, document, frk, Handlebars) {

    // Create the defaults once 
    var componentName = 'searchResultsLiterature',
            defaults = {};
    // The actual plugin constructor 
    function Component(element, options) {
        var _self = this;
        this.element = element;
        this.options = $.extend({}, defaults, options);
        
        this.loadMoreBtn = $(this.element).find('.load-more-results');
        this._name = componentName;

        this.resultList = $(this.element).find('.list-group');
        
        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
        	$(this.element).hide();
        	this.controller = frk.searchController;
        	this.controller.isSearchResultsPage = true;
            $(this.loadMoreBtn).hide();
            var _self = this;
			if ($(this.resultList).data('ftiTemplate')) {
				var templateUrl = $(this.resultList).data('ftiTemplate');
				frk.load(templateUrl, 'html').then(function(template){
					_self.template = template;
					_self.updateView(_self.controller.model);
					_self.bindEvents();
				},function(error){
					throw 'Error loading: ' + templateUrl;
				});
			} else {
				throw 'No Search Result Template specified';
			}

			this.initView();
        },
        
        bindEvents: function() {
        	var _self = this;
            $(this.element).find('.panel-title a').each(function () {
                $(this).on('click', function (event, data) {
                    console.log('*********************** literature show all link hit');
                    _self.controller.updateFilter('literature'); 
                });
            });
            
            $(this.element).find('.load-more-results').each(function () {
                $(this).on('click', function (event, data) {
                    console.log('*********************** literature show more hit');
                    _self.controller.loadMore('literature');
                });
            });
            
            // events from controller
            $(this.controller).on('searchStarted', function(e, model) {
            	_self.updateView(model);
            });
            
            $(this.controller).on('literatureSearchFinished', function (e, model) {
            	_self.updateView(model);
            });
            $(this.controller).on('filterUpdated', function (e, model) {
             	_self.updateView(model);
            });

        },
        
        updateView: function(model) {
            switch(model.filter) {
	            case 'all': $(this.element).show();
	            			$(this.loadMoreBtn).hide();
	            			this.render(model);
	            			break;
	            case 'literature': $(this.element).show();
						           if (model.literature.total > model.literature.pageSize) {
						        	   $(this.loadMoreBtn).show();
						           } else {
						        	   $(this.loadMoreBtn).hide();
						           }
						           this.render(model);
						           break;
    			default: $(this.element).hide();
            }
		},

        initView: function () {
            var _self = this;
            // view values
            this.view = {
                searchResultCount: function() {
                    return _self.data ? _self.data["com.frk.ci.gws.orderlit.model.viewmodel.LiteratureResultVM"].totalResults : "0";
                },
                searchResultCountClientOnly: function() {
                    return _self.data ? _self.data["com.frk.ci.gws.orderlit.model.viewmodel.LiteratureResultVM"].filters.audienceFilter.facetValues[0].matches : "0";
                }
            };
            // init view engine for data binding
            this.viewEngine = frk.viewEngine(this.view);
            this.viewEngine.init();
            this.viewEngine.updateAll();
        },
        render: function (model) {
        	if (model.literature.isSearching) {
        		$(this.resultList).throbber('addLoader');
        	} else {
                // compile template and get html 
                var html = model.literature.error;
                if (html === null) {
                	html = Handlebars.compile(this.template)({
                		results: model.literature.results.slice(model[model.filter].pageStart, model[model.filter].pageSize)
                	});
                } else {
                	html = '<div class="col-sm-12">' + html + '</div>';
                }
                // add html to content
                $(this.resultList).empty();
                $(this.resultList).append(html);
                // update view values
                this.viewEngine.updateAll();
                
                this.quickViewLink = $(this.element).find('[data-fti-component="quick-view"]').each(function(){
                   	// alert("Before calling    $(this).quickView()");
                       $(this).quickView(); // this creates an instance of buttonCart for each element
                   });
                
                this.cartButton = $(this.element).find('[data-fti-component="button-cart"]').each(function(){
                    $(this).buttonCart(); // this creates an instance of buttonCart for each element
                });
	
        	}
        },
        clearResults: function () {
            $(this.searchBox).parent().find('.' + this.options.resultClass).remove();
        },
        handleItem: function (event) {
            event.preventDefault();
            
            if (event.type === 'mouseover') {
                $(event.target).addClass('active');
            }
            if (event.type === 'mouseleave') {
                $(event.target).removeClass('active');
            }
            if (event.type === 'click') {
                $(this.searchBox).val($(event.target).html());
                $(this.searchBox).attr('value', $(event.target).attr('id'));
                this.clearResults();
            }
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };


})(jQuery, document, frk, Handlebars);
