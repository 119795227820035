;
(function ($) {

    // Create the defaults once 
    var componentName = 'togglePopularFunds',
            defaults = {
            };

    // The actual plugin constructor 
    function Component(element, options, Handlebars) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;

        this.cardTemplate = '';
        
        this.init();
    }

    // Component methods 
    Component.prototype = {
       
        init: function () {
          this.extractData();
          // this.createTable();
        },

        extractData: function(){

          var _self = this,
              fundsContainer = $(this.element),
              funds = fundsContainer.find('tbody tr'),
              fundsJson = {'funds': []},
              fund;

          funds.each(function(index) {

            var fund = $(this),
                symbol = fund.find('.symbol').text(),
                title = fund.find('.fund-name a'),
                titleText = title.text(),
                titleLink = title.attr('href'),
                factsheet = '#',
                assetClass = fund.find('.asset-class').text(),
                netAssetValue = fund.find('.net-asset-value').text(),
                currentFund = { 
                  fundSymbol: symbol,
                  fundTitle: titleText, 
                  fundLink: titleLink, 
                  fundFactsheet: factsheet, 
                  fundAssetClass: assetClass,
                  fundNetAssetValue: netAssetValue
                };

            fundsJson.funds.push(currentFund);

          });  

          this.fundsData = JSON.parse(JSON.stringify(fundsJson));

          console.log(this.fundsData)

        },

        createTable: function(){
           
            var template = Handlebars.compile(this.tableTemplate),
                tableHtml = template(this.cardsData),
                gridHtml = $('.perspectives').html();


            $('.list-view').on('click', function(e){
              $('.perspectives').empty();
              $('.perspectives').html(tableHtml);
              e.preventDefault();
            });

            $('.grid-view').on('click', function(e){
              $('.perspectives').empty();
              $('.perspectives').html(gridHtml);
              e.preventDefault();
            });

        },

        toggleView: function() {

          $('.list-view').on('click', function(e){

          });

        },

        updateTable: function(){

        },

        updateTiles: function(){

        }

    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };
    
})(jQuery);


