/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
/* global brightcove */

// used exclusively for brightcove video player


// line below this is causing an error. have commented it out to allow js to build.
//brightcove.createExperiences();

//smart 
var player,
APIModules,
videoPlayer;

function onTemplateLoad(experienceID){
 player = brightcove.api.getExperience(experienceID);
 APIModules = brightcove.api.modules.APIModules;
}

function onTemplateReady(evt){
 videoPlayer = player.getModule(APIModules.VIDEO_PLAYER);
 videoPlayer.play();
}