(function ($, window, Date, moment) {
    frk = window.frk || {};
    var capitalGains = frk.capitalGains = {};
    /**
     * Constants
     */
    capitalGains.ESTIMATE_LONG_TERM = '5';
    capitalGains.ESTIMATE_SHORT_TERM = '6';
    capitalGains.LONG_TERM = '4';
    capitalGains.SHORT_TERM = '3';
    /**
     * Return the alternate view of each fund
     * 
     * @returns {Object} the alternative view and associated data
     */
    capitalGains.getAltView = function (cgFund, year, todaysDate) {
        var today = moment(todaysDate);
        var altViews = [];
        var scheduleMonths = cgFund.cgSchedule.val ? cgFund.cgSchedule.val.split(',') : ['december'];
        
        // So, for each scheduled months
        for (var m in scheduleMonths) {
            var altView = {},
                    scheduleMonthId = frk.getMonthId(scheduleMonths[m].trim().toLowerCase() === 'annually' ? 'december' : scheduleMonths[m].trim());
            if ((!cgFund.lstDist || cgFund.lstDist.length === 0 ||
                    !cgFund.lstDist[0].distAmt || //this line is because sometime lstDist is [{}] intead of []
                    !capitalGains.hasDistributionsForYear(cgFund, year)) &&
                    !capitalGains.hasDateInsteadOfDistributions(cgFund) && // if distAmt is a date, then it's going to be view C
                    capitalGains.isWithinOneMonthPrior(today, scheduleMonthId, year) === -1) {
                // BRD RULE:
                // No distribution values or date information is available for the fund for the current year.  
                // AND the current date is more than one month prior to the scheduled distribution month for the fund, 
                //Display:
                // nothing
                console.log('view A for', cgFund.legalName.val);
                altView.view = 'A';
            }
            else if (capitalGains.hasEstimate(cgFund, scheduleMonthId, year) &&
                    !capitalGains.hasDateInsteadOfDistributions(cgFund) &&
                    !capitalGains.hasDistributions(cgFund, scheduleMonthId, year) &&
                    capitalGains.isWithinOneMonthPrior(today, scheduleMonthId, year) <= 0 &&
                    !capitalGains.isTodayAfterExtimateExDate(cgFund, today, scheduleMonthId, year)) {
                // BRD RULE: OK
                //Distribution estimate values have been received for the fund
                //AND
                //Actual distributions have not been received for the applicable distribution month
                //AND
                //The current date is within the distribution month For example: the distribution month is June and the current date is prior to July 1
                //Display: 
                // Record Date, Ex date, Payable date
                // Reinvestement : TBD
                // ROW 1/2 estimate value for Preliminary long/short-term capital gains per share in red italics text followed by the text: (est.)
                // ROW 3: Bold text: {MMM} TOTAL , where {MMM} is the month from the data element:  Capital Gains Schedule. 
                //         The estimate value for Capital Gains Distribution Total in red italics text followed by the text: (est.) 
                //console.log('view D for', cgFund.legalName.val);
                altView.view = 'D';
                altView.upcomingMonth = frk.MONTHS_SHORT[scheduleMonthId].toUpperCase();
                altView.data = capitalGains.getEstimate(cgFund, scheduleMonthId, year);
                // get Record Date, Ex date, Payable date
                // get Row 1/2
            }
            // No estimate and no distributions for scheduled month and within one month prior to scheduled month
            else if (!capitalGains.hasDistributions(cgFund, scheduleMonthId, year) &&
                    !capitalGains.hasDateInsteadOfDistributions(cgFund) &&
                    capitalGains.isWithinOneMonthPrior(today, scheduleMonthId, year) === 0) {
                // BRD RULE: OK
                // No distribution values or date information is available for the scheduled distribution month.
                // AND the current date is within one month prior to the scheduled distribution month for the fund
                //Display:
                // populated with TBDs for Record Date, Ex-Date, Payable Date, and, reinvestment price
                // Populated with "MON TOTAL: Pending" where MON is the upcoming distribution month for the fund
                //console.log('view B for', cgFund.legalName.val);
                altView.view = 'B';
                altView.upcomingMonth = frk.MONTHS_SHORT[scheduleMonthId].toUpperCase();
            }
            else if (capitalGains.hasDateInsteadOfDistributions(cgFund) &&
                    capitalGains.hasEstimateForYear(cgFund, today.year())) {
                // BRD RULE:
                //If distribution date information (i.e. values for all three elements: Record date, ex-date, payable date) is available for a fund’s scheduled distribution month
                // AND
                //no estimate or distribution data has been received (Long-Term and Short-Term Capital Gain Amounts)
                //Display:
                // Record Date, Ex date, Payable date
                // Reinvestement : TBD
                // Populate the Per Share Amounts field with: ‘Projected estimates available: MMM-DD’ where MMM-DD is the value from the data element: Capital Gains Estimate date
//                console.log('view C for', cgFund.legalName.val);
                altView.view = 'C';
                altView.data = capitalGains.getDateForEstimate(cgFund, scheduleMonthId, year);
            }
            else {
                // BRD RULE: OK
                //If no actual distribution data has been received but the current date is after the Ex-Date associated to the estimate data, the estimates rows will not display
                //OR
                //If actual distribution values have been received for the fund 
                //
                //Display: 
                // Record Date, Ex date, Payable date, Reinvestment price
                // Populate Pershare amout field 1 and 2 with:
                // if dist val then Short/Long term vals
                // if not dist val and curent date is after dist month (over 1 month) then Long/Short term: 0.000
                // Populate filed 3 with {MMM} TOTAL, where {MMM} is the month from the data element:  Capital Gains Schedule
                // Value for Capital Gains Distribution Total
                if (capitalGains.hasDistributions(cgFund, scheduleMonthId, year) ||
                        (capitalGains.hasEstimate(cgFund, scheduleMonthId, year) &&
                                !capitalGains.hasDistributions(cgFund, scheduleMonthId, year) &&
                                capitalGains.isTodayAfterExtimateExDate(cgFund, today, scheduleMonthId, year))) {

//                    console.log('view E for', cgFund.legalName.val);
                    altView.view = 'E';
                    altView.upcomingMonth = frk.MONTHS_SHORT[scheduleMonthId].toUpperCase();
                    altView.data = capitalGains.getDistributions(cgFund, scheduleMonthId, year);
                }
                // speciale case added. From Meeera:
                // If the current month is after the distribution month and a fund does not have any date, estimate, 
                // or actual distribution in the system; display the distribution table for that fund with em-dashes 
                // for the dates and reinvestment price and values of 0.000000 for Long-Term, Short-Term and {Mon} Total.
                else {
//                    console.log('view E(bis) for', cgFund.legalName.val);
                    altView.view = 'E';
                    altView.data = capitalGains.getDistributions(cgFund, scheduleMonthId, year);
                    altView.data.exDate = '-';
                    altView.data.paybleDate = '-';
                    altView.data.recordDate = '-';
                }
            }
            // add the alternative view to the object
            altViews.push(altView);
        }

        // We also need to check if we have distribution data for non-scheduled month...
        var nonScMonths = []; // array on non scheduled month
        // build array
        for (var i = 0; i < 12; i++) {
            nonScMonths.push(i);
        }
        //remove sched. months (compute diff)
        for (var m in scheduleMonths) {
            var mi = frk.getMonthId(scheduleMonths[m].trim().toLowerCase() === 'annually' ? 'december' : scheduleMonths[m].trim());
            var spliceIndex = 0;
            for (var i=0;i<nonScMonths.length;i++) {
                if (parseInt(nonScMonths[i]) === parseInt(mi)) {
                    nonScMonths.splice(spliceIndex, 1);
                }
                else
                    spliceIndex+=1;
            }
        }
        // run through
        for (var m in nonScMonths) {
            if (capitalGains.hasDistributions(cgFund, nonScMonths[m], year)) {
                altView = {};
                altView.view = 'E';
                altView.upcomingMonth = frk.MONTHS_SHORT[nonScMonths[m]].toUpperCase();
                altView.data = capitalGains.getDistributions(cgFund, nonScMonths[m], year);
                
                if(altView.data.total !== "0.000000"){
                    altViews.push(altView);
                    // reorder altViews
                    altViews.sort(function(a, b) {
                        return  frk.MONTHS_SHORT.indexOf(a.upcomingMonth.charAt(0) + a.upcomingMonth.slice(1).toLowerCase()) - frk.MONTHS_SHORT.indexOf(b.upcomingMonth.charAt(0) + b.upcomingMonth.slice(1).toLowerCase());
                    });
                }
            }
        }
        return altViews;
    };
    /**
     * When a fund has distributions data, 
     * figure out if it has estimate for scheduled month
     */
    capitalGains.hasEstimate = function (cgFund, scheduleMonthId, year) {
        var hasLongEst = false,
                hasShortEst = false;
        // Go through dist list
        for (var d in cgFund.lstDist) {
            // check if date of estimate/distribution is for the scheduled month
            if (capitalGains.isWithinOneMonthPrior(cgFund.lstDist[d].exDate.alt, scheduleMonthId, year) === 0) {
                if (cgFund.lstDist[d].distType.alt === capitalGains.ESTIMATE_LONG_TERM) {
                    hasLongEst = true;
                } else if (cgFund.lstDist[d].distType.alt === capitalGains.ESTIMATE_SHORT_TERM) {
                    hasShortEst = true;
                }
            }
        }

        return (hasLongEst && hasShortEst);
    };
    /**
     * When a fund has distributions data, 
     * figure out if it has actual distributions data for scheduled month
     */
    capitalGains.hasDistributions = function (cgFund, scheduleMonthId, year) {
        var hasLongDist = false,
                hasShortDist = false;
        // Go through dist list
        for (var d in cgFund.lstDist) {
            // check if date of estimate/distribution is for the scheduled month
            if (capitalGains.isWithinOneMonthPrior(cgFund.lstDist[d].exDate.alt, scheduleMonthId, year) === 0) {
                if (cgFund.lstDist[d].distType.alt === capitalGains.LONG_TERM) {
                    hasLongDist = true;
                } else if (cgFund.lstDist[d].distType.alt === capitalGains.SHORT_TERM) {
                    hasShortDist = true;
                }
            }
        }

        return (hasLongDist || hasShortDist);
    };
    /**
     * 
     */
    capitalGains.hasEstimateForYear = function (cgFund, year) {
        var hasLongEst = false,
                hasShortEst = false;
        // Go through dist list
        for (var d in cgFund.lstDist) {
            var fundYear = new Date(Date.parse(cgFund.lstDist[d].exDate.alt)).getFullYear();
            if ((fundYear - year) === 0) {
                if (cgFund.lstDist[d].distType.alt === capitalGains.ESTIMATE_LONG_TERM) {
                    hasLongEst = true;
                } else if (cgFund.lstDist[d].distType.alt === capitalGains.ESTIMATE_SHORT_TERM) {
                    hasShortEst = true;
                }
            }
        }

        return (hasLongEst && hasShortEst);
    };
    /**
     * 
     */
    capitalGains.hasDistributionsForYear = function (cgFund, year) {
        var hasLongDist = false,
                hasShortDist = false;
        // Go through dist list
        for (var d in cgFund.lstDist) {
            var fundYear = new Date(Date.parse(cgFund.lstDist[d].exDate.alt)).getFullYear();
            if ((fundYear - year) === 0) {
                if (cgFund.lstDist[d].distType.alt === capitalGains.LONG_TERM) {
                    hasLongDist = true;
                } else if (cgFund.lstDist[d].distType.alt === capitalGains.SHORT_TERM) {
                    hasShortDist = true;
                }
            }
        }

        return (hasLongDist && hasShortDist);
    };
    /**
     * 
     * @param {type} cgFund
     * @param {type} scheduleMonthId
     * @param {type} year
     * @returns {}
     */
    capitalGains.hasNullForEstimate = function (cgFund, scheduleMonthId, year) {
        var hasNullForEstimate = true;
        // Go through dist list
        for (var d in cgFund.lstDist) {
            // check if date of estimate/distribution is for the scheduled month
//            if (capitalGains.isWithinOneMonthPrior(cgFund.lstDist[d].exDate.alt, scheduleMonthId, year) === 0) {
            if ((cgFund.lstDist[d].distType.alt === capitalGains.ESTIMATE_LONG_TERM ||
                    cgFund.lstDist[d].distType.alt === capitalGains.ESTIMATE_SHORT_TERM) &&
                    cgFund.lstDist[d].distAmt.val !== '0.0000-0.0000') {
                hasNullForEstimate = false;
                break;
            }
//            }
        }
        return hasNullForEstimate;
    };
    /**
     * 
     * @param {type} cgFund
     * @returns {undefined}
     */
    capitalGains.hasDateInsteadOfDistributions = function (cgFund) {
        // Go through dist list
        for (var d in cgFund.lstDist) {
            var d = new Date(cgFund.lstDist[d].distAmt.val);
            if (!isNaN(d.getTime())) {
                return true;
            }
        }
        return false;
    };
    /**
     * Get estimate data
     * @param {type} cgFund
     * @param {type} scheduleMonthId
     * @param {type} year
     * @returns {}
     */
    capitalGains.getEstimate = function (cgFund, scheduleMonthId, year) {
        var data = {};
        // Go through dist list
        for (var d in cgFund.lstDist) {
            // check if date of estimate/distribution is for the scheduled month
            if (capitalGains.isWithinOneMonthPrior(cgFund.lstDist[d].exDate.alt, scheduleMonthId, year) === 0) {
                if (cgFund.lstDist[d].distType.alt === capitalGains.ESTIMATE_LONG_TERM) {
                    data.longEst = cgFund.lstDist[d].distAmt.val;
                } else if (cgFund.lstDist[d].distType.alt === capitalGains.ESTIMATE_SHORT_TERM) {
                    data.shortEst = cgFund.lstDist[d].distAmt.val;
                }

                if (data.longEst && data.shortEst) {
                    data.exDate = capitalGains.formatDate(cgFund.lstDist[d].exDate.alt);
                    data.paybleDate = capitalGains.formatDate(cgFund.lstDist[d].paybleDate.alt);
                    data.recordDate = capitalGains.formatDate(cgFund.lstDist[d].recordDate.alt);
                    data.reInvPrice = cgFund.lstDist[d].reInvPrice.alt;
                    break; // we have everything we need
                }
            }
        }

        if (!data.longEst) {
            data.longEst = '0.0000-0.0000';
        }
        if (!data.shortEst) {
            data.shortEst = '0.0000-0.0000';
        }
        var ls = data.longEst.trim().split('-');
        var ss = data.shortEst.trim().split('-');
        var ts = [
            parseFloat(parseFloat(ls[0]) + parseFloat(ss[0])).toFixed(4),
            parseFloat(parseFloat(ls[1]) + parseFloat(ss[1])).toFixed(4)
        ];
        data.longEst = ls[0] + ' - ' + ls[1];
        data.shortEst = ss[0] + ' - ' + ss[1];
        data.total = ts[0] + ' - ' + ts[1];
        return data;
    };
    capitalGains.getDistributions = function (cgFund, scheduleMonthId, year) {
        var data = {};
        // Go through dist list
        for (var d in cgFund.lstDist) {
            // check if date of estimate/distribution is for the scheduled month
            if (capitalGains.isWithinOneMonthPrior(cgFund.lstDist[d].exDate.alt, scheduleMonthId, year) === 0) {
                if (cgFund.lstDist[d].distType.alt === capitalGains.LONG_TERM) {
                    data.long = cgFund.lstDist[d].distAmt.val;
                } else if (cgFund.lstDist[d].distType.alt === capitalGains.SHORT_TERM) {
                    data.short = cgFund.lstDist[d].distAmt.val;
                }

                if (data.long || data.short && !(data.long && data.short)) {
                    data.exDate = capitalGains.formatDate(cgFund.lstDist[d].exDate.alt);
                    data.paybleDate = capitalGains.formatDate(cgFund.lstDist[d].paybleDate.alt);
                    data.recordDate = capitalGains.formatDate(cgFund.lstDist[d].recordDate.alt);
                    data.reInvPrice = cgFund.lstDist[d].reInvPrice.alt;
                    //break; // we have everything we need
                }
            }
        }


        if (!data.long) {
            data.long = '0.000000';
        }
        if (!data.short) {
            data.short = '0.000000';
        }
        data.total = parseFloat(parseFloat(data.long) + parseFloat(data.short)).toFixed(6) + '';
        if (data.total === '0.000000')
            data.reInvPrice = '';
        return data;
    };
    capitalGains.getDateForEstimate = function (cgFund, scheduleMonthId, year) {
        var data = {};
        // Go through dist list
        for (var d in cgFund.lstDist) {
            // check if date of estimate/distribution is for the scheduled month
            if (capitalGains.isWithinOneMonthPrior(cgFund.lstDist[d].exDate.alt, scheduleMonthId, year) === 0) {
                if ((cgFund.lstDist[d].distType.alt === capitalGains.ESTIMATE_LONG_TERM ||
                        cgFund.lstDist[d].distType.alt === capitalGains.ESTIMATE_SHORT_TERM)) {
                    data.distAmt = capitalGains.formatProjectedEstimateDate(cgFund.lstDist[d].distAmt.val);
//                    data.distAmt = capitalGains.formatProjectedEstimateDate(cgFund.lstDist[d].exDate.alt);
                    data.exDate = capitalGains.formatDate(cgFund.lstDist[d].exDate.alt);
                    data.paybleDate = capitalGains.formatDate(cgFund.lstDist[d].paybleDate.alt);
                    data.recordDate = capitalGains.formatDate(cgFund.lstDist[d].recordDate.alt);
                    break;
                }
            }
        }

        return data;
    };
    /**
     * 
     * @param {type} date A date
     * @param {type} scheduledMonth The scheduled month
     * @param {type} year the year displayed
     * @returns {Number} 0 if within, 1 if after, -1 if before
     * 
     * For exemple: schedule month is sepetember
     * if date year is 2015 and year is 2014, return 1
     * if date year is 2015 and year is 2015, continue test
     * if current month is january-august, return -1
     * if current month is september, return 0
     * if current month is october-december, return 1
     */
    capitalGains.isWithinOneMonthPrior = function (date, scheduledMonthId, year) {
        var d = moment(new Date(date));
        // year calculation
        var yr = (d.year() - year > 0) ? 1 : (d.year() - year < 0) ? -1 : 0;
        // month calculation
        var r = (yr === 0) ? d.month() - scheduledMonthId : yr;
        return (r > 0) ? 1 : (r <= -1) ? -1 : 0;
    };
    /**
     * Return true if today is after fund's Ex-date
     * 
     * @param {type} cgFund the fund data
     * @param {type} today Today's date
     * @returns {Boolean}
     */
    capitalGains.isTodayAfterExtimateExDate = function (cgFund, today, scheduleMonthId, year) {
        var exDate = '';
        for (var d in cgFund.lstDist) {
            // check if date of estimate/distribution is for the scheduled month
            if (capitalGains.isWithinOneMonthPrior(cgFund.lstDist[d].exDate.alt, scheduleMonthId, year) === 0) {
                if ((cgFund.lstDist[d].distType.alt === capitalGains.ESTIMATE_LONG_TERM ||
                        cgFund.lstDist[d].distType.alt === capitalGains.ESTIMATE_SHORT_TERM)) {
                    exDate = cgFund.lstDist[d].exDate.alt;
                }
            }
        }
//        console.log(moment(new Date(today)).isAfter(new Date(exDate)));
        return moment(new Date(today)).isAfter(new Date(exDate));
    };
    /**
     * Get a date as mm/dd/yyy and return mm-dd
     * @param {type} date
     * @returns {unresolved}
     */
    capitalGains.formatDate = function (date) {
        var ds = date.split('/');
        return parseInt(ds[0], 10) + '-' + ds[1];
    };
    capitalGains.formatProjectedEstimateDate = function (date) {
        var ds = date.split('/');
        return frk.MONTHS_SHORT[parseInt(ds[0]) - 1].toUpperCase() + '-' + ds[1];
    };
})(jQuery, window, Date, moment);


