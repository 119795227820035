/**
 * A module for loading and initialising modules automatically 
 * 
 */
;
(function ($) {
    // load all modules on the page
    $(document).on('ready', function () {
        $('body').loader({
            dataAttributes: ['ftiModule'] //'ftiComponent'
        });
    });
})(jQuery);

