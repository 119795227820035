;
(function ($) {

  // Create the defaults once 
  var componentName = 'toggleCollapse',
  defaults = {
  };

  // The actual plugin constructor 
  function Component(element, options) {
    this.element = element;
    this.options = $.extend({}, defaults, options);
    this._defaults = defaults;
    this._name = componentName;

    this.init();
  }

  // Component methods 
  Component.prototype = {
    init: function () {
      $(this.element).on('click',function(e){
        var el = $(this);
        el.find('i')
            .toggleClass('ft-icon-down-carrot')
            .toggleClass('ft-icon-right-carrot');
        e.preventDefault();
      });
    }
  };

  $.fn[componentName] = function (options) {
    return this.each(function () {
      if (!$.data(this, 'component_' + componentName)) {
        $.data(this, 'component_' + componentName,
          new Component(this, options));
      }
    });
  };
    
})(jQuery);


