/**
 * Content selector module
 * 
 * Use a dropdown to show/hide one of several pieces of content.
 *
 * author Ben St Johnston
 * 
 * The option values of the select box should be the ids of the pieces of content to show or hide
 *
 *	<select data-fti-module="content-selector" data-content-selector-targets="contact-us" name="emailTopic" class="form-control">
 *		<option value="">-</option>
 *		<option value="orderclientstatements">Order Client Statements</option>
 *		<option value="other">Other</option>
 *		<option value="feedback">Feedback</option>
 *	</select>
 *
 * The value of data-content-selector-targets defines the list of pieces of content on the page that are shown or hidden. e.g.
 * 
 * 		<form id="feedback"  data-content-selector-target="contact-us">
 * 
 * data-content-selector-target in the feedback form matches data-content-selector-targets in the content-selector, 
 * so the form will only appear when the feedback option has been selected.
 */
;
(function ($, document, frk) {

    var componentName = 'contentSelector';

    function Component(element, options) {
        this._name = componentName;
        this.element = element;
        this.init();
    }

    function showSelectedContent(targets, targetId) {
    	targets.each(function() {
     		if (this.id === targetId) {
        		$(this).show();
    		} else {
        		$(this).hide();
    		}
    	});
    }
    
    Component.prototype = {
    	init: function() {
    		console.log("content-selector loading");
            $(this.element).on("change", this, this.contentSelected);
            this.targets = $('*[data-content-selector-target="' + $(this.element).data("content-selector-targets") +'"]');
            showSelectedContent(this.targets, this.value);
    	},
        
        contentSelected: function (event) {
        	var targetId = event.target.value;
        	var targets = event.data.targets;
        	showSelectedContent(targets, targetId);
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName, new Component(this,
                        options));
            }
        });
    };


})(jQuery, document, frk);

