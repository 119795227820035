/** 
 * "My Cart" header list
 */
;
(function ($, document, frk, Handlebars) {

    var componentName = 'cartList',
            defaults = {
                cartDataUrl: '/forms-literature/getCartDetails',
                modalTemplateUrl: '/assets/templates/html/components/literature/order-lit/added-to-cart-list.html'
            };

    // The plugin constructor 
    function Component(element, options) {
        this.element = element;

        this.options = $.extend({}, defaults, options);

        this._defaults = defaults;
        this._name = componentName;
       
        this.templateUrl = $(this.element).data('fti-template');
        this.cartList = $(document).find('[data-fti-component="cart-list"]');
        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
            var _self = this;

            // Download the list template then render the current state of the cart
            if (this.templateUrl) {
                this.getCartTemplate().then(function () {
                
                    $(_self.element).on('updateCart', (function (e) {
                        _self.updateCart(e, _self.cartTemplate);
                    }).bind(_self));
                    _self.updateCart();
                });
            }
            
            if (this.options.modalTemplateUrl) {
                this.getModalTemplate().then(function () {
                    $(_self.element).on('showItemAddedModal', (function (e) {
                        _self.showItemAddedModal(e);
                    }).bind(_self));
                    $(_self.element).on('showComparisonModal', (function (e,version) {
                        _self.showComparisonModal(e,version);
                    }).bind(_self));                    
                }, function() {
                    console.error('error getting modal template');
                });
            }
            
            $(this.element).on('removeItem', (function (e) {
                _self.removeItem(e);
            }).bind(_self));

        },
        getCartTemplate: function () {
        
            var dfd = $.Deferred();
            var _self = this;
            if (!_self.cartTemplate) {
                $.ajax({
                    url: _self.templateUrl,
                    dataType: 'html'
                }).done(function (data) {
                    _self.cartTemplate = data;
                    dfd.resolve(data);
                }).fail(function () {
                    dfd.reject('Unable to load data');
                });
            }
            else {
                dfd.resolve(this.cartTemplate);
            }
            return dfd.promise();
        },
        getModalTemplate: function () {
        
            var dfd = $.Deferred();
            var _self = this;
            if (!_self.modalTemplate) {
                $.ajax({
                    url: _self.options.modalTemplateUrl,
                    dataType: 'html'
                }).done(function (data) {
                    _self.modalTemplate = data;
                    dfd.resolve(data);
                }).fail(function () {
                    dfd.reject('Unable to load data');
                });
            }
            else {
                dfd.resolve(this.modalTemplate);
            }
            return dfd.promise();
        },
        getCartData: function () {
        
            var _self = this;
            var dfd = $.Deferred();
          
            $.ajax({
                url: this.options.cartDataUrl ,
                dataType: 'json', 
                type: 'GET', 
                cache:false 
                
                
               
            }).done(function (data) {
            	
                dfd.resolve(data);
            }).fail(function (jqXHR, textStatus, errorThrown) {
                dfd.reject('Unable to load data: ' + textStatus + " " + errorThrown);
            });
            return dfd.promise();
        },
        updateCart: function (e) {
        	
            var _self = this;
          
           
            this.getCartData().then(function (data) {
            	
                // Re-render the header cart drop-down
            
                var html = Handlebars.compile(_self.cartTemplate)({object: data});
               
                $(_self.cartList).find('.cart-items li').remove();
               
                $(_self.cartList).find('.cart-items').append(html);
               
                // Update the item count in the header
                var cartCount = data.length;
              
                $("#cart-count").empty().append(cartCount);
               
                if(cartCount != null && cartCount !="" && cartCount > 0){
                	   $("#signin-signout").removeAttr("href").css("cursor","pointer");
                       $("#signin-signout").attr('data-target','#signout-modal');
                       $("#signin-signout").attr('data-toggle','modal');
                       $("#signin-signout").html('Sign Out');
                }
             
                $.each(data, function(key, value) {
                	var obj = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].mandatoryMaterials;
                	if(typeof(obj) != "undefined" && null != obj && "" != obj){
                    	    $("#madatoryCartMessage").html("Mandatory items may have been added.");
                    	}
                });
              
            }, function (errorMessage) {
                console.error(errorMessage);
            });
        },
        showItemAddedModal: function (e) {
        
            var _self = this;
            var html = Handlebars.compile(_self.modalTemplate)({object: e.litData});
            var addedCount = e.litData.length;
            var lightBoxName = e.lgtName;
           
            $("#addedItems").html(html);
            // TODO - "Items" to be a label.
            $("#addedItemsCount").html(addedCount + (addedCount === 1 ? " item" : " items"));
            if(addedCount != null && addedCount !="" && addedCount > 0){
          	   $("#signin-signout").removeAttr("href").css("cursor","pointer");
                 $("#signin-signout").attr('data-target','#signout-modal');
                 $("#signin-signout").attr('data-toggle','modal');
                 $("#signin-signout").html('Sign Out');
          }
            $.each(e.litData, function(key, value) {
            	var obj = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].mandatoryMaterials;
            
            	if(typeof(obj) != "undefined" && null != obj && "" != obj){
                	     $("#madatoryMessage").html("Mandatory items may have been added.");
                	     $("#madatoryCartMessage").html("Mandatory items may have been added.");
                	     if(typeof(lightBoxName) != "undefined" && null != lightBoxName && "" != lightBoxName && lightBoxName =="quickView"){
                	      $("#quickViewMandatory").html("Mandatory items may have been added.");
                	     }
                	}
            });
            
            if(typeof(lightBoxName) != "undefined" && null != lightBoxName && "" != lightBoxName && lightBoxName =="quickView"){
            	 $('#lit-modal').modal('hide');
            }else{
            	 $('#lit-modal').modal('show');
            }
           
        },
        removeItem: function (e) {
            console.log('removing ', e.litData);
        },
        showComparisonModal: function (e,version) {
        
            var _self = this;                        
            if(typeof(lightBoxName) != "undefined" && null != lightBoxName && "" != lightBoxName && lightBoxName =="quickView"){
            	 $('#comparison-modal').modal('hide');                 
            }else{
                
                switch (version) {
                    case 'money':
                            $('#comparison-message-money').show();
                            $('#comparison-message-toomany').hide();
                            break;
                    case 'toomany':
                            $('#comparison-message-money').hide();
                            $('#comparison-message-toomany').show();
                            break;                    
                    default:
                            $('#comparison-message-money').show();
                            $('#comparison-message-toomany').show();
                            break;
                };                  
            	 $('#comparison-modal').modal('show');
                 
            }
           
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };

    // ----------
    // markup hook
    $(document).find('[data-fti-component="cart-list"]').each(function () {
    	
        $(this).cartList();
    });

})(jQuery, document, frk, Handlebars);
