/** 
 * My Literature Details
 * Component to handle view and actions from a single piece of literature
 * 
 * Author: Ben St Johnston
 * 
 * To Do: 
 *    thumbnails
 *    mandatory materials
 *    downloads
 *    add to cart
 * 
 */
;
(function($, document, frk, Handlebars) {

	var componentName = 'myLiteratureDetails', defaults = {};

	function Component(element, options) {

		this.element = element;
		this.data = options.data;
		this.controller = options.controller;
		this._name = componentName;
		
		this.init();
	}

	Component.prototype = {
		init : function() {
			var _self = this;

			this.setDocumentFormat();
			
			if (this.data.finraPath === "") {
				$(this.element).find('[data-show-finra-link]').each(function(index, el) {
					$(el).hide();
				});
			} else {
				$(this.element).find('[data-finra-link]').each(function(index, el) {
					$(el).attr('href', _self.data.finraPath);
				});
			}
			
			$(this.element).find('[data-delete-button]').each(function(index, el) {
				$(el).on("click", _self, _self.removeLiteratureHistory);
			});
			
			this.cartButton = $(this.element).find('[data-fti-component="button-cart"]').each(function(){
	            $(this).buttonCart(); // this creates an instance of buttonCart for each element
	        });
			
			this.initView();
			
		},
		initView : function() {

			var _self = this;

			this.view = {
					updatedDate: function() {
						return  "Updated " + _self.formatDateYYMM(_self.data.publicationDate);
					},
					clientUse: function() {
						return _self.data.isClientUse?"Yes":"No";
					},
					nextUpdate: function() {
						return _self.formatDateYYMM(_self.data.nextUpdateDate);
					},
					publicationDate: function() {
						return _self.formatDateYYMM(_self.data.publicationDate);
					}
			};

			this.viewEngine = frk.viewEngine(this.view);
			this.viewEngine.init();
			this.viewEngine.updateAll();
		},
		formatDateYYMM: function(dateStr) {
			return  (dateStr !== undefined && dateStr.trim() !== "" && dateStr.length > 7) ? dateStr.substr(5, 2) + "/" + dateStr.substr(2, 2) : "";
		},
		setDocumentFormat: function() {
			var _self = this;
			$(this.element).find('[data-doc-format1]').each(function(index, el) {
				$(el).addClass(_self.data.format).html(_self.data.format);
			});
			$(this.element).find('[data-doc-format2]').each(function(index, el) {
				$(el).addClass(_self.data.format + "-lg");
			});
		},
		removeLiteratureHistory: function(e) {
			var _self = e.data;
			e.preventDefault();
			$(_self.element).slideUp();
			$.ajax({
				url : '/forms-literature/remove-literature',
				dataType : 'text',
				data: { packageCode: _self.data.packageCode,packageType: _self.data.literatureType },
				type: 'POST',
				success: function(data) {
					$(_self.element).remove();
					delete _self;
					_self.controller.removeDataItem(_self.data);
					location.reload(true);
				},
				error: function(data) {
					
					console.error(data);
				}
		   });
		}
		
	};

	$.fn[componentName] = function(options) {
		return this.each(function() {
			if (!$.data(this, 'component_' + componentName)) {
				$.data(this, 'component_' + componentName, new Component(this,
						options));
			}
		});
	};


})(jQuery, document, frk, Handlebars);