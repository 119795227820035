/** 
 * Generic PPSS Plugin. Works with PPSS, 529 and VIP
 * 
 */
;
(function ($, window, frk, Handlebars, viewport) {

    // Create the defaults once 
    var componentName = 'ppssPlugin',
            defaults = {
                hasFundFilter: true,
                hasTabs: true,
                hasFavorite: true,
                hasSalesCharges: true,
                hasEndChange: true,
                dataEntryPoint: 'productsVO.ppsList',
                caveatEntryPoint: 'productsVO.caveatvo',
                searchFields: ['symbol.val', 'fundShortName.val', 'fundNumber.val']
            };
    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        // The fund filter
        this.fundFilter = $(this.element).find('[data-fti-subcomponent="ppss-fund-filter"]')[0];
        // Sales charges button
        this.toggleSalesCharges = $(this.element).find('[data-ppss-sales-charges="toggle"]')[0];
        this.toggleFav = $(this.element).find('[data-ppss-favorite="toggle"]')[0];
        // Change between month end / quarter end
        this.changeEnd = $(this.element).find('[data-ppss-end-change=""]')[0];
        // Labels URL
        this._labelsUrl = this.options.ftiLabels;
        // as of dates
        this.asOf = {};
        // Options passed to handlebars when rendering
        this.renderOptions = {
            isLoggedIn: this.options.ftiLoggedIn ? this.options.ftiLoggedIn : 'false',
            actor: this.options.ftiActor,
            signinUrl: this.options.ftiSigninUrl ? this.options.ftiSigninUrl : '#',
            registrationUrl: this.options.ftiRegistrationUrl ? this.options.ftiRegistrationUrl : '#'
        };
        // Tabs elements, templates URL, status, panel, etc...
        this.tabs = {};
        // the fields the search box filters against
        this.fields = this.options.searchFields;
        // Everything that needs to be rendered on the page using data binding
        this.view = {};
        this.selectedFunds = [];
        // Sorthing module
        this.Sorter = new frk.PPSSSort();
        // Call init method
        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
            var _self = this;
            this.addLoader();
            this._initLabels();

            // get and set json url
            if (this.options.ftiJson) {
                this.jsonURL = this.options.ftiJson;
            }
            else {
                throw 'No JSON Data specified';
            }

            //get and set tabs
            if (this.options.hasTabs) {
                $(this.element).find('.nav-tabs a').each(function () {
                    // get tab ID
                    var id = $(this).attr('href').replace('#', '');
                    // add it to tabs object and setup elements and status object
                    _self.tabs[id] = {
                        element: $(this),
                        table: $(_self.element).find('.tab-content #' + id + ' table')[0],
                        content: $(_self.element).find('.tab-content #' + id + ' [data-fti-template]'),
                        searchBox: $(_self.element).find('.tab-content #' + id + ' .search-box input'),
                        loaded: false,
                        active: false,
                        refresh: false
                    };
                });
            }
            else {
                this.tabs[0] = {
                    element: $(this),
                    table: $(this.element).find('table')[0],
                    content: $(this.element).find('[data-fti-template]'),
                    searchBox: $('.search-box input'),
                    loaded: false,
                    active: true,
                    refresh: true
                };
            }
            // get and set templates url
            for (var tab in this.tabs) {
                if (this.tabs[tab].content.data('ftiTemplate')) {
                    this.tabs[tab].templateUrl = $(this.tabs[tab].content).data('ftiTemplate');
                }
                else {
                    throw 'No Template URL specified for ' + tab;
                }
            }
            // Init data
            this._getData().then(
                    function (data, caveatData) {
                        // setting up global vars for data (can be handled by different tabs)
                        frk._data = frk._filteredData = frk._displayedData = data;
                        var panels = [];
                        for (tab in _self.tabs) {
                            // implement panels
                            _self.tabs[tab].panel = new frk.PPSSPanel(_self.Sorter);
                            panels.push(_self.tabs[tab].panel);
                            // add favourite module
                            if (_self.options.hasFavorite) {
                                $(_self.tabs[tab].table).ppssActionMenu({selectedFunds: _self.selectedFunds, isMaster: (window.Object.keys(_self.tabs).indexOf(tab) === 0) ? true : false});
                            }
                        }
                        // init as of dates
                        _self._initAsOf(data);
                        // init sorting
                        _self._initSorting();
                        // init filters
                        _self._initFilters(panels, data);
                        // init view (data binding)
                        _self._initView();
                        // init caveats
                        $(_self.element).caveat({data: caveatData});
                        // init tabs listeners
                        if (_self.options.hasTabs) {
                            _self._listenTabClick();
                        }
                        // add legal title at bottom of page
                        if(caveatData)
                            $('#important-legal .legal').prepend('<h3 class="ili-heading">Important Legal Information</h3>').labels();
                        // check hash and try to load specified tab if exists
                        var i = $.inArray(window.location.hash.replace('#', ''), window.Object.keys(_self.tabs));
                        if (window.location.hash !== '' && i > -1) {
                            $('a[href="' + window.location.hash + '"]').tab('show');
                            var e = {data: {tab: window.Object.keys(_self.tabs)[i]}};
                            _self._loadTab(e);
                        }
                        else {
                            // diplay first tab by default
                            var e = {data: {tab: window.Object.keys(_self.tabs)[0]}};
                            _self._loadTab(e);
                        }                        
                    }
            );
        },
        _initAsOf: function (data) {
            if (data && data.length > 0) {

                this.asOf.distRateDate = data[0].distRateDate;
                this.asOf.secYieldDate = data[0].maxPriceDateOvr;
                this.asOf.navDt = data[0].navDt;
                this.asOf.inception_dt = data[0].inception_dt;
                this.asOf.maxNavAsOf = {'alt': frk.getLatest(data, 'navDt.alt')};
				
				this.asOf.maxMktNavAsOf = {'alt': frk.getLatest(data, 'mktNavDate.alt')};
                this.asOf.maxMktNavMstAsOf = {'alt': frk.getLatest(data, 'mktNavMasterFundDate.alt')};
                this.asOf.maxShHldAsOf = {'alt': frk.getLatest(data, 'shareHolderNetFlowDate.alt')};
                this.asOf.maxDailLiqAsOf = {'alt': frk.getLatest(data, 'dailyLiquidAssetDate.alt')};
                
                this.asOf.ytdAsOf529 = {'alt': frk.getLatest(data, 'perfDet.AsOfDate.alt')};
                this.asOf.navAsOf529 = {'alt': frk.getLatest(data, 'perfDet.AATotReturn.asofDate.alt')};
                this.asOf.priceNavAsOf529 = {'alt': frk.getLatest(data, 'priceDet.asOfDate.alt')};
                this.asOf.priceBreakpointAsOf = {'alt': frk.getLatest(data, 'priceDet.asOfDate.alt')};
            }
        },
        _initLabels: function () {
            var _self = this;
            
            this._getLabels().then(function (labelsData) {
                $(_self.element).labels({data: labelsData.labels.label});
            });
        },
        _initSorting: function () {
            var _self = this;
            $(this.element).find('[data-sort]').each(function () {

                // get function and key
                var split = $(this).data('sort').split('|');
                var options = [];

                // Config, when need to sort on different field depending on application status (Pop/Nav/Monthend/Quarter, etc...)
                if ($(this).data('sortConfig')) {
                    var optionsSplit = $(this).data('sortConfig').split(',');
                    for (var op = 0; op < optionsSplit.length; op++) {
                        var s = optionsSplit[op].split('|');
                        options.push({mode: s[0], field: s[1]});
                    }
                    options.push({mode: 'default', field: split[1]});
                }

                var active = !!($(this).parent('[aria-sorted]').length);
                _self.Sorter.addSortable('sort_' + split[1], {order: split[0], field: split[1]}, options, active);
                $(this).on('click', {tab: $(this).closest('.tab-pane').attr('id')}, function (e) {
                    $('[data-ppss-favorite="tool-bar"]').trigger('resetSelected');
                    _self._sort(e, split);
                });
            });
        },
        _initFilters: function (panels, data) {
            var _self = this;
            if (this.options.hasFavorite) {
                $(this.toggleFav).find('[data-button]').each(function () {
                    _self._handleFavDisplayOption(this);
                    if ($(this).is(':checked')) {
                        _self.renderOptions.favOn = $(this).val() !== '' ? 'true' : 'false';
                    }
                });
            }
            for (var tab in this.tabs) {
                // search box
                this.tabs[tab].panel.addFilter('searchbox', {query: $(this.tabs[tab].searchBox).val() ? $(this.tabs[tab].searchBox).val() : '', fields: this.fields}, true);
                $(this.tabs[tab].searchBox).on('keyup',
                        {tab: tab}, function (e) {
                    frk.delay(_self._search.bind(_self, e), 500);
                });
            }
            //Fund filter
            if (this.options.hasFundFilter) {
                $(this.fundFilter).ppssFundFilter({
                    ppssPanels: panels,
                    data: data,
                    renderOptions: this.renderOptions
                });
                this._listenFundFilterChanges();
            }
            // Sales charges module
            if (this.options.hasSalesCharges) {
                this._listenSalesChargesChanges();
                $(this.toggleSalesCharges).ppssSalesCharges();
                this.renderOptions.isSalesChargesOn = $(this.toggleSalesCharges).find('input:checked').val() === 'true';
            }
            // End module 
            if (this.options.hasEndChange) {
                $(this.changeEnd).ppssChangeEnd();
                this.renderOptions.endMonth = $(this.changeEnd).val() === 'month';
                this._listenEndChanges();
            }
        },
        _initView: function () {
            var _self = this;
            for (var tab in this.tabs) {
                // view values for number of funds displayed per tabs
                // view name is xFundsnameOfTabID or xFunds0 if no tabs
                this.view[frk.toCamelCase('xFunds-' + tab)] = (function (t) {
                    return _self.tabs[t].panel.getNbFundDisplayed();
                }).bind(this, tab);
            }
            this.view['shareClassDisplayed'] = function () {
                return $('#share-class').val() !== '' ? $('#share-class option:selected').html() : 'All';
            };
            this.view['distrRateAsOf'] = function () {
                return (frk._displayedData[0].firstMF) ? 'As of '+ (_self.asOf.distRateNAVDt.alt || 'mm/dd/yyyy') : 'As of ' + (_self.asOf.distRateNAVDt.alt || 'mm/dd/yyyy');
            };
            this.view['standardYieldAsOf'] = function () {
                return (frk._displayedData[0].firstMF) ? 'As of ' + (_self.asOf.distRateNAVDt.alt || 'mm/dd/yyyy') : 'As of ' + (_self.asOf.maxSecYieldDate.alt || 'mm/dd/yyyy');
            };
            this.view['navAsOf'] = function () {
                return _self.renderOptions.endMonth ? _self.asOf.avgAnlTotRtnDtME.alt : _self.asOf.avgAnlTotRtnDtQE.alt;
            };
            this.view['morningstarAsOf'] = function () {
                return _self.asOf.maxMorningstarRatingDate.alt;
            };
            this.view['fundCategoryAsOf'] = function () {
                return _self.asOf.maxMorningstarRatingDate.alt;
            };
            this.view['ytdAsOf'] = function () {
                return _self.asOf.ytdTotRtnNAVDt.alt;
            };
            this.view['inception_dt'] = function () {
                return _self.asOf.inception_dt.alt;
            };
            this.view['navAsOf529'] = function () {
                return _self.asOf.navAsOf529.alt;
            };
            this.view['priceNavAsOf529'] = function () {
                return _self.asOf.priceNavAsOf529.alt;
            };
            this.view['ytdAsOf529'] = function () {
                return _self.asOf.ytdAsOf529.alt;
            };
            this.view['maxNavAsOf'] = function () {
                return _self.asOf.maxNavAsOf.alt;
            };
            this.view['maxMktNavAsOf'] = function () {
                return _self.asOf.maxMktNavAsOf.alt;
            };
            this.view['maxMktNavMstAsOf'] = function () {
                return _self.asOf.maxMktNavMstAsOf.alt;
            };
            this.view['maxShHldAsOf'] = function () {
                return _self.asOf.maxShHldAsOf.alt;
            };
            this.view['maxDailLiqAsOf'] = function () {
                return _self.asOf.maxDailLiqAsOf.alt;
            };
            this.view['priceBreakpointAsOf'] = function () {
                return _self.asOf.priceBreakpointAsOf.alt;
            };
            this.view['removeOrAdd'] = function () {
                return (_self.renderOptions.favOn === 'true') ? '<span data-fti-label="add-to-favourite.remove"></span>' : '<span data-fti-label="add-to-favourite.add"></span>';
            };
            if (this.options.hasSalesCharges) {
                this.view['popOrNav'] = function () {
                    return _self.renderOptions.isSalesChargesOn ? '<span data-fti-label="pop"></span>' : '<span data-fti-label="nav"></span>';
                };
                this.view['popOrNavCaveat'] = function () {
                    return _self.renderOptions.isSalesChargesOn ? '<span data-fti-caveat="Fund Tab|Distribution Rate at POP"></span>' : '<span data-fti-caveat="Fund Tab|Distribution Rate at NAV"></span>';
                };
                this.view['distRatePopOrNavCaveat'] = function () {
                    return (frk._displayedData[0].firstMF) ? '' : _self.renderOptions.isSalesChargesOn ? '<span data-fti-caveat="Fund Tab|As of Date Distribution Rate at POP"></span>' : '<span data-fti-caveat="Fund Tab|As of Date Distribution Rate at NAV"></span>';
                };
                this.view['standardYieldCaveat'] = function () {
                    return (frk._displayedData[0].firstMF) ? '' : '<span data-fti-caveat="Fund Tab|As of Date 30-day Standardized Yield"></span>';
                };
                this.view['navTotalRetPopOrNavCaveat'] = function () {
                    return _self.renderOptions.isSalesChargesOn ? '<span data-fti-caveat="Fund Tab|Average Annual Total Return at POP"></span>' : '<span data-fti-caveat="Fund Tab|Average Annual Total Return at NAV"></span>';
                };
                this.view['navAsOfTotalRetPopOrNavCaveat'] = function () {
                    return _self.renderOptions.isSalesChargesOn ? '<span data-fti-caveat="Fund Tab|As of Date Avg Annual Total Return at POP"></span>' : '<span data-fti-caveat="Fund Tab|As of Date Avg Annual Total Return at NAV"></span>';
                };
                this.view['popOrNavAvCumCaveat'] = function () {
                    return _self.renderOptions.isSalesChargesOn ?
                            _self.renderOptions.endMonth ? '<span data-fti-caveat="Performance|Average Annual Total Return @POP"></span>' : '<span data-fti-caveat="Performance|Cumulative Total Return @POP"></span>' :
                            _self.renderOptions.endMonth ? '<span data-fti-caveat="Performance|Average Annual Total Return @NAV"></span>' : '<span data-fti-caveat="Performance|Cumulative Total Return @NAV"></span>';
                };
                this.view['1yrAvCumCaveat'] = function () {
                    return _self.renderOptions.endMonth ? '<span data-fti-caveat="Performance|Average Annual Total Return 1Yr"></span>' : '<span data-fti-caveat="Performance|Cumulative Total Return 1Yr"></span>';
                };
                this.view['3yrAvCumCaveat'] = function () {
                    return _self.renderOptions.endMonth ? '<span data-fti-caveat="Performance|Average Annual Total Return 3Yr"></span>' : '<span data-fti-caveat="Performance|Cumulative Total Return 3Yr"></span>';
                };
                this.view['5yrAvCumCaveat'] = function () {
                    return _self.renderOptions.endMonth ? '<span data-fti-caveat="Performance|Average Annual Total Return 5Yr"></span>' : '<span data-fti-caveat="Performance|Cumulative Total Return 5Yr"></span>';
                };
                this.view['10yrAvCumCaveat'] = function () {
                    return _self.renderOptions.endMonth ? '<span data-fti-caveat="Performance|Average Annual Total Return 10Yr"></span>' : '<span data-fti-caveat="Performance|Cumulative Total Return 10Yr"></span>';
                };
                this.view['lifeAvCumCaveat'] = function () {
                    return _self.renderOptions.endMonth ? '<span data-fti-caveat="Performance|Average Annual Total Return Life of Fund"></span>' : '<span data-fti-caveat="Performance|Cumulative Total Return Life of Fund"></span>';
                };
            }
            this.view['mutualFundHeaderCurrent'] = function () {
                return (frk._displayedData[0].firstMF) ? '<span data-fti-label="7-day-current-yield"></span>' : '<span data-fti-label="distribution-rate"></span>';
            };
            this.view['mutualFundHeaderCurrentPopOrNav'] = function () {
                return (frk._displayedData[0].firstMF) ? '' : _self.renderOptions.isSalesChargesOn ? '<span data-fti-label="pop"></span>' : '<span data-fti-label="nav"></span>';
            },
            this.view['mutualFundHeaderCurrentCaveat'] = function () {
                return (frk._displayedData[0].firstMF) ? '<span data-fti-caveat="Fund Tab|7day Current Yield"></span>' : _self.renderOptions.isSalesChargesOn ? '<span data-fti-caveat="Fund Tab|Distribution Rate at POP"></span>' : '<span data-fti-caveat="Fund Tab|Distribution Rate at NAV"></span>';
            };
            this.view['mutualFundHeaderEffective'] = function () {
                return (frk._displayedData[0].firstMF) ? '<span data-fti-label="7-day-effective-yield"></span>' : '<span data-fti-label="30-days-stand-yield"></span>';
            };
            this.view['mutualFundHeaderEffectiveCaveat'] = function () {
                return (frk._displayedData[0].firstMF) ? '<span data-fti-caveat="Fund Tab|7day Effective Yield"></span>' : '<span data-fti-caveat="Fund Tab|30-day Standardized Yield"></span>';
            };

            // init view engine for data binding
            this.viewEngine = frk.viewEngine(this.view);
            this.viewEngine.init();
        },
        _getData: function () {
            var _self = this,
                    dfd = $.Deferred(),
                    load = function (withFav) {
                        frk.load(_self.jsonURL, 'json').then(
                                function (data) {
                                    var dataEP = frk.getValueByString(data, _self.options.dataEntryPoint);
                                    // get caveat data
                                    var caveatEP = frk.getValueByString(data, _self.options.caveatEntryPoint);
                                    var dataAsOf = {};
                                    if (_self.options.type !== '529') {
                                        dataAsOf = {
                                            avgAnlTotRtnDtME: data.productsVO.avgAnlTotRtnDtME,
                                            avgAnlTotRtnDtQE: data.productsVO.avgAnlTotRtnDtQE,
                                            distRateNAVDt: data.productsVO.distRateNAVDt,
                                            secYieldDt: data.productsVO.secYieldDt,
                                            ytdTotRtnNAVDt: data.productsVO.ytdTotRtnNAVDt,
                                            maxMorningstarRatingDate: data.productsVO.maxMorningstarRatingDate,
                                            maxPriceDateOvr: data.productsVO.maxPriceDateOvr,
                                            maxSecYieldWoWvrDate: data.productsVO.maxSecYieldWoWvrDate,
                                            maxSecYieldDate: data.productsVO.maxSecYieldDate,
                                            ytdAtPopQEDate: data.productsVO.ytdAtPopQEDate,
                                            ytdAtNavQEDate: data.productsVO.ytdAtNavQEDate,
                                            ytdAtPopMEDate: data.productsVO.ytdAtPopMEDate,
                                            ytdAtNavMEDate: data.productsVO.ytdAtNavMEDate,
                                            maxDistRateDate: data.productsVO.maxDistRateDate,
                                            maxMktNavFndDt: data.productsVO.maxMktNavFndDt,
                                            maxNavDt: data.productsVO.maxNavDt
                                        };
                                        // We need to cleanup the data
                                        // remove Liquidated funds, check scheduled dates, etc...
                                        if (_self.options.type === 'ppss') {
                                            frk.PPSSBrRules(data.productsVO.ppsList, dataAsOf);
                                        } else if (_self.options.type === 'vip') {
                                            frk.PPSSVIPBrRules(data.productsVO.ppsList, dataAsOf);
                                        } else if (_self.options.type === 'money') {
                                        	
                                        	frk.PPSSMONEYBrRules(data.productsVO.ppsList, dataAsOf);
                                        }
                                        if (withFav) {
                                            frk.FavoriteFunds.addFav({funds: dataEP});
                                        }
                                    }
//                                    else if (_self.options.type === '529'){
                                    else {
                                        frk.PPSS529BrRules(dataEP, dataAsOf);
                                    }

                                    _self.asOf = dataAsOf;
                                    dfd.resolve(dataEP, caveatEP, dataAsOf);
                                },
                                function (message) {
                                    dfd.reject(message);
                                }
                        );
                    };

            if (_self.options.hasFavorite && _self.renderOptions.isLoggedIn === true) {
                frk.load(_self.options.ftiFavorite, 'json').then(function (favData) {
                    frk.FavoriteFunds.setData(favData, _self.options.ftiActor, _self.options.ftiFavorite);
                    load(true);
                });
            }
            else {
                load();
            }

            return dfd.promise();
        },
        _getTemplate: function (tab) {
            var dfd = $.Deferred();
            var _self = this;
            if (!_self.tabs[tab].template) {
                frk.load(this.tabs[tab].templateUrl, 'html').then(
                        function (data) {
                            // save data
                            _self.tabs[tab].template = data;
                            dfd.resolve(data);
                        },
                        function (message) {
                            dfd.reject(message);
                        }
                );
            }
            else {
                dfd.resolve(this.tabs[tab].template);
            }
            return dfd.promise();
        },
        _getLabels: function () {
            var _self = this,
                    _dfd = $.Deferred();
            if (!this._labels) {
                frk.load(this._labelsUrl, 'json').then(
                        function (data) {
                            // save data
                            _self._labels = data;
                            _dfd.resolve(data);
                        },
                        function (message) {
                            _dfd.reject(message);
                        }
                );
            }
            else {
                _dfd.resolve(this._labels);
            }
            return _dfd.promise();
        },
        _listenTabClick: function () {
            // set up listeners on tabs
            for (var tab in this.tabs) {
                $(this.tabs[tab].element).on('click', {tab: tab}, this._loadTab.bind(this));
            }
        },
        _listenFundFilterChanges: function () {
            var _self = this;
            $(this.fundFilter).on('readyToRender', function (e) {
                _self.addLoader();
                for (var tab in _self.tabs) {
                    if (_self.tabs[tab].active) {
                        //render
                        _self._render(
                                tab,
                                _self.tabs[tab].template,
                                {funds: _self.tabs[tab].panel.update(), renderOptions: _self.renderOptions}
                        );
                        if (_self.options.hasTabs)
                            _self._updateTabsStatus(tab);
                    }
                }
                _self.removeLoader();
            });
        },
        _listenSalesChargesChanges: function () {
            var _self = this;
            $(this.toggleSalesCharges).on('readyToRender', function (e) {
                _self.addLoader();
                _self.renderOptions.isSalesChargesOn = e.isSalesChargesOn;
                var mode = (_self.renderOptions.isSalesChargesOn && !_self.renderOptions.endMonth) ? 'sc-qtr' :
                        (_self.renderOptions.isSalesChargesOn && _self.renderOptions.endMonth) ? 'sc' :
                        (!_self.renderOptions.isSalesChargesOn && !_self.renderOptions.endMonth) ? 'qtr' :
                        'default';
                _self.Sorter.switchSortMode(mode);
                for (var tab in _self.tabs) {
                    if (_self.tabs[tab].active) {
                        //render
                        _self._render(
                                tab,
                                _self.tabs[tab].template,
                                {funds: _self.tabs[tab].panel.update(), renderOptions: _self.renderOptions}
                        );
                        if (_self.options.hasTabs)
                            _self._updateTabsStatus(tab);
                    }
                }
                _self.removeLoader();
            });
        },
        _listenEndChanges: function () {
            var _self = this;
            $(this.changeEnd).on('readyToRender', function (e) {
                _self.addLoader();
                _self.renderOptions.endMonth = e.endMonth;
                var mode = (_self.renderOptions.isSalesChargesOn && !_self.renderOptions.endMonth) ? 'sc-qtr' :
                        (_self.renderOptions.isSalesChargesOn && _self.renderOptions.endMonth) ? 'sc' :
                        (!_self.renderOptions.isSalesChargesOn && !_self.renderOptions.endMonth) ? 'qtr' :
                        'default';
                _self.Sorter.switchSortMode(mode);
                for (var tab in _self.tabs) {
                    if (_self.tabs[tab].active) {
                        //render
                        _self._render(
                                tab,
                                _self.tabs[tab].template,
                                {funds: _self.tabs[tab].panel.update(), renderOptions: _self.renderOptions}
                        );
                        if (_self.options.hasTabs)
                            _self._updateTabsStatus(tab);
                    }
                }
                _self.removeLoader();
            });
        },
        /**
         * When tab is clicked
         * if clicked for the first time, load template and render data
         * otherwise refresh content if needed
         * Update tabs status
         * 
         * @param {type} e event of the tab just clicked
         */
        _loadTab: function (e) {
            var _self = this;
            // Data hasn't been loaded
            if (!this.tabs[e.data.tab].loaded) {
                this.addLoader();
                // Get template
                this._getTemplate(e.data.tab).then(
                        function (template) {
                            // change data loaded status
                            _self.tabs[e.data.tab].loaded = true;
                            _self._render(e.data.tab, template, {funds: _self.tabs[e.data.tab].panel.update(), renderOptions: _self.renderOptions});
                            _self.removeLoader();
                            $('.table-responsive').data('component_visualHint').showVisualHint();
                        },
                        function (message) {
                            // Can't get template, throw error
                            //throw message;
                        }
                );
                // custom functions per tabs
                if (_self.options.type === '529') {
                    if (e.data.tab === 'price-529') {
                        $('[data-ppss-sales-charges="toggle] label').addClass('disabled');
                    }
                }
            }
            // otherwise, if tabs need to be updated
            else if (this.tabs[e.data.tab].refresh) {
                this._render(e.data.tab, this.tabs[e.data.tab].template, {funds: this.tabs[e.data.tab].panel.update(), renderOptions: this.renderOptions});
            }

            //update tabs status
            if (this.options.hasTabs)
                this._updateTabsStatus(e.data.tab);
        },
        _addViewportToOptions: function () {
            this.renderOptions.viewport = viewport.current();
        },
        _render: function (tab, template, data) {
            if (this.tabs[tab].loaded) {
                var html = '';
                this._addViewportToOptions();
                // compile template and get html 
                if (this.options.type === 'ppss') {
                    html = Handlebars.compile(template)(frk.moneyFund(frk.FavoriteFunds.addFav(data)));
                }
                else {
                    html = Handlebars.compile(template)(data);
                }
                // add html to tab content
                $(this.tabs[tab].content).empty();
                $(this.tabs[tab].content).append(html);
                // update view values
                this.viewEngine.updateAll(true);


				// handle document link for selected fund configured in fund-config file
                this._handleDocumentLink(tab,html);

				// handle master fund display for selected fund configured in fund-config file
                this._handleMasterFundDisplay(tab,html);

                // trigger some stuff of favourites
                if (this.options.hasFavorite) {
                    $(this.tabs[tab].table).trigger('ppssFavoriteChange');
                }
                // update labels
                $(this.element).labels();
                // update caveats
                $(this.element).caveat('render');          
                // reload modules in newly added DOM
                $(this.tabs[tab].table).loader();
                $(this.element).find('[data-target-modal="historical-modal"]').each(function (index) {
                	$(this).historicalModal();
               });
            }
            setTimeout(function() {
                $('.table-responsive').data('component_visualHint').showVisualHint();
            }, 10);
        },
		_handleDocumentLink: function (tab,html) {
       	 var fds = frk.UsConfig.getInstitutionalFunds('documentLink');                
            var datatable=this.tabs[tab].table;
            var link = '<P><a href="http://www.ftinstitutional.com/ftinstitutional/institutional/investment-solutions/institutional-mutual-funds/mutual-fund-documents" target="_blank">Document</a></P>';
                       
            if (fds) {
                      	
             if(fds.fundID.length !== undefined) {
       		for (var f in fds.fundID) {
                $('.document[fund-id='+fds.fundID[f].val+']',datatable).html(link); 
               }
            }
            else {
       		 $('.document[fund-id='+fds.fundID.val+']',datatable).html(link);   
            }
                   
       	}
       },
       _handleMasterFundDisplay: function (tab,html) {
    		 var fds = frk.UsConfig.getInstitutionalFunds('masterLink');                
    	     var datatable=this.tabs[tab].table;
    	                 
    	     if (fds) {
    	               	
    	      if(fds.fundID.length !== undefined) {
    			for (var f in fds.fundID) {
    	          $('.feederFund[fund-id='+fds.fundID[f].val+']',datatable).hide();
    	         $('.masterFund[fund-id='+fds.fundID[f].val+']',datatable).show();
    	        }
    	     }
    	     else {
    			 $('.feederFund[fund-id='+fds.fundID.val+']',datatable).hide();
    			 $('.masterFund[fund-id='+fds.fundID.val+']',datatable).show();
    	     }
    	            
    		}
    	},
        _handleFavDisplayOption: function (element) {
            var _self = this;

            $(element).on('change', function (e) {
                _self.renderOptions.favOn = $(e.target).val() !== '' ? 'true' : 'false';
            });
        },
        _search: function (e) {
            // add loader
            this.addLoader();
            $('[data-ppss-favorite="tool-bar"]').trigger('resetSelected');
            // update filter query
            this.tabs[e.data.tab].panel.modifyFilterQuery('searchbox', $(this.tabs[e.data.tab].searchBox).val());
            // render
            this._render(
                    e.data.tab,
                    this.tabs[e.data.tab].template,
                    {funds: this.tabs[e.data.tab].panel.filter('searchbox'), renderOptions: this.renderOptions}
            );
            // remove loader
            this.removeLoader();
            //
            for (var t in this.tabs) {
                if (t !== e.data.tab) {
                    $(this.tabs[t].searchBox).val($(this.tabs[e.data.tab].searchBox).val());
                    // update filter query
                    this.tabs[t].panel.modifyFilterQuery('searchbox', $(this.tabs[e.data.tab].searchBox).val());
                    // render
                    this._render(
                            t,
                            this.tabs[t].template,
                            {funds: this.tabs[t].panel.filter('searchbox'), renderOptions: this.renderOptions}
                    );
                }
            }
        },
        _sort: function (e, split) {
            e.preventDefault();
            // add loader
            this.addLoader();
            if (!e.data.tab || Object.keys(this.tabs).length==1) {
                e.data.tab = 0;
            } // quick fix, when no tabs
            // sort from filtered data
            this._render(
                    e.data.tab,
                    this.tabs[e.data.tab].template,
                    {funds: this.tabs[e.data.tab].panel.sort('sort_' + split[1], $(e.currentTarget).data('reverse')), renderOptions: this.renderOptions}
            );
            // remove loader
            this.removeLoader();
            //add active class /remove from previous (check all tabs, and add remove on others with same sorting attr)
            // current
            $(this.element).find('[data-sort="' + $(e.currentTarget).data('sort') + '"]').parent().addClass('current-sort');

            //others
            //$(e.target).parent().siblings().removeClass('current-sort');
            $(this.element).find('[data-sort]').not('[data-sort="' + $(e.currentTarget).data('sort') + '"]').parent().removeClass('current-sort');
            //$(e.target).parent().siblings().removeAttr('aria-sorted');
            $(this.element).find('[data-sort]').not('[data-sort="' + $(e.currentTarget).data('sort') + '"]').parent().removeAttr('aria-sorted');
            
            //current
            //caret up/down + aria attributes
            if ($(e.currentTarget).data('reverse')) {
                //$(e.target).addClass('dropup');
                $(this.element).find('[data-sort="' + $(e.currentTarget).data('sort') + '"]').removeClass('dropup');
                //$(e.target).parent().attr('aria-sorted', 'ascending');
                $(this.element).find('[data-sort="' + $(e.currentTarget).data('sort') + '"]').parent().attr('aria-sorted', 'ascending');
            }
            else {
                //$(e.target).removeClass('dropup');
                $(this.element).find('[data-sort="' + $(e.currentTarget).data('sort') + '"]').addClass('dropup');
                //$(e.target).parent().attr('aria-sorted', 'descending');
                $(this.element).find('[data-sort="' + $(e.currentTarget).data('sort') + '"]').parent().attr('aria-sorted', 'descending');
            }
            // next click will revese order
            //$(e.target).data('reverse', !$(e.target).data('reverse'));
            $(this.element).find('[data-sort="' + $(e.currentTarget).data('sort') + '"]').data('reverse', !$(e.currentTarget).data('reverse'));

            if (this.options.hasTabs)
                this._updateTabsStatus(e.data.tab);
        },
        /**
         * Set up current tab as active tab
         * Also set tab as up to date, no need to refresh when clicking
         * Tab has lauch data computation, so other tabs don't need to compute data again
         * There are not active though, and they'll have to refresh on next click
         * 
         * @param {type} currentTab user is viewing
         */
        _updateTabsStatus: function (currentTab) {
            //update tabs status
            this.tabs[currentTab].active = true;
            this.tabs[currentTab].refresh = false;
            // other tabs will have to refresh on click
            // but won't have to compute date
            for (var t in this.tabs) {
                if (t !== currentTab) {
                    // previous active tab sorting needs to persist
                    if (this.tabs[t].active === true) {
                        //sync sorters
                    }
                    this.tabs[t].active = false;
                    this.tabs[t].refresh = true;
                    this.tabs[t].panel.setUpToDate().toTrue();
                }
            }
        },
        addLoader: function () {
            $(this.element).throbber('addLoader');
        },
        removeLoader: function () {
            $(this.element).throbber('removeLoader');
        }
    };
    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };
})(jQuery, window, frk, Handlebars, ResponsiveBootstrapToolkit);
