/**
 * Ftichart component plugin
 * This is used to render custom highcharts based on their associated table
 */
// We need the ability to call resize highcharts on page tabs are selected
$(function () {
    // fix dimensions of chart that was in a hidden element
    $('[data-fti-component="pricing-at-nav"]').on('shown.bs.tab', 'a[data-toggle="tab"]', function (e) {
        // on tab selection event
        var visibleModule = $('[data-fti-module="pricing-at-nav-chart"].active');
        visibleModule['pricingAtNavChart']();
    });
});

;
(function ($, window, document, undefined) {

	  this.navDecimal = 2;
    // Create the defaults once
    var componentName = "pricingAtNavChart",
            defaults = {
                prefix: "$",
                seriesName: "NAV"
            };

    // The actual plugin constructor
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.chart;
        this.data = [];
        this.init();
       
    }

    // Create methods associated with the plugin
    Component.prototype = {
        reload: function () {
            console.log('reload');
            this.init();
        },
        init: function () {

            var _self = this;
            _self.chart = $(_self.element).find('.chart:visible').first();
            _self.chart.css('min-height', '400px');
            
         

            // Get Json url from chart attribute
            if (_self.chart.data('ftiJson')) {
                this.jsonURL = _self.chart.data('ftiJson');
             
                if( this.jsonURL.indexOf("fundCategory= Money Funds") != -1){
                	navDecimal = 4;
                }
               
              } else {
                return null;
            }
 
            // Load the jsonURL for the chart if not already loaded
            if (_self.data.length === 0) {

                // Add Loader until we retrieve the JSON
                _self.addLoader();

                frk.load(this.jsonURL, 'json').then(
                        function (data) {

                            if (typeof data.productsVO.priceDetVO !== 'undefined') {

                                var navDet = data.productsVO.priceDetVO[0].navDet;
                                var navData = [];

                                // Loop through NAVs
                                for (var i = 1; i < navDet.length; i++) {
                                    var date = Date.parse(moment(navDet[i].navdt));//Date.parse(navDet[i].navdt.replace(/(\d{4})-(\d{2})-(\d{2})/, "$2 $3 $1"));
                                    navData.push([date, parseFloat(navDet[i].nav)]);
                                }
                                _self.data = JSON.parse(JSON.stringify(navData));
                                _self.buildChart();
                               

                            } else {
                                _self.showErrorMessage();
                            }
                        },
                        function () {
                            _self.showErrorMessage();
                        }
                );
            }

        },
        showErrorMessage: function () {            
            var _self = this;
            
            $(_self.element).find('#loader').remove();
            $('[data-fti-component="pricing-at-nav-chart"]').find('.pricing-chart-error').removeClass('hidden');
            $('[data-fti-component="pricing-at-nav-chart"]').find('.pricing-chart-tabs').hide();
        },
        buildChart: function () {
           
            var _self = this;
           
            // Remove the loader
            _self.removeLoader();

            _self.chart.highcharts({
                exporting: {
                    enabled: false
                },
                legend: {
                    enabled: false
                },
                xAxis: {
                    type: 'datetime'
                },
                yAxis: {
                    labels: {
                        format: _self.options.prefix + '{value:.2f}'
                    },
                    title: ""
                },
                plotOptions: {
                    series: {
                        fillOpacity: 0,
                        marker: {
                            enabled: false
                        }
                    }
                },
                title: {
                    text: ""
                },
                credits: {
                    enabled: false
                },
                series: [{
                        name: _self.options.seriesName,
                        type: 'area',
                        data: _self.data,
                        tooltip: {
                            pointFormat: '{series.name}: <b>{point.y}</b><br/>',
                            valueDecimals: navDecimal,
                            valuePrefix: _self.options.prefix
                        }
                    }]
            });
        },
        addLoader: function () {
            var _self = this;
            // TODO: implement a better solution
            // Text content in code is not ideal
            $(_self.element).find('#loader').remove();
            $(_self.element).append('<div id="loader" style="background-color:white;position:absolute;width:100%;height:100%;left:0; top:0;z-index:100;opacity:0.75;"><div class="loader">Loading...</div></div>');
        },
        removeLoader: function () {
            var _self = this;
            window.setTimeout(function () {
                $(_self.element).find('#loader').remove();
            }, 500);
        }

    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "component_" + componentName)) {
                $.data(this, "component_" + componentName, new Component(this, options));
            } else {
                console.log($.data(this, "component_" + componentName));
                $.data(this, "component_" + componentName).reload();
            }
        });
    };

})(jQuery, window, document);
