/**
 * Cart Address
 */

 
 
(function ($, document, frk, Handlebars) {
	
	 

    // Create the defaults once 
    var componentName = 'cartconfirmorder',  
            defaults = {
    		modalTemplateUrl: '/assets/templates/html/components/literature/order-lit/mandatory-materials-cart-list.html'
            };

    var jsonURL;
    
    // The actual plugin constructor 
    function Component(element, options) {
    	
        this.element = element;
        this.varcancel = $(this.element).find('#cancel');

        this.options = $.extend({}, defaults, options);
        
        
        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
        	/* $(document).find('[data-fti-element="removeItem"]').on('click', function() {
        	
        		this.pkCode = $('#pkCode').val();
             	this.ltType = $('#ltType').val();
        		removeItemFromCart(this.pkCode,this.ltType);
        		
        	}); */
        	
        	 if ($(this.element).data('ftiJson')) {
                 jsonURL = $(this.element).data('ftiJson');
             }
        	 
        	 $(this.varcancel).on('click', function () {
        		// alert("cancel");  
        		 $.ajax({
                     type: 'POST',
                     url: '/forms-literature/remove-all-cartItems' 
                 }).done(function(e, data) {
                     console.log("post result: " + e + data);
                 });
        	 }); 
        	 
        	$(document).find('[data-fti-element="remove_me"]').on('click', function() {
        		 
        		 var _self = this; 
        		 
        		/* this.pkCode = $('#pkCode').val();
        		 this.ltType = $('#ltType').val();
        		 
        		 alert("pkcode is "+$('#pkCode').val());
        		 alert("lttype is "+$('#ltType').val());
        		
        		 var itemSelected = this.pkCode+'-'+this.ltType;*/
        		 
        		 var itemSelected = $(this).attr('id');
        		
    	        $.ajax({
    	            url: jsonURL,
    	            dataType: 'json'
    	        }).done(function (data) {
    	            _self.data = data;
    	            getModalTemplate().then(function () {
    	            removeCartItem(_self.data,itemSelected,modalTemplate);
    	            
                   
                 }, function() {
                     console.error('error getting modal template');
                 });
    	            
    	        }).fail(function () {
    	            console.log('Unable to load data');
    	        });
        		
        		//_self.data = _self.getData();
        		
        		
        		
        		
        	 });
        
        	
        	function getModalTemplate() {
                var dfd = $.Deferred();
                var _self = this;
                if (!this.modalTemplate) {
                	
                    $.ajax({
                        url: '/assets/templates/html/components/literature/order-lit/mandatory-materials-cart-list.html',
                        dataType: 'html'
                    }).done(function (data) {
                    	
                       modalTemplate = data;
                       
                        dfd.resolve(data);
                    }).fail(function () {
                    	
                        dfd.reject('Unable to load data');
                    });
                }
                else {
                	
                    dfd.resolve(this.modalTemplate);
                }
                return dfd.promise();
            }

        	function removeCartItem (data,literatureCode,modalTemplate){
        		
        		$.each(data, function(key, value) {
    	    
        			var litCode = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].literatureCode;
        			
        			if(litCode == literatureCode){
        				console.log("step - 1");
    		   			var madatoryVar = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].mandatoryMaterials;
    		   			var parentVar = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].parents;
    		   			var packageCodeVar = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].packageCode;
    		   			var packageTypeVar = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].literatureType;
    		   			var internettitleVar = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].internetTitle;
    		   			
    		   			if(typeof madatoryVar !== "undefined" && madatoryVar != null && madatoryVar !="" && (parentVar == null || parentVar == "")){
    		   			var jsonObj = [];
    		   			var items = {};
    					var divided = madatoryVar.split(',');
    					for(i=0;i<divided.length;i++){
    						var item = {};
    						$.each(data, function(key, value) {
    							var litCde = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].literatureCode;
    							if(divided[i] == litCde){
	        						var item = {};
	        				        item ["title"] = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].internetTitle;
	        				        item ["litCode"] = value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].literatureCode;
	        				        jsonObj.push(item);
	        						
    							}
    					});
    				}
    				items["items"]=jsonObj;
    				$("#parentLiterature").html(internettitleVar);
    				$("#parent_lit").val(litCode);
    				var html = Handlebars.compile(modalTemplate)(items);
    		        
    				$("#mandItems").html(html);
    				$('#mand-modal').modal('show');
    				
    			}else if(typeof parentVar !== "undefined" && parentVar != null && parentVar != ""){
    				
    				$("#"+value['com.frk.ci.gws.orderlit.model.viewmodel.LiteratureInformationVM'].literatureCode).show();
    				
    			}else{
    				
    				removeItemFromCart(packageCodeVar,packageTypeVar);
    			}
    			
    		}
    		
    	});
    	} 
    	
       function removeItemFromCart(packageCode,packageType){


    	    $.ajax({
    	        url: '/forms-literature/remove-cartItem',
    	        dataType: 'text',
    	        type: 'POST',
    	        data: {packageCode: packageCode, packageType: packageType},
    	        success: function (data) {
    	        	 var rolePattern =  /(investor|advisor)\/.*/;
    	             var role = rolePattern.exec(window.location.pathname);
    	            if(role[1] === 'advisor'){
    	            	  window.location.href = "/advisor/resources/lit/checkout-review";
    	         	}else{
    	         		 window.location.href = "/investor/resources/lit/checkout-review";     
    	         	}
    	            console.log("Success");
    	        },
    	        error: function () {
    	            console.log("Failed");
    	        }
    	    });

    	 }
       
        }   
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };

    // ----------
    // markup hook
    $(document).find('[data-fti-component="cart-confirm-order"]').each(function () {
    	
        $(this).cartconfirmorder();
    });
    // ----

    
    

})(jQuery, document, frk, Handlebars);

function removeItems() {
	
	var mandetoryMap="";
	var count = 0;
	$('input:checkbox[name=litCode]').each(function() 
			{    
			    if($(this).is(':checked')){
			    	
			    	mandetoryMap = $(this).val()+"~"+mandetoryMap;
			    	
			    	
			    }
			      
			});
	
	 $.ajax({
	        url: '/forms-literature/removeMandatoryAndParentItems',
	        dataType: 'text',
	        type: 'POST',
	        data: {mandetoryMap: mandetoryMap,parentLiterature:$("#parent_lit").val()},
	        success: function (data) {
	        	window.location.reload(true); 
	        },
	        error: function () {
	            console.log("Failed");
	        }
	    });
	
	
   
}
