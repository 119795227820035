/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

(function ($, document) {
    // Create the defaults once
    var componentName = 'pressReleases',
        defaults = {};

    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.init();
    }

    Component.prototype = {
        init: function () {

            var usedNames = {};
            var pressTableRow = $('#press-release-table tbody tr');
            var selectedPast = $('select#past');
            var selectedClosedFund = $("select#all-closed-end");

            $(this.element).find("#all-closed-end option").each(function () {
                if (usedNames[this.value]) {
                    $(this).remove();
                } else {
                    usedNames[this.value] = this.text;
                }
            });

            pressTableRow.each(function () {
                var pressReleaseDate = $(this).find('.date').text();
                var today = $.now();                            // today's date in milliseconds
                var twelveMonths = 31536000000;                 // 12 months in milliseconds
                var twelveMonthsAgo = today - twelveMonths;     // 12 months ago

                if (new Date(pressReleaseDate) >= new Date(twelveMonthsAgo)) {
                    $(this).attr('data-past-year', 'true');
                }
            });

            selectedPast.change(function () {
                var pressTableRowSchema = '#press-release-table tbody tr';
                pressTableRow.addClass('hiddendate');

                if ($('input[name=pr-releases]:checked').val() === 'closed-end') {
                    if (selectedPast.val() === 'past-12-months') {
                        if (selectedClosedFund.val() === 'all-closed-end') {
                            pressTableRowSchema += '.close-end'
                                + '[data-past-year=true]';
                        } else {
                            pressTableRowSchema += '.close-end'
                                + '.' + selectedClosedFund.val()
                                + '[data-past-year=true]';
                        }
                    } else {
                        if (selectedClosedFund.val() === 'all-closed-end') {
                            pressTableRowSchema += '.close-end'
                                + '[data-year='
                                + selectedPast.val() + ']';
                        } else {
                            pressTableRowSchema += '.close-end'
                                + '.' + selectedClosedFund.val()
                                + '[data-year=' + selectedPast.val() + ']';
                        }
                    }
                } else {
                    if (selectedPast.val() === 'past-12-months') {
                        pressTableRowSchema += '.pr-rows'
                            + '[data-past-year=true]';
                    } else {
                        pressTableRowSchema += '.pr-rows'
                            + '[data-year=' + selectedPast.val() + ']';
                    }
                }
                $(pressTableRowSchema).removeClass('hiddendate');
            });

            $('input[name$="pr-releases"]').click(function () {
                selectedPast.get(0).selectedIndex = 0;
                selectedPast.trigger('change');
            });

            selectedClosedFund.change(function () {
                selectedPast.trigger('change');
            });

            $(function () {
                setTimeout(function() {
                    selectedPast.trigger('change');
                }, 10);
            });

        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "component_" + componentName)) {
                $.data(this, "component_" + componentName, new Component(this, options));
            }
        });
    };

})(jQuery, document);
