/* 
 * US Config class
 * 
 */
frk = window.frk || {};
frk.UsConfig = {};

/**
 * Constructor
 * @param {type} usConfigData
 * @param {type} actorType
 * @returns {us-config_L6.UsConfig}
 */
frk.UsConfig.setData = function UsConfig(usConfigData, actorType) {
    this.data = usConfigData;
    this.actor = actorType;
//        console.log(this.data, this.actor);
};

frk.UsConfig.getData = function () {
    return this.data;
};

frk.UsConfig.getActor = function () {
    return this.actor;
};

frk.UsConfig.isDataSet = function () {
	return this.data !== undefined;
}

/**
 * Returns custom fund URLs or default one
 * @param {String} pageType
 * @param {String} fundNumber
 * @param {String} fundCategory
 * @returns {String} Fund URL
 */
frk.UsConfig.getProductPageUrl = function (pageType, fundNumber, fundCategory) {
    ///Properties/Data/Result/providers/provider[@id = 'us.fund-config-data']/config
    //$utilsFundConfig/customProductPages/actor[@id = $actor]
    //$utilsFundConfig/productPages/actor[@id = $actor]
    for (var c in this.data.config.customProductPages.actor) {
        if (this.data.config.customProductPages.actor[c].id === this.actor) {
            // <!-- Check for fund specific -->
            if (this.data.config.customProductPages.actor[c].customLinksByFund &&
                    this.data.config.customProductPages.actor[c].customLinksByFund[pageType + 'Link'] &&
                    this.data.config.customProductPages.actor[c].customLinksByFund.fundId.val === fundCategory) {
//                    console.log('returning custom link by fund');
                return this.data.config.customProductPages.actor[c].customLinksByFund[pageType + 'Link'].val;
            }
            // <!-- Check for fund category -->
            else if (this.data.config.customProductPages.actor[c].customLinksByCategory &&
                    this.data.config.customProductPages.actor[c].customLinksByCategory[pageType + 'Link'] &&
                    this.data.config.customProductPages.actor[c].customLinksByCategory.fundCat.val === fundCategory) {
//                    console.log('returning custom link by cat');
                return this.data.config.customProductPages.actor[c].customLinksByCategory[pageType + 'Link'].val;
            }
        }
    }
    // <!-- Fallback to actor default-->
    for (var a in this.data.config.productPages.actor) {
        if (this.data.config.productPages.actor[a].id === this.actor) {
//                console.log('returning default');
            return this.data.config.productPages.actor[a][pageType + 'Link'];
        }
    }

    // nothing found :(
    return "";
};

/**
 * Returns an array of Popular fund Ids
 * @returns {Array} List of fund Ids
 */
frk.UsConfig.getPopularFunds = function () {
    return this.data.config.popularFunds.fundID;
};


/**
 * Returns an array of Institutional fund Ids
 * @returns {Array} List of fund Ids
 */
frk.UsConfig.getInstitutionalFunds = function (showData) {
	   if (showData === 'documentLink'){
		   return this.data.config.global.institutionalFunds.displayDocumentLink;
	   } else {
		   return this.data.config.global.institutionalFunds.institutionalMasterFund;
	   }
};

frk.UsConfig.getDisplayNotData = function (type) {
    try {
        if (type === 'vip') {

            for (var a in this.data.config.vip.actor) {
                if (this.data.config.vip.actor[a].id === this.actor) {
                    return this.data.config.vip.actor[a];
                }
            }
        }
        else {
            for (var a in this.data.config.ppss.actor) {
                if (this.data.config.ppss.actor[a].id === this.actor) {
                    return this.data.config.ppss.actor[a];
                }
            }
        }
    }
    catch (e) {
        return false;
    }
};

