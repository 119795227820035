/* 
 * Client side Label module
 * 
 * Init with label data and all element with data-fti-label="labelName"
 * will be replace with the appropriate label. e.g. 
 *   <p><span data-fti-label="asset-class"></span></p>
 *   replaced with:
 *   <p>Asset class</p>
 *   
 * To init the plugin:  $('myElement').labels({data: caveatData});
 * To update:           $('myElement').labels();
 * 
 */
;
(function ($) {

    // Create the defaults once 
    var componentName = 'labels',
            defaults = {
            };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;

        this._labelData = this.options.data || {};

        this.render();
    }

    /**
     * Searches the element for data-fti-label attrs
     * and try to replace with text value
     * 
     * @returns {undefined}
     */
    Component.prototype.render = function () {
        // render caveats if any
        if (this._labelData) {
            var _self = this;
            // first pass on init, render labels and remove data-attrs
            $(this.element).find('[data-fti-label]').each(function () {
                var k = $(this).data('ftiLabel');

                if (k) {
                    var found = false;
                    for (var c in _self._labelData) {
                        // we have a match
                        if (_self._labelData[c].key === k) {
                            // add sup
                            $(this).after(_self._labelData[c].value);
                            found = true;
                            break; // avoid display label twice is error in xml (fix added to XSL func.)
                        }
                    }
                    if (!found) {
                        $(this).after('[' + k + ']');
                    }
                }
                // remove element so we don't have to deal with it in the future
                $(this).remove();
            });
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            // first call, init
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            } else {
                // already been called on this element, 
                // only method we can call
                $.data(this, 'component_' + componentName).render();
            }
        });
    };
})(jQuery);
