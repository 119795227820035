/* 
 * US Config class
 * 
 */
;
(function (frk) {

    frk.ftnConfig = {};

    /**
     * Constructor
     * @param {type} usConfigData
     * @param {type} actorType
     * @returns {us-config_L6.UsConfig}
     */
    frk.ftnConfig.setData = function (usConfigData, actorType) {
        this.data = usConfigData;
        this.actor = actorType;
    };

    frk.ftnConfig.getData = function () {
        return this.data;
    };

})(frk);
