/** 
 * Cart/Details
 * 
 */
;
(function ($, document) {

    // Create the defaults once 
    var componentName = 'cartDetails',
            defaults = {
                cartDataUrl: '/forms-literature/getCartDetails'
            };

    // The actual plugin constructor 
    function Component(element, options) {
        this.element = element;

        this.options = $.extend({}, defaults, options);

        this._defaults = defaults;
        this._name = componentName;

        this.cartList = $(document).find('[data-fti-component="cart-list"]');

        this.init();
    }

    // Component methods 
    Component.prototype = {
        init: function () {
            // when add to cart button is clicked
            var _self = this;
            // try to get data
            this.getCartData().then(function (data) {
                // GOT the data
                console.log($(_self.cartList));
                $(_self.cartList).trigger({
                    type: 'addItem',
                    litData: data
                });
            }, function (errorMeassage) {
                // FAILED...
                console.log(errorMeassage);
            });
        },
        getCartData: function () {
            var dfd = $.Deferred();

            $.ajax({
                url: this.options.cartDataUrl,
                dataType: 'json',
                type: 'GET'
            }).done(function (data) {
                console.log("getCartData success");
                dfd.resolve(data);
            }).fail(function () {
                console.log("getCartData failed");
                dfd.reject('Unable to load data');
            });
            return dfd.promise();
        }
    };

    $.fn[componentName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'component_' + componentName)) {
                $.data(this, 'component_' + componentName,
                        new Component(this, options));
            }
        });
    };

    // markup hook
    $(document).find('[data-fti-cart="cartdetails"]').each(function () {
        $(this).cartDetails();
    });

})(jQuery, document);