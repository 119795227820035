/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

(function ($, document, frk) {
    // Create the defaults once 
    var componentName = 'tableSorterExt',
            defaults = {
            };

    function Component(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = componentName;
        this.fundFilter = $(this.element).find("#asset-class");
        this.fundSearch = $(this.element).find("#fund-srch");
        this.tableRows = $(this.element).find('#fund-commentaries-table tbody tr');
        this.btnToggleColumn = $(this.element).find('[data-quarterly]');
        this.fundCommentariesTable = $(this.element).find('[data-table-sorter]');
        this.init();
    }

    Component.prototype = {
        init: function () {
            
            var _self = this;
            $(this.fundSearch).keyup(function () {
                var text = $(this).val();
                $(_self.tableRows).each(function () {                
                    if ($(this).attr('data-search').toLowerCase().indexOf(text.toLowerCase()) >= 0) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                });
            });

            $(this.fundFilter).change(function () {
                if ($('option:selected', this).val() !== 'All') {
                    $(_self.tableRows).addClass('hidden');
                    $('[data-filter="' + $('option:selected', this).val() + '"]').removeClass('hidden');
                } else {
                    $(_self.tableRows).removeClass('hidden');
                }
            });


            var headers = {};
            var sortList = [];
            var i = 0;

            this.btnToggleColumn.click(function () {
	var textButton = $(this).text(); 
	var textMonthly = $(this).attr("data-monthly");
	var textQuarterly = $(this).attr("data-quarterly");
	$(this).text(textButton === textMonthly ? textQuarterly : textMonthly);

	$('.monthly').toggleClass('hidden-xs');
	$('.quarterly').toggleClass('hidden-xs');
    });

            $('th', this.fundCommentariesTable).each(function () {
                if ($(this).attr('aria-sorted') === 'none') {
                    headers[i] = {
                        sorter: false
                    };
                } else if ($(this).attr('aria-sorted') === 'descending') {
                    sortList.push([i, 1]);
                } else if ($(this).attr('aria-sorted') === 'ascending') {
                    sortList.push([i, 0]);
                }
                i++;
            });

            this.fundCommentariesTable.tablesorter({
                sortList: sortList,
                headers: headers
            });

            $(".radio-controls-pr input").on("click", function () {
                if ($('#closed-end').is(':checked')) {
                    $('#all-closed-end').prop('disabled', false);
                }
                else if ($('#all-pr').is(':checked')) {
                    $('#all-closed-end').prop('disabled', true);
                }
            });

        }
    }

   $.fn[componentName] = function (options) {
        return this.each(function () {
            $.data(this, 'component_' + componentName,
                    new Component(this, options));
        });
    };
 
})(jQuery, document, frk);